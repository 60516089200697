import { tryon_TryonInfo } from "./klothed-api";

export async function tryonSync(tryonID: number, token: string) {
    return new Promise<tryon_TryonInfo>((res, rej) => {
        const adamURL = process.env.REACT_APP_API_URL;
        if (!adamURL) {
            rej();
            return;
        }
        const webSocketURL = `ws${adamURL.slice(4)}/secure/tryon/ws?token=${token}`;
        const socket = new WebSocket(webSocketURL);
        socket.onopen = () => {
            socket.send(String(tryonID));
        };
        socket.onmessage = (event) => {
            const wsResponse = JSON.parse(event.data);
            if (wsResponse.status === "processing") {
                socket.send(String(wsResponse.id));
            } else {
                // finished
                res(wsResponse);
                socket.close(1000, "exhausted requests");
            }
        };
        socket.onclose = (event) => {
            if (!event.wasClean) {
                rej(event);
            }
        };

        socket.onerror = (error) => {
            console.error(error);
        };
    });
}
