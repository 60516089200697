import classNames from "classnames";
import React from "react";

type Props = {
    children: React.ReactNode;
    className?: string;
};

export const ColumnBoxesText: React.FC<Props> = ({ children, className }) => (
    <div className={classNames("column-box column-box__text", className)}>{children}</div>
);
