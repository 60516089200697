import React, { useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

/**
 * Internal dependencies.
 */
import { ReactComponent as Ralph } from "@v2/assets/logoSlider/logo-ralph.svg";
import { ReactComponent as Forever } from "@v2/assets/logoSlider/logo-forever.svg";
import { ReactComponent as Nike } from "@v2/assets/logoSlider/logo-nike.svg";
import { ReactComponent as Lululemon } from "@v2/assets/logoSlider/logo-lululemon.svg";
import { ReactComponent as Intermix } from "@v2/assets/logoSlider/logo-intermix.svg";
// import { ReactComponent as Brooks } from "@v2/assets/logoSlider/logo-brooks.svg";
// import { ReactComponent as Revolve } from "@v2/assets/logoSlider/logo-revolve.svg";
import { ReactComponent as Under } from "@v2/assets/logoSlider/logo-under.svg";
import { ReactComponent as Everlane } from "@v2/assets/logoSlider/logo-everlane.svg";
import { ReactComponent as Faherty } from "@v2/assets/logoSlider/logo-faherty.svg";
// import { ReactComponent as JCrew } from "@v2/assets/logoSlider/logo-jcrew.svg";
// import { ReactComponent as Kate } from "@v2/assets/logoSlider/logo-kate.svg";
import { ReactComponent as Neiman } from "@v2/assets/logoSlider/logo-neiman.svg";
import Saks from "@v2/assets/logoSlider/logo-saks.webp";
// import { ReactComponent as Macys } from "@v2/assets/logoSlider/logo-macys.svg";
// import { ReactComponent as Lane } from "@v2/assets/logoSlider/logo-lane.svg";
// import { ReactComponent as Gap } from "@v2/assets/logoSlider/logo-gap.svg";
// import { ReactComponent as Shopbop } from "@v2/assets/logoSlider/logo-shopbop.svg";
import { ReactComponent as Splendid } from "@v2/assets/logoSlider/logo-splendid.svg";
// import { ReactComponent as Lord } from "@v2/assets/logoSlider/logo-lord.svg";
// import { ReactComponent as GoodAmerica } from "@v2/assets/logoSlider/logo-good-america.svg";
// import { ReactComponent as NetAPorter } from "@v2/assets/logoSlider/logo-net-a-porter.svg";
// import { ReactComponent as HM } from "@v2/assets/logoSlider/logo-h-m.svg";
// import { ReactComponent as Veronica } from "@v2/assets/logoSlider/logo-veronica.svg";
// import Lafayette from "@v2/assets/logoSlider/logo-lafayette.webp";
// import Dia from "@v2/assets/logoSlider/logo-dia.webp";
// import FreePeople from "@v2/assets/logoSlider/logo-free-people.webp";
import ClubMonaco from "@v2/assets/logoSlider/logo-club-monaco.webp";
// import Universal from "@v2/assets/logoSlider/logo-universal.webp";
import { ReactComponent as Bergdorf } from "@v2/assets/logoSlider/logo-bergdore.svg";

import Hudson from "@v2/assets/logoSlider/logo-hudson.webp";
import Joes from "@v2/assets/logoSlider/logo-joes.webp";
import Robert from "@v2/assets/logoSlider/logo-robertgraham.webp";

import "./logoSlider.scss";

type LogoProp = {
    logo: React.ReactNode;
};

type SlidesProp = LogoProp[];

const slides: SlidesProp = [
    {
        logo: <Nike />,
    },
    {
        logo: <Forever />,
    },
    {
        logo: <Ralph />,
    },
    {
        logo: <Lululemon />,
    },
    {
        logo: <Intermix />,
    },
    {
        logo: <img src={Robert} width={178} height={56} />,
    },
    {
        logo: <Under />,
    },
    {
        logo: <Everlane />,
    },
    {
        logo: <Faherty />,
    },
    {
        logo: <img src={Joes} width={178} height={56} />,
    },
    {
        logo: <img src={Hudson} width={178} height={56} />,
    },
    {
        logo: <Neiman />,
    },
    {
        logo: <img src={Saks} width={178} height={56} />,
    },
    {
        logo: <Splendid />,
    },
    {
        logo: <Bergdorf />,
    },
    {
        logo: <img src={ClubMonaco} width={178} height={11} />,
    },
];

export const LogoSlider = () => {
    const autoplay = useRef(Autoplay({ delay: 1500, stopOnInteraction: false }));
    const [viewportRef] = useEmblaCarousel({ axis: "y", loop: true }, [autoplay.current]);
    return (
        <div className="logo-wrapper">
            <div className="logo__viewport" ref={viewportRef}>
                <div className="logo__container">
                    {slides.map((item: LogoProp, index: number) => (
                        <div className="logo__slide" key={index}>
                            <div className="logo__item">{item.logo}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
