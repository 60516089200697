import React from "react";
import UserSignUp from "@v2/components/signUp";

const SignUpPage = () => {
    return (
        <div className="user_signup">
            <UserSignUp />
        </div>
    );
};

export default SignUpPage;
