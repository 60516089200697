import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { product_CatalogItemResponse, share_CreateShareResponse, tryon_TryonInfo } from "./klothed-api";
import { AuthState, useSiteSession } from "./session";
import { useNavigate } from "react-router-dom";
import { Id, toast } from "react-toastify";

export function TileContextProvider({
    item,
    hideDeletedTryonTiles,
    hideReportedTryonTiles,
    hideUnfavoredTryonTiles,
    autoTryon,
}: {
    item: product_CatalogItemResponse;
    hideDeletedTryonTiles?: boolean;
    hideReportedTryonTiles?: boolean;
    hideUnfavoredTryonTiles?: boolean;
    autoTryon?: boolean;
}) {
    const { token, adam, retailers, authState, updateFavorites, updateCloset, pushTryon, isPopup, filterSortEnabled } = useSiteSession();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showTryon, setShowTryon] = useState(false);
    const [isHidden, setHidden] = useState(false);
    const [tryonInProgress, setTryonInProgress] = useState(false);
    const [tryonInQueue, setTryonInQueue] = useState(false);
    const [tryonState, setTryonState] = useState<tryon_TryonInfo>();
    const [tryonFailed, setTryonFailed] = useState(false);
    const [hideOverlay, setHideOverlay] = useState(false);
    const deletedThisSession = useRef(false);
    const [tile, setTile] = useState<product_CatalogItemResponse>(item);
    useEffect(() => {
        setHideOverlay(false);
        if (item) {
            setTile(item);
            setHidden(false);
        } else {
            setLoading(true);
        }
    }, [item, setLoading, setHidden]);

    useEffect(() => {
        if (isHidden) {
            isPopup.current = false;
        }
    }, [isHidden]);

    useEffect(() => {
        const hasTryon = token && tile.id && tile.id > 0;
        if (
            !hasTryon &&
            autoTryon &&
            !deletedThisSession.current &&
            authState === AuthState.TryonProfile &&
            process.env.REACT_APP_AUTO_TRYON_ENABLE &&
            !filterSortEnabled
        ) {
            toggleTryonImage();
        } else {
            setShowTryon(!!hasTryon);
        }
        setLoading(false);
    }, [tile, authState, setShowTryon, setLoading, retailers, token, filterSortEnabled]);

    const setFav = useCallback(
        async (tryon_id?: number) => {
            if (tryon_id) {
                const res = await adam?.secureFavoritesTryonPost({
                    info: {
                        tryon_id,
                    },
                });
                setTile((tile) => ({ ...tile, favorites_id: res.id }));
            }
        },
        [adam, setTile]
    );

    const deleteFav = useCallback(
        async (favorites_id?: number) => {
            if (favorites_id && favorites_id > 0) {
                // Remove favorite
                await adam?.secureFavoritesFavoriteIdDelete({
                    favorite_id: favorites_id,
                });
                if (hideUnfavoredTryonTiles) {
                    setHidden(true);
                } else {
                    setTile((tile) => ({ ...tile, favorites_id: undefined }));
                }
            }
        },
        [adam, setTile, hideUnfavoredTryonTiles]
    );

    const toggleFav = useCallback(async () => {
        if (tile && tile.favorites_id && tile.favorites_id > 0) {
            await deleteFav(tile.favorites_id);
            updateFavorites();
            updateCloset();
        } else if (tile && tile.id) {
            await setFav(tile.id);
            updateFavorites();
            updateCloset();
        }
    }, [setFav, deleteFav, tile, updateFavorites, updateCloset]);

    const shareTryon = useCallback(async () => {
        if (!tile || !tile.id) return;
        const s: share_CreateShareResponse = await adam?.secureShareTryonIdPost({
            tryon_id: tile.id,
        });
        // safari fix in a timeout, async functions cannot call window.open on safari
        setTimeout(() => {
            window.open(`${process.env.REACT_APP_WEB_URL}/s/${s.uid}`, "_blank");
        });
    }, [adam, tile]);

    const deleteTryon = useCallback(async () => {
        deletedThisSession.current = true;
        if (!tile || !tile.id) return;
        try {
            await adam?.secureTryonDelete({
                tryon_id: [tile.id],
            });
            if (hideDeletedTryonTiles) {
                setHidden(true);
            } else {
                setTile((tile) => ({ ...tile, favorites_id: undefined, id: undefined }));
            }
        } catch (err) {
            console.error(err);
        }
    }, [adam, hideDeletedTryonTiles, setShowTryon, setHidden]);
    const reportToastID = useRef<Id>();

    const reportTryon = useCallback(async () => {
        if (!tile || !tile.id || !adam) return;
        try {
            const reportPromise = adam.secureReportPost({
                info: {
                    tryon_id: tile.id,
                    image_id: tile.image_id,
                    category: "report-tryon-rack",
                    info: {
                        text: "TODO: Rack reports don't have user input yet",
                        selection: "rack",
                        url: tile.product_url,
                    },
                },
            });
            reportToastID.current = toast.loading("Reporting...");
            try {
                await reportPromise;
                toast.update(reportToastID.current, { render: "Thanks for reporting!", isLoading: false, type: "success", autoClose: 5000 });
            } catch (err) {
                console.error(err);
                toast.update(reportToastID.current, { render: "Failed to report. Please try again later.", isLoading: false, type: "error", autoClose: 5000 });
            }
            if (hideReportedTryonTiles) setHidden(true);
        } catch (err) {
            console.error(err);
        }
    }, [adam, tile]);

    const isFav = !!(tile.favorites_id && tile.favorites_id > 0);

    useEffect(() => {
        if (!tryonState) {
            return;
        }
        setTryonInQueue(false);
        if (tryonState.status === "failure") {
            // display error somewhere
            console.error("tryon failed");
            setTryonFailed(true);
            setTryonInProgress(false);
        } else if (tryonState.status === "success") {
            setTryonFailed(false);
            setTryonInProgress(false);
            setShowTryon(true);
            setTile((t) => ({
                ...t,
                id: tryonState.id,
            }));
        } else {
            setTryonInProgress(true);
            setShowTryon(true);
        }
    }, [tryonState, setTryonInProgress, setShowTryon, setTile, setTryonFailed, setTryonInQueue]);

    const toggleTryonImage = useCallback(() => {
        if (authState === AuthState.TryonProfile) {
            if (!tile.id && !tryonInQueue && !tryonInProgress) {
                setTryonInQueue((t) => {
                    if (!t) {
                        pushTryon({
                            product: item,
                            setTryonState,
                        });
                    }
                    return true;
                });
                setShowTryon(true);
            } else if (tryonInQueue || tryonInProgress) {
                // TODO: should display disabled
                setShowTryon(true);
            } else {
                setTryonInQueue(false);
                setShowTryon((s) => !s);
            }
        } else if (authState === AuthState.NoUserAccount) {
            navigate(process.env.REACT_APP_WEB_LOGIN2 || "#");
        } else if (authState === AuthState.NoTryOnProfile) {
            navigate(process.env.REACT_APP_WEB_SHOPPER_PHOTO || "#");
        } else {
            console.error("failed to trigger tryon");
        }
    }, [tryonState, setShowTryon, tile, pushTryon, setTryonState, setTryonInQueue, tryonInQueue, tryonInProgress]);

    return {
        loading,
        context: {
            isFav,
            setFav,
            toggleTryonImage,
            showTryon,
            tile,
            setHidden,
            isHidden,
            setShowTryon,
            toggleFav,
            shareTryon,
            deleteTryon,
            reportTryon,
            setTile,
            tryonInProgress,
            tryonFailed,
            tryonInQueue,
            hideOverlay,
            setHideOverlay,
        },
    };
}

export interface TileContextInterface {
    showTryon: boolean;
    setShowTryon: (show: boolean) => void;
    toggleTryonImage: () => void;
    isHidden: boolean;
    setHidden: (hide: boolean) => void;
    isFav: boolean;
    toggleFav: () => Promise<void>;
    shareTryon: () => Promise<void>;
    deleteTryon: () => Promise<void>;
    reportTryon: () => Promise<void>;
    tile: product_CatalogItemResponse;
    setTile: (tile: product_CatalogItemResponse) => void;
    // setTryonInProgress: (inProgress: boolean) => void;
    tryonInQueue: boolean;
    tryonInProgress: boolean;
    tryonFailed: boolean;
    hideOverlay: boolean;
    setHideOverlay: Dispatch<SetStateAction<boolean>>;
}

export const TileContext = createContext<{
    loading: boolean;
    context: TileContextInterface;
}>({
    loading: true,
    context: {} as TileContextInterface,
});

export const useTileSession = () => useContext(TileContext);
