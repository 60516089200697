import React, { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "@v1/../firebase-config";
import "./settings.scss";
import { ReactComponent as Notification } from "./assets/notification.svg";
import { ReactComponent as NotificationSelected } from "./assets/notificationSelected.svg";
import { ReactComponent as T_and_c } from "./assets/t&c.svg";
import { ReactComponent as T_and_cSelected } from "./assets/t&CSelected.svg";
import { ReactComponent as PrivacyPolicy } from "./assets/privacyPolicy.svg";
import { ReactComponent as PrivacyPolicySelected } from "./assets/privacyPolicySelected.svg";
import { ReactComponent as Help } from "./assets/help.svg";
import { ReactComponent as HelpSelected } from "./assets/helpSelected.svg";
import { ReactComponent as DeleteAcc } from "./assets/deleteAcc.svg";
import { ReactComponent as DeleteAccSelected } from "./assets/deleteAccSelected.svg";
import { ReactComponent as Mail } from "./assets/mail.svg";
import { ReactComponent as Instagram } from "./assets/instagram.svg";
import { ReactComponent as Facebook } from "./assets/facebook.svg";
import { ReactComponent as Twitter } from "./assets/twitter.svg";
import Legal from "../legal/legal";
import { RightPane } from "../loginAndSignUp/loginAndSignUp";
import { ReactComponent as CloseSvg } from "../createProfile/assets/close.svg";
import { klothedAPI } from "../services";
import { ReactComponent as Eye } from "../loginAndSignUp/assets/eye.svg";
import isStrongPassword from "validator/lib/isStrongPassword";
import HelpPage from "../helpPage/help";
import { ReactComponent as SettingsIcon } from "../home/assets/settings.svg";

enum SettingsMenu {
    Notifications = 0,
    TermsAndCondition = 1,
    PrivacyPolicy = 2,
    Help = 3,
    DeleteMyAccount = 4,
    Default = 5,
}

interface SettingsProps {
    deleteAccountCallback: () => void;
}

interface SettingsProfileDataItem {
    icon: React.FunctionComponent;
    iconSelected: React.FunctionComponent;
    text: string;
}

function Settings(props: SettingsProps) {
    const [settingsMenu, setSettingMenu] = useState<SettingsMenu>(SettingsMenu.Notifications);
    const [passwordCurrent, setPasswordCurrent] = useState("");
    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
    const [notificationKnobOne, setNotificationKnobOne] = useState(false);
    const [notificationKnobTwo, setNotificationKnobTwo] = useState(false);
    const [notificationKnobThree, setNotificationKnobThree] = useState(false);

    const settingsProfileData: SettingsProfileDataItem[] = [
        { icon: Notification, iconSelected: NotificationSelected, text: "Notifications" },
        { icon: T_and_c, iconSelected: T_and_cSelected, text: "Terms of Service" },
        { icon: PrivacyPolicy, iconSelected: PrivacyPolicySelected, text: "Privacy Policy" },
        { icon: Help, iconSelected: HelpSelected, text: "Help" },
        { icon: DeleteAcc, iconSelected: DeleteAccSelected, text: "Delete my account" },
    ];

    useEffect(() => {
        setPasswordCurrent("");
        setShowPasswordCurrent(false);
    }, [settingsMenu]);

    const iconGrp = [<Mail key={0} />, <Instagram key={1} />, <Facebook key={2} />, <Twitter key={4} />];

    const onClickLogOut = () => {
        sessionStorage.removeItem("shopperID");
        sessionStorage.removeItem("accessToken");
        signOut(auth);
    };

    const onClickDeleteAccount = () => {
        if (passwordCurrent) {
            klothedAPI.secureShopperDelete().then(() => {
                onClickLogOut();
                props.deleteAccountCallback();
            });
        }
    };

    return (
        <div className="settingsHomePage">
            <div className="settingsHomePage_headerText">{settingsProfileData?.[settingsMenu]?.text}</div>
            {settingsMenu === SettingsMenu.DeleteMyAccount && <div onClick={() => setSettingMenu(SettingsMenu.Default)} className="delete_overlay"></div>}
            {settingsMenu === SettingsMenu.DeleteMyAccount && (
                <div className="delete_account">
                    <div onClick={() => setSettingMenu(SettingsMenu.Default)} className="delete_account_closeDiv">
                        <CloseSvg className="delete_account_closeImg" />
                    </div>
                    <div className="delete_account_text">{"Delete your Klothed account?"}</div>
                    <div className="password_input_div">
                        <input
                            className={`password_input_box ${showPasswordCurrent ? "showPassword" : "hidePassword"}`}
                            placeholder=" "
                            type={showPasswordCurrent ? "text" : "password"}
                            value={passwordCurrent}
                            onChange={(event) => {
                                setPasswordCurrent(event.target.value);
                            }}
                        />
                        {/* PASSWORD CURRENT */}
                        <label className={`password_input_label`}>{"Current Password"}</label>
                        <Eye className="showPasswordIMG" onClick={() => setShowPasswordCurrent(!showPasswordCurrent)} />
                    </div>
                    <div className="delete_account_BtnDiv">
                        <div
                            onClick={() => onClickDeleteAccount()}
                            className={`delete_account_Btn delete_account_BtnYes ${isStrongPassword(passwordCurrent) ? "" : "delete_account_BtnInactive"}`}
                        >
                            {"Yes, delete it"}
                        </div>
                        <div onClick={() => setSettingMenu(SettingsMenu.Default)} className="delete_account_Btn delete_account_BtnNo">
                            {"No, Keep It"}
                        </div>
                    </div>
                </div>
            )}
            {settingsMenu !== SettingsMenu.Help && (
                <div className="settingsHomePage_leftPane">
                    <div className="settingsHomePage_leftPaneFixed">
                        {settingsProfileData.map((item, index) => (
                            <div onClick={() => setSettingMenu(index)} className={`settingsHomePage_leftPane_IconGrp`} key={index}>
                                <div
                                    className={`settingsHomePage_leftPane_IconDiv ${settingsMenu === index ? "settingsHomePage_leftPane_IconDivSelected" : ""}`}
                                >
                                    {settingsMenu === index ? <item.iconSelected /> : <item.icon />}
                                </div>
                                <div className="settingsHomePage_leftPane_IconValue">{item.text}</div>
                            </div>
                        ))}
                        <div className="settingsHomePage_leftPane_horizontalSplit settingsHomePage_leftPane_horizontalSplitTop"></div>
                        <div className="settingsHomePageIconGrp">{iconGrp.map((item) => item)}</div>
                        <div className="settingsHomePage_leftPane_horizontalSplit settingsHomePage_leftPane_horizontalSplitBottom"></div>
                        <div onClick={() => onClickLogOut()} className="logOutBtn">
                            {"LOG OUT"}
                        </div>
                    </div>
                </div>
            )}
            {settingsMenu !== SettingsMenu.Help && (
                <div className="settingsHomePage_rightPane">
                    <div className="blackbox"></div>
                    {settingsMenu === SettingsMenu.TermsAndCondition && <Legal legal={RightPane.TermsOfService} className="settingsLegal"></Legal>}
                    {settingsMenu === SettingsMenu.PrivacyPolicy && <Legal legal={RightPane.PrivacyPolicy} className="settingsLegal"></Legal>}
                    {settingsMenu === SettingsMenu.Notifications && (
                        <div className="notificationsClass">
                            <div className="notificationEmailHeading">{"Emails"}</div>
                            <div className="notificationEmailHeading1">{"News and Update Emails"}</div>
                            <div
                                onClick={() => setNotificationKnobOne(!notificationKnobOne)}
                                className={`toggleKnob ${notificationKnobOne ? "toggleKnobActive" : "toggleKnobDeactive"}`}
                            >
                                <div className="toggleKnobSwitch"></div>
                            </div>
                            <div className="notificationEmailHeading2">{"Learn about new tools and features."}</div>
                            <div className="horizontalSep"></div>
                            <div className="notificationEmailHeading3">{"Reminder Emails"}</div>
                            <div
                                onClick={() => setNotificationKnobTwo(!notificationKnobTwo)}
                                className={`toggleKnob ${notificationKnobTwo ? "toggleKnobActive" : "toggleKnobDeactive"}`}
                            >
                                <div className="toggleKnobSwitch"></div>
                            </div>
                            <div className="notificationEmailHeading4">
                                {"Get emailed about product try-ons you might want to revisit, share, purchase, and more."}
                            </div>
                            <div className="horizontalSep"></div>
                            <div className="notificationEmailHeading5">{"Push Notifications"}</div>
                            <div
                                onClick={() => setNotificationKnobThree(!notificationKnobThree)}
                                className={`toggleKnob ${notificationKnobThree ? "toggleKnobActive" : "toggleKnobDeactive"}`}
                            >
                                <div className="toggleKnobSwitch"></div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {settingsMenu === SettingsMenu.Help && <HelpPage className={"settingsHelp"} />}
            {settingsMenu === SettingsMenu.Help && <div className="blackboxHelp"></div>}
            {settingsMenu === SettingsMenu.Help && (
                <div onClick={() => setSettingMenu(SettingsMenu.Notifications)} className="goToSettings">
                    {"Settings Menu"}
                    <SettingsIcon />
                </div>
            )}
        </div>
    );
}

export default Settings;
