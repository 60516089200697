import { useSiteSession } from "@v2/utils";
import React, { useEffect } from "react";

export function CookiePage() {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Cookie policy.`);
        setPageDescription(`Klothed - Everything looks better on you. - Cookie policy.`);
    }, [setPageTitle, setPageDescription]);

    return (
        <div className="policy-page">
            <p>How we use cookies and similar technologies</p>
            <p>
                Like most other online services, Klothed uses cookies, pixel tags, web beacons, and other markers (we&#39;ll just call them &quot;cookies&quot;)
                to collect some of the data discussed in this Privacy Policy. These help us operate our services, analyze engagement and usage, and provide a
                relevant and personalized shopping experience and determine when a commission has been earned. See below to learn how you can manage your cookie
                preferences.
            </p>
            <p>
                Some cookies can be temporary (&quot;session cookies&quot; like those used for navigating your browser) and disappear once you close it. Others
                last longer (&quot;persistent cookies,&quot; like when you ask a site to remember your login) and are saved to your computer until you delete
                them.
            </p>
            <p>Our uses of cookies and similar technologies fall into the following general categories:</p>
            <ol>
                <li>
                    <p>
                        Essential: We may use cookies, web beacons, or other similar technologies that are necessary to the operation of our sites, services,
                        applications, and tools. This includes technologies that allow you access to our sites, services, applications, and tools; that are
                        required to identify irregular site behavior, prevent fraudulent activity and improve security; or that allow you to make use of
                        functions we may provide such as saved search, or other functions;
                    </p>
                </li>
                <li>
                    <p>
                        Performance: We may use cookies, web beacons, or other similar technologies to assess the performance of our websites, applications,
                        services, and tools, including as part of our analytic practices to help us understand how our visitors use our websites, determine if
                        you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our website content,
                        applications, services, or tools;
                    </p>
                </li>
                <li>
                    <p>
                        Functionality: We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when
                        accessing or using our sites, services, applications, or tools. This may include identifying you when you sign into our sites or keeping
                        track of your specific preferences, interests, or past items viewed so that we may enhance the presentation of content on our sites;
                    </p>
                </li>
                <li>
                    <p>
                        Marketing: We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests,
                        on our sites or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and
                        content that have been delivered to you, such as whether you have clicked on an advertisement.
                    </p>
                </li>
            </ol>
            <p>
                Any personal information that we collect and store through use of these technologies are based on your consent, obtained through a conspicuous
                disclosure on our website during your first visit. If you want to disable cookies entirely, your browser or mobile device might have an option
                to do that. For more information, including instructions on disabling cookies, please visit:{" "}
                <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a> However, this may affect your ability to browse this Website.
            </p>
        </div>
    );
}
