import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./help.scss";
import { ReactComponent as KlothedLogo } from "../assets/logo-beta.svg";
import RightSlider from "../rightSlider/rightSlider";
import { QAPair } from "./helpQAPair";
import Footer from "../components/footer/footer";

interface HelpProps {
    className?: string;
}
function Help(props: HelpProps) {
    const [showRightSlider, setShowRightSlider] = useState<boolean>(false);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [window.location.pathname, showRightSlider]);

    return (
        <div className={`help_page ${props.className}`}>
            <Link to={process.env.REACT_APP_WEB_RACKS_HOME || "#"}>
                <KlothedLogo className="klothed_logo"></KlothedLogo>
            </Link>
            {!showRightSlider && (
                <div className="rightSliderLine">
                    <div onClick={() => setShowRightSlider(true)} className="rightSliderLine__">
                        <div className="rightSliderLine_"></div>
                        <div className="rightSliderLine_"></div>
                    </div>
                </div>
            )}
            {showRightSlider && <div className="rightSliderOverlay"></div>}
            {showRightSlider && <RightSlider closeAct={() => setShowRightSlider(false)}></RightSlider>}

            <div className="help_left_section">
                <div className="leftFixedSection">
                    <div className="leftHeading">{"How can we help?"}</div>
                    <div className="leftHeadingTwo">
                        We want you to love getting Klothed every day. Email us at{" "}
                        <a className="orangeText" href="mailto:help@getklothed.com">
                            {"help@getklothed.com"}
                        </a>{" "}
                        if you need help or aren’t satisfied with something.
                    </div>
                </div>
            </div>
            <div className="help_right_section">
                <div className="leftTop_heading">{"HELP"}</div>
                <div className="horizonta_seperator_legal"></div>
                <div className="fewFAQ">{"A few FAQs..."}</div>
                <div className="qa_pairs">
                    {QAPair.map((pair, index) => (
                        <div key={index} className={"qa_pair"}>
                            <div className="pair_question">{pair.question}</div>
                            <div className="pair_answer">{pair.answer()}</div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Help;
