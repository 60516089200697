import React from "react";
import { AuthState, auth, useSiteSession } from "@v2/utils";
import { signOut } from "firebase/auth";
import { Menu, Item } from "react-contexify";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

export const MainMenuID = "main_menu";
export function MainMenu() {
    const { authState, favoriteProducts, closetProducts } = useSiteSession();
    const hasFavorites = favoriteProducts?.count && favoriteProducts.count > 0;
    const hasCloset = closetProducts?.count && closetProducts.count > 0;
    const history = createBrowserHistory();

    return (
        <Menu id={MainMenuID} theme="klothed-menu">
            <Item className="title">
                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_RACKS_HOME || "#" }}>Try it on.</Link>
            </Item>
            {authState === AuthState.NoUserAccount && (
                <>
                    <Item>
                        <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_SIGNUP2 || "#" }}>Sign up.</Link>
                    </Item>
                    <Item>
                        <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_LOGIN2 || "#" }}>Log in.</Link>
                    </Item>
                </>
            )}
            {authState > AuthState.NoUserAccount && (
                <>
                    <Item disabled={authState !== AuthState.TryonProfile}>
                        <Link
                            to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_FAVORITES_HOME || "#" }}
                            className={`${!hasFavorites && "disabled"}`}
                        >
                            Your loves.
                        </Link>
                    </Item>
                    <Item disabled={authState !== AuthState.TryonProfile}>
                        <Link
                            to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_CLOSET_HOME || "#" }}
                            className={`${!hasCloset && "disabled"}`}
                        >
                            Your latest looks.
                        </Link>
                    </Item>
                </>
            )}
            {authState > AuthState.NoUserAccount && (
                <Item>
                    <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_SHOPPER || "#" }}>Your profile.</Link>
                </Item>
            )}
            <Item>
                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_ABOUT_US || "#" }}>About.</Link>
            </Item>
            <Item>
                <a href={process.env.REACT_APP_WEB_BLOG_HOME || "#"}>Blog.</a>
            </Item>
            <Item
                onClick={(event) => {
                    event.props.policies();
                }}
                closeOnClick={false}
            >
                Policies.
            </Item>
            <Item>
                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_HELP || "#" }}>Help.</Link>
            </Item>
            {authState > AuthState.NoUserAccount && (
                <Item
                    onClick={() => {
                        signOut(auth);
                    }}
                >
                    Log out
                </Item>
            )}
        </Menu>
    );
}
