/**
 * External dependencies.
 */
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

/**
 * Internal dependencies.
 */
import { Container } from "../container";
import Phone1 from "@v2/assets/imageProcess/phone1.webp";
import Phone2 from "@v2/assets/imageProcess/phone2.webp";
import Phone3 from "@v2/assets/imageProcess/phone3.webp";

import "./imageProgress.scss";

export const ImageProgress = () => {
    const TIMER = 1300;
    const stepsArray = [Phone1, Phone2, Phone3];
    const timeout = useRef<NodeJS.Timer | null>(null);
    const [active, setActive] = useState(0);

    const handleInterval = useCallback(() => {
        timeout.current = setInterval(() => {
            setActive((old: number) => (old < stepsArray.length - 1 ? old + 1 : 0));
        }, TIMER);
    }, []);

    useEffect(() => {
        handleInterval();
        return () => {
            if (timeout.current) {
                clearInterval(timeout.current);
            }
        };
    }, []);

    return (
        <div className="image-progress">
            <Container className="smaller">
                <div className="image-progress__inner">
                    {stepsArray.map((image: string, index: number) => (
                        <div
                            className={classNames("image-progress__item", {
                                "is-active": active === index,
                            })}
                            key={index}
                        >
                            <div className="image">
                                <img src={image} width={600} height={536} />
                            </div>
                            <div className="progress">
                                <div className="progress__inner"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};
