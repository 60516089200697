import React from "react";
import { QAPair } from "./helpQAPair";

export function HelpComponent() {
    return (
        <div className="help_page_v2">
            <div className="help_left_section">
                <div className="leftFixedSection">
                    <div className="leftHeading">{"How can we help?"}</div>
                    <div className="leftHeadingTwo">
                        We want you to love getting Klothed every day. Email us at{" "}
                        <a className="orangeText" href="mailto:help@getklothed.com">
                            {"help@getklothed.com"}
                        </a>{" "}
                        if you need help or aren’t satisfied with something.
                    </div>
                </div>
            </div>
            <div className="help_right_section">
                <div className="leftTop_heading">{"HELP"}</div>
                <div className="horizontal_separator_legal"></div>
                <div className="fewFAQ">{"A few FAQs..."}</div>
                <div className="qa_pairs">
                    {QAPair.map((pair, index) => (
                        <div key={index} className={"qa_pair"}>
                            <div className="pair_question">{pair.question}</div>
                            <div className="pair_answer">{pair.answer()}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
