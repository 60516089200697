import { useSiteSession } from "@v2/utils";
import React, { useEffect } from "react";

export function PrivacyPage() {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Privacy policy.`);
        setPageDescription(`Klothed - Everything looks better on you. - Privacy policy.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <div className="policy-page">
            <p>KLOTHED PRIVACY POLICY</p>
            <h2 id="last-revised-on-june-15-2023">Last revised on June 15, 2023</h2>
            <h2 id="welcome-summary">Welcome Summary</h2>
            <p>
                This Privacy Policy explains what Klothed is, and how we collect, use, store, protect, and share your personal information obtained in
                connection with our Service which is described in our Terms of Service. It&#39;s important that you read this entire policy, but here&#39;s a
                summary to get you started:
            </p>
            <ul>
                <li>
                    <h3 id="what-is-klothed-klothed-is-ai-for-clothing-try-on-our-klothed-racks-product-is-a-netflix-like-ui-where-shoppers-can-browse-a-curated-set-of-thousands-of-in-season-items-across-major-retailers-e-g-saks-nike-ralph-lauren-and-try-on-every-single-one-the-shopper-gets-a-completely-personalized-style-preview-of-how-they-would-look-wearing-an-item-klothed-racks-is-free-to-shoppers-">
                        What is Klothed? Klothed is AI for clothing try-on. Our Klothed Racks product is a Netflix-like UI where shoppers can browse a curated
                        set of thousands of in-season items across major retailers (e.g. Saks, Nike, Ralph Lauren) and try-on every single one. The shopper gets
                        a completely personalized style preview of how they would look wearing an item. Klothed Racks is free to shoppers.
                    </h3>
                </li>
            </ul>
            <p>
                Shoppers can sign up to use our Klothed Racks and related services at <a href="http://www.getklothed.com">getklothed.com</a>. After signing up,
                you will get full access to browse our Klothed Racks. If you want to try-on items on Klothed Racks, you must complete your profile by uploading
                a photo of yourself and entering your height. Once your profile is complete you may try-on items by clicking the &quot;Try It On&quot; button
                under each product picture. You can share your try-on pictures with friends, family, and followers. To purchase a product, click the &quot;Shop
                Now&quot; button to go to the product page on the retailer site to purchase the product. Your Try-On images are saved in &quot;Your latest
                looks&#39;&#39; Rack for easy reference. We may enable you to create your own Racks (referred to as &quot;Edits&quot;) and to allow others to
                subscribe to your Edits. You in kind will have access to Edits created by others that you may subscribe to.
            </p>
            <p>
                In addition to Klothed Racks, you can also download our iOS Mobile App and Safari Extension from the Apple App Store along with the Chrome
                Desktop Extension from the Chrome Web Store. Use The Extensions to try-on items while browsing any of our covered retailers sites. These
                extensions are free for shoppers to use as well.{" "}
            </p>
            <p>
                We may also allow you to share your Klothed ID with others (friends and family) so they can try styles on you and make style suggestions or to
                buy you a gift. If these features are implemented, only you will be able to grant and revoke this permission.{" "}
            </p>
            <p>
                If you allow Klothed access to your social media account(s) then we promise never to post or share anything without your explicit permission.
                It&#39;s your social media, you&#39;re in charge.{" "}
            </p>
            <p>
                Klothed does not charge you fees for its Service. When you click on the &quot;K&quot; icon to create a try-on image in either extension or when
                you press the &quot;Shop Now&quot; button in Klothed Racks, it will cause a cookie or other affiliate code or link identifying Klothed to be
                placed. This will allow the marketing affiliate network, the retailer, or other entity to associate any purchase you make with Klothed. When you
                then make a purchase or click on a merchant link while using Klothed or someone you share a try-on picture with clicks on a link to the
                merchant, the merchant may pay us a fee either directly or through an affiliate network or aggregator. That is how we make money with your
                personal information. We do not need to (or want to) sell your personal information.
            </p>
            <p>
                Our AI technology is embeddable directly in merchants&#39; sites as well as other applications and mediums using our API. This service is not
                free and requires an additional license. In the future, we anticipate that Third-Parties (persons or entities other than you or Klothed such as
                merchants) may build applications that leverage our Try-On engine to create photorealistic images of our shoppers to show our shoppers how they
                look wearing specific products or to provide other benefits or services derived from the try-on pictures that are relevant to you. For example,
                a clothing retailer might like to show you images of you wearing their latest fashions or a car company might like to show you images where you
                are enjoying the drive in one of their sports cars. Then, with your permission (and only with your permission), we would allow the retailer or
                car company to use Klothed to create a photorealistic picture showing you in their car. This picture will be created using the data obtained
                when you create your account. We will not provide any Third-Party with the picture you uploaded or the data we obtained from the picture. These
                Third-Parties may pay us a fee to create these photorealistic images and make them available to you. We also will not work with any
                Third-Parties that are not building something that might benefit you in some way.
            </p>
            <p>
                We are constantly working to improve our AI, software, and services to make your try-on picture as accurate and fast as possible, and to make
                the Service as easy to use as possible. To help improve picture accuracy and our Service, we review submissions, try-ons, and suggestions made
                by shoppers like you. You understand and acknowledge that any improvements, modifications and changes arising from or in connection with the
                information you convey relating to the Klothed try-on images and/or Services, remain or become the exclusive property of the Klothed.{" "}
            </p>
            <p>
                While we cannot guarantee complete try-on image accuracy or that we will be able to create a try-on picture with every model picture, we believe
                that you will find that our try-on image will provide the utility to build purchase confidence, help you make better style selections while
                reducing your need to make returns, and to boost your online shopping enjoyment.{" "}
            </p>
            <p>
                Users are responsible for their purchase decision including being aware of any limitation in the applicable merchant website return policy.
                Klothed Does Not Warranty Any Purchase By The User Or Make Any Guarantees Regarding Purchased Items Or How Those Items May Fit And Look On The
                User.{" "}
            </p>
            <ul>
                <li>
                    <h3 id="we-don-t-sell-your-personal-information-respecting-user-privacy-is-a-key-part-of-our-mission-we-don-t-sell-your-personal-information-ever-we-use-it-solely-to-provide-our-services-to-you-our-business-model-with-respect-to-use-of-your-personal-information-is-based-on-merchants-paying-us-a-fee-when-you-buy-a-merchant-s-product-after-creating-a-try-on-picture-or-in-some-cases-we-may-have-a-direct-agreement-with-the-merchant-to-pay-us-a-fee-when-a-try-on-picture-is-created-by-you-or-with-your-express-permission-by-a-merchant-">
                        We don&#39;t sell your personal information. Respecting user privacy is a key part of our mission. We don&#39;t sell your personal
                        information, ever. We use it solely to provide our services to you. Our business model with respect to use of your personal information
                        is based on merchants paying us a fee when you buy a merchant&#39;s product after creating a try-on picture or in some cases we may have
                        a direct agreement with the merchant to pay us a fee when a try-on picture is created by you or with your express permission by a
                        merchant.
                    </h3>
                </li>
                <li>
                    <h3 id="we-limit-what-information-is-required-and-collected-we-require-the-information-from-you-that-enables-us-to-create-your-account-and-allow-the-creation-of-try-on-images-we-also-collect-your-shopping-information-necessary-or-helpful-to-provide-and-improve-our-services-and-to-satisfy-our-legal-requirements-any-other-information-requested-is-optional-">
                        We limit what information is required and collected. We require the information from you that enables us to create your account and
                        allow the creation of try-on images. We also collect your shopping information necessary or helpful to provide and improve our services
                        and to satisfy our legal requirements. Any other information requested is optional.
                    </h3>
                </li>
                <li>
                    <h3 id="we-give-you-control-your-try-on-images-are-private-to-you-but-we-give-you-the-ability-to-share-access-to-your-try-on-pictures-with-friends-family-and-social-media-followers-">
                        We give you control. Your try-on images are private to you. But we give you the ability to share access to your try-on pictures with
                        friends, family and social media followers.
                    </h3>
                </li>
            </ul>
            <h2 id="how-we-use-your-account-information-">How We Use Your Account Information. </h2>
            <p>
                We use your picture and height to create a personalized 3D body model that our AI uses to enable you to virtually try-on clothing across
                supported merchant product model images. You can think of this as your personalized &quot;Digital Try-On ID&quot;.
            </p>
            <p>
                When you visit our Klothed Racks and desire to see what you would look like wearing an item worn by a product model on one of the Racks, we will
                use your Digital Try-On ID to create a try-on image of you wearing that clothing item. That is how Klothed creates your try-on image. The try-on
                images are added to your &quot;Latest Looks&quot; for future reference and comparison.Where the feature is enabled, a try-on image may also be
                created by a merchant, to whom you have given express permission, to use our API to use Klothed to create a try-on images using the
                merchant&#39;s clothes (as described in more detail below).
            </p>
            <p>
                Our goal is to give you a better sense of how you would look in the specific style that the product model is wearing. Klothed is designed to
                help you make a more informed purchase decision. Ultimately, we hope this leads you to feel more comfortable buying a product online and more
                likely to be delighted with the item once it arrives.
            </p>
            <p>
                When you make a purchase after you create a try-on picture, the merchant, often through an affiliate network or network aggregator, sometimes
                pays us a fee. Or, as described in more detail below, we may be paid a fee by a merchant if you give the merchant permission to use Klothed to
                create try-on pictures of you in the merchant&#39;s fashions. That&#39;s how we make money. We do not need to (nor want to) sell your personal
                information.
            </p>
            <h2 id="the-information-we-collect">The information we collect</h2>
            <p>###</p>
            <h3 id="account-information-obtained-from-you-and-use-when-you-create-a-klothed-account-you-create-a-username-and-password-and-provide-us-with-your-email-address-so-that-we-can-contact-you-you-may-also-provide-us-with-a-picture-of-yourself-and-your-height-we-may-ask-for-other-optional-information-to-personalize-your-profile-to-allow-us-to-enhance-our-services-to-you-">
                Account information obtained from you and use. When you create a Klothed account, you create a username and password, and provide us with your
                email address so that we can contact you. You may also provide us with a picture of yourself and your height. We may ask for other optional
                information to personalize your profile to allow us to enhance our services to you.
            </h3>
            <p>
                If you choose to create your Klothed account login using a third-party service (e.g. Google, Facebook, etc) or link a social media profile (like
                Facebook) at sign-in, Klothed will store the information to enable these connections for login purposes - we will not store your passwords.
                Linking your social media profile grants Klothed access to the profile, but only as allowed by your permission settings, that platform&#39;s
                terms of service, and this Privacy Policy. We will NOT track any of your social media activity, and we will not post anything on your behalf
                without your explicit permission. We may allow you to post your try-on picture on social media, but you decide when and if to post.
            </p>
            <p>
                If you choose to sign in with Google (or other supported login services), you&#39;ll have the option of importing your contacts so that you can
                refer your friends or family to Klothed or share one or more of your try-on pictures with them. Referral addresses are masked, so we never see
                or store them, nor can we contact anyone without your permission.
            </p>
            <p>
                Over time we will keep track of your shopping history (Items you&#39;ve looked at, tried on, and bought) to enhance your digital shopping
                experience. This experience may be designed to provide you with the ability to review your clothing try-on picture history, put together and
                store outfits, share looks, seek style recommendations, maintain an organized view of your clothing purchase behavior, and much more. We may use
                the information to provide you with shopping suggestions and or new opportunities. In addition, in the future we plan to allow you to grant
                other people and applications access (and to revoke access) to review your try-on images and use your Digital Try-On ID.
            </p>
            <p>As you continue to use Klothed&#39;s services, you might also provide information when you:</p>
            <ul>
                <li>
                    <p>Save try-on pictures; and</p>
                </li>
                <li>
                    <p>Share try-on pictures with others to keep track of who you have shared with.</p>
                </li>
            </ul>
            <p>We will also:</p>
            <ul>
                <li>
                    <p>Create a list of stores you like; and</p>
                </li>
                <li>
                    <p>Communicate with you through email or other means.</p>
                </li>
            </ul>
            <p>
                We may also use the information we collect from you in aggregate or otherwise anonymized form. This lets us look at interactions with our site
                and services generally and does not identify you or any specific person. We use this general data for research, development, marketing,
                analytics, and to enhance the shopping experience for Klothed shoppers.
            </p>
            <h3 id="information-from-actions-you-take-klothed-also-collects-technical-information-about-your-device-and-use-of-klothed-to-make-sure-that-our-services-are-working-correctly-we-may-collect-information-on-the-sites-you-visit-to-improve-our-service-to-you-and-provide-the-most-accurate-and-relevant-results-about-your-shopping-history-the-information-we-collect-will-be-used-to-help-us-provide-you-with-a-better-online-shopping-experience-and-to-help-us-develop-improve-and-market-our-services-">
                Information from actions you take. Klothed also collects technical information about your device and use of Klothed to make sure that our
                services are working correctly. We may collect information on the sites you visit to improve our service to you and provide the most accurate
                and relevant results about your shopping history. The information we collect will be used to help us provide you with a better online shopping
                experience and to help us develop, improve, and market our services.
            </h3>
            <p>
                On retail sites, we may collect limited information regarding your purchases on a retailer site while using Klothed (for example, the order
                total and the fact that an order was completed) for purposes including internal analytics and commission collection from the retailer. We will
                not collect any credit card numbers or personal credit information (PCI). The information you share, and we collect, enables us to run and
                improve our services.
            </p>
            <h2 id="how-we-use-cookies-and-similar-technologies">How we use cookies and similar technologies</h2>
            <p>
                Like most other online services, Klothed uses cookies, pixel tags, web beacons, and other markers (we&#39;ll just call them &quot;cookies&quot;)
                to collect some of the data discussed in this Privacy Policy. These help us operate our services, analyze engagement and usage, and provide a
                relevant and personalized shopping experience and determine when a commission has been earned. See below to learn how you can manage your cookie
                preferences.
            </p>
            <p>
                Some cookies can be temporary (&quot;session cookies&quot; like those used for navigating your browser) and disappear once you close it. Others
                last longer (&quot;persistent cookies,&quot; like when you ask a site to remember your login) and are saved to your computer until you delete
                them.
            </p>
            <p>Our uses of cookies and similar technologies fall into the following general categories:</p>
            <ol>
                <li>
                    <h4 id="essential-we-may-use-cookies-web-beacons-or-other-similar-technologies-that-are-necessary-to-the-operation-of-our-sites-services-applications-and-tools-this-includes-technologies-that-allow-you-access-to-our-sites-services-applications-and-tools-that-are-required-to-identify-irregular-site-behavior-prevent-fraudulent-activity-and-improve-security-or-that-allow-you-to-make-use-of-functions-we-may-provide-such-as-saved-search-or-other-functions-">
                        Essential: We may use cookies, web beacons, or other similar technologies that are necessary to the operation of our sites, services,
                        applications, and tools. This includes technologies that allow you access to our sites, services, applications, and tools; that are
                        required to identify irregular site behavior, prevent fraudulent activity and improve security; or that allow you to make use of
                        functions we may provide such as saved search, or other functions;
                    </h4>
                </li>
                <li>
                    <h4 id="performance-we-may-use-cookies-web-beacons-or-other-similar-technologies-to-assess-the-performance-of-our-websites-applications-services-and-tools-including-as-part-of-our-analytic-practices-to-help-us-understand-how-our-visitors-use-our-websites-determine-if-you-have-interacted-with-our-messaging-determine-whether-you-have-viewed-an-item-or-link-or-to-improve-our-website-content-applications-services-or-tools-">
                        Performance: We may use cookies, web beacons, or other similar technologies to assess the performance of our websites, applications,
                        services, and tools, including as part of our analytic practices to help us understand how our visitors use our websites, determine if
                        you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our website content,
                        applications, services, or tools;
                    </h4>
                </li>
                <li>
                    <h4 id="functionality-we-may-use-cookies-web-beacons-or-other-similar-technologies-that-allow-us-to-offer-you-enhanced-functionality-when-accessing-or-using-our-sites-services-applications-or-tools-this-may-include-identifying-you-when-you-sign-into-our-sites-or-keeping-track-of-your-specific-preferences-interests-or-past-items-viewed-so-that-we-may-enhance-the-presentation-of-content-on-our-sites-">
                        Functionality: We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when
                        accessing or using our sites, services, applications, or tools. This may include identifying you when you sign into our sites or keeping
                        track of your specific preferences, interests, or past items viewed so that we may enhance the presentation of content on our sites;
                    </h4>
                </li>
                <li>
                    <h4 id="marketing-we-may-use-first-party-or-third-party-cookies-and-web-beacons-to-deliver-content-including-ads-relevant-to-your-interests-on-our-sites-or-on-third-party-sites-this-includes-using-technologies-to-understand-the-usefulness-to-you-of-the-advertisements-and-content-that-have-been-delivered-to-you-such-as-whether-you-have-clicked-on-an-advertisement-">
                        Marketing: We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests,
                        on our sites or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and
                        content that have been delivered to you, such as whether you have clicked on an advertisement.
                    </h4>
                </li>
            </ol>
            <p>You can delete your account at any time.</p>
            <h2 id="how-we-share-your-information">How we share your information</h2>
            <p>
                We know how important your personal data is to you, so we will never sell it. We&#39;ll only share it with your consent or in ways you&#39;d
                expect (as we explain in this Privacy Policy).
            </p>
            <p>That means we will share if we are legally required to do so.</p>
            <p>
                We may share information with our service providers (businesses that work for and with us) for the purpose of running Klothed and providing its
                services. These include businesses that perform services on our behalf, including to help us maintain our Services, to provide support for
                legal, banking, security protection, our own marketing, and shopper service. Please know that when we share information with our service
                providers, it&#39;s for the purposes outlined in this Privacy Policy and not for their independent use.
            </p>
            <p>We may also share information in the following cases:</p>
            <ol>
                <li>
                    <h3 id="with-your-express-consent-">with your express consent;</h3>
                </li>
                <li>
                    <h3 id="in-an-aggregate-or-anonymized-format-that-does-not-identify-any-specific-person-">
                        in an aggregate or anonymized format that does not identify any specific person;
                    </h3>
                </li>
                <li>
                    <h3 id="as-required-by-law-or-to-comply-or-respond-to-a-valid-government-request-">
                        as required by law, or to comply or respond to a valid government request;
                    </h3>
                </li>
                <li>
                    <h3 id="when-we-believe-in-good-faith-that-it-s-necessary-to-protect-our-rights-protect-your-safety-or-the-safety-of-others-or-investigate-fraud-and">
                        when we believe in good faith that it&#39;s necessary to protect our rights, protect your safety or the safety of others, or investigate
                        fraud; and
                    </h3>
                </li>
                <li>
                    <h3 id="with-a-buyer-or-successor-if-klothed-is-involved-in-a-merger-acquisition-or-similar-corporate-transaction-if-a-transaction-happens-you-will-be-notified-via-email-and-or-a-prominent-notice-on-the-website-of-any-change-in-ownership-as-well-as-any-choices-you-will-have-as-a-result-">
                        with a buyer or successor if Klothed is involved in a merger, acquisition, or similar corporate transaction. If a transaction happens,
                        you will be notified via email and/or a prominent notice on the Website of any change in ownership, as well as any choices you will have
                        as a result.
                    </h3>
                </li>
            </ol>
            <h2 id="how-we-protect-your-data">How we protect your data</h2>
            <p>
                The security of your information is important to us. Our team is dedicated to protecting your information and have put in place physical,
                electronic, and procedural safeguards.
            </p>
            <p>
                These measures include limiting access, using encryption, testing for vulnerabilities, advanced malware detection, employing pseudonymization
                and anonymization techniques, and more.
            </p>
            <p>
                Though we hate to say it, despite our efforts, we can&#39;t guarantee that user information will not be accessed, viewed, disclosed, altered, or
                destroyed as a result of a breach of any of our safeguards. You provide us with information at your own risk.
            </p>
            <p>
                Klothed retains information about you as long as you maintain your account. If you delete your account, we&#39;ll keep information we need to
                meet our legal obligations and to defend against legal claims.
            </p>
            <h2 id="terminating-your-account-and-managing-emails">Terminating Your Account and Managing Emails</h2>
            <p>
                We&#39;ve made it easy close your Klothed account at any time. Simply login, go to the &quot;SETTINGS&quot; page and click on &quot;Delete my
                account&quot;.\ If you would like to delete your profile or personal information, you can always ask us by emailing{" "}
                <a href="mailto:privacy@getKlothed.com">privacy@getklothed.com</a>. We will delete any personal information we have, although the following
                exceptions apply: (1) we may still store your personal information if we need it to meet our legal or compliance obligations and/or to defend
                against legal claims; and (2) we may also continue to store your personal data in an aggregated and anonymized format that doesn&#39;t identify
                you and can&#39;t be attributed to you, even after you&#39;ve deleted your profile.
            </p>
            <h2 id="additional-rights-regarding-your-data">Additional rights regarding your data</h2>
            <p>
                Depending on where you live, you may have additional rights as to the personal information that you share and Klothed collects.\ California
                residents.
            </p>
            <p>If you live in California and use Klothed, you have some additional rights when it comes to your data.</p>
            <ul>
                <li>
                    <h4 id="right-to-delete-you-can-ask-us-to-delete-the-personal-information-we-have-about-you-">
                        Right to Delete: You can ask us to delete the personal information we have about you.
                    </h4>
                </li>
                <li>
                    <h4 id="right-to-know-you-can-ask-us-for-a-list-of-the-categories-of-personal-information-we-have-about-you-the-categories-of-sources-from-which-we-get-personal-information-the-business-purposes-for-which-we-collect-personal-information-and-the-categories-of-third-parties-with-whom-it-is-shared-you-can-also-see-that-in-this-privacy-policy-you-also-can-ask-us-for-a-copy-of-the-pieces-of-personal-information-we-have-about-you-">
                        Right to Know: You can ask us for a list of the categories of personal information we have about you, the categories of sources from
                        which we get personal information, the business purposes for which we collect personal information, and the categories of third parties
                        with whom it is shared (you can also see that in this Privacy Policy). You also can ask us for a copy of the pieces of personal
                        information we have about you.
                    </h4>
                </li>
            </ul>
            <p>
                California law gives you the right to opt out of the sale of your personal information. But Klothed does not and will not sell (or rent, or
                disclose for value) any of your personal information in the first place. So you&#39;re good.
            </p>
            <p>
                To exercise any of these rights, you (or your authorized agent) can contact us through the &quot;Contact us&quot; webform on our Help page (
                <a href="http://www.getklothed.com/help">getKlothed.com/help</a>) or by sending an email to{" "}
                <a href="mailto:privacy@getKlothed.com">privacy@getklothed.com</a>. We will confirm receipt of your request within 10 days, and will provide a
                response to you within 45 days of your request, though in some exceptional cases it might take longer (if that happens, we&#39;ll let you know).
                We will need your name and email address to verify your request, and may also ask for additional information if necessary to verify the identity
                of the person making the request. We reserve the right to deny your request if we cannot verify your identity, or if an exemption applies (for
                example, where fulfilling your request would adversely affect other individuals, or where we have a conflicting legal obligation). That said, we
                will not discriminate against you for exercising any of your privacy rights.
            </p>
            <p>
                To learn about the categories of personal information that Klothed uses, please see the section of this policy called &quot;What data we collect
                and why.&quot; The personal information that we have collected in the past 12 months falls into the following categories as set out under
                California law:
            </p>
            <ul>
                <li>
                    <p>The contact information you provide -- California law refers to these as identifiers.</p>
                </li>
                <li>
                    <p>
                        If you shop through the Klothed Mobile App, if collected by Klothed, other individual information like billing address, credit or debit
                        card information -- this category includes personal information protected under pre-existing California law (Cal. Civ. Code 1798.80(e)),
                        and overlaps with other categories.
                    </p>
                </li>
                <li>
                    <p>
                        We don&#39;t track your location, but your IP address gives us a general idea of the city, state, and country - what California law
                        calls geolocation.
                    </p>
                </li>
                <li>
                    <p>Shopping and Klothed usage data -- California law calls this internet activity.</p>
                </li>
                <li>
                    <p>Purchases on retail sites when using Klothed -- this is commercial information under California law.</p>
                </li>
            </ul>
            <p>
                To learn about the limited ways in which we disclose data for our business purposes -- that is, to a service provider -- and the categories of
                those service providers, please see the section of this policy called &quot;How we share your information&quot;.
            </p>
            <p>
                Please note that, while Klothed provides tools to manage your privacy as described in this policy, we do not support &quot;Do Not Track&quot;
                browser settings at this time.
            </p>
            <p>
                Lastly, California residents also have the right to request this information in an accessible alternative format. If you have a disability and
                would like to access this Privacy Policy in an alternative format, please contact us by email or mail as detailed below.
            </p>
            <h2 id="minors">Minors</h2>
            <p>
                We created Klothed for the exclusive use of adults (18 and older) or teens from 13 to 17 with their parent&#39;s consent. We don&#39;t knowingly
                collect or solicit personal information from children. If you are a child 13-17, please do not attempt to use or register for Klothed&#39;s
                services or send any personal information to us without your parents&#39; consent.
            </p>
            <p>
                If we learn we have collected personal information from someone under 13 or from 13-17 without their parents&#39; consent, we will delete that
                information as quickly as possible. If you are a parent or guardian of a child who you think may have given us some personal information or
                posted information on any public portion of Klothed&#39;s services, please let us know at{" "}
                <a href="mailto:privacy@getKlothed.com">privacy@getklothed.com</a>. We&#39;ll help you remove it.
            </p>
            <h2 id="changes-to-this-policy">Changes to this policy</h2>
            <p>
                We will continue to update our policies and practices as needed. We will notify you of any changes to our Privacy Policy by posting any changes
                here. If we do, you&#39;ll see that the date at the top of this Privacy Policy has changed.
            </p>
            <h2 id="how-to-contact-us">How to contact us</h2>
            <p>
                If you have any questions about our privacy policies and practice, please contact us at{" "}
                <a href="mailto:privacy@getKlothed.com">privacy@getklothed.com</a>.
            </p>
        </div>
    );
}
