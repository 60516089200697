/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import "./twoColumns.scss";
import { Container } from "../container";

type Props = {
    children: React.ReactNode;
    className?: string;
    align?: string;
    justify?: string;
    smaller?: boolean;
};

const Column = ({ children, className }: Props) => <div className={classNames("column", className)}>{children}</div>;

export const TwoColumns = ({ children, className, align = "center", smaller, justify = "space-between" }: Props) => (
    <div className={classNames("two-columns", `align-${align}`, `justify-${justify}`, className)}>
        <Container className={classNames({ smaller: smaller })}>
            <div className="two-columns__inner">{children}</div>
        </Container>
    </div>
);

TwoColumns.Column = Column;
