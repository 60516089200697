import React, { useState } from "react";
import { auth } from "@v1/../firebase-config";
import "./addExtension.scss";
import tutorialImage from "./assets/tutorialImage.png";

interface AddExtensionProps {
    addExtensionCallback: () => void;
}

const addExtensionText = [
    "Install the extension",
    "Go to a product page on a retailer's site",
    "Click on the Klothed icon that slides in",
    "And voila! You're wearing the product",
];

function AddExtension(props: AddExtensionProps) {
    const [textNumber, setTextNumber] = useState(0);
    const firstName = auth?.currentUser?.displayName?.split(" ")?.[0];
    return (
        <div className="add_extension">
            <div className="add_extension_leftPane">
                {addExtensionText.map((item, index) => (
                    <div key={index} className={`add_extension_Grp ${textNumber === index ? "add_extension_Grp_Active" : ""}`}>
                        <div className="add_extension_grp_NumAndText">
                            <div className="add_extension_grp_Num">{index + 1}</div>
                            <div className="add_extension_grp_TextGrp">
                                <div className={`add_extension_grp_Text`}>{item}</div>
                                {textNumber === index && (
                                    <div
                                        onClick={() => {
                                            textNumber < 3 ? setTextNumber(textNumber + 1) : props.addExtensionCallback();
                                        }}
                                        className={`addExtension_NextBtn ${textNumber === 3 ? "addExtension_NextBtnColour" : ""}`}
                                    >
                                        {textNumber < 3 ? "NEXT" : "Try It On"}
                                    </div>
                                )}
                                <div className="add_extension_Grp_Line"></div>
                                {firstName && textNumber === 3 && index === 3 && (
                                    <div className="add_extension_LeftBottomText">
                                        <span>{"Everything looks\nbetter"}</span>
                                        <span className="text_italic">{" on you,\n"}</span>
                                        <span className="text_capitalize">{`${firstName}.`}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="add_extension_rightPane">
                <div className="addExtensionRightHeaderText">{"How It Works"}</div>
                <div className="addExtensionRightHeaderLine"></div>
                <img src={tutorialImage} className="addExtensionImg"></img>
            </div>
        </div>
    );
}

export default AddExtension;
