import React, { MouseEvent } from "react";

import { MainMenu, MainMenuID } from "./main";
import { PoliciesMenu, PoliciesMenuID } from "./policies";
import { useContextMenu } from "react-contexify";
//  https://en.wikipedia.org/wiki/Geometric_Shapes_(Unicode_block) ◀

export function ContextMenu() {
    return (
        <>
            <MainMenu />
            <PoliciesMenu />
        </>
    );
}

export function ShowMainMenu(event: MouseEvent) {
    const { show, hideAll } = useContextMenu();
    // Hide if any menu is being displayed.
    if (document.getElementsByClassName("contexify").length > 0) {
        hideAll();
        return;
    }

    // Display main menu
    const params = {
        event,
        position: {
            x: 0,
            y: 0,
        },
        id: MainMenuID,
        props: {
            policies: () =>
                show({
                    event,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    id: PoliciesMenuID,
                    props: {
                        back: () => {
                            show(params);
                        },
                    },
                }),
        },
    };
    show(params);
}
