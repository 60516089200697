import React, { useState, useEffect } from "react";
import { auth } from "@v1/../firebase-config";
import "./homeProfile.scss";
import { ReactComponent as EmailIcon } from "./assets/email.svg";
import { ReactComponent as EmailSelected } from "./assets/emailSelected.svg";
import { ReactComponent as Height } from "./assets/height.svg";
import { ReactComponent as HeightSelected } from "./assets/heightSelected.svg";
import { ReactComponent as Image } from "./assets/image.svg";
import { ReactComponent as ImageSelected } from "./assets/imageSelected.svg";
import { ReactComponent as Name } from "./assets/name.svg";
import { ReactComponent as NameSelected } from "./assets/nameSelected.svg";
import { ReactComponent as PasswordIcon } from "./assets/password.svg";
import { ReactComponent as PasswordSelected } from "./assets/passwordSelected.svg";
import { ReactComponent as Style } from "./assets/style.svg";
import { ReactComponent as StyleSelected } from "./assets/styleSelected.svg";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";
import { ReactComponent as Eye } from "../loginAndSignUp/assets/eye.svg";
import UploadPhoto from "../createProfile/uploadPhoto/uploadPhoto";
import SelectHeight from "../createProfile/selectHeight/selectHeight";
import SelectStyle from "../createProfile/selectStyle/selectStyle";
import DummyUserImg from "./assets/dummyUserImg.svg";
import { updateEmail, updateProfile, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";

interface homeProfileDataItem {
    icon: React.FunctionComponent;
    iconSelected: React.FunctionComponent;
    text: string;
    value: string | JSX.Element[] | undefined | null;
    enableItem: boolean | undefined;
}

enum HomeProfileMenus {
    Name = 0,
    Email = 1,
    Password = 2,
    Image = 3,
    Height = 4,
    Style = 5,
}

function HomeProfile() {
    const shopperDetails = sessionStorage.getItem("shopperID");
    let height_ = "";
    let style_ = "";
    if (shopperDetails && shopperDetails !== "undefined" && JSON.parse(shopperDetails)) {
        const shopperDetailsParsed = JSON.parse(shopperDetails);
        height_ = shopperDetailsParsed?.info?.height;
        style_ = shopperDetailsParsed?.info?.style;
    }

    const roundPasswordGenerator = () => {
        const arr = [];
        for (let i = 0; i < 8; i++) {
            arr.push(<div className="passwordRounds"></div>);
        }
        return arr;
    };

    const toFeetAndInches = (value: string, toUnit: "feet" | "inches") => {
        const valueInt = parseInt(value);
        const realFeet = (valueInt * 0.3937) / 12;
        const feet = Math.floor(realFeet);
        const inches = Math.round((realFeet - feet) * 12);
        const toReturn = toUnit === "feet" ? (inches === 12 ? feet + 1 : feet) : inches === 12 ? 0 : inches;
        return JSON.stringify(toReturn);
    };

    const getStyleText = (value: string) => {
        if (value === "women") {
            return "Women's styles";
        } else if (value === "men") {
            return "Men's styles";
        } else if (value === "both") {
            return "Women's + Men's clothes";
        }
    };

    const enableItem = auth?.currentUser?.providerData?.[0]?.providerId?.toLowerCase().includes("password");
    const homeProfileData: homeProfileDataItem[] = [
        {
            icon: Name,
            iconSelected: NameSelected,
            text: "My Name",
            value: `${auth?.currentUser?.displayName?.split(" ")?.[0]} ${auth?.currentUser?.displayName?.split(" ")?.[1]}`,
            enableItem,
        },
        { icon: EmailIcon, iconSelected: EmailSelected, text: "My Email", value: auth?.currentUser?.email, enableItem },
        { icon: PasswordIcon, iconSelected: PasswordSelected, text: "My Password", value: roundPasswordGenerator(), enableItem },
        {
            icon: Image,
            iconSelected: ImageSelected,
            text: "My Photo",
            value: `${process.env.REACT_APP_API_URL}/secure/shopper/image?token=${sessionStorage.getItem("accessToken")}`,
            enableItem: true,
        },
        {
            icon: Height,
            iconSelected: HeightSelected,
            text: "My Height",
            value: height_ ? `${toFeetAndInches(height_, "feet")} ft ${toFeetAndInches(height_, "inches")} in` : "",
            enableItem: true,
        },
        { icon: Style, iconSelected: StyleSelected, text: "My Style", value: getStyleText(style_), enableItem: true },
    ];

    //States****
    //To Refresh Page
    const [refresh, setRefresh] = useState(true);
    //Page - State
    const [profile, setProfile] = useState<HomeProfileMenus>(
        auth?.currentUser?.providerData?.[0]?.providerId?.toLowerCase().includes("password") ? HomeProfileMenus.Name : HomeProfileMenus.Image
    );
    //Name
    const [firstName, setFirstName] = useState(auth?.currentUser?.displayName ? auth?.currentUser?.displayName?.split(" ")?.[0] : "");
    const [lastName, setLastName] = useState(auth?.currentUser?.displayName ? auth?.currentUser?.displayName?.split(" ")?.[1] : "");
    const [nameTouched, setNameTouched] = useState(false);
    //Email
    const [email, setEmail] = useState(auth?.currentUser?.email);
    const [isEmailValid, setIsEmailValid] = useState(false);
    //Password
    const [isGoodPassword, setIsGoodPassword] = useState(false);
    const [passwordCurrent, setPasswordCurrent] = useState("");
    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
    const [passwordNew, setPasswordNew] = useState("");
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    //Image
    const [showUploadImageComponent, setShowUploadImageComponent] = useState(false);
    //Height

    const onNameSubmit = () => {
        if (auth.currentUser) {
            updateProfile(auth.currentUser, { displayName: `${firstName} ${lastName}` }).then(() => setRefresh(!refresh));
        }
    };

    useEffect(() => {
        setIsGoodPassword(false);
        setPasswordCurrent("");
        setShowPasswordCurrent(false);
        setPasswordNew("");
        setShowPasswordNew(false);
        setPasswordConfirm("");
        setShowPasswordConfirm(false);
    }, [profile]);

    const onEmailSubmit = () => {
        if (auth.currentUser?.email && email && passwordCurrent) {
            const credential = EmailAuthProvider.credential(auth.currentUser.email, passwordCurrent);
            reauthenticateWithCredential(auth.currentUser, credential)
                .then((userCredentials) => {
                    updateEmail(userCredentials.user, email).then(() => setRefresh(!refresh));
                })
                .catch((reason) => {
                    console.warn(reason);
                });
        }
    };

    const onPasswordSubmit = () => {
        if (auth.currentUser?.email) {
            const credential = EmailAuthProvider.credential(auth.currentUser.email, passwordCurrent);
            reauthenticateWithCredential(auth.currentUser, credential)
                .then((userCredentials) => {
                    updatePassword(userCredentials.user, passwordNew);
                })
                .catch((reason) => {
                    console.warn(reason);
                });
        }
    };

    return showUploadImageComponent ? (
        <UploadPhoto setPhotoUploaded={() => setShowUploadImageComponent(false)} imageAlreadyUploaded={true}></UploadPhoto>
    ) : (
        <div className="homeProfile">
            <div className="homeProfile_headerText">{homeProfileData[profile].text}</div>
            <div className="homeProfile_leftPane">
                {homeProfileData.map((item, index) => (
                    <div onClick={() => setProfile(index)} className={`homeProfile_leftPane_IconGrp ${item.enableItem ? "activeIconGrp" : ""}`} key={index}>
                        <div className={`homeProfile_leftPane_IconDiv ${profile === index ? "homeProfile_leftPane_IconDivSelected" : ""}`}>
                            {profile === index ? <item.iconSelected /> : <item.icon />}
                        </div>
                        {item.icon === Image ? (
                            <img
                                className="homeProfile_leftPane_IconImg"
                                src={typeof item.value === "string" ? item.value : DummyUserImg}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = DummyUserImg;
                                }}
                            ></img>
                        ) : (
                            <div className="homeProfile_leftPane_IconValue">{item.value}</div>
                        )}
                    </div>
                ))}
            </div>
            <div className="homeProfile_rightPane">
                {profile === HomeProfileMenus.Name && (
                    <div className="homeProfile_rightPane_name">
                        <div className="email_input_div firstNameIp">
                            <input
                                className="email_input_box"
                                placeholder=" "
                                type="text"
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value);
                                    setNameTouched(true);
                                }}
                            />
                            {/* FIRST NAME */}
                            <label className="email_input_label">{"Enter first name"}</label>
                        </div>
                        <div className="email_input_div lastNameIp">
                            <input
                                className="email_input_box"
                                placeholder=" "
                                type="text"
                                value={lastName}
                                onChange={(event) => {
                                    setLastName(event.target.value);
                                    setNameTouched(true);
                                }}
                            />
                            {/* LAST NAME */}
                            <label className="email_input_label">{"Enter last name"}</label>
                        </div>
                        <div
                            onClick={() => {
                                setNameTouched(false);
                                onNameSubmit();
                            }}
                            className={`nameSubmitBtn ${firstName && lastName && nameTouched ? "nameSubmitBtnActive" : ""}`}
                        >
                            {"SUBMIT"}
                        </div>
                    </div>
                )}
                {profile === HomeProfileMenus.Email && (
                    <div className="homeProfile_rightPane_name homeProfile_rightPane_email">
                        <div className="email_input_div">
                            <input
                                className={`email_input_box`}
                                placeholder=" "
                                type="email"
                                value={email ? email : ""}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    setIsEmailValid(isEmail(event.target.value));
                                }}
                            />
                            {/* EMAIL ADDRESS */}
                            <label className={`email_input_label`}>{"Enter email address"}</label>
                        </div>
                        <div className="password_input_div">
                            <input
                                autoComplete="off"
                                className={`password_input_box ${showPasswordCurrent ? "showPassword" : "hidePassword"}`}
                                placeholder=" "
                                type={showPasswordCurrent ? "text" : "password"}
                                value={passwordCurrent}
                                onChange={(event) => {
                                    setPasswordCurrent(event.target.value);
                                }}
                            />
                            {/* PASSWORD CURRENT */}
                            <label className={`password_input_label`}>{"Current Password"}</label>
                            <Eye className="showPasswordIMG" onClick={() => setShowPasswordCurrent(!showPasswordCurrent)} />
                        </div>
                        <div
                            onClick={() => {
                                setIsEmailValid(false);
                                onEmailSubmit();
                            }}
                            className={`nameSubmitBtn ${email && isEmailValid && isStrongPassword(passwordCurrent) ? "nameSubmitBtnActive" : ""}`}
                        >
                            {"SUBMIT"}
                        </div>
                    </div>
                )}
                {profile === HomeProfileMenus.Password && (
                    <div className="homeProfile_rightPane_name homeProfile_rightPane_Password">
                        <div className="password_input_div">
                            <input
                                className={`password_input_box ${showPasswordCurrent ? "showPassword" : "hidePassword"}`}
                                placeholder=" "
                                type={showPasswordCurrent ? "text" : "password"}
                                value={passwordCurrent}
                                onChange={(event) => {
                                    setPasswordCurrent(event.target.value);
                                }}
                                autoComplete="off"
                            />
                            {/* PASSWORD CURRENT */}
                            <label className={`password_input_label`}>{"Current Password"}</label>
                            <Eye className="showPasswordIMG" onClick={() => setShowPasswordCurrent(!showPasswordCurrent)} />
                        </div>
                        <div className="passwordSuggestionText">
                            {"Your new password should have at least 8\ncharacters, 1 number, 1 symbol and each of uppercase and lowercase character."}
                        </div>
                        <div className="password_input_div">
                            <input
                                className={`password_input_box ${showPasswordNew ? "showPassword" : "hidePassword"}`}
                                placeholder=" "
                                type={showPasswordNew ? "text" : "password"}
                                value={passwordNew}
                                onChange={(event) => {
                                    setPasswordNew(event.target.value);
                                    setIsGoodPassword(isStrongPassword(event.target.value));
                                }}
                                autoComplete="off"
                            />
                            {/* PASSWORD NEW */}
                            <label className={`password_input_label`}>{"Enter New Password"}</label>
                            <Eye className="showPasswordIMG" onClick={() => setShowPasswordNew(!showPasswordNew)} />
                        </div>
                        <div className="password_input_div">
                            <input
                                className={`password_input_box ${showPasswordConfirm ? "showPassword" : "hidePassword"}`}
                                placeholder=" "
                                type={showPasswordConfirm ? "text" : "password"}
                                value={passwordConfirm}
                                onChange={(event) => {
                                    setPasswordConfirm(event.target.value);
                                }}
                                autoComplete="off"
                            />
                            {/* PASSWORD CONFIRM */}
                            <label className={`password_input_label`}>{"Confirm New Password"}</label>
                            <Eye className="showPasswordIMG" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} />
                        </div>
                        <div
                            onClick={() => {
                                setIsGoodPassword(false);
                                onPasswordSubmit();
                                setPasswordCurrent("");
                                setPasswordNew("");
                                setPasswordConfirm("");
                            }}
                            className={`nameSubmitBtn ${passwordCurrent && isGoodPassword && passwordNew === passwordConfirm ? "nameSubmitBtnActive" : ""}`}
                        >
                            {"SUBMIT"}
                        </div>
                    </div>
                )}
                {profile === HomeProfileMenus.Image && (
                    <div className="changeProfileImageDiv">
                        <div className="changeProfileImage">
                            <img
                                className="profileImage"
                                src={`${process.env.REACT_APP_API_URL}/secure/shopper/image?token=${sessionStorage.getItem("accessToken")}`}
                            ></img>
                            <div onClick={() => setShowUploadImageComponent(true)} className="changeImageBtn">
                                {"CHANGE PHOTO"}
                            </div>
                        </div>
                    </div>
                )}
                {profile === HomeProfileMenus.Height && (
                    <SelectHeight heightUpdatedCallback={() => setRefresh(!refresh)} className="selectHeightProfile"></SelectHeight>
                )}
                {profile === HomeProfileMenus.Style && (
                    <SelectStyle styleSelectCallback={() => setRefresh(!refresh)} className="selectStyleProfile"></SelectStyle>
                )}
            </div>
        </div>
    );
}

export default HomeProfile;
