/**
 * External dependencies.
 */
import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

/**
 * Internal dependencies.
 */
import { Container } from "@v2/components/burger";
import Logo from "v2/assets/header-logo.webp";
import { AuthState, ShopperImageState, useSiteSession } from "@v2/utils";
import { parseFullName } from "parse-full-name";
import { ShowMainMenu } from "../menu";
import { WorkerStatusType } from "@v2/utils/session/serverContext";
import { createBrowserHistory } from "history";

const ShopperStatus = () => {
    const { shopperImageState, serverState } = useSiteSession();

    const [title, setTitle] = useState<string>();

    useEffect(() => {
        if (serverState.status === WorkerStatusType.Congested) {
            setTitle("Servers are busy.");
        } else if (shopperImageState === ShopperImageState.Unknown) {
            setTitle("Incomplete try-on profile.");
        } else if (shopperImageState === ShopperImageState.Approved) {
            setTitle("Ready.");
        } else if (shopperImageState === ShopperImageState.InReview) {
            setTitle("In review.");
        } else if (shopperImageState === ShopperImageState.Rejected) {
            setTitle("Photo error.");
        } else {
            setTitle(shopperImageState);
        }
    }, [setTitle, serverState, shopperImageState]);

    return (
        <div className={classNames("status", shopperImageState)} title={title}>
            {serverState.status === WorkerStatusType.Congested ? (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.114 26h20.587c.855 0 1.388-.922.966-1.666L17.368 6.558a1.106 1.106 0 0 0-1.922 0L5.148 24.334A1.114 1.114 0 0 0 6.114 26Zm11.41-3.333h-2.222v-2.222h2.222v2.222Zm-1.111-4.444c-.611 0-1.111-.5-1.111-1.11V14.89c0-.61.5-1.11 1.11-1.11.612 0 1.112.5 1.112 1.11v2.222c0 .611-.5 1.111-1.111 1.111Z"
                        fill="var(--klothed-textColor-error)"
                    />
                </svg>
            ) : shopperImageState === ShopperImageState.Approved ? (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 6C10.486 6 6 10.486 6 16s4.486 10 10 10 10-4.486 10-10S21.514 6 16 6Zm5.204 6.649-6.461 7.692a.77.77 0 0 1-.577.274h-.013a.77.77 0 0 1-.572-.254l-2.769-3.077a.77.77 0 1 1 1.143-1.03l2.178 2.42 5.893-7.015a.77.77 0 0 1 1.178.99Z"
                        fill="#0ED948"
                    />
                </svg>
            ) : (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.114 26h20.587c.855 0 1.388-.922.966-1.666L17.368 6.558a1.106 1.106 0 0 0-1.922 0L5.148 24.334A1.114 1.114 0 0 0 6.114 26Zm11.41-3.333h-2.222v-2.222h2.222v2.222Zm-1.111-4.444c-.611 0-1.111-.5-1.111-1.11V14.89c0-.61.5-1.11 1.11-1.11.612 0 1.112.5 1.112 1.11v2.222c0 .611-.5 1.111-1.111 1.111Z"
                        fill="var(--klothed-textColor-error)"
                    />
                </svg>
            )}
        </div>
    );
};

const Header = () => {
    const { user, authState, toggleTheme } = useSiteSession();
    const currentScroll = useRef(window.pageYOffset);
    const [visible, setVisible] = useState<boolean>(true);

    const handleScroll = () => {
        if (window.pageYOffset === 0 || currentScroll.current === 0) {
            setVisible(true);
        } else {
            setVisible(currentScroll.current >= window.pageYOffset);
        }

        currentScroll.current = window.pageYOffset;
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const history = createBrowserHistory();

    return (
        <header className="header_v2">
            <Container
                className={classNames({
                    "is-hidden": !visible,
                })}
            >
                <div className="header__inner">
                    <div
                        onClick={(event) => {
                            ShowMainMenu(event);
                        }}
                        className="rightSliderLine"
                    >
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path stroke="var(--klothed-textColor)" strokeWidth="2" strokeLinecap="round" d="M7 11h18M7 19h18" />
                        </svg>
                    </div>
                    <div className="spacer" />

                    <div className="logo">
                        <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_RACKS_HOME || "#" }}>
                            <img src={Logo} height="auto" width="auto" alt="Klothed logo" />
                        </Link>
                    </div>

                    <div className="spacer" />
                    <div className="toggle-theme" onClick={toggleTheme}>
                        <svg width="32" height="32" viewBox="-4 -4 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-1.5v-17a8.5 8.5 0 1 1 0 17Z"
                                fill="var(--klothed-textColor)"
                            />
                        </svg>
                    </div>
                    {authState !== AuthState.Unknown && (
                        <div className="user">
                            {authState > AuthState.NoUserAccount && user ? (
                                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_SHOPPER || "#" }}>
                                    <ShopperStatus />
                                    <div className="name" title={`${user.displayName} (${user.email})`}>
                                        {parseFullName(user.displayName || "Unnamed").first}
                                    </div>
                                </Link>
                            ) : (
                                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_LOGIN2 || "#" }}>LOGIN</Link>
                            )}
                        </div>
                    )}
                </div>
            </Container>
        </header>
    );
};

export default Header;
