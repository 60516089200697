import React, { useState, useEffect } from "react";
import "./resetPassword.scss";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as KlothedLogo } from "../assets/logo-beta.svg";
import RightSlider from "../rightSlider/rightSlider";
import { ReactComponent as Eye } from "../loginAndSignUp/assets/eye.svg";
import isStrongPassword from "validator/lib/isStrongPassword";
import { confirmPasswordReset, onAuthStateChanged } from "firebase/auth";
import { auth } from "@v1/../firebase-config";
import { ReactComponent as SuccessKey } from "./assets/successKey.svg";
import { RightPane } from "../loginAndSignUp/loginAndSignUp";
import Footer from "../components/footer/footer";

function ResetPassword() {
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oobCode = urlParams.get("oobCode");
    if (!oobCode) {
        navigate(process.env.REACT_APP_WEB_LOGIN || "#");
    }
    const continueUrl = urlParams.get("continueUrl");
    const email = continueUrl?.split("email")[1].substring(1);

    const [resetEmailSuccess, setResetEmailSuccess] = useState(false);
    const [showRightSlider, setShowRightSlider] = useState<boolean>(false);
    const [showPage, setShowPage] = useState<boolean>(false);
    4;
    //Pasword State
    const [isGoodPassword, setIsGoodPassword] = useState(false);
    const [passwordNew, setPasswordNew] = useState("");
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            user ? navigate(process.env.REACT_APP_WEB_SIGN_IN || "#") : setShowPage(true);
        });
    }, []);

    const onClickResetPassword = () => {
        if (oobCode) {
            confirmPasswordReset(auth, oobCode, passwordNew).then(() => {
                setResetEmailSuccess(true);
            });
        }
    };

    return showPage ? (
        <div className="resetPassword">
            <Link to={process.env.REACT_APP_API_URL || "#"}>
                <KlothedLogo className="klothed_logo"></KlothedLogo>
            </Link>
            {!showRightSlider && (
                <div onClick={() => setShowRightSlider(true)} className="rightSliderLine">
                    <div className="rightSliderLine_"></div>
                    <div className="rightSliderLine_"></div>
                </div>
            )}
            {!resetEmailSuccess ? (
                <div className="email_enterDiv">
                    <div className="email_enterDivText">{"Reset your password"}</div>
                    <div className="email_input_div">
                        <input className="email_input_box" placeholder=" " type="email" value={email ? email : ""} disabled={true} />
                        <label className="email_input_label">{"Email address"}</label>
                    </div>
                    <div className="passwordSuggestion">
                        {"Your new password should have at least 8 characters, 1 number, 1 symbol and each of uppercase and lowercase character."}
                    </div>
                    <div className="password_input_div password_input_div1">
                        <input
                            className={`password_input_box ${showPasswordNew ? "showPassword" : "hidePassword"}`}
                            placeholder=" "
                            type={showPasswordNew ? "text" : "password"}
                            value={passwordNew}
                            onChange={(event) => {
                                setPasswordNew(event.target.value);
                                setIsGoodPassword(isStrongPassword(event.target.value));
                            }}
                        />
                        {/* PASSWORD NEW */}
                        <label className={`password_input_label`}>{"Enter New Password"}</label>
                        <Eye className="showPasswordIMG" onClick={() => setShowPasswordNew(!showPasswordNew)} />
                    </div>
                    <div className="password_input_div password_input_div2">
                        <input
                            className={`password_input_box ${showPasswordConfirm ? "showPassword" : "hidePassword"}`}
                            placeholder=" "
                            type={showPasswordConfirm ? "text" : "password"}
                            value={passwordConfirm}
                            onChange={(event) => {
                                setPasswordConfirm(event.target.value);
                            }}
                        />
                        {/* PASSWORD CONFIRM */}
                        <label className={`password_input_label`}>{"Confirm New Password"}</label>
                        <Eye className="showPasswordIMG" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} />
                    </div>
                    <div
                        onClick={isGoodPassword && passwordNew === passwordConfirm ? onClickResetPassword : undefined}
                        className={`email_enterDivSubmit ${isGoodPassword && passwordNew === passwordConfirm ? "email_enterDivSubmitActive" : ""}`}
                    >
                        {"RESET PASSWORD"}
                    </div>
                </div>
            ) : (
                <div className="resetPasswordSuccess">
                    <div className="resetPasswordSuccessText">{"Your password was successfully changed"}</div>
                    <SuccessKey></SuccessKey>
                    <div
                        onClick={() => {
                            sessionStorage.setItem("returnFromForgotPassword", JSON.stringify(RightPane.Login));
                            navigate(process.env.REACT_APP_WEB_SIGN_IN || "#");
                        }}
                        className="logIn_wrapper"
                    >
                        <div className="logIn">{"LOG IN"}</div>
                    </div>
                </div>
            )}
            <Footer />
            {showRightSlider && <div className="rightSliderOverlay"></div>}
            {showRightSlider && <RightSlider closeAct={() => setShowRightSlider(false)}></RightSlider>}
        </div>
    ) : null;
}

export default ResetPassword;
