import React, { useCallback, useRef, useState } from "react";
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, updateProfile, OAuthProvider } from "firebase/auth";
import { auth } from "@v2/utils";
import { ReactComponent as Google } from "@v2/assets/google.svg";
import { ReactComponent as Apple } from "@v2/assets/apple.svg";
import isEmail from "validator/lib/isEmail";
import { ReactComponent as Eye } from "@v2/assets/eye.svg";
import { ReactComponent as AppStore } from "@v2/assets/appStore.svg";
import chrome from "@v2/assets/chrome.webp";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PasswordStrengthBar from "react-password-strength-bar";
import { createBrowserHistory } from "history";

function SignUp({ displayStoreLinks }: { displayStoreLinks?: boolean }) {
    const emailAlreadyRegistered = "Sorry, the email you entered already belong to an account. Please try again.";

    const history = createBrowserHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isGoodPassword, setIsGoodPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [mailError, setMailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const onClickSignUp = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                updateProfile(userCredential.user, { displayName: `${firstName} ${lastName}` });
            })
            .catch((error) => {
                if (error && error.code.includes("email-already-in-use")) {
                    setErrorMessage(emailAlreadyRegistered);
                } else {
                    setErrorMessage(error.message);
                }
            });
    };

    const googleProvider = useRef(new GoogleAuthProvider());
    const googleSignUp = useCallback(() => {
        return signInWithPopup(auth, googleProvider.current);
    }, [auth]);

    const appleProvider = useRef(
        (() => {
            const provider = new OAuthProvider("apple.com");
            provider.addScope("email");
            provider.addScope("name");
            return provider;
        })()
    );
    const appleSignUp = useCallback(() => {
        return signInWithPopup(auth, appleProvider.current);
    }, [auth]);

    const emailComponent = (
        <>
            <div className="email_input_div firstNameIp">
                <input
                    className="email_input_box"
                    placeholder=" "
                    type="text"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    autoComplete="given-name"
                />
                {/* FIRST NAME */}
                <label className="email_input_label">{"Enter first name"}</label>
            </div>
            <div className="email_input_div lastNameIp">
                <input
                    className="email_input_box"
                    placeholder=" "
                    type="text"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    autoComplete="family-name"
                />
                {/* LAST NAME */}
                <label className="email_input_label">{"Enter last name"}</label>
            </div>
            <div className="email_input_div">
                <input
                    className={classNames("email_input_box", mailError && "email_input_box_error")}
                    placeholder=" "
                    type="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                        setIsEmailValid(isEmail(event.target.value));
                    }}
                    autoComplete="email"
                    onBlur={() => {
                        if (email && !isEmailValid) {
                            setErrorMessage("Please enter a valid email address.");
                            setMailError(true);
                            setPasswordError(false);
                        }
                    }}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                        setPasswordError(false);
                    }}
                />
                {/* EMAIL ADDRESS */}
                <label className={classNames("email_input_label", mailError && "email_input_label_error")}>{"Enter email address"}</label>
            </div>
            <div className="password_input_div">
                <input
                    className={classNames("password_input_box", showPassword ? "showPassword" : "hidePassword", passwordError && "password_input_box_error")}
                    placeholder=" "
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    autoComplete="new-password"
                    onBlur={() => {
                        if (password && !isGoodPassword) {
                            setErrorMessage("Please enter a stronger password.");
                            setPasswordError(true);
                            setMailError(false);
                        }
                    }}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                        setPasswordError(false);
                    }}
                />
                {/* PASSWORD */}
                <label className={classNames("password_input_label", passwordError && "password_input_label_error")}>{"Enter password"}</label>
                <Eye className="showPasswordIMG" onClick={() => setShowPassword(!showPassword)}></Eye>
            </div>
            <div className="password_input_div">
                <input
                    className={classNames("password_input_box", showPassword ? "showPassword" : "hidePassword", passwordError && "password_input_box_error")}
                    placeholder=" "
                    type={showPassword ? "text" : "password"}
                    value={repeatPassword}
                    onChange={(event) => {
                        setRepeatPassword(event.target.value);
                    }}
                    autoComplete="new-password"
                    onBlur={() => {
                        if (password && !isGoodPassword) {
                            setErrorMessage("Please enter a stronger password.");
                            setPasswordError(true);
                            setMailError(false);
                        } else if (repeatPassword && repeatPassword !== password) {
                            setErrorMessage("Passwords do not match.");
                            setPasswordError(true);
                            setMailError(false);
                        }
                    }}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                        setPasswordError(false);
                    }}
                />
                {/* PASSWORD */}
                <label className={classNames("password_input_label", passwordError && "password_input_label_error")}>{"Repeat password"}</label>
            </div>
            <PasswordStrengthBar password={password} onChangeScore={(s) => setIsGoodPassword(s > 1)} />
            <br />
            <div className="legal">
                <div className="legal_acceptance">
                    <div>
                        <span>{"By signing up, you agree to Klothed’s "}</span>
                    </div>
                    <div>
                        <Link
                            to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_TERMS_OF_SERVICE || "#" }}
                            className="legal_acceptance_link"
                        >
                            <span>Terms of Service</span>
                            <hr />
                        </Link>
                        <span>and</span>
                        <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_PRIVACY || "#" }} className="legal_acceptance_link">
                            <span>Privacy Policy</span>
                            <hr />
                        </Link>
                        .
                    </div>
                </div>
                <div className="legal_disclaimer">
                    Not all features described on the Website are enabled or fully functional during the Beta Testing period.
                </div>
            </div>
            <input
                type="submit"
                className={classNames(
                    "signUp_button",
                    repeatPassword &&
                        repeatPassword === password &&
                        email &&
                        isEmailValid &&
                        password &&
                        isGoodPassword &&
                        firstName &&
                        lastName &&
                        "signUp_button_active"
                )}
                value="SIGN UP"
            />
            <div className="alternate_login_options">
                <div className="alternate_signUp">{"or"}</div>
                <Google onClick={() => googleSignUp()} className="alternate_login_options_CTA"></Google>
                <Apple onClick={() => appleSignUp()} className="alternate_login_options_CTA"></Apple>
            </div>
        </>
    );

    const nonEmailComponent = (
        <>
            <div className="legal">
                <div className="legal_acceptance">
                    <div>
                        <span>{"By signing up, you agree to Klothed’s "}</span>
                    </div>
                    <div>
                        <Link
                            to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_TERMS_OF_SERVICE || "#" }}
                            className="legal_acceptance_link"
                        >
                            <span>Terms of Service</span>
                            <hr />
                        </Link>
                        <span>and</span>
                        <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_PRIVACY || "#" }} className="legal_acceptance_link">
                            <span>Privacy Policy</span>
                            <hr />
                        </Link>
                        .
                    </div>
                </div>
                <div className="legal_disclaimer">
                    Not all features described on the Website are enabled or fully functional during the Beta Testing period.
                </div>
            </div>
            <div className="alternate_login_options">
                <Google onClick={() => googleSignUp()} className="alternate_login_options_CTA"></Google>
                <Apple onClick={() => appleSignUp()} className="alternate_login_options_CTA"></Apple>
            </div>
        </>
    );

    return (
        <div className="signUp_section_v2">
            {errorMessage ? (
                <div className="errorMessage__">
                    <div className="errorMessageHeading">{errorMessage}</div>
                    <div className="horizontal_separator horizontal_separator_error"></div>
                </div>
            ) : (
                <div className="signUp_top_header">{"Try on styles from your\nfavorite online retailer.\nIt's free."}</div>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onClickSignUp();
                }}
            >
                {process.env.REACT_APP_EMAIL_ENABLE ? emailComponent : nonEmailComponent}
                <div className="horizontal_separator"></div>
                <div className="already_account">
                    {"Have an account?"}
                    <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_LOGIN2 || "#" }}>
                        <div className={"login_button"}>LOG IN</div>
                    </Link>
                </div>
                <div className="horizontal_separator"></div>
                {displayStoreLinks && (
                    <div className="store_CTA">
                        <div className="selector">
                            <a href={process.env.REACT_APP_EXTENSION_MOBILE || "#"} target={"_blank"} rel={"noreferrer"}>
                                <div className="getApp">{"Get The App"}</div>
                                <AppStore className="appleAppStore" />
                            </a>
                        </div>
                        <div className="selector">
                            <a href={process.env.REACT_APP_EXTENSION_DESKTOP} target={"_blank"} rel={"noreferrer"}>
                                <div className="getApp">{"Get the extension"}</div>
                                <div className="extensionLink">
                                    <img className="chromeLogo" src={chrome}></img>
                                    <div className="extensionText">
                                        <div className="extensionTextTop">{"Available On"}</div>
                                        <div className="extensionTextBottom">{"chrome web store"}</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

export default SignUp;
