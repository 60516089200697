import { Dispatch, SetStateAction, useState } from "react";
import PhoneImage from "@v2/assets/phone-image.webp";

export interface HelmetContext {
    pageTitle: string;
    setPageTitle: Dispatch<SetStateAction<string>>;
    pageDescription: string;
    setPageDescription: Dispatch<SetStateAction<string>>;
    pageImage: string;
    setPageImage: Dispatch<SetStateAction<string>>;
}

export function HelmetContextProvider(): HelmetContext {
    const [pageTitle, setPageTitle] = useState("Klothed - Everything looks better on you.");
    const [pageDescription, setPageDescription] = useState("Klothed - Everything looks better on you.");
    const [pageImage, setPageImage] = useState(PhoneImage);

    return { pageTitle, setPageTitle, pageDescription, setPageDescription, pageImage, setPageImage };
}
