export async function checkImage(url: string) {
    return new Promise<boolean>((res) => {
        const image = new Image();
        image.onload = function () {
            if ((this as HTMLImageElement).width > 0) {
                res(true);
            }
        };
        image.onerror = () => {
            res(false);
        };
        image.src = url;
    });
}

export async function checkImageThenSet(url: string, set: (url: string) => void, fallback = "") {
    if (await checkImage(url)) {
        set(url);
    } else {
        console.error("failed to fetch", url);
        set(fallback);
    }
}
