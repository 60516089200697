import React from "react";
import { EditsPage } from "@v2/components/edits";

export default function EditsHome() {
    return (
        <div className="edits_home">
            <EditsPage />
        </div>
    );
}
