import React, { Fragment, SyntheticEvent, useCallback, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

/**
 * Internal dependencies.
 */
import { ReactComponent as Link } from "./assets/icon-link.svg";

const LinkCopy = () => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("Copy Try-on");
    const [clicked, setClicked] = useState(false);
    const handleCopy: React.MouseEventHandler<HTMLAnchorElement> | undefined = useCallback((e: SyntheticEvent) => {
        e.preventDefault();
        const href = window.location.href;
        navigator.clipboard.writeText(href);
        setText("Try-on copied to clipboard");
        setClicked(true);
        setTimeout(() => {
            setText("Copy Try-on");
            setClicked(false);
            setOpen(false);
        }, 2000);
    }, []);

    return (
        <Fragment>
            <a href="#" id="share-link" onClick={handleCopy} onMouseEnter={() => setOpen(true)} onMouseLeave={() => !clicked && setOpen(false)}>
                <Link />
            </a>
            <Tooltip anchorId="share-link" content={text} className="tooltip" isOpen={open} />
        </Fragment>
    );
};

export default LinkCopy;
