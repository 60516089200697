import React from "react";
import "./legal.scss";
import { RightPane } from "../loginAndSignUp/loginAndSignUp";
import { Link } from "react-router-dom";

interface LegalProps {
    legal: RightPane;
    className?: string;
}
function Legal(props: LegalProps) {
    return props.legal === RightPane.PrivacyPolicy ? (
        <div className={`legal_section ${props.className}`}>
            <div className="leftTop_heading">{"PRIVACY POLICY"}</div>
            <div className="horizonta_seperator_legal"></div>
            <div className="rightTop_heading">{"Privacy Policy"}</div>
            <div className="revisionDate_legal">{"Last revised on November 15, 2022"}</div>
            <div className="legal_text">
                <div style={{ fontSize: "24px" }}>{"Welcome Summary"}</div>
                <br></br>
                <div>
                    {
                        "This Privacy Policy explains what Klothed is, and how we collect, use, store, protect, and share your personal information obtained in connection with our Service which is described in our "
                    }
                    <a className="orangeText" href={process.env.REACT_APP_WEB_TERMS_OF_SERVICE} target={"_blank"} rel="noreferrer">
                        {"Terms of Service"}
                    </a>
                    {".  It’s important that you read this entire policy, but here’s a summary to get you started:"}
                </div>
                <br></br>
                <ul>
                    <li>
                        <span style={{ fontWeight: "bolder" }}>{"What is Klothed? "}</span>
                        {
                            "We are a generative AI shopping tool designed to provide shoppers, at no charge, with the ability to create a photorealistic virtual try-on image of themselves wearing clothing shown on any product page across a growing list of online  merchants.  You see yourself in the clothes before you buy to increase your confidence that you will like your purchases and reduce the incidents of bothersome returns.  We call your picture a “try-on picture”.   As explained below, your try-on pictures are saved and you can share your try-on pictures with others."
                        }
                    </li>
                    <br></br>
                    <li>
                        <span style={{ fontWeight: "bolder" }}>{"We don’t sell your personal information. "}</span>
                        {
                            "Respecting user privacy is a key part of our mission.  We don’t sell your personal information, ever.  We use it solely to provide our services to you.  Our business model with respect to use of your personal information is based on merchants paying us a fee when you buy a merchant’s product after creating a try-on picture or in some cases we may have a direct agreement with the merchant to pay us a fee when a try-on picture is created by you or with your express permission by a merchant."
                        }
                    </li>
                    <br></br>
                    <li>
                        <span style={{ fontWeight: "bolder" }}>{"We limit what information is required and collected. "}</span>
                        {
                            "We require the information from you that enables us to create your account and allow the creation of try-on pictures.  We also collect your shopping information necessary or helpful to provide and improve our services and to satisfy our legal requirements. Any other information requested is optional."
                        }
                    </li>
                    <br></br>
                    <li>
                        <span style={{ fontWeight: "bolder" }}>{"We give you control. "}</span>
                        {
                            'Your try-on pictures are private to you.  But we give you the ability to share access to your try-on pictures with friends, family and social media followers.  We also save your try-on picture in your personal "CLOSET" which acts as your personal try-on picture closet.  The try-on pictures in Your Closet allow you to compare the same or similar styles from the same or different merchants at any time.  When you decide to make a purchase, one click on your try-on picture takes you directly to the merchant’s Website product page so you can make the purchase.'
                        }
                    </li>
                </ul>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"How Klothed Works."}</div>
                <br></br>
                <div>
                    {
                        "You download our Browser Extension or App and install it.  You will be asked to create an account and during the process, you are required to provide us with a full length picture of yourself and to tell us your height."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "We use your picture and height to create a personalized 3D body model that we use to enable you to virtually try-on clothing across supported merchant websites. You can think of this as your personalized “Digital Try-On ID”."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "When you visit a product page on a merchant’s website and desire to see what you would look like wearing the product offered in the pose of the merchant’s product model, the Klothed browser extension will use your Digital, Try-On ID to create a try-on picture of you wearing those clothes.  That is how Klothed creates your try-on picture.  A try-on picture may also be created by you using a Klothed app (or by a merchant, to whom you have given express permission, to use our API to use Klothed to create a try-on picture using the merchant’s clothes as described in more detail below).  The try-on pictures are added to your CLOSET for future reference and comparison."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Then, when you decide to go shopping online at one of our supported merchant’s sites showing a product model wearing the clothing you are interested in, with only a click, our technology enables you to create your clothing try-on picture.  If you visit a merchant site we currently don’t support, not to worry, we will happily add that merchant to our queue of merchants to get setup - please email "
                    }
                    <a className="orangeText" href="mailto:help@getklothed.com">
                        {"help@getklothed.com"}
                    </a>
                    {"."}
                </div>
                <br></br>
                <div>
                    {
                        "Our goal is to give you a better sense of how you would look in the specific style that the product model is wearing on the product page you are viewing.  Klothed is designed to help you make a more informed purchase decision.  Ultimately, we hope this leads you to feel more comfortable buying a product online and more likely to be delighted with the item once it arrives. "
                    }
                </div>
                <br></br>
                <div>
                    {
                        "When you make a purchase after you create a try-on picture, the merchant, often through an affiliate network or network aggregator, sometimes pays us a fee.  Or, as described in more detail below, we may be paid a fee by a merchant "
                    }
                    <span style={{ fontStyle: "italic" }}>{"if you give the merchant permission "}</span>
                    {
                        "to use Klothed to create try-on pictures of you in the merchant's fashions. That’s how we make money.  We do not need to (nor want to) sell your personal information."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "We may allow you to share your try-on picture with family, friends, or others in your social community to see what they think of the style on you.  We may also allow you to share your Klothed ID with others (friends and family) so they can try styles on you and make style suggestions or to buy you a gift.  If these features are implemented, only you will be able to grant and revoke this permission. "
                    }
                </div>
                <br></br>
                <div>
                    {
                        "If you allow Klothed access to your social media account(s) then we promise never to post or share anything without your explicit permission. It’s your social media, you’re in charge. "
                    }
                </div>
                <br></br>
                <div>
                    {
                        "In the future, we anticipate that Third-Parties (persons or entities other than you or Klothed such as merchants) may build applications that leverage our Try-On engine to create photorealistic images of our shoppers to show our shoppers how they look wearing specific products or to provide other benefits or services derived from the try-on pictures that are relevant to you.  For example, a car company might like to show you images where you are enjoying the drive in one of their sports cars. Then, "
                    }
                    <span style={{ fontStyle: "italic" }}>{"with your permission "}</span>
                    {
                        "(and only with your permission), we would allow the car company to use Klothed to create a photorealistic picture showing you in their car.  This picture will be created using the data obtained when you create your account.  We will not provide any Third-Party with the picture you uploaded or the data we obtained from the picture.  These Third-Parties may pay us a fee to create these photorealistic images and make them available to you. We also will not work with any Third-Parties that are not building something that might benefit you in some way."
                    }
                </div>
                <br></br>
                <div>
                    {"As mentioned earlier, with "}
                    <span style={{ fontStyle: "italic" }}>{"your written permission "}</span>
                    {
                        "(opt-in), we may provide you with try-on pictures of you in specific merchants’ fashion products.  This will allow merchants to alert you to products and styles that they believe may be of interest to you.  Even with such permission, we will not provide the merchants with any picture you provide to us or your height.  We will create the try-on pictures and make them available to you. If you give retailers permission to alert you to their products, the merchants  will likely pay us a fee for any purchase you make or in some cases for making the try-on picture."
                    }
                </div>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"The information we collect"}</div>
                <br></br>
                <div>
                    <span style={{ fontWeight: "bold" }}>{"Account information obtained from you and use. "}</span>
                    {
                        "When you create a Klothed account, you create a username and password, and provide us with your email address so that we can contact you..  You also provide us with a picture of yourself and your height.  We may ask for other optional information to personalize your profile to allow us to enhance our services to you."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "If you choose to create your Klothed account login using a third-party service (e.g. Google, Facebook, etc) or link a social media profile (like Facebook) at sign-in, Klothed will store the information to enable these connections for login purposes - we will not store your passwords. Linking your social media profile grants Klothed access to the profile, but only as allowed by your permission settings, that platform's terms of service, and this Privacy Policy. We will NOT track any of your social media activity, and we will not post anything on your behalf without your explicit permission.  We may allow you to post your try-on picture on social media, but you decide when and if to post."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "If you choose to sign in with Google (or other supported login services), you'll have the option of importing your contacts so that you can refer your friends or family to Klothed or share one or more of your try-on pictures with them. Referral addresses are masked, so we never see or store them, nor can we contact anyone without your permission."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Over time we will keep track of your shopping history (Items you’ve looked at, tried on, and bought) to create a digital closet for you.  Your digital closet is designed to provide you with the ability to review your clothing try-on picture history, put together and store outfits, share looks, seek style recommendations, maintain an organized view of your clothing purchase behavior, and much more. In addition, in the future we plan to allow you to grant other people and applications access (and to revoke access) to review your digital closet."
                    }
                </div>
                <br></br>
                <div>{"As you continue to use Klothed's services, you might also provide information when you:"}</div>
                <ul>
                    <li>{"Save try-on pictures; and"}</li>
                    <br></br>
                    <li>{"Share try-on pictures with friends or family to keep track of who you have shared with."}</li>
                </ul>
                <br></br>
                <div>{"We will also:"}</div>
                <ul>
                    <li>{"Create a list of stores you like; and"}</li>
                    <br></br>
                    <li>{"Communicate with you through email or other means."}</li>
                </ul>
                <br></br>
                <div>
                    {
                        "We may also use the information we collect from you in aggregate or otherwise anonymized form. This lets us look at interactions with our site and services generally and does not identify you or any specific person. We use this general data for research, development, marketing, analytics, and to enhance the shopping experience for Klothed shoppers."
                    }
                </div>
                <br></br>
                <div>
                    <span style={{ fontWeight: "bold" }}>{"Information from actions you take. "}</span>
                    {
                        "Klothed also collects technical information about your device and use of Klothed to make sure that our services are working correctly. We may collect information on the sites you visit to improve our service to you and provide the most accurate and relevant results about your shopping history. The information we collect will be used to help us provide you with a better online shopping experience and to help us develop, improve, and market our services. "
                    }
                </div>
                <br></br>
                <div>
                    {
                        "On retail sites, we may collect limited information regarding your purchases on a retailer site while using Klothed (for example, the order total and the fact that an order was completed) for purposes including internal analytics and commission collection from the retailer. We will not collect any credit card numbers or personal credit information (PCI). The information you share, and we collect, enables us to run and improve our services."
                    }
                </div>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"How we use cookies and similar technologies"}</div>
                <br></br>
                <div>
                    {
                        "Like most other online services, Klothed uses cookies, pixel tags, web beacons, and other markers (we'll just call them “cookies”) to collect some of the data discussed in this Privacy Policy. These help us operate our services, analyze engagement and usage, and provide a relevant and personalized shopping experience and determine when a commission has been earned. See below to learn how you can manage your cookie preferences."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Some cookies can be temporary (“session cookies” like those used for navigating your browser) and disappear once you close it. Others last longer (“persistent cookies,” like when you ask a site to remember your login) and are saved to your computer until you delete them."
                    }
                </div>
                <br></br>
                <div>{"Our uses of cookies and similar technologies fall into the following general categories:"}</div>
                <ol type="1">
                    <li>
                        <span style={{ fontWeight: "bold" }}>{"Essential: "}</span>
                        {
                            "We may use cookies, web beacons, or other similar technologies that are necessary to the operation of our sites, services, applications, and tools. This includes technologies that allow you access to our sites, services, applications, and tools; that are required to identify irregular site behavior, prevent fraudulent activity and improve security; or that allow you to make use of functions we may provide such as saved search, or other functions;"
                        }
                    </li>
                    <br></br>
                    <li>
                        <span style={{ fontWeight: "bold" }}>{"Performance: "}</span>
                        {
                            "We may use cookies, web beacons, or other similar technologies to assess the performance of our websites, applications, services, and tools, including as part of our analytic practices to help us understand how our visitors use our websites, determine if you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our website content, applications, services, or tools;"
                        }
                    </li>
                    <br></br>
                    <li>
                        <span style={{ fontWeight: "bold" }}>{"Functionality: "}</span>
                        {
                            "We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when accessing or using our sites, services, applications, or tools. This may include identifying you when you sign into our sites or keeping track of your specific preferences, interests, or past items viewed so that we may enhance the presentation of content on our sites;"
                        }
                    </li>
                    <br></br>
                    <li>
                        <span style={{ fontWeight: "bold" }}>{"Marketing: "}</span>
                        {
                            "We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests, on our sites or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that have been delivered to you, such as whether you have clicked on an advertisement."
                        }
                    </li>
                </ol>
                <br></br>
                <div>
                    {
                        "Any personal information that we collect and store through use of these technologies for categories 2 through 4 above are based on your consent, obtained through a conspicuous disclosure on our website during your first visit. You can withdraw this consent in your account settings. If you want to disable cookies entirely, your browser or mobile device might have an option to do that. For more information, including instructions on disabling cookies, please visit: "
                    }
                    <a className="orangeText" href={"http://www.allaboutcookies.org/"} target={"_blank"} rel="noreferrer">
                        {"http://www.allaboutcookies.org"}
                    </a>
                </div>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"How we share your information"}</div>
                <br></br>
                <div>
                    {
                        "We know how important your personal data is to you, so we will never sell it. We'll only share it with your consent or in ways you'd expect (as we explain in this Privacy Policy)."
                    }
                </div>
                <br></br>
                <div>{"That means we will share if we are legally required to do so."}</div>
                <br></br>
                <div>
                    {
                        "We may share information with our service providers (businesses that work for and with us) for the purpose of running Klothed and providing its services. These include businesses that perform services on our behalf, including to help us maintain our Services, to provide support for legal, banking, security protection, our own marketing, and shopper service. Please know that when we share information with our service providers, it's for the purposes outlined in this Privacy Policy and not for their independent use."
                    }
                </div>
                <br></br>
                <div>{"We may also share information in the following cases:"}</div>
                <ol type={"1"}>
                    <li>{"With your express consent;"}</li>
                    <br></br>
                    <li>{"In an aggregate or anonymized format that does not identify any specific person;"}</li>
                    <br></br>
                    <li>{"As required by law, or to comply or respond to a valid government request;"}</li>
                    <br></br>
                    <li>
                        {
                            "When we believe in good faith that it's necessary to protect our rights, protect your safety or the safety of others, or investigate fraud;"
                        }
                    </li>
                    <br></br>
                    <li>
                        {
                            "With a buyer or successor if Klothed is involved in a merger, acquisition, or similar corporate transaction. If a transaction happens, you will be notified via email and/or a prominent notice on the Website of any change in ownership, as well as any choices you will have as a result;"
                        }
                    </li>
                </ol>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"How we protect your data"}</div>
                <br></br>
                <div>
                    {
                        "The security of your information is important to us. Our team is dedicated to protecting your information and have put in place physical, electronic, and procedural safeguards."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "These measures include limiting access, using encryption, testing for vulnerabilities, advanced malware detection, employing pseudonymization and anonymization techniques, and more."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Though we hate to say it, despite our efforts, we can't guarantee that user information will not be accessed, viewed, disclosed, altered, or destroyed as a result of a breach of any of our safeguards. You provide us with information at your own risk."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Klothed retains information about you as long as you maintain your account.  If you delete your account, we’ll keep information we need to meet our legal obligations and to defend against legal claims."
                    }
                </div>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"Terminating Your Account and Managing Emails"}</div>
                <br></br>
                <div>
                    {
                        "We've made it easy close your Klothed account at any time. Simply login, go to the “SETTINGS” page and click on “Delete my account”. Then uninstall the Klothed browser extension.  You can also uninstall the Klothed Mobile App in the same way you would uninstall other mobile apps from your mobile device."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "You can manage emails.  You can turn off and on a) emails, b) Reminder Emails and c) Push notification emails.  Please note that limiting emails s may impact Klothed's ability to provide certain of the Services to you."
                    }
                </div>
                <br></br>
                <div>
                    {"If you would like to delete your profile or personal information, you can always ask us by emailing "}
                    <a className="orangeText" href="mailto:privacy@getklothed.com">
                        {"privacy@getklothed.com"}
                    </a>
                    {
                        ". We will delete any personal information we have, although the following exceptions apply: (1) we may still store your personal information if we need it to meet our legal or compliance obligations and/or to defend against  legal claims; and (2) we may also continue to store your personal data in an aggregated and anonymized format that doesn’t identify you and can’t be attributed to you, even after you’ve deleted your profile."
                    }
                </div>
                <br></br>
                <div style={{ fontSize: "24px" }}>{"Additional rights regarding your data"}</div>
                <br></br>
                <div>
                    {
                        "Depending on where you live, you may have additional rights as to the personal information that you share and Klothed collects. Residents of California can click below to learn more."
                    }
                </div>
                <br></br>
                <div style={{ fontStyle: "italic" }}>{"California residents."}</div>
                <br></br>
                <div>{"If you live in California and use Klothed, you have some additional rights when it comes to your data."}</div>
                <ul>
                    <li>{"Right to Delete: You can ask us to delete the personal information we have about you."}</li>
                    <br></br>
                    <li>
                        {
                            "Right to Know: You can ask us for a list of the categories of personal information we have about you, the categories of sources from which we get personal information, the business purposes for which we collect personal information, and the categories of third parties with whom it is shared (you can also see that in this Privacy Policy). You also can ask us for a copy of the pieces of personal information we have about you."
                        }
                    </li>
                </ul>
                <br></br>
                <div>
                    {
                        "California law gives you the right to opt out of the sale of your personal information. But Klothed does not and will not sell (or rent, or disclose for value) any of your personal information in the first place. So you're good."
                    }
                </div>
                <br></br>
                <div>
                    {"To exercise any of these rights, you (or your authorized agent) can contact us through the “Contact us” webform on our "}
                    <Link className="orangeText" to={process.env.REACT_APP_WEB_HELP || "#"} target={"_blank"}>
                        {"Help page"}
                    </Link>
                    {" or by sending an email to "}
                    <a className="orangeText" href="mailto:privacy@getklothed.com">
                        {"privacy@getklothed.com"}
                    </a>
                    {
                        ". We will confirm receipt of your request within 10 days, and will provide a response to you within 45 days of your request, though in some exceptional cases it might take longer (if that happens, we'll let you know). We will need your name and email address to verify your request, and may also ask for additional information if necessary to verify the identity of the person making the request. We reserve the right to deny your request if we cannot verify your identity, or if an exemption applies (for example, where fulfilling your request would adversely affect other individuals, or where we have a conflicting legal obligation). That said, we will not discriminate against you for exercising any of your privacy rights."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "To learn about the categories of personal information that Klothed uses, please see the section of this policy called “What data we collect and why.” The personal information that we have collected in the past 12 months falls into the following categories as set out under California law:"
                    }
                </div>
                <ul>
                    <li>{"The contact information you provide – California law refers to these as identifiers."}</li>
                    <br></br>
                    <li>
                        {
                            "If you shop through the Klothed Mobile App, other individual information like billing address, credit or debit card information – this category includes personal information protected under pre-existing California law (Cal. Civ. Code 1798.80(e)), and overlaps with other categories."
                        }
                    </li>
                    <br></br>
                    <li>
                        {
                            "We don't track your location, but your IP address gives us a general idea of the city, state, and country - what California law calls geolocation."
                        }
                    </li>
                    <br></br>
                    <li>{"Shopping and Klothed usage data – California law calls this internet activity."}</li>
                    <br></br>
                    <li>{"Purchases on retail sites when using Klothed – this is commercial information under California law."}</li>
                </ul>
                <br></br>
                <div>
                    {
                        "To learn about the limited ways in which we disclose data for our business purposes -- that is, to a service provider -- and the categories of those service providers, please see the section of this policy called “How we share your data.”"
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Please note that, while Klothed provides tools to manage your privacy as described in this policy, we do not support “Do Not Track” browser settings at this time."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "Lastly, California residents also have the right to request this information in an accessible alternative format. If you have a disability and would like to access this privacy policy in an alternative format, please contact us by email or mail as detailed below."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Minors"}</div>
                <div>
                    {
                        "We created Klothed for the exclusive use of adults (18 and older) or teens from 13 to 17 with their parent’s consent. We don't knowingly collect or solicit personal information from children. If you are a child 13-17, please do not attempt to use or register for Klothed's services or send any personal information to us without your parents’ consent."
                    }
                </div>
                <br></br>
                <div>
                    {
                        "If we learn we have collected personal information from someone under 13 or from 13-17 without their parents’ consent, we will delete that information as quickly as possible. If you are a parent or guardian of a child who you think may have given us some personal information or posted information on any public portion of Klothed's services, please let us know at "
                    }
                    <a className="orangeText" href="mailto:privacy@getklothed.com">
                        {"privacy@getklothed.com"}
                    </a>
                    {". We'll help you remove it."}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Changes to this policy"}</div>
                <div>
                    {
                        "We will continue to update our policies and practices as needed. We will notify you of any changes to our Privacy Policy by posting any changes here. If we do, you'll see that the date at the top of this Privacy Policy has changed."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"How to contact us"}</div>
                <div>
                    {"If you have any questions about our privacy policies and practice, please contact us at "}
                    <a className="orangeText" href="mailto:privacy@getklothed.com">
                        {"privacy@getklothed.com"}
                    </a>
                    {"."}
                </div>
            </div>
        </div>
    ) : (
        <div className={`legal_section ${props.className}`}>
            <div className="leftTop_heading">{"TERMS OF SERVICE"}</div>
            <div className="horizonta_seperator_legal"></div>
            <div className="rightTop_heading">{"Terms Of Service"}</div>
            <div className="revisionDate_legal">{"Last updated November 21, 2022"}</div>
            <div className="legal_text">
                <div style={{ fontWeight: "bold" }}>{"Introduction"}</div>
                <div>{`These Terms of Service (the "Agreement") are a contract between you and Klothed Technologies Inc. ("Klothed") and govern your access to and use of the Klothed mobile application (the "Klothed Mobile App"), the Klothed browser extension, the Klothed website located at ${process.env.REACT_APP_WEB_URL} and all other Klothed-branded websites (each, a "Website"), and other software applications and online services provided by Klothed (collectively, the "Service").`}</div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Accepting the Terms"}</div>
                <div>{`By using any portion of the Service, you agree to comply with and be bound by this Agreement. The term "you" or "User" or “Shopper” refers to the user of our Service . The terms "we" or "our" or “us” refer to Klothed. You must be an individual and at least 13 years old, or the age of majority in your state, province, or country of residence, to use the Service. If you are under 18, you represent that you have your parent or guardian’s permission to use the Service. Please have them read this Agreement with you. You may not use the Service or accept this Agreement if you are not of a legal age and able and competent to form a binding contract with Klothed or if you are at least 13,under 18, and do not have your parent’s approval. If you use the Service, you represent that you have the capacity to be bound by this Agreement. The Service is for our customers’ personal, non-commercial shopping use. You agree that you are not using the Service for or on behalf of anyone other than you or for any commercial purpose.`}</div>
                <br></br>
                <div>
                    {
                        "THIS AGREEMENT CONTAINS (1) AN ARBITRATION PROVISION; (2) A WAIVER OF RIGHTS TO BRING A CLASS ACTION LAWSUIT AGAINST US; AND (3) A RELEASE BY YOU OF CLAIMS FOR DAMAGE AGAINST US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICE, TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. BY USING THE SERVICE, YOU AGREE TO THESE PROVISIONS."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Amendments to this Agreement"}</div>
                <div>
                    {
                        "Klothed may amend this Agreement or other policies that govern the Service from time to time. Any changes to the Agreement will be posted to the Terms of Service, and the posted Agreement will indicate the date the Agreement was last revised. If you do not agree to the changes, you must uninstall any applicable Klothed software and stop using the Service. By continuing to use the Service after any changes to this Agreement are posted on the Website, you agree to be bound by those changes."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Privacy and your Personal Information"}</div>
                <div>
                    {`The Service is subject to Klothed's privacy and data protection practices (our "Privacy Policy"). Please read Klothed's `}
                    <a className="orangeText" href={process.env.REACT_APP_WEB_PRIVACY} target={"_blank"} rel="noreferrer">
                        {"Privacy Policy"}
                    </a>
                    {
                        ". Our Privacy Policy explains how Klothed collects, uses and shares your information when you visit our Website, use the Klothed Mobile App or otherwise access the Service. By using the Service, you consent to Klothed's collection, use, and sharing of your information as set forth in our Privacy Policy."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Description of the Service"}</div>
                <div>
                    {
                        "The Service provides registered Klothed shoppers with access to Klothed’s unique generative AI software  designed to help shoppers build purchase confidence and aid in their decision to purchase a particular clothing item.  Klothed requires that the clothing must be displayed on a product model and only works on specific merchant websites that are listed "
                    }
                    <a className="orangeText" href={process.env.REACT_APP_WEB_RETAILERS} target={"_blank"} rel="noreferrer">
                        {"here"}
                    </a>
                    {
                        ". Once you have signed up for our service, which is free to you, you need only upload a photo of yourself and provide Klothed with your height for our software to do its magic.  Then, when you visit a Webpage displaying a product that you would like to try on,  click on the Klothed “K” icon that will appear on the page of supported merchants and Klothed shows you how you would look wearing the same clothes as the model.  We call this a try-on picture.  The try-on picture will bear the Klothed “getKlothed”™ logo.  This generative AI was created using advanced neural network and machine learning techniques."
                    }
                    <br></br>
                    <br></br>
                    {
                        "Also, all your try-on pictures will be saved to your CLOSET on our Website.  You can access your CLOSET when you sign in.  In MY CLOSET, you will be able to view your try-ons and go directly to the merchant’s product page by clicking on the picture should you decide to make a purchase. "
                    }
                    <br></br>
                    <br></br>
                    {
                        "Additional Klothed Service features may include saving the try-on picture, allowing you to share the try-on picture with friends,family, or social media followers before and after a clothing purchase, maintaining a list of supported merchant links to browse and such other related features as Klothed may develop from time to time.  Klothed may offer a variety of ways to access aspects of our Service, including our Website, browser extension, and the Klothed Mobile App. All aspects of our Service may not be available through all channels. For example, some aspects of our Service may only be available through the Klothed browser extension."
                    }
                    <br></br>
                    <br></br>
                    {
                        'Klothed does not charge you fees for its Service. When you  click on the "K" icon to create a try-on picture, it will cause a cookie or other affiliate code or link identifying Klothed to be placed.  This will allow the marketing affiliate network, the retailer, or other entity to associate any purchase you make with Klothed.  When you then make a purchase or click on a merchant link while using Klothed or someone you share a try-on picture with clicks on a link to the merchant, the merchant may pay us a fee either directly or through an affiliate network or aggregator. That is how we make money with your personal information.  We do not need to (or want to) sell your personal information.'
                    }
                    <br></br>
                    <br></br>
                    {"Any information collected from you is used solely in accordance with our "}
                    <a className="orangeText" href={process.env.REACT_APP_WEB_PRIVACY} target={"_blank"} rel="noreferrer">
                        {"Privacy Policy"}
                    </a>
                    {"."}
                    <br></br>
                    <br></br>
                    {
                        "We are constantly working to improve our Klothed software and services to make your try-on picture as accurate and the services as easy to use as possible.  To help improve picture accuracy and our services, we review submissions and suggestions made by shoppers like you.  You understand and acknowledge that any improvements, modifications and changes arising from or in connection with the information you convey relating to the Klothed try-on pictures and/or services, remain or become the exclusive property of the Klothed."
                    }
                    <br></br>
                    <br></br>
                    {
                        "While we cannot guarantee complete try-on picture accuracy or that we will be able to create a try-on picture with every model picture, we believe that you will find that our try-on picture will significantly help you make better style selections and reduce returns in many, many instances."
                    }
                    <br></br>
                    <br></br>
                    {
                        "Users are responsible for their purchase decision including being aware of any limitation in the applicable merchant website return policy.Klothed Does Not Warranty Any Purchase By The User Or Make Any Guarantees Regarding Purchased Items Or How Those Items May Fit And Look On The Use."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Geographic Limitations"}</div>
                <div>
                    {
                        "At this time, the Services may only be used by you if you are physically located within the United States and its territories.  You agree not to access the Service from outside the United States."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Beta Testing"}</div>
                <div>
                    {`During Beta testing of Klothed, all features described on the Website may not yet be enabled or fully functional.  You will automatically transition to the production version of Klothed when Beta testing ends.`}
                    <br></br>
                    <br></br>
                    <div style={{ fontWeight: "bold" }}>{"Beta Tester Confidentiality And Non-Disclosure Provisions"}</div>
                    {`During Beta testing of Klothed, all features described on the Website may not yet be enabled or fully functional.  You will automatically transition to the production version of Klothed when Beta testing ends.`}
                    <br></br>
                    <br></br>
                    <div style={{ fontWeight: "bold" }}>{"Purpose"}</div>
                    {`From time to time, Klothed may conduct beta testing programs ("Beta Test") to improve its software and services.   During a Beta Test, Klothed may disclose confidential information to participants.  If you participate in a Beta Test program, any such disclosure of confidential information shall be subject to the terms and conditions in these Beta Tester Confidentiality and Non-Disclosure Provisions (the “`}
                    {<b>Confidentiality Provisions</b>}
                    {"“)."}

                    <br></br>
                    <br></br>
                    <div style={{ fontWeight: "bold" }}>{"Definitions"}</div>
                    {`Confidential Information” means any and all information, oral as well as visual or written (including graphic material), that you receive from the Klothed, before or after this Confidentiality Agreement is entered into,  and which is related to one or more of the following: `}
                    <br></br>
                    <br></br>
                    {"-the Purpose;"}
                    <br></br>
                    <br></br>
                    {
                        "-the business, product, financial, operational, technical, administrative, marketing, planning, know-how, research, development and staff information, methods, records and data of the Klothed;"
                    }
                    <br></br>
                    <br></br>
                    {
                        "-proprietary information, data, know-how, formulae, processes and engineering processes, strategies, designs, photographs, drawings, specifications, software, inventions, patents, technology, hardware configuration information, samples, technical literature, contract terms and data or other material attributable to or deriving its existence from discussions relating to the Purpose and/or the Klothed; and"
                    }
                    <br></br>
                    <br></br>
                    {"-any notes, extracts, analyses or materials prepared by or on behalf of you which are copied or derived from Confidential Information."}
                    <br></br>
                    <br></br>
                    {"-Information shall not be considered as Confidential Information if the Klothed expressly declares it to be non-confidential."}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Confidentiality Promise"}</div>
                <div>
                    <br></br>
                    <b>You promise to:</b>
                    <br></br>
                    <br></br>
                    {"-keep secret and not disclose or reveal any Confidential Information obtained from Klothed;"}
                    <br></br>
                    <br></br>
                    {
                        "-not without prior written consent of Klothed, use the Confidential Information for any other purpose than the Purpose of these Confidentiality Provisions ;"
                    }
                    <br></br>
                    <br></br>
                    {"-take all steps necessary to prevent Confidential Information from being disclosed to any other person;"}
                    <br></br>
                    <br></br>
                    {"-take all steps necessary to prevent Confidential Information from being disclosed to any other person;"}
                    <br></br>
                    <br></br>
                    {
                        "-not without prior written consent of Klothed, make any record or copy of any drawing, description, specification, or other document or other disclosure or embodiment of the Confidential Information; and"
                    }
                    <br></br>
                    <br></br>
                    {
                        "-not copy, reverse engineer, reverse compile, or attempt to derive the composition of, or information underlying, the Confidential Information"
                    }
                    <br></br>
                    <br></br>
                    <b>You acknowledge and agree that unauthorized use or disclosure of Confidential Information may violate applicable trade secret laws.</b>
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Permitted Disclosure"}</div>
                <div>
                    <br></br>
                    {"The confidentiality undertaking above shall not apply to any Confidential Information that the you can establish:"}
                    <br></br>
                    <br></br>
                    {"-is or becomes available to the public (otherwise than by breach of this Agreement or any other confidentiality undertaking);"}
                    <br></br>
                    <br></br>
                    {"-was available to the you on a non-confidential basis prior to its disclosure to the you;"}
                    <br></br>
                    <br></br>
                    {
                        "-is independently developed by you (provided that the information developed does not include or rely on any Confidential Information); or"
                    }
                    <br></br>
                    <br></br>
                    {
                        "-that it is required to disclose by law or by the rules of any regulatory body to which you are subject, provided that it provides reasonable prior notice to the Klothed, unless a court prohibits such notice."
                    }
                </div>
                <br></br>
                <div>{"Return Or Destruction Of Confidential Information"}</div>
                <div>
                    <br></br>
                    {
                        "-Upon the Klothed’s request, all Confidential Information received by you shall, regardless of medium, be immediately returned or destroyed and you shall thereafter not use received Confidential Information for any purposes whatsoever. you undertake to, upon request by Klothed, certify in writing that such destruction or return has occurred."
                    }
                    <br></br>
                    <br></br>
                    {"-The return or destruction terms  above shall not apply if:"}
                    <ul>
                        <li style={{ marginLeft: "40px" }}>{"you are required to keep the Confidential Information under Applicable Law; or"}</li>
                        <li style={{ marginLeft: "40px" }}>
                            {
                                "the Confidential Information is contained in computer records or electronically stored files which may not be destroyed with reasonable measures."
                            }
                        </li>
                    </ul>
                    <br></br>
                    {
                        "-Any Confidential Information retained with reference to this provision shall remain strictly confidential in accordance with the confidentiality obligations of this Confidentiality Provisions."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"No Warranty Or Grant Of Rights"}</div>
                <div>
                    <br></br>
                    {
                        "You acknowledge that Klothed does not make any expressed or implied representation or warranty as to the accuracy or completeness of the Confidential Information. You agree that you are not entitled to rely on the accuracy or completeness of the Confidential Information provided by  Klothed."
                    }
                    <br></br>
                    <br></br>
                    {
                        "The disclosure of Confidential Information by Klothed shall not form any offer by, or representation or warranty on the part of, Klothed to enter into any further agreement in relation to the Purpose."
                    }
                    <br></br>
                    <br></br>
                    {"This Confidentiality Agreement does not grant you any right, title or interest in or to the Confidential Information."}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Breach Of Contract"}</div>
                <div>
                    <br></br>
                    {
                        "In addition to any other legal remedies that Klothed may have, you shall be responsible to Klothed for any breach of these Confidentiality Provisions by you and by any other person or entity that may receive any Confidential Information from or through you."
                    }
                    <br></br>
                    <br></br>
                    {
                        "You acknowledge and agree that a breach by you of these Confidentiality Provisions may result in immediate and irreparable harm to Klothed, for which there will be no adequate remedy at law. Without prejudice to any other rights and remedies it may have, the Klothed will be entitled to seek equitable relief to compel you to cease and desist all unauthorized use and disclosure of its Confidential Information. "
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Notification and Waiver"}</div>
                <div>
                    <br></br>
                    {
                        "You will notify the Klothed immediately in writing if it becomes aware that the Confidential Information has been disclosed to an unauthorized third party."
                    }
                    <br></br>
                    <br></br>
                    {"A waiver of any term of these Confidentiality Provisions will be valid only if it is in writing and signed by Klothed."}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Beta Tester Duties"}</div>
                <div>
                    <br></br>
                    {
                        "You agree to make reasonable efforts to report any flaws, errors or imperfections discovered during Beta Testing of the try-on services. You  understand and acknowledge that any improvements, modifications and changes arising from or in connection with the information you convey in connection with the Purpose, remain or become the exclusive property of Klothed as provided in the Terms of Service."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Merchant Websites and Purchases"}</div>
                <div>
                    <br></br>
                    {
                        "You may reach a third-party merchant website through a link from our Website or a merchant website may be displayed to you through the Klothed Mobile App. However, in all cases any merchant product will be purchased directly from the merchant and through the merchant's website or merchant App. You are not buying the product from or through Klothed. Klothed is not a seller, reseller or distributor of any clothing or other products. It is strictly a provider of Klothed Services that you can use to create your own try-on picture and save or share it with others."
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Merchant Returns"}</div>
                <div>
                    <br></br>
                    {
                        "If you are unhappy with your merchant product purchase, if you wish to return your merchant product, if you ordered a merchant product and it has not arrived, or if you have another customer service inquiry regarding your purchased merchant product, you will need to contact the third-party merchant from whom you purchased the product. Klothed is not involved in your merchant product purchase and is not able to assist with any customer service inquiries or returns related to that purchase."
                    }
                    <br></br>
                    <br></br>
                    <b>Klothed Is Not Responsible for Third-Parties</b>
                    {" (a person or entity who not a party to this Agreement between you and Klothed is referred to in this Agreement as a “third-party”)"}
                    <br></br>
                    <br></br>
                    {
                        "All products you purchase from a third-party merchant, including those linked from our Website or Klothed or displayed through the Klothed Mobile App or through the Klothed browser extension, or the Klothed mobile browser extension, are: (a) priced by the applicable third-party merchant (including whether such prices include applicable local, provincial, state, or federal taxes, and any additional fees, such as shipping and handling); and (b) fulfilled, shipped, and otherwise serviced (including but not limited to the processing of returns, refunds, and cancellations) by the applicable third-party merchant and not by Klothed. Klothed is not responsible for any third-party merchant products or any information contained on the third-party merchant's website (including if the third-party merchant's website is displayed through the Klothed Mobile App)."
                    }
                    <br></br>
                    <br></br>
                    {
                        "You agree that your purchase from a third-party merchant is subject to such merchant's own terms and conditions applicable to such purchase. You agree that Klothed is in no way responsible or liable to you for any products you purchase through or using the Service, including for any product liability claim, improper charges, delivery failures, pricing errors, or inaccurate product descriptions. You acknowledge and agree that Klothed is not liable or otherwise responsible for any breaches of credit card or debit card security or privacy by any third-party merchants."
                    }
                    <br></br>
                    <br></br>
                    {
                        "Klothed does not endorse, warrant or guarantee the products or services that are displayed through the Service, including any offers for third-party products and other advertised links to third-party websites made available to you by Klothed. Klothed is not an agent, distributor, re-seller, broker or otherwise responsible for third-party merchants or the activities or policies of those merchant websites, or the products or services available on them. Klothed does not promise or guarantee that the product details, prices, coupon availability or other service terms, rates or rewards offered by any particular advertiser or other third-party via our Service are the best prices, best terms or lowest rates available in the market."
                    }
                    <br></br>
                    <br></br>
                    {
                        "When you access third-party websites through our Service, you accept that there are risks in doing so, and that Klothed is not responsible for such risks. We encourage you to be aware when you are on a third-party merchant website and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize."
                    }
                    <br></br>
                    <br></br>
                    {
                        "Klothed has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices or opinions expressed in any third-party websites or by any third-party that you interact with through the Service. In addition, Klothed will not and cannot monitor, verify, censor or edit the content of any third-party site or service."
                    }
                    <br></br>
                    <br></br>
                    {
                        'By using the Service, you release and hold us harmless from any and all liability arising from your use of any third-party website or service. If you have a dispute with any third-party (including a merchant from whom you purchase a product in connection with the Service), you agree that Klothed is under no obligation to become involved. To the maximum extent permitted by applicable law, you release Klothed, and any parent company, our affiliates, and each of their respective officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to any dispute you have with a third-party in connection with the Service. If you are a California resident, you shall and hereby do waive California Civil Code Section 1542, which says: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY."'
                    }
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Klothed Mobile App and Browser Extensions"}</div>
                <div>
                    <br></br>
                    {`The Klothed Mobile App and browser extensions work on an application linked to a particular device and/or operating system such as Apple's iOS operating system or Android or Windows or macOS, and for the browser extension, Safari or Chrome or Edge. Your use of the Klothed Mobile App or browser extension may be subject to separate agreements you may enter into with your mobile device operating system or Web browser provider (e.g., Apple®, Google® or Microsoft® your mobile device manufacturer (e.g., Apple®, Samsung®), your mobile service carrier (e.g., AT&T® or Verizon®), and other parties involved in providing your mobile device service or Web browser services (which we individually refer to as a “Covered Third Party” and which we collectively refer to as "Covered Third Parties"). You agree to comply with all applicable Covered Third Party terms of service when using the Klothed Mobile App or browser extension. We are not a party to those terms of service agreements and we have no responsibility for the products and services provided by Covered Third Parties. You acknowledge and agree that this agreement is between you and Klothed, not with any Covered Third Party. You acknowledge and agree that we (and not any Covered Third Party) are solely responsible for the Klothed Mobile App and browser extension and for providing any maintenance or support services for the Klothed Mobile App and browser extension.`}
                    <br></br>
                    <br></br>
                    {`Covered Third Parties have no warranty obligations whatsoever with respect to the Klothed Mobile App or browser extension, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure of the Klothed Mobile App or browser extension to conform to any warranty provided by us (if any), will be our sole responsibility.`}
                    <br></br>
                    <br></br>
                    {`We, not any Covered Third Parties, are responsible for addressing any claims relating to the Klothed Mobile App and browser extension, including, but not limited to: (i) product liability claims; (ii) any claim that the Klothed Mobile App or browser extension fails to conform to any applicable legal or regulatory requirement; (iii) claims arising under consumer protection, privacy, or similar legislation; and (iv) intellectual property claims.`}
                    <br></br>
                    <br></br>
                    {`If you are using the Klothed Mobile App or browser extension on an Apple® or Google® or other device, you acknowledge and agree that Apple® or Google®  (as applicable), and their subsidiaries (as applicable), are third party beneficiaries of this Agreement, and that they will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third-party beneficiary. Such rights may also accrue to other handset manufacturers and operating systems which participate in the Klothed Mobile App or allow the use of the browser extension.`}
                    <br></br>
                    <br></br>
                    {`To the extent the other terms and conditions of the rest of this Agreement conflict with the terms and conditions of this section, the terms and conditions in this section apply, but solely with respect to your use of the Klothed Mobile App or browser extension downloaded from the Apple App Store or Google Play Store.`}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Account Information from Third Party Sites"}</div>
                <div>
                    <br></br>
                    {`When you establish an account with Klothed, we may allow you to register your account, log-in, or otherwise link your Klothed account with an account you maintain with a third-party, such as Facebook® or Google® ("Linked Account"). You represent that any Linked Account belongs to you and you are permitted to link it to your Klothed account. Klothed does not control the policies and practices of any third-party site or service, including any Linked Account you connect to the Service and Klothed is not responsible for any actions or omissions of providers of a Linked Account, including related to accuracy, legality or non-infringement. Klothed is not responsible for the products and services offered by or through your Linked Account. If permitted by your Linked Account, Klothed may import information from your Linked Accounts to help better offer the Service to you.`}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Your Log-In and Account Information"}</div>
                <div>
                    <br></br>
                    {`You agree and understand that you are responsible for maintaining the confidentiality of your Login ID, email address, password and any other personal identifying information or other codes used to access your Klothed account or the Service ("Login Information''). If you become aware of any unauthorized use of your Log-In Information, you agree to notify Klothed immediately.`}
                    <br></br>
                    <br></br>
                    {`Accurate records enable Klothed to provide the Service to you. You agree to keep your email address, your other contact information in your Klothed account profile accurate and up-to-date. If you do not do this, the accuracy and effectiveness of the Service to you will be affected and you may not receive account notices that we deliver to you. If you fail to keep your contact information updated, you will still be deemed to have received any notices we deliver to the email address you last provided.`}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Electronic Notices"}</div>
                <div>
                    <br></br>
                    {`By providing us with your email address, you agree to receive all account related notices electronically, to that email address. It is your responsibility to update or change that address, as appropriate. Notices will be provided in HTML (or, if your system does not support HTML, in plain-text) in the text of the e-mail or through a link to the appropriate page on our site, accessible through any standard modern, commercially available internet browser. We may also use your email address to contact you about our own and third-parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile and /or click "Unsubscribe" in any e-mail communications we send you.`}
                </div>
                <br></br>
                <div style={{ fontWeight: "bold" }}>{"Electronic Notifications"}</div>
                <div>
                    <br></br>
                    {`Klothed may provide you with electronic notifications by email, push notification or other electronic means. Some electronic notifications are mandatory, including security-related notifications, such as when you update your log-in information or password.`}
                    <br />
                    <br />
                    {`Other types of notifications are voluntary and may be modified through the Service. They may be customized, deactivated or reactivated by you. These notifications allow you to choose alert messages for your account. Klothed may add new notifications from time to time, or cease to provide certain notifications at any time upon its sole discretion. Each alert has different options available, and you may be asked to select from among these options upon activation of your notifications service. You may also be able to modify how you receive push notifications via the Klothed Mobile App through your mobile device by changing your mobile device settings.`}
                    <br />
                    <br />
                    {`Electronic notifications will be sent to the email address you have provided as your primary email address for the Service.`}
                    <br />
                    <br />
                    {`Because notifications are not encrypted, we will never include your password. However, notifications may include your Klothed login information and some information about your accounts. Anyone with access to your email will be able to view the content of these notifications. While you may disable certain voluntary notifications, we will still send you Service-related notices as needed to allow us to provide you the Service.`}
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Klothed's Intellectual Property Rights"}</div>
                <div>
                    <br />
                    {`The contents of the Service, including its "look and feel" (e.g., text, graphics, images, logos and button icons), photographs, editorial content, notices, software (including html-based computer programs) and other material are protected under copyright, trademark and other laws. The contents of the Service belong or are licensed to Klothed or its software or content suppliers. Klothed grants you the right to view and use the Service subject to this Agreement. Any distribution, reprint or electronic reproduction of any content from the Service, other than as expressly permitted in this Agreement or with our prior written consent, is prohibited. If you would like to request such permission, shoot us an email at `}
                    <a className="orangeText" href="mailto:info@getklothed.com">
                        {"info@getklothed.com"}
                    </a>
                    {`.`}
                    <br />
                    <br />
                    {`Except as may be expressly permitted by the Service features, your right to access and use the Service is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use our Service for lawful, internal, and personal non-commercial  shopping purposes. Subject to your compliance with this Agreement, Klothed hereby grants to you, a personal, worldwide, non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to (i) use the software and content provided to you as part of the Service (for personal, non-commercial purposes; and (ii) download a single copy of our software for such use.`}
                    <br />
                    <br />
                    {`You agree that Klothed may use your feedback, suggestions, or ideas in any way, including in future modifications of the Service, other products or services, advertising or marketing materials (although any use of your personal information will be subject to our privacy policy).`}
                </div>
                <div style={{ fontWeight: "bold" }}>{"Technology Disclaimer"}</div>
                <div>
                    <br />
                    {`Your access and use of the Service may be interrupted from time to time due to third-party failures outside of our control (such as unavailability of general Internet infrastructure). Access and use may also be interrupted for periodic updating, maintenance or repair of the Service by Klothed. While we will make reasonable efforts to make the Service available to you, we do not promise that it will be available at all times.`}
                    <br />
                    <br />
                    {`You are responsible for providing the mobile device, wireless service plan, software, Internet connections, and/or other equipment or services that you need to download, install, and use the Klothed Mobile App. We do not guarantee that the Klothed Mobile App can be accessed and used on any particular mobile device or with any particular service plan. We do not guarantee that the Klothed Mobile App will be available in, or that orders for products can be placed from, any particular geographic location.`}
                    <br />
                    <br />
                    {`Klothed cannot always foresee or anticipate technical or other difficulties which may result in failure to obtain data or loss of data, personalization settings or other service interruptions. Klothed cannot assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure to store any user data, communications or personalization settings.`}
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Rules for Posting Content"}</div>
                <div>
                    <br />
                    {`As part of the Service, Klothed may allow Users to post content on various publicly available locations in the Service ("User Content"). You agree in posting User Content to follow the following rules:`}
                    <ul>
                        <li>You are responsible for all User Content you submit to the Service.</li>
                        <li>
                            By submitting User Content to us, you represent that you have all necessary rights and hereby grant us a perpetual, worldwide,
                            irrevocable, non-exclusive, royalty-free, sublicensable and transferable license to use, reproduce, distribute, prepare derivative
                            works of, modify, display, and perform all or any portion of the User Content in connection with the Service and our business,
                            including without limitation for promoting and redistributing part or all of the site (and derivative works thereof) in any media
                            formats and through any media channels. You also hereby grant each User a non-exclusive license to access and use your User Content
                            through the Service, and to use, reproduce, distribute, prepare derivative works of, display and perform such User Content as
                            permitted through the functionality of the Service and under this Agreement.
                        </li>
                        <li>
                            You may not post or transmit any message, data, image or program that would violate the property rights of others, including
                            unauthorized copyrighted text, images or programs, trade secrets or other confidential proprietary information, and trademarks or
                            service marks used in an infringing fashion.
                        </li>
                        <li>
                            {`You may not interfere with other Users' use of the Service, including, without limitation, disrupting the normal flow of dialogue in an interactive area of the Service, deleting or revising any content posted by another person or entity, or taking any action that imposes a disproportionate burden on the Service infrastructure or that negatively affects the availability of the Service to others.`}
                        </li>
                    </ul>
                </div>
                <div style={{ fontWeight: "bold" }}>{"Prohibited Activities"}</div>
                <div>
                    <br />
                    <div>{`You represent, warrant, and agree that you will not contribute any content or otherwise use the Service or interact with the Service in a manner that:`}</div>
                    <ol>
                        <li>{"Infringes or violates the intellectual property rights or any other rights of anyone else (including Klothed);"}</li>
                        <li>{"Violates any law or regulation or this Agreement;"}</li>
                        <li>{"Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;"}</li>
                        <li>
                            {
                                "Jeopardizes the security of your Klothed account or anyone else's (such as allowing someone else to log in to the Service as you);"
                            }
                        </li>
                        <li>{"Attempts, in any manner, to obtain the password, account, or other security information from any other User;"}</li>
                        <li>{"Violates the security of any computer network, or cracks any passwords or security encryption codes;"}</li>
                        <li>
                            {
                                'Runs Maillist, Listserv, any form of auto-responder or "spam" on the Service, or any processes that run or are activated while you are not logged into the Service, or that otherwise interfere with the proper working of the Service (including by placing an unreasonable load on the Service\'s infrastructure) or that would bypass the navigational structure or presentation of the Service;'
                            }
                        </li>
                        <li>
                            {
                                '"Crawls," "scrapes," or "spiders" any page, data, or portion of or relating to the Service or Content (through use of manual or automated means);'
                            }
                        </li>
                        <li>{"Copies or stores any significant portion of the Content;"}</li>
                        <li>
                            {
                                "Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Service; or"
                            }
                        </li>
                        <li>
                            {
                                "Circumvents, removes, alters, deactivates, degrades or thwarts any protective or security measure, or any content protections, which Klothed has implemented as part of the Service; or "
                            }
                        </li>
                        <li>
                            {
                                "Results in the uploading of a photograph or picture of anyone other than yourself or publicly posting a picture created using the Service if that picture shows anyone other than yourself."
                            }
                        </li>
                    </ol>
                    <br />
                    {"If you violate or infringe any of the rules above, we may immediately suspend or terminate your right to use or access the Service."}
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"User Representations Concerning Legal Compliance"}</div>
                <div>
                    <br />
                    <b>
                        {
                            'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE CONTENT AND ALL SERVICES AND PRODUCTS ASSOCIATED WITH THE SERVICE OR PROVIDED THROUGH THE SERVICE (WHETHER OR NOT SPONSORED) ARE PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS. KLOTHED MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE CONTENT OR OPERATION OF THE SERVICE OR OF THE SERVICE. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.'
                        }
                    </b>
                    <br />
                    <br />
                    <b>
                        {
                            "KLOTHED MAKES NO REPRESENTATIONS, WARRANTIES, CONDITIONS, OR GUARANTEES, EXPRESS OR IMPLIED, REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE CONTENT ON THE SERVICE OR OF THE SERVICE (WHETHER OR NOT SPONSORED), AND EXPRESSLY DISCLAIMS ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE. KLOTHED MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE THAT THE CONTENT THAT MAY BE AVAILABLE THROUGH THE SERVICE IS FREE OF INFECTION FROM ANY VIRUSES OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE INTENDED TO DAMAGE, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE ANY SYSTEM, DATA OR PERSONAL INFORMATION. YOU ARE RESPONSIBLE FOR ENSURING THE ACCURACY OF ANY USER CONTENT THAT YOU PROVIDE, AND WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY FOR THE ACCURACY OF SUCH CONTENT."
                        }
                    </b>
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Notification Disclaimer"}</div>
                <div>
                    <br />
                    {
                        "You understand and agree that any notifications provided to you through the Service may be delayed or prevented by a variety of factors. Klothed does its best to provide notifications in a timely manner with accurate information. However, we neither guarantee the delivery nor the accuracy of the content of any notification. You also agree that Klothed shall not be liable for any delays, failure to deliver, or misdirected delivery of any notification; for any errors in the content of a notification; or for any actions taken or not taken by you or any third-party in reliance on a notification."
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Limitations on Klothed's Liability"}</div>
                <div>
                    <br />
                    {
                        "KLOTHED SHALL IN NO EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, REVENUE OR BUSINESS, ARISING IN WHOLE OR IN PART FROM YOUR ACCESS TO THE SERVICE, YOUR USE OF THE SERVICE OR THIS AGREEMENT, OR FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED ON OR THROUGH THE SERVICE, EVEN IF KLOTHED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, KLOTHED'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO $500.00 (FIVE HUNDRED UNITED STATES DOLLARS)."
                    }
                    <br />
                    <br />
                    {
                        "BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, THE LIABILITY OF KLOTHED SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. THEREFORE, THE FOREGOING LIMITATIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. NOTHING IN THIS AGREEMENT AFFECTS STATUTORY RIGHTS THAT CANNOT BE WAIVED OR LIMITED BY CONTRACT."
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Your Indemnification of Klothed"}</div>
                <div>
                    <br />
                    {
                        "You shall defend, indemnify and hold harmless Klothed and its officers, directors, shareholders, and employees, from and against all claims and expenses, including but not limited to attorney's fees and other legal expenses, in whole or in part arising out of or attributable to your use of the Service (including, but not limited to any purchases you make within the Klothed Mobile App or Browser Extension) or any breach of this Agreement by you or your violation of any law or the rights of a third-party when using the Service."
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Ending your Relationship with Klothed (and vice versa)"}</div>
                <div>
                    <br />
                    {"This Agreement will continue to apply until terminated by either you or Klothed as set out below."}
                    <br />
                    <br />
                    {
                        "If you want to terminate this Agreement, you may do so immediately at any time by selecting the “Delete my account” feature and by uninstalling the Klothed tool you are using, the Web extension and/or app, and stop visiting our Website and using the Service.  Any data records retained shall be retained in accordance with our privacy policy."
                    }
                    <br />
                    <br />
                    {
                        "Klothed may immediately, at any time, terminate this Agreement (and therefore your right to access and use the Service) by notifying you (if safe to do so) to the e-mail address provided by you as part of your account registration process."
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>
                    {"ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS"}
                </div>
                <div>
                    <br />
                    <b>
                        {
                            "Arbitration. YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD-PARTY) WITH REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THIS AGREEMENT, YOUR USE OF THE SERVICE, AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION ASSOCIATION'S RULES FOR ARBITRATION OF CONSUMER-RELATED DISPUTES AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED, HOWEVER, THAT TO THE EXTENT THAT YOU HAVE IN ANY MANNER VIOLATED OR THREATENED TO VIOLATE OUR INTELLECTUAL PROPERTY RIGHTS, WE MAY SEEK INJUNCTIVE OR OTHER APPROPRIATE RELIEF IN ANY STATE OR FEDERAL COURT IN THE STATE OF CALIFORNIA. DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION."
                        }
                    </b>
                    <br />
                    <br />
                    {"If you wish to begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to:"}
                    <br />
                    <br />
                    {`Klothed Technologies
Attn: Dispute
279 Mill Road
Westhampton Beach, NY 11978
`}

                    <br />
                    <br />
                    {
                        "As an alternative, you may bring your claim in your local \"small claims\" court, if permitted by that small claims court's rules and if within such court's jurisdiction, unless such action is transferred, removed or appealed to a different court. You may bring claims only on your own behalf. Neither you nor we will participate in a class action or class-wide arbitration for any claims covered by this agreement to arbitrate. BY AGREEING TO THIS ARBITRATION CLAUSE & CLASS ACTION WAIVER, YOU AND KLOTHED ARE GIVING UP THE RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM THAT EACH SIDE MAY HAVE AGAINST THE OTHER INCLUDING ANY RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. You also agree not to participate in claims brought in a private attorney general or representative capacity, or consolidated claims involving another person's account, if we are a party to the proceeding. This dispute resolution provision will be governed by the Federal Arbitration Act and not by any state law concerning arbitration. In the event the American Arbitration Association is unwilling or unable to set a hearing date within one hundred sixty (160) days of filing the case, then either we or you can elect to have the arbitration administered instead by the Judicial Arbitration and Mediation Services. Judgment on the award rendered by the arbitrator may be entered in any court having competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator will not have authority to award damages, remedies or awards that conflict with this Agreement. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of, related to or connected with the use of the Service or this Agreement must be filed within one (1) year after such claim of action arose or be forever banned."
                    }
                    <br />
                    <br />
                    {
                        "If you do not want to arbitrate disputes with Klothed and you are an individual, you may opt out of this ARBITRATION CLAUSE & CLASS ACTION WAIVER by sending an email to "
                    }
                    <a className="orangeText" href="mailto:iprivacy@getklothed.com">
                        {"privacy@getklothed.com"}
                    </a>
                    {" within thirty (30) days of the first date you access or use the Service. "}
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Severability."}</div>
                <div>
                    <br />
                    {
                        "If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration section will be null and void. "
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Survival"}</div>
                <div>
                    <br />
                    {"This arbitration agreement will survive the termination of your relationship with us."}
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Governing Law and Forum for Disputes"}</div>
                <div>
                    <br />
                    {
                        "This Agreement, and your relationship with Klothed under this Agreement, shall be governed by the laws of the State of New York in the United States of America without regard to its conflict or choice of laws provisions. Any dispute with Klothed, or its officers, directors, employees, agents or affiliates, arising under or in relation to this Agreement shall be resolved exclusively as specified in the ARBITRATION CLAUSE & CLASS ACTION WAIVER clause above, except with respect to imminent harm requiring temporary or preliminary injunctive relief in which case Klothed may seek such relief in any court with jurisdiction over the parties. You understand that, in return for agreement to this provision, Klothed is able to offer the Service at the terms designated, with little or no charge to you, and that your assent to this provision is an indispensable consideration to this Agreement."
                    }
                    <br />
                    <br />
                    {
                        "You also acknowledge and understand that, with respect to any dispute with Klothed, its officers, directors, employees, agents or affiliates, arising out of or relating to your use of the Service or this Agreement:"
                    }
                    <ul>
                        <li>{"OU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY; and"}</li>
                        <li>
                            {
                                "YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT INVOLVING ANY SUCH DISPUTE."
                            }
                        </li>
                    </ul>
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Copyright Disputes and User Content"}</div>
                <div>
                    <br />
                    {`You may have heard of the Digital Millennium Copyright Act (the "DMCA"), as it relates to online service providers, like Klothed, being asked to remove user generated material that allegedly violates someone's copyright. We respect others' intellectual property rights, and we reserve the right to delete or disable User Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers; to review our complete Copyright Dispute Policy and learn how to report potentially infringing content, visit ${process.env.REACT_APP_WEB_URL}/copyright . To learn more about the DMCA, visit `}
                    <a className="orangeText" href={"https://www.copyright.gov/reports/studies/dmca/dmca_executive.html"} target={"_blank"} rel="noreferrer">
                        {"https://www.copyright.gov/reports/studies/dmca/dmca_executive.html"}
                    </a>
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Consumer Rights Notices"}</div>
                <div>
                    <br />
                    {"California Consumer Rights Notice. Under California Civil Code Section 1789"}
                    <br />
                    <br />
                    {
                        "California users of the Service receive the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952- 5210."
                    }
                    <br />
                    <br />
                    {
                        "Maryland Consumer Rights Notice. For Members using Klothed's checkout with gift card service, the Commissioner of Financial Regulation for the State of Maryland will accept all questions or complaints regarding our service provider Blackhawk Network California, Inc. (Lic # 1044077) at: Office of the Commissioner of Financial Regulation 500 N Calvert Street, Suite 402 Baltimore, Maryland 21202, or by telephone at (888) 784-0136."
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Assignment"}</div>
                <div>
                    <br />
                    {
                        "Klothed may assign, without recourse, any or all of its rights or obligations under this Agreement, including all or a portion of this Agreement"
                    }
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Miscellaneous"}</div>
                <div>
                    <br />
                    {
                        "If any portion of this Agreement is deemed unlawful, void or unenforceable by any arbitrator or court of competent jurisdiction, this Agreement as a whole shall not be deemed unlawful, void or unenforceable, but only that portion of this Agreement that is unlawful, void or unenforceable shall be stricken from this Agreement."
                    }
                    <br />
                    <br />
                    {
                        "You agree that if Klothed does not exercise or enforce any legal right or remedy which is contained in the Agreement (or which Klothed has the benefit of under any applicable law), this will not be taken to be a formal waiver of Klothed's rights and that those rights or remedies will still be available to Klothed."
                    }
                    <br />
                    <br />
                    {
                        "All covenants, agreements, representations and warranties made in this Agreement shall survive your acceptance of this Agreement and the termination of this Agreement."
                    }
                    <br />
                    <br />
                    {'The term "including" in this Agreement means "including but not limited to."'}
                </div>
                <br />
                <div style={{ fontWeight: "bold" }}>{"Contact Us"}</div>
                <div>
                    <br />
                    {"Comments, questions, concerns, complaints, claims or suggestions may be communicated by emailing us at "}
                    <a className="orangeText" href="mailto:help@getKlothed.com">
                        {"help@getKlothed.com"}
                    </a>
                    {", calling us at (631) 898-5725 or writing us at Klothed Technologies Inc., 279 Mill Road, Westhampton Beach, NY 11978"}
                </div>
            </div>
        </div>
    );
}

export default Legal;
