import React from "react";
import Profile from "./profile";

export const UserProfile = () => {
    return (
        <div className="artifact_group">
            <Profile />
        </div>
    );
};
