import React, { useCallback, useState } from "react";
import { ReactComponent as HeartTransparent } from "./assets/heartTransparent.svg";
import { ReactComponent as HeartSelected } from "./assets/heartSelected.svg";
import { TileDetailPopup } from "./tileDetail";
import { useTileSession, useSiteSession } from "@v2/utils";
import { TileImageComponent } from "./tileImage";

export function CatalogTileTemplate() {
    const { isPopup } = useSiteSession();
    const { context } = useTileSession();
    const {
        showTryon,
        tile,
        toggleTryonImage,
        tryonInProgress: processing,
        tryonInQueue: queued,
        isFav: fillHeart,
        toggleFav: heartClicked,
        isHidden,
    } = context;
    const { product_brand: productBrand, product_url: productURL, product_sale_price: productSalePrice, product_price: productPrice } = tile;
    const [displayPopup, setDisplayPopup] = useState(false);

    const popupRequest = useCallback(() => {
        if (!isPopup.current) {
            isPopup.current = true;
            setDisplayPopup(true);
        }
    }, [setDisplayPopup]);

    return isHidden ? (
        <></>
    ) : (
        <div className="catalogProductsItem">
            <div className="catalogProductsItemInside">
                <div className="catalogProductsItemImage">
                    <TileImageComponent displayLarge={false} onClick={popupRequest} />
                    {tile.id && (
                        <div onClick={heartClicked} className="favoriteIcon_">
                            {fillHeart ? <HeartSelected /> : <HeartTransparent />}
                        </div>
                    )}
                </div>
            </div>
            <a href={productURL} target={"_blank"} className="itemShopDetails" rel="noreferrer">
                <div className="itemShopDetailsShopNow">
                    <span>Shop Now</span>
                    <hr />
                </div>
                <div className="itemShopDetailsProduct">
                    <div className="itemShopDetailsBrand">{productBrand && productBrand}</div>
                    <div className="itemShopDetailsRetailer">{productSalePrice ? `$${productSalePrice}` : productPrice ? `$${productPrice}` : undefined}</div>
                </div>
            </a>
            <div className="itemTryonToggle" onClick={toggleTryonImage}>
                <div>
                    <span>{showTryon ? (queued ? "HANG TIGHT" : processing ? "UP NEXT" : "VIEW MODEL") : "TRY IT ON"}</span>
                </div>
            </div>
            <TileDetailPopup
                open={displayPopup}
                close={() => {
                    setDisplayPopup(false);
                    setTimeout(() => {
                        isPopup.current = false;
                    }, 500);
                }}
            />
        </div>
    );
}
