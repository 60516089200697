import React from "react";

/**
 * Internal dependencies.
 */
import { Button, TryShare, TryImageText } from "@v2/components/burger";

// import { ReactComponent as Share } from "./assets/icon-share.svg";
import Logo from "@v2/assets/logo.webp";
import LogoMobile from "@v2/assets/logo-mobile.webp";

export const SharedMobileV2 = ({
    TryOnImage,
    productURL,
    productName,
    retailerLogo,
    retailerName,
}: {
    TryOnImage: string;
    productURL: string;
    productName: string;
    retailerLogo: string;
    retailerName: string;
    shareMobileLink: () => void;
}) => {
    return (
        <div className="try-on-wrapper mobile">
            <div className="try-on">
                <div className="try-on__item-image">{<img src={TryOnImage} alt="try on image" />}</div>
                <div className="try-on__inner">
                    <div className="try-on__item try-on__item--flex">
                        <div className="try-on__block">
                            <div className="try-on__text">
                                <h5>Check out this try-on from</h5>
                                <div className="try-on__text-logo">
                                    <picture>
                                        <source media="(max-width: 767px)" srcSet={LogoMobile} />
                                        <source media="(min-width: 768px)" srcSet={Logo} />
                                        <img src={Logo} width={197} alt="try on image" />
                                    </picture>
                                </div>
                            </div>
                            <div className="try-on__button">
                                <h5>Now it’s your turn...</h5>
                                <Button href={productURL} type="fill">
                                    Try It on
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="try-on__item">
                        <TryImageText productName={productName} retailer={retailerName} retailerLogo={retailerLogo} />
                        <div className="mobile-only">
                            <Button href={productURL || "#"} type="fill">
                                Try It on
                            </Button>
                        </div>
                    </div>
                    <div className="try-on__item">
                        <TryShare image={TryOnImage} brand={productName} display={retailerName} />
                    </div>
                </div>
            </div>
        </div>
    );
};
