import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import { BrandsAvailableEntry, brandsAvailable } from "./data";
import { useSiteSession } from "@v2/utils";

export const FilterBrandComponent = ({ filterBrands, setFilterBrands }: { filterBrands: string[]; setFilterBrands: Dispatch<SetStateAction<string[]>> }) => {
    const { style } = useSiteSession();
    const [brandsAvailableI, setBrandsAvailable] = useState<BrandsAvailableEntry[]>([]);

    // Currently active brand filters
    const [activeValue, setActiveValue] = useState<BrandsAvailableEntry[]>();

    useEffect(() => {
        if (!style) return;
        if (style == "both") {
            setBrandsAvailable(brandsAvailable[2]);
        } else if (style == "women") {
            setBrandsAvailable(brandsAvailable[1]);
        } else if (style == "men") {
            setBrandsAvailable(brandsAvailable[0]);
        }
    }, [style, setBrandsAvailable]);

    const onChange = useCallback(
        (values: MultiValue<BrandsAvailableEntry>) => {
            setFilterBrands(values.map((k) => k.value));
        },
        [setFilterBrands]
    );

    useEffect(() => {
        if (!filterBrands || !brandsAvailableI || brandsAvailableI.length === 0) {
            setActiveValue(undefined);
            return;
        }
        const brandsFound = brandsAvailableI.filter((b) => filterBrands.some((v) => v == b.value));
        setActiveValue(brandsFound);
    }, [filterBrands, brandsAvailableI, setActiveValue]);

    return activeValue ? (
        <Select
            isMulti
            name="brands"
            options={brandsAvailableI}
            onChange={onChange}
            defaultValue={activeValue}
            className="basic-multi-select"
            classNamePrefix="select"
        />
    ) : (
        <></>
    );
};
