import React from "react";
import Retailers from "@v2/components/retailers";

function RetailersHome() {
    return (
        <div className="retailers_v2">
            <Retailers />
        </div>
    );
}

export default RetailersHome;
