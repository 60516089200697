import React from "react";
import { Socials } from "../socials";
import { Link } from "react-router-dom";
import { ReactComponent as KlothedLogo } from "@v2/assets/Klothed_logo.svg";
import PhoneImage from "@v2/assets/phone-image.webp";

export function Welcome() {
    return (
        <div className="welcome artifact_group">
            <img src={PhoneImage} className="image" />
            <div className="klothed-desc">
                <div className="klothed-white">
                    <span className="tm">TM</span>
                    <KlothedLogo className="image" />
                    <span>BETA</span>
                </div>
                <h4>
                    AI to try on clothes. Generate images of yourself wearing styles. Browse thousands of products on the Klothed Racks, and try on looks as you
                    shop.
                </h4>
                <Link className="signUp_button" to={process.env.REACT_APP_WEB_SIGNUP2 || "#"}>
                    SIGN UP NOW
                </Link>
            </div>
            <Socials />
        </div>
    );
}
