import React, { useCallback, useEffect, useRef, useState } from "react";
import { AuthState, klothedAPI, product_CatalogItemResponse, product_ShopperCatalogsResponse, useSiteSession } from "@v2/utils";
import { useNavigate, useParams } from "react-router-dom";
import { CatalogGridComponent, CatalogGridProductContentTemplate } from "./catalogGridTemplate";
import { FilterComponent } from "../filter";
import { SortOptions } from "../filter/sort";
import { Welcome } from "../welcome";

export function EditsPage() {
    const { adam, authState, shopperCatalogs, style, setPageTitle, setPageDescription, minPrice, maxPrice, brandsSelected, sortMethod } = useSiteSession();
    const [catalogProducts, setCatalogProducts] = useState<product_CatalogItemResponse[]>();
    const [hasMore, setHasMore] = useState(false);
    const page = useRef(0);

    const navigate = useNavigate();
    const { uid } = useParams();

    const shopperCatalog = shopperCatalogs ? shopperCatalogs.find((s) => s.uid === uid) : undefined;

    const title = style === "men" && shopperCatalog?.title === "Dresses." ? "Clothing." : shopperCatalog?.title || "Edit";

    const [notFound, setNotFound] = useState(false);

    const nextSecure = useCallback(async () => {
        if (!adam || !uid) {
            return;
        }
        try {
            let res: product_ShopperCatalogsResponse;
            if (page.current === 0 && shopperCatalog) {
                res = shopperCatalog;
                setNotFound(false);
            } else {
                res = (await adam.secureProductModesensCatalogsUidPageGet({
                    page: page.current,
                    uid,
                    max_price: maxPrice && maxPrice > 0 ? maxPrice : undefined,
                    min_price: minPrice && minPrice > 0 ? minPrice : undefined,
                    brands: brandsSelected,
                    sort: sortMethod ? SortOptions[sortMethod] : undefined,
                })) as product_ShopperCatalogsResponse;
            }
            page.current += 1;
            const resProducts = res.products;
            if (Array.isArray(resProducts)) {
                setCatalogProducts((c) => {
                    if (!c) return resProducts;
                    return [...c, ...resProducts];
                });
                setNotFound(false);
            }
        } catch (err) {
            console.error(err);
            setNotFound(true);
        }
    }, [setCatalogProducts, adam, setHasMore, shopperCatalog, setNotFound, minPrice, maxPrice]);

    const nextPublic = useCallback(async () => {
        if (!uid) {
            return;
        }
        try {
            let res: product_ShopperCatalogsResponse;
            if (page.current === 0 && shopperCatalog) {
                res = shopperCatalog;
                setNotFound(false);
            } else {
                res = (await klothedAPI.secureProductModesensCatalogsUidPageGet({
                    page: page.current,
                    uid,
                    max_price: maxPrice && maxPrice > 0 ? maxPrice : undefined,
                    min_price: minPrice && minPrice > 0 ? minPrice : undefined,
                    brands: brandsSelected,
                    sort: sortMethod ? SortOptions[sortMethod] : undefined,
                })) as product_ShopperCatalogsResponse;
                setNotFound(false);
            }
            page.current += 1;
            const resProducts = res.products;
            if (Array.isArray(resProducts)) {
                setCatalogProducts((c) => {
                    if (!c) return resProducts;
                    return [...c, ...resProducts];
                });
            }
        } catch (err) {
            console.error(err);
            setNotFound(true);
        }
    }, [setCatalogProducts, setHasMore, shopperCatalog, setNotFound, minPrice, maxPrice]);

    useEffect(() => {
        if (shopperCatalog?.count && catalogProducts) {
            setHasMore(catalogProducts.length < shopperCatalog.count);
        }
    }, [catalogProducts, setHasMore, shopperCatalog?.count]);

    const next = useCallback(() => {
        if (!shopperCatalogs || !uid) {
            return;
        }
        switch (authState) {
            case AuthState.NoUserAccount:
            case AuthState.NoAdmittance:
            case AuthState.ServerError:
                nextPublic();
                return; // show sign up page
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                nextSecure();
                return;
        }
    }, [authState, shopperCatalogs, uid, nextPublic, nextSecure]);

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoUserAccount:
            case AuthState.NoAdmittance:
            case AuthState.ServerError:
                next();
                return; // show sign up page
            case AuthState.NoKlothedAccount:
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                next();
                return;
        }
    }, [authState, navigate, nextSecure, nextPublic, shopperCatalog]);

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - ${title}`);
        setPageDescription(`Klothed - Everything looks better on you. - ${title}`);
    }, [title, setPageTitle, setPageDescription]);

    return authState === AuthState.Unknown ? (
        <div className="artifact_group">Loading</div>
    ) : notFound ? (
        <div className="artifact_group">Not found</div>
    ) : (
        <>
            {authState === AuthState.NoUserAccount && <Welcome />}
            <FilterComponent />
            {shopperCatalog && (
                <CatalogGridComponent title={title} count={shopperCatalog?.count} hasMore={hasMore} next={next}>
                    {[
                        ...CatalogGridProductContentTemplate({
                            hideDeletedTryonTiles: false,
                            hideReportedTryonTiles: false,
                            hideUnfavoredTryonTiles: false,
                            products: catalogProducts || [],
                            autoTryon: Number(process.env.REACT_APP_AUTO_TRYON_TILES),
                        }),
                    ]}
                </CatalogGridComponent>
            )}
        </>
    );
}
