/**
 * External dependencies.
 */
import React from "react";

/**
 * Internal dependencies.
 */
import { Container } from "../container";
import "./howText.scss";

export const HowText = () => (
    <div className="how-text">
        <Container className="smaller">
            <div className="how-text__inner">
                <div className="top-text">
                    <h5>
                        MAKE THE PURCHASE KNOWING YOUR NEW CLOTHING MATCHES YOUR PERSONAL STYLE AND BEAUTY. SHARE YOUR TRY-ON PICS WITH FRIENDS FOR FEEDBACK AND
                        FUN.
                    </h5>
                </div>
                <div className="bottom-text">
                    <h3>
                        Klothed redefines online shopping to rival in-store shopping, reduce returns, and respond to your routine.{" "}
                        <span>It makes shopping all about you.</span>
                    </h3>
                </div>
            </div>
        </Container>
    </div>
);
