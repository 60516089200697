/**
 * External dependencies.
 */
import React, { useEffect } from "react";

/**
 * Internal dependencies.
 */
import { Layout, HeadingLine, Button, ColumnBoxes, ColumnBoxesText, Container, ImageProgress, LogoSlider } from "@v2/components/burger";
import { ReactComponent as Star } from "./assets/start.svg";
import { ReactComponent as Bag } from "./assets/bag.svg";
import Image from "./assets/image.jpg";

import "./aboutFashion.scss";
import { useSiteSession } from "@v2/utils";

const AboutFashion = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - About.`);
        setPageDescription(`Klothed - Everything looks better on you. - About.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout spacing className="about-fashion">
            <HeadingLine>
                <h2>
                    <span>
                        DRESS YOUR BEST<strong className="no-ls">.</strong>
                    </span>
                </h2>
            </HeadingLine>
            <Container className="smaller">
                <ColumnBoxes className="no-padding">
                    <ColumnBoxesText>
                        <Star />
                        <h3>
                            Enjoy <span>the rush</span> of
                            <br /> each purchase.
                        </h3>
                    </ColumnBoxesText>
                </ColumnBoxes>
            </Container>
            <ImageProgress />
            <div className="column-boxes-wrapper">
                <Container className="smaller">
                    <ColumnBoxes className="no-padding">
                        <ColumnBoxesText>
                            <LogoSlider />
                        </ColumnBoxesText>
                        <ColumnBoxesText className="border-left">
                            <img src={Image} width={456} height={633} loading="lazy" />
                        </ColumnBoxesText>
                    </ColumnBoxes>
                </Container>
                <Container className="smaller">
                    <ColumnBoxes className="no-padding justify-end">
                        <ColumnBoxesText>
                            <Bag />
                            <h3>
                                Try on clothing from your favorite <span>retailers</span>.
                            </h3>
                            <Button href={process.env.REACT_APP_WEB_RACKS_HOME || "#"}>Brands</Button>
                        </ColumnBoxesText>
                    </ColumnBoxes>
                </Container>
            </div>
        </Layout>
    );
};

export default AboutFashion;
