import classNames from "classnames";
import React from "react";

import "./try-image-text.scss";

type Props = {
    highlight?: boolean;
    retailer?: string;
    retailerLogo?: string;
    productName?: string;
};

export const TryImageText: React.FC<Props> = ({ highlight, productName, retailer, retailerLogo }) => (
    <div
        className={classNames("try-on__image-text", {
            "try-on__image-text--highlight": highlight,
        })}
    >
        <div className="try-on__image-text__inner">
            <div className="try-on__col">
                <img src={retailerLogo} width={48} height={48} />
            </div>
            <div className="try-on__col">
                <h5>{retailer}</h5>
                <p>{productName}</p>
            </div>
        </div>
    </div>
);
