/**
 * External dependencies.
 */
import React from "react";
import useEmblaCarousel from "embla-carousel-react";

/**
 * Internal dependencies.
 */
import "./imageSlider.scss";

type Props = {
    images?: string[];
    style?: object;
};

export const ImageSlider: React.FC<Props> = ({ images, style }) => {
    const [emblaRef] = useEmblaCarousel({
        align: 0,
        containScroll: "keepSnaps",
    });
    return (
        <div className="image-slider" ref={emblaRef} style={style}>
            <div className="image-slider__container">
                {images?.map((img, index) => (
                    <div className="image-slider__slide" key={index}>
                        <img src={img} width="456" height="684" />
                    </div>
                ))}
            </div>
        </div>
    );
};
