import React, { useState, useEffect, useRef } from "react";
import dummyBlack from "./assets/black.png";

interface CustomImageProps {
    className?: string;
    width?: number;
    height?: number;
    source: string;
    index?: number;
}

function CustomImage(props: CustomImageProps) {
    const [src, setSrc] = useState("");

    const imgRef = useRef<HTMLImageElement>(null);
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                setSrc(props.source);
            }
        },
        { rootMargin: "0px 0px 1000px 0px" }
    );

    useEffect(() => {
        if (imgRef && imgRef.current) {
            observer.observe(imgRef.current);
        }
    }, []);

    return <img ref={imgRef} height={props.height} width={props.width} src={src ? src : dummyBlack} className={`customImage ${props.className}`}></img>;
}

export default CustomImage;
