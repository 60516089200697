import React, { Dispatch, SetStateAction } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

export const FilterPriceComponent = ({
    filterMaxPrice,
    filterMinPrice,
    setFilterMaxPrice,
    setFilterMinPrice,
    highestPrice = 5000,
}: {
    filterMinPrice: number;
    setFilterMinPrice: Dispatch<SetStateAction<number>>;
    filterMaxPrice: number;
    setFilterMaxPrice: Dispatch<SetStateAction<number>>;
    highestPrice?: number;
}) => {
    return (
        <>
            <div className="filter_price">
                <label htmlFor="input-min_price">${filterMinPrice}</label>
                <label htmlFor="input-max_price">${filterMaxPrice === 0 || filterMaxPrice === highestPrice ? `${highestPrice}+` : filterMaxPrice}</label>
            </div>
            <RangeSlider
                min={0}
                max={highestPrice}
                onInput={(value: number[]) => {
                    if (value[0] !== highestPrice) {
                        setFilterMinPrice(value[0]);
                    } else {
                        setFilterMinPrice(0);
                    }
                    if (value[1] !== highestPrice) {
                        setFilterMaxPrice(value[1]);
                    } else {
                        setFilterMaxPrice(0);
                    }
                }}
                defaultValue={[filterMinPrice, filterMaxPrice]}
            />
        </>
    );
};
