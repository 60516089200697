import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

/**
 * Internal dependencies.
 */
import Laptop from "@v2/assets/laptop.webp";

import "./autoTab.scss";
import { AutoTabItem } from "./autoTabItem";

type PropItem = {
    content: React.ReactNode;
    button?: {
        text: string;
        link: string;
    };
    image: string;
    additionalContent?: React.ReactNode;
};

type Props = {
    data: PropItem[];
    title: string;
    isMobile: boolean;
};

export const AutoTab: React.FC<Props> = ({ data, title, isMobile }) => {
    const TIMER = 3000;
    const timeout = useRef<NodeJS.Timer | null>(null);
    const [active, setActive] = useState(0);

    const handleInterval = useCallback(() => {
        timeout.current = setInterval(() => {
            setActive((old: number) => (old < data.length - 1 ? old + 1 : 0));
        }, TIMER);
    }, []);

    const handleClick = (index: number) => {
        setActive(index);
        resetInterval();
    };

    const resetInterval = () => {
        if (timeout.current) {
            clearInterval(timeout.current);
        }
        handleInterval();
    };

    useEffect(() => {
        handleInterval();
        return () => {
            if (timeout.current) {
                clearInterval(timeout.current);
            }
        };
    }, []);

    return (
        <div className="auto-tab">
            <div className="auto-tab__inner">
                <div className="auto-tab__left">
                    <h3>{title}</h3>
                    <div className="auto-tab__list">
                        {data.map((item: PropItem, index: number) => (
                            <AutoTabItem key={index} handleClick={handleClick} index={index} data={item} active={active === index} />
                        ))}
                    </div>
                </div>
                <div className="auto-tab__right">
                    <div className="auto-tab__image">
                        {!isMobile ? (
                            <div className="auto-tab__laptop">
                                <img src={Laptop} width="794" height="468" loading="lazy" />
                                {data.map((item: PropItem, ind: number) => (
                                    <div
                                        key={ind}
                                        className={classNames("image", {
                                            "is-active": ind === active,
                                        })}
                                    >
                                        <img src={item.image} width={576} height={360} loading="lazy" />
                                        {item.additionalContent}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="auto-tab__mobile">
                                {data.map((item: PropItem, ind: number) => (
                                    <div
                                        key={ind}
                                        className={classNames("image", {
                                            "is-active": ind === active,
                                        })}
                                    >
                                        <img src={item.image} width={226} height={442} loading="lazy" key={ind} />
                                        {item.additionalContent}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
