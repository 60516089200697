/**
 * External dependencies.
 */
import React, { useEffect } from "react";

/**
 * Internal dependencies.
 */
import { Layout, ActiveTab, HeadingLine, TwoColumns, HowText } from "@v2/components/burger";
import "./howItWorks.scss";
import { useSiteSession } from "@v2/utils";

const HowItWorks = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - How it works.`);
        setPageDescription(`Klothed - Everything looks better on you. - How it works.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout spacing className="how-it-works">
            <HeadingLine>
                <h2>
                    <span>
                        THIS IS WHAT <em>PERSONALIZED SHOPPING</em> REALLY MEANS<strong className="no-ls">.</strong>
                    </span>
                </h2>
            </HeadingLine>
            <TwoColumns className="justify-flex-end">
                <TwoColumns.Column className="column--text">
                    <h5>Forget the model. Everything looks better on you. Shop at any of our covered retailers to see how you look in any style.</h5>

                    <h5>
                        Klothed leverages the latest advancements in computer vision, graphics, and machine learning to allow shoppers to virtually try on
                        clothing. Using mobile and desktop browser extensions, Klothed delivers photorealistic images of shoppers wearing the clothing that is
                        sold on fashion eCommerce sites.
                    </h5>
                </TwoColumns.Column>
            </TwoColumns>
            <ActiveTab />
            <HowText />
        </Layout>
    );
};

export default HowItWorks;
