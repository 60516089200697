import React, { useState } from "react";
import "./selectStyle.scss";
import { ReactComponent as Checked } from "./assets/checked.svg";
import { ReactComponent as UnChecked } from "./assets/unChecked.svg";
import { klothedAPI } from "../../services";

interface SelectStylesProps {
    className?: string;
    styleSelectCallback: () => void;
}

function SelectStyle(props: SelectStylesProps) {
    const shopperDetails = sessionStorage.getItem("shopperID");
    let styleFromlocalStorage = -1;

    if (shopperDetails && JSON.parse(shopperDetails)?.info?.style) {
        if (JSON.parse(shopperDetails)?.info?.style === "women") {
            styleFromlocalStorage = 0;
        } else if (JSON.parse(shopperDetails)?.info?.style === "men") {
            styleFromlocalStorage = 1;
        } else if (JSON.parse(shopperDetails)?.info?.style === "both") {
            styleFromlocalStorage = 2;
        }
    }

    const [style, setStyle] = useState<number>(styleFromlocalStorage);
    const styleTypes = [
        ["Women's styles", "women"],
        ["Men's styles", "men"],
        ["Both!", "both"],
    ];

    const onClickNext = () => {
        const shopperID = sessionStorage.getItem("shopperID");
        if (shopperID) {
            const shopperIDParsed = JSON.parse(shopperID);
            klothedAPI.secureShopperHeightAndStylePut({ style: styleTypes?.[style][1], height: shopperIDParsed?.info?.height }).then((response) => {
                if (response) {
                    sessionStorage.setItem("shopperID", JSON.stringify(response));
                    props.styleSelectCallback();
                }
            });
        }
    };

    return (
        <div className={`selectStyle ${props.className}`}>
            <div className="selectStyle_leftPane">
                <div className="selectStyle_leftPane_heading">{"When shopping for\nyourself, do you like\nto buy..."}</div>
            </div>
            <div className="selectStyle_rightPane">
                <div className="selectStyleToggleGrp">
                    {styleTypes.map((item, index) => (
                        <div onClick={() => setStyle(index)} key={index} className={"selectStyleToggleItem"}>
                            {style === index ? <Checked className="styleSelectImg" /> : <UnChecked className="styleSelectImg" />}
                            <div className="styleSelectText">{item[0]}</div>
                        </div>
                    ))}
                </div>
                <div onClick={() => onClickNext()} className={`selectStyleNextBtn ${style !== -1 ? "selectStyleNextBtnActive" : ""}`}>
                    {props.className ? "SUBMIT" : "NEXT"}
                </div>
            </div>
        </div>
    );
}

export default SelectStyle;
