import React from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { useSiteSession } from "@v2/utils";
import { ContextMenu } from "@v2/components/menu";

export const Utility = () => {
    const { pageTitle, pageDescription, pageImage } = useSiteSession();

    return (
        <header className="header_v2">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:url" content={location.href} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:site_name" content={pageTitle} />
                <link rel="canonical" href={location.href} />
                <meta name="description" content={pageDescription} />
                <meta name="author" content="Klothed" />
            </Helmet>
            <ContextMenu />
            <ToastContainer position="bottom-right" autoClose={5000} />
            <div id="popup-root" />
        </header>
    );
};
