import React, { useEffect } from "react";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";
import { Email } from "./email";

export const UserProfileEmail = () => {
    const { authState, setPageTitle, setPageDescription } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Modify email.`);
        setPageDescription(`Klothed - Everything looks better on you. - Modify email.`);
    }, [setPageTitle, setPageDescription]);
    const navigate = useNavigate();

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoKlothedAccount:
                // Create a shopper account
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoUserAccount:
                navigate(process.env.REACT_APP_WEB_LOGIN2 || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                return;
        }
    }, [authState, navigate]);

    return (
        <div className="artifact_group">
            <Email />
        </div>
    );
};
