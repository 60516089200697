import React from "react";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";

export function HeightComponent() {
    const { heightInFeetAndInches } = useSiteSession();

    return (
        <div className="height">
            <h2>Your height.</h2>
            <span>We use your height to scale your try-ons to fit you.</span>
            <div className="height-group">
                <input
                    name="feet"
                    id="input-feet"
                    type="number"
                    defaultValue={heightInFeetAndInches && heightInFeetAndInches.feet}
                    autoComplete="off"
                    max={7}
                    min={4}
                    required
                ></input>
                <label htmlFor="input-feet">ft</label>
                <input
                    name="inches"
                    id="input-inches"
                    type="number"
                    defaultValue={heightInFeetAndInches && heightInFeetAndInches.inches}
                    autoComplete="off"
                    max={11}
                    min={0}
                    maxLength={2}
                    required
                ></input>
                <label htmlFor="input-inches">in</label>
            </div>
        </div>
    );
}

export function Height() {
    const { authState, syncHeightAndStyle } = useSiteSession();
    const navigate = useNavigate();

    return authState === AuthState.Unknown ? (
        <div>Loading...</div>
    ) : (
        <div className="profile_height_style_section_v2">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const feet = Number(e.currentTarget["feet"].value);
                    const inches = Number(e.currentTarget["inches"].value);
                    syncHeightAndStyle({ heightInFeetAndInches: { feet, inches } }).then(() => navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#"));
                }}
            >
                <HeightComponent />
                <input type="submit" value="SAVE" />
            </form>
        </div>
    );
}
