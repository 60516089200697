import React from "react";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";
import { StyleComponent } from "../style/style";
import { HeightComponent } from "../height/height";

export function HeightAndStyle() {
    const { authState, syncHeightAndStyle, createKlothedAccount } = useSiteSession();
    const navigate = useNavigate();

    return authState === AuthState.Unknown ? (
        <div>Loading...</div>
    ) : (
        <div className="profile_height_style_section_v2">
            {authState === AuthState.NoKlothedAccount && <span>Let’s get dressed! But first...</span>}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const feet = Number(e.currentTarget["feet"].value);
                    const inches = Number(e.currentTarget["inches"].value);
                    const style = e.currentTarget["style-selection"].value;
                    if (authState === AuthState.NoKlothedAccount) {
                        createKlothedAccount({ feet, inches }, style).then(() => navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#"));
                    } else {
                        syncHeightAndStyle({ heightInFeetAndInches: { feet, inches }, style }).then(() =>
                            navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#")
                        );
                    }
                }}
            >
                <HeightComponent />
                <StyleComponent />
                <input type="submit" value="LET'S GO!" />
            </form>
        </div>
    );
}
