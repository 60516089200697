/**
 * External dependencies.
 */
import React, { useEffect } from "react";

/**
 * Internal dependencies.
 */
import { HeadingLine, Share, Layout, TeamRowImage, Container } from "@v2/components/burger";

import Img1_1 from "@v2/assets/teamMembers/i1-1.webp";
import Img1_2 from "@v2/assets/teamMembers/i1-2.webp";
import Img1_3 from "@v2/assets/teamMembers/i1-3.webp";
import Img3_1 from "@v2/assets/teamMembers/i3-1.webp";
import Img3_2 from "@v2/assets/teamMembers/i3-2.webp";
import Img3_3 from "@v2/assets/teamMembers/i3-3.webp";

import "./teamMembers.scss";
import { useSiteSession } from "@v2/utils";

const TeamMembers = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Team.`);
        setPageDescription(`Klothed - Everything looks better on you. - Team.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout spacing>
            <HeadingLine side="left" className="no-margin">
                <h2>
                    <span>
                        WE BEGIN OUR JOURNEY FOCUSED ON THE WAY PEOPLE SHOP FOR CLOTHING ONLINE<strong className="no-ls">.</strong>
                    </span>
                </h2>
            </HeadingLine>
            <div className="team-row">
                <Container className="smaller">
                    <div className="team-row__inner">
                        <div className="team-row__left">
                            <h5>GREG MUNVES</h5>
                            <h5>COFOUNDER + CEO</h5>
                            <Share email="greg@getklothed.com" linkedin="https://www.linkedin.com/in/munves" twitter="https://twitter.com/munves" />
                        </div>
                        <div className="team-row__right">
                            <TeamRowImage img1={Img1_1} img2={Img1_2} img3={Img1_3} />
                        </div>
                    </div>
                </Container>
            </div>
            <div className="team-row">
                <Container className="smaller">
                    <div className="team-row__inner">
                        <div className="team-row__left">
                            <h5>JAMES O’BRIEN</h5>
                            <h5>COFOUNDER + CHIEF SCIENTIST</h5>
                            <Share
                                email="james@getklothed.com"
                                linkedin="https://www.linkedin.com/in/jamesfobrien/"
                                twitter="https://twitter.com/xjamesobrienx"
                            />
                        </div>
                        <div className="team-row__right">
                            <TeamRowImage img1={Img3_1} img2={Img3_2} img3={Img3_3} />
                        </div>
                    </div>
                </Container>
            </div>
            <div className="team-row">
                <Container className="smaller">
                    <div className="team-row__inner">
                        <div className="team-row__left">
                            <h3>
                                We believe you should be able to <span>discover and develop</span> your distinct style online.
                            </h3>
                        </div>
                        <div className="team-row__right">
                            <h5>
                                WE RECOGNIZE THAT EVERYONE IS UNIQUELY BEAUTIFUL. OUR TECHNOLOGY ENABLES YOU TO SEE HOW STYLES AT DOZENS OF ONLINE RETAILERS
                                LOOK ON YOU BEFORE YOU BUY. THAT WAY, YOU CAN MAKE FEWER RETURNS AND ENJOY THE RUSH OF EVERY SINGLE PURCHASE.
                            </h5>
                        </div>
                    </div>
                </Container>
            </div>
        </Layout>
    );
};

export default TeamMembers;
