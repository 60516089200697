import * as React from "react";
const PasswordKeyIcon = () => (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m4.573 19.353-.585-.619 1.41-1.409-.722-.722 1.41-1.375.721.722 1.444-1.444.619.619-.379.378.55.516.379-.378.309.343 1.134 1.135-1.031.997-1.134-1.1-.722.687 1.1 1.1-.79.756-1.066-1.1-.722.722 1.1 1.1-1.032 1.032-1.1-1.1-.034-.035-.31-.31.379-.343-.55-.55-.378.378Z"
            fill="var(--klothed-textColor)"
        />
        <path
            d="M18.974 4.778a2.01 2.01 0 0 0 1.032-1.787c0-1.135-.928-2.029-2.063-2.029-.756 0-1.444.413-1.787.997A2.585 2.585 0 0 0 13.92.687c-1.444 0-2.578 1.135-2.578 2.544 0 1.41 1.169 2.544 2.578 2.544.138 0 .24 0 .344-.034l-.378.343h-.79v.757l-.207.206-.069-.069a.478.478 0 0 0-.653 0l-.034.035a.436.436 0 0 0 0 .618l.069.069-9.557 9.522.172.172-.24.24c-.241-.103-.55-.103-.722.07-.241.24-.172.687.137.996.31.31.79.378 1.031.137.172-.171.172-.446.07-.721l.24-.241.172.172 9.556-9.453.069.069a.478.478 0 0 0 .653 0l.034-.035a.436.436 0 0 0 0-.619l-.069-.068.207-.207h.79v-.756l.378-.344c0 .103-.034.241-.034.344 0 1.41 1.169 2.544 2.578 2.544 1.41 0 2.578-1.135 2.578-2.544a2.36 2.36 0 0 0-1.272-2.2Zm-1.271 3.885a1.702 1.702 0 0 1-1.72-1.72c0-.377.104-.721.31-.996l-.412-.413.171-.171c.276.137.55.103.722-.07.241-.24.172-.687-.137-.996-.31-.31-.79-.378-1.031-.138-.172.172-.172.447-.07.722l-.171.172-.412-.412a1.767 1.767 0 0 1-1.032.343 1.702 1.702 0 0 1-1.718-1.718c0-.963.756-1.72 1.718-1.72.963 0 1.719.757 1.719 1.72h1.066c0-.07-.035-.138-.035-.207a1.27 1.27 0 0 1 1.272-1.272 1.27 1.27 0 0 1 1.272 1.272c0 .688-.584 1.272-1.272 1.272-.069 0-.172 0-.24-.034v.962c.962 0 1.718.757 1.718 1.72 0 .962-.756 1.683-1.718 1.683Z"
            fill="var(--klothed-textColor)"
        />
    </svg>
);
export default PasswordKeyIcon;
