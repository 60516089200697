import React from "react";
import { onAuthStateChanged, signOut, Unsubscribe, User } from "firebase/auth";
import { auth } from "@v1/../firebase-config";
import "./App.scss";
import { ReactComponent as KlothedLogo } from "./assets/logo-beta.svg";
import LoginAndSignUp from "./loginAndSignUp/loginAndSignUp";
import CreateProfile from "./createProfile/createProfile";
import Home from "./home/home";
import { klothedAPI } from "./services";
import RightSlider from "./rightSlider/rightSlider";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

export enum Component {
    "LoginAndSignUp",
    "CreateProfile",
    "Home",
    "Catalog",
}

interface AppState {
    component: Component;
    showApp: boolean;
    showRightSlider: boolean;
    refresh: boolean;
    shopperAuthError: string;
}

class App extends React.Component {
    state: AppState = {
        component: Component.LoginAndSignUp,
        showApp: false,
        showRightSlider: false,
        refresh: false,
        shopperAuthError: "",
    };

    public authChangeCallback: Unsubscribe | null = null;

    componentDidMount() {
        this.authChangeCallback = onAuthStateChanged(
            auth,
            async (user) => {
                if (user) {
                    const accessToken = await auth.currentUser?.getIdToken();
                    if (accessToken) {
                        sessionStorage.setItem("accessToken", accessToken);
                        // Send the token to klothed extension if available
                        if (document.body.getAttribute("klothed-extension") === "true") {
                            window.postMessage(
                                {
                                    type: "klothed_user",
                                    user: JSON.parse(JSON.stringify(user)),
                                },
                                "*"
                            );
                        }
                    }
                    klothedAPI
                        .secureShopperGet()
                        .then((val) => {
                            if (val && val === 404) {
                                this.postShopper(user);
                            } else if (val) {
                                this.setState({ shopperAuthError: "" });
                                sessionStorage.setItem("shopperID", JSON.stringify(val));
                                const shopperInfo = val.info;
                                if (shopperInfo && shopperInfo?.activeImageID && shopperInfo?.height && shopperInfo?.style && shopperInfo?.style != "unknown") {
                                    // navigation.
                                    this.setState({ component: Component.Home, showApp: true });
                                } else {
                                    this.setState({ component: Component.CreateProfile, showApp: true });
                                }
                            } else {
                                this.setState({ shopperAuthError: "Something went wrong, please try again later." });
                            }
                        })
                        .catch((err) => {
                            if (err && err.status === 404) {
                                this.setState({ shopperAuthError: "" });
                                this.postShopper(user);
                            } else {
                                console.error(err);
                                sessionStorage.removeItem("shopperID");
                                sessionStorage.removeItem("accessToken");
                                signOut(auth);
                                this.setState({ shopperAuthError: "Something went wrong, please try again later." });
                            }
                        });
                } else {
                    this.setState({ component: Component.LoginAndSignUp, showApp: true });
                }
            },
            () => {
                this.setState({ component: Component.LoginAndSignUp, showApp: true });
            }
        );
    }

    componentWillUnmount() {
        this.authChangeCallback ? this.authChangeCallback() : null;
    }

    public deleteAccountCallback = () => {
        this.setState({ component: Component.LoginAndSignUp });
    };

    isHome() {
        return this.state.component === Component.CreateProfile || this.state.component === Component.LoginAndSignUp;
    }

    render() {
        return this.state.showApp ? (
            <div className={`App ${this.state.showRightSlider ? "rightSliderHeight" : ""}`}>
                <Link to={process.env.REACT_APP_WEB_RACKS_HOME || "/"}>
                    <KlothedLogo className="klothed_logo" />
                </Link>
                {(this.state.component === Component.LoginAndSignUp || this.state.component === Component.CreateProfile) && !this.state.showRightSlider && (
                    <div
                        onClick={() => {
                            this.setState({ showRightSlider: true });
                        }}
                        className="rightSliderLine"
                    >
                        <div className="rightSliderLine_"></div>
                        <div className="rightSliderLine_"></div>
                    </div>
                )}
                {this.state.component === Component.LoginAndSignUp && (
                    <LoginAndSignUp shopperAuthError={this.state.shopperAuthError} refresh={this.state.refresh}></LoginAndSignUp>
                )}
                {this.state.component === Component.CreateProfile && (
                    <CreateProfile
                        addExtensionCallback={() => {
                            window.open(process.env.REACT_APP_EXTENSION_DESKTOP, "_blank");
                            setTimeout(() => {
                                window.location.href = process.env.REACT_APP_WEB_RETAILERS || "#";
                            }, 300);
                        }}
                    ></CreateProfile>
                )}
                {this.state.component === Component.Catalog && <Navigate to={process.env.REACT_APP_WEB_RACKS_HOME || "/"} />}
                {this.state.component === Component.Home && <Home deleteAccountCallback={() => this.deleteAccountCallback()}></Home>}
                {this.state.showRightSlider && <div className="rightSliderOverlay"></div>}
                {this.state.showRightSlider && (
                    <RightSlider
                        setCompFromApp={(comp: Component) => this.setState({ component: comp, refresh: !this.state.refresh })}
                        closeAct={() => this.setState({ showRightSlider: false })}
                    ></RightSlider>
                )}
            </div>
        ) : null;
    }

    public postShopper = (user: User) => {
        const payload = {
            info: {
                uid: user.uid,
                info: {
                    displayName: user.displayName,
                    email: user.email,
                },
            },
        };
        klothedAPI
            .secureShopperPost(payload)
            .then((val) => {
                if (val) {
                    sessionStorage.setItem("shopperID", JSON.stringify(val));
                    this.setState({ component: Component.CreateProfile, showApp: true });
                }
            })
            .catch(() => {
                setTimeout(() => {
                    klothedAPI
                        .secureShopperPost(payload)
                        .then((val) => {
                            if (val) {
                                sessionStorage.setItem("shopperID", JSON.stringify(val));
                                this.setState({ component: Component.CreateProfile, showApp: true });
                            }
                        })
                        .catch(() => {
                            setTimeout(() => {
                                klothedAPI
                                    .secureShopperPost(payload)
                                    .then((val) => {
                                        if (val) {
                                            sessionStorage.setItem("shopperID", JSON.stringify(val));
                                            this.setState({ component: Component.CreateProfile, showApp: true });
                                        }
                                    })
                                    .catch(() => {
                                        this.setState({
                                            shopperAuthError: "Something went wrong, please try again later.",
                                            component: Component.LoginAndSignUp,
                                            showApp: true,
                                        });
                                    });
                            }, 3000);
                        });
                }, 3000);
            });
    };
}

export default App;
