import React from "react";
import UserVerification from "@v2/components/verify";

export default function VerifyPage() {
    return (
        <div className="user_verify">
            <UserVerification />
        </div>
    );
}
