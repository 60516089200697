import { useSiteSession } from "@v2/utils";
import React, { useEffect } from "react";

export function TermsOfServicePage() {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Terms of service.`);
        setPageDescription(`Klothed - Everything looks better on you. - Terms of service.`);
    }, [setPageTitle, setPageDescription]);

    return (
        <div className="policy-page">
            <p>KLOTHED SHOPPER TERMS OF SERVICE</p>
            <h2 id="last-updated-june-14-2023">Last updated June 14, 2023</h2>
            <h2 id="introduction">Introduction</h2>
            <p>
                These Terms of Service (the &quot;Agreement&quot;) are a contract between you and Klothed Technologies Inc. (&quot;Klothed&quot;) and govern
                your access to and use of the Klothed technology to browse products and try-on clothing, as explained in more detail in the Description of
                Services below. The Agreement governs the use of the Klothed website located at getklothed.com and all other Klothed-branded websites (each, a
                &quot;Website&quot;) and other software applications and online services provided by Klothed (collectively, the &quot;Service&quot;).
            </p>
            <h2 id="accepting-the-terms">Accepting the Terms</h2>
            <p>
                By using any portion of the Service, you agree to comply with and be bound by this Agreement. The term &quot;you&quot; or &quot;User&quot; or
                &quot;Shopper&quot; refers to the user of our Service . The terms &quot;we&quot; or &quot;our&quot; or &quot;us&quot; refer to Klothed. You must
                be an individual and at least 13 years old, or the age of majority in your state, province, or country of residence, to use the Service. If you
                are under 18, you represent that you have your parent or guardian&#39;s permission to use the Service. Please have them read this Agreement with
                you. You may not use the Service or accept this Agreement if you are not of a legal age and able and competent to form a binding contract with
                Klothed or if you are at least 13,under 18, and do not have your parent&#39;s approval. If you use the Service, you represent that you have the
                capacity to be bound by this Agreement. The Service is for our customers&#39; personal, non-commercial shopping use. You agree that you are not
                using the Service for or on behalf of anyone other than you or for any commercial purpose.
            </p>
            <p>
                THIS AGREEMENT CONTAINS (1) AN ARBITRATION PROVISION; (2) A WAIVER OF RIGHTS TO BRING A CLASS ACTION LAWSUIT AGAINST US; AND (3) A RELEASE BY
                YOU OF CLAIMS FOR DAMAGE AGAINST US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICE, TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. BY USING
                THE SERVICE, YOU AGREE TO THESE PROVISIONS.
            </p>
            <h2 id="amendments-to-this-agreement">Amendments to this Agreement</h2>
            <p>
                Klothed may amend this Agreement or other policies that govern the Service from time to time. Any changes to the Agreement will be posted to the
                Terms of Service, and the posted Agreement will indicate the date the Agreement was last revised. If you do not agree to the changes, you must
                uninstall any applicable Klothed software and stop using the Service. By continuing to use the Service after any changes to this Agreement are
                posted on the Website, you agree to be bound by those changes.
            </p>
            <h2 id="privacy-and-your-personal-information">Privacy and your Personal Information</h2>
            <p>
                The Service is subject to Klothed&#39;s privacy and data protection practices (our &quot;Privacy Policy&quot;). Please read Klothed&#39;s
                Privacy Policy. Our Privacy Policy explains how Klothed collects, uses and shares your information when you visit our Website, or otherwise
                access the Service. By using the Service, you consent to Klothed&#39;s collection, use, and sharing of your information as set forth in our
                Privacy Policy.
            </p>
            <h2 id="description-of-the-service">Description of the Service</h2>
            <p>
                Klothed is AI for clothing try-on. Our Klothed Racks product is a shopping experience where shoppers can browse a curated set of thousands of
                in-season items across hundreds of major brands and try-on every single one. The shopper gets a completely personalized style preview of how
                they would look wearing an item. Klothed Racks is free to shoppers.{" "}
            </p>
            <p>
                Shoppers can sign up to use our Klothed Racks and related services at <a href="http://www.getklothed.com">getklothed.com</a>. After signup, you
                will get full access to browse our Klothed Racks. If you want to try-on items on Klothed Racks, you must complete your profile by uploading a
                photo of yourself and entering your height. Once your profile is complete you may try-on items by clicking the &quot;Try It On&quot; button
                under each product picture. You can share your try-on pictures with friends, family, and followers. To purchase a product, click the &quot;Shop
                Now&quot; button to go to the product page on the retailer site to purchase the product. Your Try-On images are saved in &quot;Your latest
                looks&quot; Rack for easy reference. We may enable you to create your own Racks (referred to as &quot;Edits&quot;) and to allow others to
                subscribe to your Edits. You in kind will have access to Edits created by others that you may subscribe to.
            </p>
            <p>
                We may also allow you to share your Klothed ID with others (friends and family) so they can try styles on you and make style suggestions or to
                buy you a gift. If these features are implemented, only you will be able to grant and revoke this permission.{" "}
            </p>
            <p>
                If you allow Klothed access to your social media account(s) then we promise never to post or share anything without your explicit permission.
                It&#39;s your social media, you&#39;re in charge.{" "}
            </p>
            <p>
                Klothed does not charge you fees for its Service. When you press the &quot;Shop Now&quot; button in Klothed Racks, it will cause a cookie or
                other affiliate code or link identifying Klothed to be placed. This will allow the marketing affiliate network, the retailer, or other entity to
                associate any purchase you make with Klothed. When you then make a purchase or click on a merchant link while using Klothed or someone you share
                a try-on picture with clicks on a link to the merchant, the merchant may pay us a fee either directly or through an affiliate network or
                aggregator. That is how we make money with your personal information. We do not need to (or want to) sell your personal information.
            </p>
            <p>
                Our AI technology may become embeddable directly in merchants&#39; sites as well as other applications and mediums using our API. This service
                is not free and requires an additional license. In the future, we anticipate that Third-Parties (persons or entities other than you or Klothed
                such as merchants) may build applications that leverage our Try-On engine to create photorealistic images of our shoppers to show our shoppers
                how they look wearing specific products or to provide other benefits or services derived from the try-on pictures that are relevant to you. For
                example, a clothing retailer might like to show you images of you wearing their latest fashions or a car company might like to show you images
                where you are enjoying the drive in one of their sports cars. Then, with your permission (and only with your permission), we would allow the
                retailer or car company to use Klothed to create a photorealistic picture showing you in their car. This picture will be created using the data
                obtained when you create your account. We will not provide any Third-Party with the picture you uploaded or the data we obtained from the
                picture. These Third-Parties may pay us a fee to create these photorealistic images and make them available to you. We also will not work with
                any Third-Parties that are not building something that might benefit you in some way.
            </p>
            <p>
                All aspects of our Service may not be available through all channels, and any information collected from you is used solely in accordance with
                our <a href="http://getklothed.com/privacy">Privacy Policy</a>.
            </p>
            <p>
                We are constantly working to improve our AI, software, and services to make your try-on picture as accurate and fast as possible, and to make
                the Service as easy to use as possible. To help improve picture accuracy and our Service, we review submissions, try-ons, and suggestions made
                by shoppers like you. You understand and acknowledge that any improvements, modifications and changes arising from or in connection with the
                information you convey relating to the Klothed try-on images and/or Services, remain or become the exclusive property of the Klothed.{" "}
            </p>
            <p>
                While we cannot guarantee complete try-on image accuracy or that we will be able to create a try-on picture with every model picture, we believe
                that you will find that our try-on image will provide the utility to build purchase confidence, help you make better style selections while
                reducing your need to make returns, and to boost your online shopping enjoyment.{" "}
            </p>
            <p>
                Users are responsible for their purchase decision including being aware of any limitation in the applicable merchant website return policy.
                Klothed Does Not Warranty Any Purchase By The User Or Make Any Guarantees Regarding Purchased Items Or How Those Items May Fit And Look On The
                User.{" "}
            </p>
            <h2 id="geographic-limitations">Geographic Limitations</h2>
            <p>
                At this time, the Services may only be used by you if you are physically located within the United States and its territories. You agree not to
                access the Service from outside the United States.
            </p>
            <h2 id="beta-testing">Beta Testing</h2>
            <p>
                During Beta testing of Klothed, all features described on the Website may not yet be enabled or fully functional. You will automatically
                transition to the production version of Klothed when Beta testing ends.
            </p>
            <h3 id="beta-tester-confidentiality-and-non-disclosure-provisions">Beta Tester Confidentiality And Non-Disclosure Provisions</h3>
            <h4 id="purpose">Purpose</h4>
            <p>
                From time to time, Klothed may conduct beta testing programs (&quot;Beta Test&#39;&#39;) to improve its software and services. During a Beta
                Test, Klothed may disclose confidential information to participants. If you participate in a Beta Test program, any such disclosure of
                confidential information shall be subject to the terms and conditions in these Beta Tester Confidentiality and Non-Disclosure Provisions (the
                &quot;Confidentiality Provisions&quot;).{" "}
            </p>
            <h4 id="definitions">Definitions</h4>
            <p>
                Confidential Information&quot; means any and all information, oral as well as visual or written (including graphic material), that you receive
                from the Klothed, before or after this Confidentiality Agreement is entered into, and which is related to one or more of the following:{" "}
            </p>
            <p>-the Purpose;</p>
            <p>
                -the business, product, financial, operational, technical, administrative, marketing, planning, know-how, research, development and staff
                information, methods, records and data of the Klothed;
            </p>
            <p>
                -proprietary information, data, know-how, formulae, processes and engineering processes, strategies, designs, photographs, drawings,
                specifications, software, inventions, patents, technology, hardware configuration information, samples, technical literature, contract terms and
                data or other material attributable to or deriving its existence from discussions relating to the Purpose and/or the Klothed; and{" "}
            </p>
            <p>-any notes, extracts, analyses or materials prepared by or on behalf of you which are copied or derived from Confidential Information. </p>
            <p>-Information shall not be considered as Confidential Information if the Klothed expressly declares it to be non-confidential.</p>
            <h4 id="confidentiality-promise">Confidentiality Promise</h4>
            <p>You promise to:</p>
            <p>-keep secret and not disclose or reveal any Confidential Information obtained from Klothed;</p>
            <p>
                -not without prior written consent of Klothed, use the Confidential Information for any other purpose than the Purpose of these Confidentiality
                Provisions ;
            </p>
            <p>-take all steps necessary to prevent Confidential Information from being disclosed to any other person;</p>
            <p>
                -not without prior written consent of Klothed, make any record or copy of any drawing, description, specification, or other document or other
                disclosure or embodiment of the Confidential Information; and
            </p>
            <p>
                -not copy, reverse engineer, reverse compile, or attempt to derive the composition of, or information underlying, the Confidential Information.
            </p>
            <p>You acknowledge and agree that unauthorized use or disclosure of Confidential Information may violate applicable trade secret laws.</p>
            <h4 id="permitted-disclosure">Permitted Disclosure</h4>
            <p>The confidentiality undertaking above shall not apply to any Confidential Information that the you can establish:</p>
            <p>-is or becomes available to the public (otherwise than by breach of this Agreement or any other confidentiality undertaking);</p>
            <p>-was available to the you on a non-confidential basis prior to its disclosure to the you;</p>
            <p>-is independently developed by you (provided that the information developed does not include or rely on any Confidential Information); or</p>
            <p>
                -that it is required to disclose by law or by the rules of any regulatory body to which you are subject, provided that it provides reasonable
                prior notice to the Klothed, unless a court prohibits such notice.
            </p>
            <h4 id="return-or-destruction-of-confidential-information">Return Or Destruction Of Confidential Information</h4>
            <p>
                -Upon the Klothed&#39;s request, all Confidential Information received by you shall, regardless of medium, be immediately returned or destroyed
                and you shall thereafter not use received Confidential Information for any purposes whatsoever. you undertake to, upon request by Klothed,
                certify in writing that such destruction or return has occurred.
            </p>
            <p>-The return or destruction terms above shall not apply if:</p>
            <p>------you are required to keep the Confidential Information under Applicable Law; or</p>
            <p>
                ------the Confidential Information is contained in computer records or electronically stored files which may not be destroyed with reasonable
                measures.
            </p>
            <p>
                -Any Confidential Information retained with reference to this provision shall remain strictly confidential in accordance with the
                confidentiality obligations of this Confidentiality Provisions.
            </p>
            <h4 id="no-warranty-or-grant-of-rights">No Warranty Or Grant Of Rights</h4>
            <p>
                You acknowledge that Klothed does not make any expressed or implied representation or warranty as to the accuracy or completeness of the
                Confidential Information. You agree that you are not entitled to rely on the accuracy or completeness of the Confidential Information provided
                by Klothed.
            </p>
            <p>
                The disclosure of Confidential Information by Klothed shall not form any offer by, or representation or warranty on the part of, Klothed to
                enter into any further agreement in relation to the Purpose.
            </p>
            <p>This Confidentiality Agreement does not grant you any right, title or interest in or to the Confidential Information.</p>
            <h4 id="breach-of-contract">Breach Of Contract</h4>
            <p>
                In addition to any other legal remedies that Klothed may have, you shall be responsible to Klothed for any breach of these Confidentiality
                Provisions by you and by any other person or entity that may receive any Confidential Information from or through you.
            </p>
            <p>
                You acknowledge and agree that a breach by you of these Confidentiality Provisions may result in immediate and irreparable harm to Klothed, for
                which there will be no adequate remedy at law. Without prejudice to any other rights and remedies it may have, the Klothed will be entitled to
                seek equitable relief to compel you to cease and desist all unauthorized use and disclosure of its Confidential Information.{" "}
            </p>
            <h4 id="notification-and-waiver">Notification and Waiver</h4>
            <p>
                You will notify the Klothed immediately in writing if it becomes aware that the Confidential Information has been disclosed to an unauthorized
                third party.
            </p>
            <p>A waiver of any term of these Confidentiality Provisions will be valid only if it is in writing and signed by Klothed. </p>
            <h4 id="beta-tester-duties">Beta Tester Duties</h4>
            <p>
                You agree to make reasonable efforts to report any flaws, errors or imperfections discovered during Beta Testing of the try-on services. You
                understand and acknowledge that any improvements, modifications and changes arising from or in connection with the information you convey in
                connection with the Purpose, remain or become the exclusive property of Klothed as provided in the Terms of Service.
            </p>
            <h2 id="merchant-websites-and-purchases">Merchant Websites and Purchases</h2>
            <p>
                You may reach a third-party merchant website through a link from our Website. However, in all cases any merchant product will be purchased
                directly from the merchant and through the merchant&#39;s website or merchant App. You are not buying the product from or through Klothed.
                Klothed is not a seller, reseller or distributor of any clothing or other products. It is strictly a provider of Klothed Services that you can
                use to create your own try-on picture and save or share it with others.
            </p>
            <h2 id="merchant-returns">Merchant Returns</h2>
            <p>
                If you are unhappy with your merchant product purchase, if you wish to return your merchant product, if you ordered a merchant product and it
                has not arrived, or if you have another customer service inquiry regarding your purchased merchant product, you will need to contact the
                third-party merchant from whom you purchased the product. Klothed is not involved in your merchant product purchase and is not able to assist
                with any customer service inquiries or returns related to that purchase.{" "}
            </p>
            <p>
                Klothed Is Not Responsible for Third-Parties (a person or entity who not a party to this Agreement between you and Klothed is referred to in
                this Agreement as a &quot;third-party&quot;)
            </p>
            <p>
                All products you purchase from a third-party merchant, including those linked from our Websiteare: (a) priced by the applicable third-party
                merchant (including whether such prices include applicable local, provincial, state, or federal taxes, and any additional fees, such as shipping
                and handling); and (b) fulfilled, shipped, and otherwise serviced (including but not limited to the processing of returns, refunds, and
                cancellations) by the applicable third-party merchant and not by Klothed. Klothed is not responsible for any third-party merchant products or
                any information contained on the third-party merchant&#39;s website.
            </p>
            <p>
                You agree that your purchase from a third-party merchant is subject to such merchant&#39;s own terms and conditions applicable to such purchase.
                You agree that Klothed is in no way responsible or liable to you for any products you purchase through or using the Service, including for any
                product liability claim, improper charges, delivery failures, pricing errors, or inaccurate product descriptions. You acknowledge and agree that
                Klothed is not liable or otherwise responsible for any breaches of credit card or debit card security or privacy by any third-party merchants.
            </p>
            <p>
                Klothed does not endorse, warrant or guarantee the products or services that are displayed through the Service, including any offers for
                third-party products and other advertised links to third-party websites made available to you by Klothed. Klothed is not an agent, distributor,
                re-seller, broker or otherwise responsible for third-party merchants or the activities or policies of those merchant websites, or the products
                or services available on them. Klothed does not promise or guarantee that the product details, prices, coupon availability or other service
                terms, rates or rewards offered by any particular advertiser or other third-party via our Service are the best prices, best terms or lowest
                rates available in the market.
            </p>
            <p>
                When you access third-party websites through our Service, you accept that there are risks in doing so, and that Klothed is not responsible for
                such risks. We encourage you to be aware when you are on a third-party merchant website and to read the terms and conditions and privacy policy
                of each third-party website or service that you visit or utilize.
            </p>
            <p>
                Klothed has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices or opinions expressed in
                any third-party websites or by any third-party that you interact with through the Service. In addition, Klothed will not and cannot monitor,
                verify, censor or edit the content of any third-party site or service.
            </p>
            <p>
                By using the Service, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.
                If you have a dispute with any third-party (including a merchant from whom you purchase a product in connection with the Service), you agree
                that Klothed is under no obligation to become involved. To the maximum extent permitted by applicable law, you release Klothed, and any parent
                company, our affiliates, and each of their respective officers, employees, agents, and successors from claims, demands, and damages of every
                kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to any dispute you
                have with a third-party in connection with the Service. If you are a California resident, you shall and hereby do waive California Civil Code
                Section 1542, which says: &quot;A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO
                EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER
                SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.&quot;
            </p>
            <h2 id="account-information-from-third-party-sites">Account Information from Third Party Sites</h2>
            <p>
                When you establish an account with Klothed, we may allow you to register your account, log-in, or otherwise link your Klothed account with an
                account you maintain with a third-party, such as Apple® or Google® (&quot;Linked Account&quot;). You represent that any Linked Account belongs
                to you and you are permitted to link it to your Klothed account. Klothed does not control the policies and practices of any third-party site or
                service, including any Linked Account you connect to the Service and Klothed is not responsible for any actions or omissions of providers of a
                Linked Account, including related to accuracy, legality or non-infringement. Klothed is not responsible for the products and services offered by
                or through your Linked Account. If permitted by your Linked Account, Klothed may import information from your Linked Accounts to help better
                offer the Service to you.
            </p>
            <h2 id="your-log-in-and-account-information">Your Log-In and Account Information</h2>
            <p>
                You agree and understand that you are responsible for maintaining the confidentiality of your Login ID, email address, password and any other
                personal identifying information or other codes used to access your Klothed account or the Service (&quot;Login Information&#39;&#39;). If you
                become aware of any unauthorized use of your Log-In Information, you agree to notify Klothed immediately.
            </p>
            <p>
                Accurate records enable Klothed to provide the Service to you. You agree to keep your email address, your other contact information in your
                Klothed account profile accurate and up-to-date. If you do not do this, the accuracy and effectiveness of the Service to you will be affected
                and you may not receive account notices that we deliver to you. If you fail to keep your contact information updated, you will still be deemed
                to have received any notices we deliver to the email address you last provided.
            </p>
            <h2 id="electronic-notices">Electronic Notices</h2>
            <p>
                By providing us with your email address, you agree to receive all account related notices electronically, to that email address. It is your
                responsibility to update or change that address, as appropriate. Notices will be provided in HTML (or, if your system does not support HTML, in
                plain-text) in the text of the e-mail or through a link to the appropriate page on our site, accessible through any standard modern,
                commercially available internet browser. We may also use your email address to contact you about our own and third-parties&#39; goods and
                services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your
                account profile and /or click &quot;Unsubscribe&quot; in any e-mail communications we send you.
            </p>
            <h2 id="electronic-notifications">Electronic Notifications</h2>
            <p>
                Klothed may provide you with electronic notifications by email, push notification or other electronic means. Some electronic notifications are
                mandatory, including security-related notifications, such as when you update your log-in information or password.
            </p>
            <p>
                Other types of notifications are voluntary and may be modified through the Service. They may be customized, deactivated or reactivated by you.
                These notifications allow you to choose alert messages for your account. Klothed may add new notifications from time to time, or cease to
                provide certain notifications at any time upon its sole discretion. Each alert has different options available, and you may be asked to select
                from among these options upon activation of your notifications service.
            </p>
            <p>Electronic notifications will be sent to the email address you have provided as your primary email address for the Service.</p>
            <p>
                Because notifications are not encrypted, we will never include your password. However, notifications may include your Klothed login information
                and some information about your accounts. Anyone with access to your email will be able to view the content of these notifications. While you
                may disable certain voluntary notifications, we will still send you Service-related notices as needed to allow us to provide you the Service.
            </p>
            <h2 id="klothed-s-intellectual-property-rights">Klothed&#39;s Intellectual Property Rights</h2>
            <p>
                The contents of the Service, including its &quot;look and feel&quot; (e.g., text, graphics, images, logos and button icons), photographs,
                editorial content, notices, software (including html-based computer programs) and other material are protected under copyright, trademark and
                other laws. The contents of the Service belong or are licensed to Klothed or its software or content suppliers. Klothed grants you the right to
                view and use the Service subject to this Agreement. Any distribution, reprint or electronic reproduction of any content from the Service, other
                than as expressly permitted in this Agreement or with our prior written consent, is prohibited. If you would like to request such permission,
                shoot us an email at <a href="mailto:info@getKlothed.com">info@getKlothed.com</a>.
            </p>
            <p>
                Except as may be expressly permitted by the Service features, your right to access and use the Service is personal to you and is not
                transferable by you to any other person or entity. You are only entitled to access and use our Service for lawful, internal, and personal
                non-commercial shopping purposes. Subject to your compliance with this Agreement, Klothed hereby grants to you, a personal, worldwide,
                non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to (i) use the software and content provided to you as part of
                the Service (for personal, non-commercial purposes; and (ii) download a single copy of our software for such use.
            </p>
            <p>
                You agree that Klothed may use your feedback, suggestions, or ideas in any way, including in future modifications of the Service, other products
                or services, advertising or marketing materials (although any use of your personal information will be subject to our privacy policy).
            </p>
            <h2 id="technology-disclaimer">Technology Disclaimer</h2>
            <p>
                Your access and use of the Service may be interrupted from time to time due to third-party failures outside of our control (such as
                unavailability of general Internet infrastructure). Access and use may also be interrupted for periodic updating, maintenance or repair of the
                Service by Klothed. While we will make reasonable efforts to make the Service available to you, we do not promise that it will be available at
                all times.
            </p>
            <p>
                You are responsible for providing the mobile device, wireless service plan, software, Internet connections, and/or other equipment or services
                that you need to download, install, and use the Klothed Racks. We do not guarantee that the Klothed Racks can be accessed and used on any
                particular mobile device or with any particular service plan. We do not guarantee that the Klothed Racks will be available in, or that orders
                for products can be placed from, any particular geographic location.
            </p>
            <p>
                Klothed cannot always foresee or anticipate technical or other difficulties which may result in failure to obtain data or loss of data,
                personalization settings or other service interruptions. Klothed cannot assume responsibility for the timeliness, accuracy, deletion,
                non-delivery or failure to store any user data, communications or personalization settings.
            </p>
            <h2 id="rules-for-posting-content">Rules for Posting Content</h2>
            <p>
                As part of the Service, Klothed may allow Users to post content on various publicly available locations in the Service (&quot;User
                Content&quot;). You agree in posting User Content to follow the following rules:
            </p>
            <p>-You are responsible for all User Content you submit to the Service.</p>
            <p>
                -By submitting User Content to us, you represent that you have all necessary rights and hereby grant us a perpetual, worldwide, irrevocable,
                non-exclusive, royalty-free, sublicensable and transferable license to use, reproduce, distribute, prepare derivative works of, modify, display,
                and perform all or any portion of the User Content in connection with the Service and our business, including without limitation for promoting
                and redistributing part or all of the site (and derivative works thereof) in any media formats and through any media channels. You also hereby
                grant each User a non-exclusive license to access and use your User Content through the Service, and to use, reproduce, distribute, prepare
                derivative works of, display and perform such User Content as permitted through the functionality of the Service and under this Agreement.
            </p>
            <p>
                -You may not post or transmit any message, data, image or program that would violate the property rights of others, including unauthorized
                copyrighted text, images or programs, trade secrets or other confidential proprietary information, and trademarks or service marks used in an
                infringing fashion.
            </p>
            <p>
                -You may not interfere with other Users&#39; use of the Service, including, without limitation, disrupting the normal flow of dialogue in an
                interactive area of the Service, deleting or revising any content posted by another person or entity, or taking any action that imposes a
                disproportionate burden on the Service infrastructure or that negatively affects the availability of the Service to others.
            </p>
            <h2 id="prohibited-activities">Prohibited Activities</h2>
            <p>
                You represent, warrant, and agree that you will not contribute any content or otherwise use the Service or interact with the Service in a manner
                that:
            </p>
            <p>1. Infringes or violates the intellectual property rights or any other rights of anyone else (including Klothed);</p>
            <p>2. Violates any law or regulation or this Agreement;</p>
            <p>3. Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</p>
            <p>4. Jeopardizes the security of your Klothed account or anyone else&#39;s (such as allowing someone else to log in to the Service as you);</p>
            <p>5. Attempts, in any manner, to obtain the password, account, or other security information from any other User;</p>
            <p>6. Violates the security of any computer network, or cracks any passwords or security encryption codes;</p>
            <p>
                7. Runs Maillist, Listserv, any form of auto-responder or &quot;spam&quot; on the Service, or any processes that run or are activated while you
                are not logged into the Service, or that otherwise interfere with the proper working of the Service (including by placing an unreasonable load
                on the Service&#39;s infrastructure) or that would bypass the navigational structure or presentation of the Service;
            </p>
            <p>
                8. &quot;Crawls,&quot; &quot;scrapes,&quot; or &quot;spiders&quot; any page, data, or portion of or relating to the Service or Content (through
                use of manual or automated means);
            </p>
            <p>9. Copies or stores any significant portion of the Content;</p>
            <p>
                10, Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the
                Service; or
            </p>
            <p>
                11. Circumvents, removes, alters, deactivates, degrades or thwarts any protective or security measure, or any content protections, which Klothed
                has implemented as part of the Service; or{" "}
            </p>
            <p>
                12. Results in the uploading of a photograph or picture of anyone other than yourself or publicly posting a picture created using the Service if
                that picture shows anyone other than yourself.
            </p>
            <p>If you violate or infringe any of the rules above, we may immediately suspend or terminate your right to use or access the Service.</p>
            <p>User Representations Concerning Legal Compliance </p>
            <p>
                The end-user must represent and warrant that (i) the end-user is not located in a region that is subject to a U.S. Government embargo, or that
                has been designated by the U.S. Government as a &quot;terrorist supporting&quot; region; and (ii) the end-user is not listed on any U.S.
                Government list of prohibited or restricted parties.
            </p>
            <h2 id="disclaimer-of-representations-and-warranties">Disclaimer of Representations and Warranties</h2>
            <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE CONTENT AND ALL SERVICES AND PRODUCTS ASSOCIATED WITH THE SERVICE OR PROVIDED THROUGH THE
                SERVICE (WHETHER OR NOT SPONSORED) ARE PROVIDED TO YOU ON AN &quot;AS-IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. KLOTHED MAKES NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE CONTENT OR OPERATION OF THE SERVICE OR OF THE SERVICE. YOU EXPRESSLY
                AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.
            </p>
            <p>
                KLOTHED MAKES NO REPRESENTATIONS, WARRANTIES, CONDITIONS, OR GUARANTEES, EXPRESS OR IMPLIED, REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS
                OF THE CONTENT ON THE SERVICE OR OF THE SERVICE (WHETHER OR NOT SPONSORED), AND EXPRESSLY DISCLAIMS ANY REPRESENTATIONS, WARRANTIES OR
                CONDITIONS OF NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE. KLOTHED MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE THAT THE CONTENT THAT
                MAY BE AVAILABLE THROUGH THE SERVICE IS FREE OF INFECTION FROM ANY VIRUSES OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT CONTAIN
                CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE INTENDED TO DAMAGE, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE ANY SYSTEM, DATA OR PERSONAL
                INFORMATION. YOU ARE RESPONSIBLE FOR ENSURING THE ACCURACY OF ANY USER CONTENT THAT YOU PROVIDE, AND WE DISCLAIM ALL LIABILITY AND
                RESPONSIBILITY FOR THE ACCURACY OF SUCH CONTENT.
            </p>
            <h2 id="notification-disclaimer">Notification Disclaimer</h2>
            <p>
                You understand and agree that any notifications provided to you through the Service may be delayed or prevented by a variety of factors. Klothed
                does its best to provide notifications in a timely manner with accurate information. However, we neither guarantee the delivery nor the accuracy
                of the content of any notification. You also agree that Klothed shall not be liable for any delays, failure to deliver, or misdirected delivery
                of any notification; for any errors in the content of a notification; or for any actions taken or not taken by you or any third-party in
                reliance on a notification.
            </p>
            <h2 id="limitations-on-klothed-s-liability">Limitations on Klothed&#39;s Liability</h2>
            <p>
                KLOTHED SHALL IN NO EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR
                OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF
                PROFIT, REVENUE OR BUSINESS, ARISING IN WHOLE OR IN PART FROM YOUR ACCESS TO THE SERVICE, YOUR USE OF THE SERVICE OR THIS AGREEMENT, OR FOR ANY
                LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED ON OR THROUGH THE SERVICE, EVEN IF KLOTHED HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, KLOTHED&#39;S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO $500.00 (FIVE HUNDRED UNITED STATES DOLLARS).
            </p>
            <p>
                BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
                STATES OR JURISDICTIONS, THE LIABILITY OF KLOTHED SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. THEREFORE, THE FOREGOING LIMITATIONS
                SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. NOTHING IN THIS AGREEMENT AFFECTS STATUTORY RIGHTS THAT CANNOT BE WAIVED OR LIMITED BY
                CONTRACT.
            </p>
            <h2 id="your-indemnification-of-klothed">Your Indemnification of Klothed</h2>
            <p>
                You shall defend, indemnify and hold harmless Klothed and its officers, directors, shareholders, and employees, from and against all claims and
                expenses, including but not limited to attorney&#39;s fees and other legal expenses, in whole or in part arising out of or attributable to your
                use of the Service (including, but not limited to any purchases you make or any breach of this Agreement by you or your violation of any law or
                the rights of a third-party when using the Service.
            </p>
            <h2 id="ending-your-relationship-with-klothed-and-vice-versa-">Ending your Relationship with Klothed (and vice versa)</h2>
            <p>This Agreement will continue to apply until terminated by either you or Klothed as set out below.</p>
            <p>
                If you want to terminate this Agreement, you may do so immediately at any time by selecting the &quot;Delete my account&quot; feature in
                Settings and stop visiting our Website and using the Service. Any data records retained shall be retained in accordance with our privacy policy.
            </p>
            <p>
                Klothed may immediately, at any time, terminate this Agreement (and therefore your right to access and use the Service) by notifying you (if
                safe to do so) to the e-mail address provided by you as part of your account registration process.
            </p>
            <h2 id="arbitration-clause-class-action-waiver-important-please-review-as-this-affects-your-legal-rights">
                ARBITRATION CLAUSE &amp; CLASS ACTION WAIVER -- IMPORTANT -- PLEASE REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS
            </h2>
            <p>
                Arbitration. YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD-PARTY) WITH REGARD TO YOUR
                RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THIS AGREEMENT, YOUR USE OF THE SERVICE, AND/OR RIGHTS OF PRIVACY AND/OR
                PUBLICITY, WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION ASSOCIATION&#39;S RULES FOR ARBITRATION OF
                CONSUMER-RELATED DISPUTES AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED, HOWEVER, THAT TO THE EXTENT THAT YOU HAVE IN ANY MANNER
                VIOLATED OR THREATENED TO VIOLATE OUR INTELLECTUAL PROPERTY RIGHTS, WE MAY SEEK INJUNCTIVE OR OTHER APPROPRIATE RELIEF IN ANY STATE OR FEDERAL
                COURT IN THE STATE OF CALIFORNIA. DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS
                THAT YOU AND WE WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION.
            </p>
            <p>If you wish to begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to:</p>
            <p>Klothed Technologies\ Attn: Dispute\ 279 Mill Road</p>
            <p>Westhampton Beach, NY 11978</p>
            <p>.</p>
            <p>
                As an alternative, you may bring your claim in your local &quot;small claims&quot; court, if permitted by that small claims court&#39;s rules
                and if within such court&#39;s jurisdiction, unless such action is transferred, removed or appealed to a different court. You may bring claims
                only on your own behalf. Neither you nor we will participate in a class action or class-wide arbitration for any claims covered by this
                agreement to arbitrate. BY AGREEING TO THIS ARBITRATION CLAUSE &amp; CLASS ACTION WAIVER, YOU AND KLOTHED ARE GIVING UP THE RIGHT TO PARTICIPATE
                AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM THAT EACH SIDE MAY HAVE AGAINST THE OTHER INCLUDING ANY RIGHT TO CLASS ARBITRATION
                OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. You also agree not to participate in claims brought in a private attorney general or
                representative capacity, or consolidated claims involving another person&#39;s account, if we are a party to the proceeding. This dispute
                resolution provision will be governed by the Federal Arbitration Act and not by any state law concerning arbitration. In the event the American
                Arbitration Association is unwilling or unable to set a hearing date within one hundred sixty (160) days of filing the case, then either we or
                you can elect to have the arbitration administered instead by the Judicial Arbitration and Mediation Services. Judgment on the award rendered by
                the arbitrator may be entered in any court having competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator will
                not have authority to award damages, remedies or awards that conflict with this Agreement. You agree that regardless of any statute or law to
                the contrary, any claim or cause of action arising out of, related to or connected with the use of the Service or this Agreement must be filed
                within one (1) year after such claim of action arose or be forever banned.
            </p>
            <p>
                If you do not want to arbitrate disputes with Klothed and you are an individual, you may opt out of this ARBITRATION CLAUSE &amp; CLASS ACTION
                WAIVER by sending an email to <a href="mailto:info@getKlothed.com">info@getKlothed.com</a> within thirty (30) days of the first date you access
                or use the Service.{" "}
            </p>
            <h2 id="severability-">Severability. </h2>
            <p>
                If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then
                all of the preceding language in this Arbitration section will be null and void.{" "}
            </p>
            <h2 id="survival-">Survival. </h2>
            <p>This arbitration agreement will survive the termination of your relationship with us.</p>
            <h2 id="governing-law-and-forum-for-disputes">Governing Law and Forum for Disputes</h2>
            <p>
                This Agreement, and your relationship with Klothed under this Agreement, shall be governed by the laws of the State of New York in the United
                States of America without regard to its conflict or choice of laws provisions. Any dispute with Klothed, or its officers, directors, employees,
                agents or affiliates, arising under or in relation to this Agreement shall be resolved exclusively as specified in the ARBITRATION CLAUSE &amp;
                CLASS ACTION WAIVER clause above, except with respect to imminent harm requiring temporary or preliminary injunctive relief in which case
                Klothed may seek such relief in any court with jurisdiction over the parties. You understand that, in return for agreement to this provision,
                Klothed is able to offer the Service at the terms designated, with little or no charge to you, and that your assent to this provision is an
                indispensable consideration to this Agreement.
            </p>
            <p>
                You also acknowledge and understand that, with respect to any dispute with Klothed, its officers, directors, employees, agents or affiliates,
                arising out of or relating to your use of the Service or this Agreement:
            </p>
            <ul>
                <li>YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY; and</li>
            </ul>
            <p>
                -YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR TO
                PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT INVOLVING ANY SUCH DISPUTE.
            </p>
            <h2 id="copyright-disputes-and-user-content">Copyright Disputes and User Content</h2>
            <p>
                You may have heard of the Digital Millennium Copyright Act (the &quot;DMCA&quot;), as it relates to online service providers, like Klothed,
                being asked to remove user generated material that allegedly violates someone&#39;s copyright. We respect others&#39; intellectual property
                rights, and we reserve the right to delete or disable User Content alleged to be infringing, and to terminate the accounts of repeat alleged
                infringers; to review our complete Copyright Dispute Policy and learn how to report potentially infringing content, visit
                www.getklothed.com/copyright . To learn more about the DMCA, visit{" "}
                <a href="https://www.copyright.gov/reports/studies/dmca/dmca_executive.html">
                    https://www.copyright.gov/reports/studies/dmca/dmca_executive.html
                </a>{" "}
            </p>
            <h2 id="consumer-rights-notices">Consumer Rights Notices</h2>
            <h3 id="california-consumer-rights-notice-under-california-civil-code-section-1789-california-users-of-the-service-receive-the-following-specific-consumer-rights-notice-the-complaint-assistance-unit-of-the-division-of-consumer-services-of-the-california-department-of-consumer-affairs-may-be-contacted-in-writing-at-1625-north-market-blvd-suite-n-112-sacramento-ca-95834-or-by-telephone-at-800-952-5210-">
                California Consumer Rights Notice. Under California Civil Code Section 1789, California users of the Service receive the following specific
                consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may
                be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952- 5210.
            </h3>
            <h2 id="assignment">Assignment</h2>
            <p>
                Klothed may assign, without recourse, any or all of its rights or obligations under this Agreement, including all or a portion of this
                Agreement.
            </p>
            <h2 id="miscellaneous">Miscellaneous</h2>
            <p>
                If any portion of this Agreement is deemed unlawful, void or unenforceable by any arbitrator or court of competent jurisdiction, this Agreement
                as a whole shall not be deemed unlawful, void or unenforceable, but only that portion of this Agreement that is unlawful, void or unenforceable
                shall be stricken from this Agreement.
            </p>
            <p>
                You agree that if Klothed does not exercise or enforce any legal right or remedy which is contained in the Agreement (or which Klothed has the
                benefit of under any applicable law), this will not be taken to be a formal waiver of Klothed&#39;s rights and that those rights or remedies
                will still be available to Klothed.
            </p>
            <p>
                All covenants, agreements, representations and warranties made in this Agreement shall survive your acceptance of this Agreement and the
                termination of this Agreement.
            </p>
            <p>The term &quot;including&quot; in this Agreement means &quot;including but not limited to.&quot;</p>
            <h2 id="contact-us">Contact Us</h2>
            <p>
                Comments, questions, concerns, complaints, claims or suggestions may be communicated by emailing us at{" "}
                <a href="mailto:info@getKlothed.com">help@getKlothed.com</a>, calling us at (631) 898-5725 or writing us at Klothed Technologies Inc., 279 Mill
                Road, Westhampton Beach, NY 11978
            </p>
        </div>
    );
}
