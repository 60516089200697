export interface HeightInFeetAndInches {
    feet: number;
    inches: number;
}

export const toFeetAndInches = (centimeters: number) => {
    const realFeet = (centimeters * 0.3937) / 12;
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);
    return {
        feet: inches === 12 ? feet + 1 : feet,
        inches: inches === 12 ? 0 : inches,
    };
};

export const toCentimeters = (heightInFeetAndInches: HeightInFeetAndInches) => {
    const cmTotal = heightInFeetAndInches.feet * 30.48 + heightInFeetAndInches.inches * 2.54;
    return Math.round(cmTotal);
};
