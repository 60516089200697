import React from "react";
import { product_ShopperCatalogsResponse } from "@v2/utils";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface InfiniteProps {
    children?: ReactNode;
    keyPerFetch: number;
    keys?: product_ShopperCatalogsResponse[];
    contentViewer: (key: product_ShopperCatalogsResponse, idx: number) => ReactNode;
    className?: string;
    height?: number;
}

export function InfiniteRacks({ children, contentViewer, keys, keyPerFetch, className, height }: InfiniteProps) {
    const [content, setContent] = useState<product_ShopperCatalogsResponse[]>([]);
    const lastIdx = useRef(0);
    const [hasMore, setHasMore] = useState(true);
    const fetchData = useCallback(async () => {
        if (!keys) return;
        const end = Math.min(lastIdx.current + keyPerFetch, keys.length);
        if (end === keys.length) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
        setContent(keys.slice(0, end));
        lastIdx.current = end;
    }, [setContent, setHasMore, keys]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <InfiniteScroll dataLength={content.length} next={fetchData} hasMore={hasMore} loader={<h4>Loading...</h4>} className={className} height={height}>
            <>
                {children}
                {content.map((c, i) => contentViewer(c, i))}
            </>
        </InfiniteScroll>
    );
}
