import { useSiteSession } from "@v2/utils";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

export function NotFoundPage() {
    const { setPageDescription, setPageTitle } = useSiteSession();
    const history = createBrowserHistory();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Not found.`);
        setPageDescription(`Klothed - Everything looks better on you. - Not found.`);
    }, [setPageTitle, setPageDescription]);

    return (
        <div className="page-404">
            <p>Hmm...</p>
            <p>Everything still looks better on you, but something isn’t right.</p>
            <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_RACKS_HOME || "#" }}>
                <button type="button">HOME</button>
            </Link>
        </div>
    );
}
