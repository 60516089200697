import useQueue from "react-use-queue";
import { tryonSync } from "../tryonSync";
import { Api, product_CatalogItemResponse, tryon_ShopperTryonInfoResult, tryon_TryonInfo } from "../klothed-api";
import { useCallback, useEffect, useState } from "react";
import { AuthState } from "./authContext";
import { toast } from "react-toastify";

export interface TryonContext {
    pushTryon: (it: TryonQueueItem) => void;
    current: { [key: number]: product_CatalogItemResponse };
    updateCloset: () => Promise<void>;
    updateFavorites: () => Promise<void>;
    closetProducts?: tryon_ShopperTryonInfoResult;
    favoriteProducts?: tryon_ShopperTryonInfoResult;
}
export interface TryonQueueItem {
    product: product_CatalogItemResponse;
    setTryonState: (state?: tryon_TryonInfo) => void;
}

export function TryonContextProvider({ adam, token, authState }: { adam?: Api; token?: string; authState: AuthState }): TryonContext {
    const queue = useQueue();

    const [current, setCurrent] = useState<{ [key: number]: product_CatalogItemResponse }>([]);
    const [closetProducts, setClosetProducts] = useState<tryon_ShopperTryonInfoResult>();
    const [favoriteProducts, setFavoriteProducts] = useState<tryon_ShopperTryonInfoResult>();

    const updateCloset = useCallback(async () => {
        if (adam) {
            try {
                const closetProducts = (await adam.secureShopperClosetGet({})) as tryon_ShopperTryonInfoResult;
                if (closetProducts && closetProducts.count && closetProducts.count > 0) {
                    setClosetProducts(closetProducts);
                    return;
                }
            } catch (err) {
                console.error(err);
            }
        }
        setClosetProducts(undefined);
    }, [setClosetProducts, token]);

    const updateFavorites = useCallback(async () => {
        if (adam) {
            try {
                const favoriteProducts = (await adam.secureShopperFavoritesGet({})) as tryon_ShopperTryonInfoResult;
                if (favoriteProducts && favoriteProducts.count && favoriteProducts.count > 0) {
                    setFavoriteProducts(favoriteProducts);
                    return;
                }
            } catch (err) {
                console.error(err);
            }
        }
        setFavoriteProducts(undefined);
    }, [setFavoriteProducts, token]);

    const pushTryon = useCallback(
        (it: TryonQueueItem) => {
            queue.addJob(async () => {
                const jobPromise = (async () => {
                    const { product, setTryonState } = it;
                    if (!adam || !product.image_id || !token) {
                        console.warn("failed to initialize tryon due to missing token or image_id");
                        return;
                    }
                    const image_id = product.image_id;
                    try {
                        let tryonState: tryon_TryonInfo = await adam.secureTryonPost({
                            product_image_id: [image_id.toString()],
                        });
                        setCurrent((c) => {
                            const c_local = c;
                            const id = tryonState.status === "success" ? tryonState.id : undefined;
                            c_local[image_id] = { ...product, id };
                            return c_local;
                        });
                        setTryonState(tryonState);
                        if (tryonState.id && tryonState.status === "processing") {
                            tryonState = await tryonSync(tryonState.id, token);
                            setTryonState(tryonState);
                        }
                        setCurrent((c) => {
                            const c_local = c;
                            const id = tryonState.status === "success" ? tryonState.id : undefined;
                            c_local[image_id] = { ...product, id };
                            updateCloset();
                            return c_local;
                        });
                    } catch (err) {
                        console.error(err);
                        setTryonState(undefined);
                    }
                })();
                toast.promise(jobPromise, {
                    pending: "Try-On in progress",
                    success: "Try-On ready",
                    error: "Try-On failed",
                });
                await jobPromise;
            });
        },
        [queue, adam, token, updateCloset]
    );

    useEffect(() => {
        if (authState === AuthState.TryonProfile) {
            updateCloset();
            updateFavorites();
        } else {
            setClosetProducts(undefined);
            setFavoriteProducts(undefined);
        }
    }, [updateCloset, updateFavorites, authState, setClosetProducts, setFavoriteProducts]);

    return { current, pushTryon, updateCloset, updateFavorites, closetProducts, favoriteProducts };
}
