import React from "react";
import { UserProfileHeight } from "@v2/components/profile/height";

export default function ProfileHeightPage() {
    return (
        <div className="user_profile_height_style">
            <UserProfileHeight />
        </div>
    );
}
