import React, { useCallback, useState } from "react";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import isEmail from "validator/lib/isEmail";

export function EmailComponent({ setValidEmail }: { setValidEmail: (k?: string) => void }) {
    const { user } = useSiteSession();
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [repeatEmail, setRepeatEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [mailError, setMailError] = useState(false);

    const blur = useCallback(() => {
        if (email && !isEmailValid) {
            setErrorMessage("Please enter a valid email address.");
            setMailError(true);
            setValidEmail(undefined);
        } else if (repeatEmail && repeatEmail !== email) {
            setErrorMessage("Emails do not match.");
            setMailError(true);
            setValidEmail(undefined);
        } else {
            setValidEmail(email);
        }
    }, [email, isEmailValid, repeatEmail, setErrorMessage, setMailError, setValidEmail]);

    return (
        <div className="email-change">
            {errorMessage ? (
                <div className="errorMessage__">
                    <div className="errorMessageHeading">{errorMessage}</div>
                    <div className="horizontal_separator horizontal_separator_error"></div>
                </div>
            ) : (
                <div className="signUp_top_header">Enter your new email</div>
            )}
            <div className="email-group old">
                <input className="email_input_box" placeholder=" " type="text" value={user?.email || "unknown"} readOnly />
                <label className="email_input_label">Old email</label>
            </div>
            <div className="email-group new">
                <input
                    name="email"
                    className={classNames("email_input_box", mailError && "email_input_box_error")}
                    placeholder=" "
                    type="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                        setIsEmailValid(isEmail(event.target.value));
                    }}
                    autoComplete="email"
                    onBlur={blur}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                    }}
                />
                <label className="email_input_label">New email</label>
            </div>
            <div className="email-group new-repeat">
                <input
                    name="email-repeat"
                    className={classNames("email_input_box", mailError && "email_input_box_error")}
                    placeholder=" "
                    type="email"
                    value={repeatEmail}
                    onChange={(event) => {
                        setRepeatEmail(event.target.value);
                    }}
                    autoComplete="email"
                    onBlur={blur}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                    }}
                />
                <label className="email_input_label">Repeat email</label>
            </div>
        </div>
    );
}

export function Email() {
    const { authState, syncEmailChange } = useSiteSession();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>();

    return authState === AuthState.Unknown ? (
        <div>Loading...</div>
    ) : (
        <div className="profile_email_section_v2">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const email = e.currentTarget["email"].value;
                    syncEmailChange(email).then(() => navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#"));
                }}
            >
                <EmailComponent setValidEmail={setEmail} />
                <input type="submit" value="SAVE" disabled={!email} />
            </form>
        </div>
    );
}
