import React, { useEffect, useState } from "react";
import "./loginAndSignUp.scss";
import PhoneImage from "./assets/phone-image.png";
import Legal from "../legal/legal";
import LogIn from "./logIn/logIn";
import SignUp from "./signUp/signUp";
import { LogoSlider, Button } from "@v2/components/burger";
import Footer from "../components/footer/footer";

export enum RightPane {
    TermsOfService,
    PrivacyPolicy,
    Help,
    SignUp,
    Login,
}

interface LoginAndSignUpProps {
    refresh: boolean;
    shopperAuthError: string;
}

function LoginAndSignUp(props: LoginAndSignUpProps) {
    //Retrun from ForgotPassword Page
    let returnFromForgotPassword: string | number | null = sessionStorage.getItem("returnFromForgotPassword");
    let returnFromForgotPasswordTypeCasted: RightPane = RightPane.Login;
    if (returnFromForgotPassword) {
        returnFromForgotPassword = parseInt(returnFromForgotPassword);
        returnFromForgotPasswordTypeCasted = returnFromForgotPassword as RightPane;
    }
    //

    useEffect(() => {
        let returnFromForgotPassword: string | number | null = sessionStorage.getItem("returnFromForgotPassword");
        sessionStorage.removeItem("returnFromForgotPassword");
        if (returnFromForgotPassword) {
            returnFromForgotPassword = parseInt(returnFromForgotPassword);
            returnFromForgotPasswordTypeCasted = returnFromForgotPassword as RightPane;
            setRightPane(returnFromForgotPasswordTypeCasted);
        }
    }, [props.refresh]);

    const [rightPane, setRightPane] = useState<RightPane>(returnFromForgotPasswordTypeCasted);

    return (
        <div onClick={() => sessionStorage.removeItem("returnFromForgotPassword")} className="login_and_signUp">
            <div className="left_pane">
                {rightPane === RightPane.Login || rightPane === RightPane.SignUp ? (
                    <div className="artifect_group">
                        <img src={PhoneImage} className="image" />
                        <h4>Try on styles from your favorite online retailer. It&apos;s free.</h4>
                        <Button
                            href={"#"}
                            fill
                            onClick={() => {
                                window.open(process.env.REACT_APP_EXTENSION_MOBILE, "_blank");
                            }}
                        >
                            Get The App
                        </Button>

                        <div className="brandsSec">
                            <LogoSlider />
                        </div>
                    </div>
                ) : (
                    <div className="backTo_Login_SignUp">
                        <div onClick={() => setRightPane(RightPane.SignUp)} className="signUp">
                            {"SIGN UP"}
                        </div>
                        <div onClick={() => setRightPane(RightPane.Login)} className="logIn_wrapper">
                            <div className="logIn">{"LOG IN"}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="right_pane">
                {rightPane === RightPane.SignUp && (
                    <SignUp
                        shopperAuthError={props.shopperAuthError}
                        goToLogIn={() => {
                            setRightPane(RightPane.Login);
                        }}
                        goToTermsOfServices={() => {
                            setRightPane(RightPane.TermsOfService);
                        }}
                        goToPrivacyPolicy={() => {
                            setRightPane(RightPane.PrivacyPolicy);
                        }}
                    ></SignUp>
                )}
                {(rightPane === RightPane.TermsOfService || rightPane === RightPane.PrivacyPolicy || rightPane === RightPane.Help) && (
                    <Legal legal={rightPane}></Legal>
                )}
                {rightPane === RightPane.Login && (
                    <LogIn
                        shopperAuthError={props.shopperAuthError}
                        goToSignUp={() => {
                            setRightPane(RightPane.SignUp);
                        }}
                    ></LogIn>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default LoginAndSignUp;
