import React, { useState } from "react";

/**
 * Internal dependencies.
 */
import StepsData, { ItemProp, ListProp } from "./stepsData";
import { ReactComponent as IconNot } from "./assets/icon-not.svg";
import MImage1 from "./assets/m-image1.jpg";
import WImage1 from "./assets/w-image1.jpg";
import "./steps.scss";

const DEFAULT_IMAGE: { men: string; women: string; both: string } = {
    men: MImage1,
    women: WImage1,
    both: WImage1,
};

const Steps = () => {
    const shopperDetails = sessionStorage.getItem("shopperID");
    let step: ItemProp[] | undefined;
    let style = "";
    if (shopperDetails && JSON.parse(shopperDetails)?.info?.style) {
        style = JSON.parse(shopperDetails)?.info?.style;
        step = style === "women" || style === "both" ? StepsData.women : StepsData.men;
    }
    const [image, setImage] = useState<string>(DEFAULT_IMAGE[style as keyof object]);
    const [not, setNot] = useState(false);

    const handleMouseEnter = (list: ListProp, item: ItemProp) => {
        if (list.image) {
            setImage(list.image);
            setNot(item.not);
        }
    };

    const handleMouseLeave = () => {
        setImage(DEFAULT_IMAGE[style as keyof object]);
        setNot(false);
    };

    return (
        <div className="steps">
            <h2>How to pose.</h2>
            <div className="steps__inner">
                <div className="steps__col">
                    {step?.map((item: ItemProp, index: number) => (
                        <div key={index}>
                            <h5>{item.title}</h5>
                            <ul>
                                {item.list?.map((list: ListProp, ind: number) => (
                                    <li key={ind} onMouseLeave={handleMouseLeave} onMouseEnter={() => handleMouseEnter(list, item)}>
                                        <p>{list.text}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className="steps__col steps__col--image">
                    <img src={image} />
                    {not && <IconNot className="image-not" />}
                </div>
            </div>
        </div>
    );
};

export default Steps;
