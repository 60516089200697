import React from "react";
import { Container } from "@v2/components/burger";

const Footer = () => (
    <footer className="footer">
        <Container>
            <div className="footer__inner">
                <p>© Klothed Technologies Inc.</p>
            </div>
        </Container>
    </footer>
);

export default Footer;
