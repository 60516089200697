import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { SharedMobileV2 } from "./mobile";
import { AuthState, klothedAPI, share_ShareInfo, useSiteSession } from "@v2/utils";
import { SharedDesktopV2 } from "./desktop";

export const ShareV2 = () => {
    const { setPageTitle, setPageDescription, setPageImage } = useSiteSession();

    const [shareCode, setShareCode] = useState("");
    const [shareLink, setShareLink] = useState("");
    const [TryOnImage, setTryOnImage] = useState("");
    const [productName, setProductName] = useState("");
    const [retailerName, setRetailerName] = useState("");
    const [retailerLogo, setRetailerLogo] = useState("");
    const [productURL, setProductURL] = useState("");

    const { authState } = useSiteSession();
    const authenticated = authState > AuthState.NoUserAccount;
    const loading = authState === AuthState.Unknown;

    useEffect(() => {
        if (!productName || !retailerName || !TryOnImage) return;
        setPageTitle(`Klothed - ${productName} from ${retailerName}`);
        setPageDescription(`Klothed - Shared virtual try on for ${productName} from ${retailerName}`);
        setPageImage(TryOnImage);
    }, [productName, retailerName, TryOnImage, setPageTitle, setPageDescription, setPageImage]);

    const shareMobileLink = useCallback(
        () =>
            navigator.share({
                title: retailerName,
                text: productName,
                url: shareLink,
            }),
        [retailerName, productName, shareLink]
    );

    useEffect(() => {
        setShareCode(document.location.pathname.split("/").at(-1) || "");
    }, [setShareCode]);
    useEffect(() => {
        if (shareCode) {
            setTryOnImage(`${process.env.REACT_APP_API_URL}/share/tryon/${shareCode}.jpg`);
        } else {
            setTryOnImage("");
        }
    }, [shareCode, setTryOnImage]);

    const fetchInfo = useCallback(async () => {
        if (!shareCode || loading) return;
        try {
            const res = (await klothedAPI.shareInfoUidGet({
                uid: shareCode,
            })) as share_ShareInfo;
            setProductName(res.product_info?.type || "");
            setRetailerName(res.retailer_name || "");
            setRetailerLogo(res.retailer_icon || "");
            if (authenticated) {
                setProductURL(res.product_info?.affiliated_url || "");
            } else {
                setProductURL(process.env.REACT_APP_WEB_HOW_IT_WORKS || "");
            }
        } catch (err) {
            console.error(err);
            setProductName("");
            setRetailerName("");
            setRetailerLogo("");
            setProductURL("");
        }
    }, [shareCode, loading, authenticated, setProductName, setRetailerName, setRetailerLogo]);

    useEffect(() => {
        if (!shareCode) return;
        setShareLink(`${process.env.REACT_APP_WEB_URL}/s/${shareCode}`);
        fetchInfo();
    }, [shareCode, fetchInfo]);

    return (
        <>
            {isMobile ? (
                <SharedMobileV2
                    TryOnImage={TryOnImage}
                    productName={productName}
                    productURL={productURL}
                    retailerLogo={retailerLogo}
                    retailerName={retailerName}
                    shareMobileLink={shareMobileLink}
                />
            ) : (
                <SharedDesktopV2
                    TryOnImage={TryOnImage}
                    productName={productName}
                    productURL={productURL}
                    retailerLogo={retailerLogo}
                    retailerName={retailerName}
                    shareMobileLink={shareMobileLink}
                />
            )}
        </>
    );
};
