import { User, sendEmailVerification } from "firebase/auth";

export function verifyUser(user: User) {
    sendEmailVerification(user, {
        url: process.env.REACT_APP_WEB_VERIFY_REDIRECT || "#",
        iOS: {
            bundleId: "com.getklothed",
        },
    });
}
