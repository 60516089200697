import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { Buffer } from "buffer";

export enum WorkerStatusType {
    Unknown = "unknown",
    Available = "available",
    Congested = "congested",
}

export interface WorkerStatusResponseType {
    status: WorkerStatusType;
    tick: string;
}

export interface ServerContext {
    serverState: WorkerStatusResponseType;
}

export function ServerContextProvider(): ServerContext {
    const [serverState, setServerState] = useState<WorkerStatusResponseType>({
        status: WorkerStatusType.Unknown,
        tick: "",
    });
    const { lastMessage } = useWebSocket(`ws${(process.env.REACT_APP_API_URL as string).slice(4)}/status/ws`);

    useEffect(() => {
        if (lastMessage) {
            setServerState(JSON.parse(Buffer.from(lastMessage.data, "base64").toString()));
        }
    }, [lastMessage, setServerState]);

    return {
        serverState,
    };
}
