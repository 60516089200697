import { useCallback, useEffect, useState } from "react";
import { AuthState } from "./authContext";
import { Api, SecureProductModesensCatalogsGetArgs, product_ShopperCatalogsResponse } from "../klothed-api";

export interface EditsContext {
    shopperCatalogs?: product_ShopperCatalogsResponse[];
}

export function EditsContextProvider({
    authState,
    adam,
    style,
    filterSortParams,
}: {
    adam?: Api;
    authState: AuthState;
    style?: string;
    filterSortParams?: SecureProductModesensCatalogsGetArgs;
}): EditsContext {
    const [shopperCatalogs, setShopperCatalogs] = useState<product_ShopperCatalogsResponse[]>();

    // const performPublicGetter = useCallback(async () => {
    //     try {
    //         setShopperCatalogs([]);
    //         const shopperCatalogs = (await klothedAPI.productCatalogsGet()) as product_ShopperCatalogsResponse[];
    //         setShopperCatalogs(shopperCatalogs);
    //     } catch (err) {
    //         console.error("failed fetch products for catalog", err);
    //     }
    // }, [setShopperCatalogs, adam]);

    const performGetter = useCallback(async () => {
        if (!adam || !filterSortParams) {
            return;
        }
        try {
            const shopperCatalogs = (await adam.secureProductModesensCatalogsGet(filterSortParams || {})) as product_ShopperCatalogsResponse[];
            setShopperCatalogs(shopperCatalogs);
        } catch (err) {
            console.error("failed fetch products for catalog", err);
            setShopperCatalogs([]);
        }
    }, [setShopperCatalogs, adam, filterSortParams]);

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return;
            case AuthState.NoUserAccount:
                // performPublicGetter();
                return;
            case AuthState.NoKlothedAccount:
                return;
            case AuthState.NoVerification:
                return;
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                performGetter();
                return;
        }
    }, [authState, performGetter, style]);

    return { shopperCatalogs };
}
