import React, { useEffect } from "react";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";
import { ForgotPassword } from "./forgotPassword";

export const UserForgotPassword = () => {
    const { authState, setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Forgot password.`);
        setPageDescription(`Klothed - Everything looks better on you. - Forgot password.`);
    }, [setPageTitle, setPageDescription]);

    const navigate = useNavigate();

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoUserAccount:
                return; // we are on the correct page
            case AuthState.NoKlothedAccount:
                // Create a shopper account
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.NoAdmittance:
            case AuthState.ServerError:
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                // Allow resetting password for the current account
                return;
        }
    }, [authState, navigate]);

    return (
        <div className="artifact_group">
            <ForgotPassword />
        </div>
    );
};
