import React from "react";
import SignUp from "./signup";

export function SignUpMobile() {
    return (
        <div className="artifact_group">
            <SignUp />
        </div>
    );
}
