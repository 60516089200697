import React, { useState } from "react";
import "./rightSlider.scss";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as GetApp } from "./assets/getApp.svg";
import { ReactComponent as GetExtension } from "./assets/getExtension.svg";
import { auth } from "@v1/../firebase-config";
import { Component } from "../App";
import { RightPane } from "../loginAndSignUp/loginAndSignUp";
import { signOut } from "firebase/auth";

interface RightSliderProps {
    className?: string;
    closeAct: () => void;
    setCompFromApp?: (comp: Component) => void;
}

function RightSlider(props: RightSliderProps) {
    const [showMoreAbout, setShowMoreAbout] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <div className={`rightSlider ${props.className} ${showMoreAbout ? "rightSliderAboutExp" : ""}`}>
            <div className="rightSlider__overlay" onClick={props.closeAct}></div>
            <div className={`rightSlider_ ${props.className} ${showMoreAbout ? "rightSliderAboutExp_" : ""}`}>
                <Close
                    onClick={() => {
                        props.closeAct();
                        setShowMoreAbout(false);
                    }}
                    className="closeRightSlider"
                />
                {!auth.currentUser && (
                    <div
                        onClick={() => {
                            sessionStorage.setItem("returnFromForgotPassword", JSON.stringify(RightPane.SignUp));
                            props.setCompFromApp ? props.setCompFromApp(Component.LoginAndSignUp) : navigate(process.env.REACT_APP_WEB_SIGNUP || "#");
                            props.closeAct();
                        }}
                        className="rightSliderOption rightSliderOptionFirst hide-on-mobile"
                    >
                        {"Sign Up"}
                    </div>
                )}
                {!auth.currentUser && (
                    <div
                        onClick={() => {
                            sessionStorage.setItem("returnFromForgotPassword", JSON.stringify(RightPane.Login));
                            props.setCompFromApp ? props.setCompFromApp(Component.LoginAndSignUp) : navigate(process.env.REACT_APP_WEB_LOGIN || "#");
                            props.closeAct();
                        }}
                        className="rightSliderOption hide-on-mobile"
                    >
                        {"Log In"}
                    </div>
                )}
                <div className={`rightSliderOption ${auth.currentUser && "rightSliderOptionFirst"}`}>
                    <a href={process.env.REACT_APP_EXTENSION_MOBILE || "#"} target={"_blank"} rel={"noreferrer"}>
                        Get the App
                        <GetApp className="getLogo_" />
                    </a>
                </div>
                <div className="rightSliderOption hide-on-mobile">
                    <a href={process.env.REACT_APP_EXTENSION_DESKTOP || "#"} target={"_blank"} rel={"noreferrer"}>
                        Get the Extension
                        <GetExtension className="getLogo_" />
                    </a>
                </div>
                <div className="rightSliderOption">
                    <Link to={process.env.REACT_APP_WEB_ABOUT_US || "#"}> {"About"}</Link>
                </div>
                {!auth.currentUser && (
                    <div className="rightSliderOption">
                        <Link to={process.env.REACT_APP_WEB_HOW_IT_WORKS || "#"}>How It Works</Link>
                    </div>
                )}
                <div className="rightSliderOption">
                    <Link to={process.env.REACT_APP_WEB_RETAILERS || "#"}>Retailers</Link>
                </div>
                {!auth.currentUser && (
                    <div className="rightSliderOption">
                        <Link to={process.env.REACT_APP_WEB_TEAM || "#"}>Team</Link>
                    </div>
                )}
                <div className="rightSliderOption">
                    <Link to={process.env.REACT_APP_WEB_HELP || "#"}>Help</Link>
                </div>
                {auth.currentUser && (
                    <div className="rightSliderOption hide-on-mobile">
                        <Link to={process.env.REACT_APP_WEB_PROFILE || "#"}>My Profile</Link>
                    </div>
                )}
                {/* {auth.currentUser && (
                    <div
                        onClick={() => {
                            props.closeAct();
                        }}
                        className="rightSliderOption"
                    >
                        {"Settings"}
                    </div>
                )} */}
                {!auth.currentUser && (
                    <div className="rightSliderOption hide-on-mobile">
                        <Link to={process.env.REACT_APP_WEB_TERMS_OF_SERVICE || "#"}>Terms of Service</Link>
                    </div>
                )}
                {!auth.currentUser && (
                    <div className="rightSliderOption hide-on-mobile">
                        <Link to={process.env.REACT_APP_WEB_PRIVACY || "#"}>Privacy Policy</Link>
                    </div>
                )}
                {auth.currentUser && (
                    <div
                        onClick={() => {
                            console.debug("Signing out");
                            sessionStorage.removeItem("shopperID");
                            sessionStorage.removeItem("accessToken");
                            signOut(auth);
                            props.closeAct();
                            setShowMoreAbout(false);
                        }}
                        className="rightSliderOption"
                    >
                        {"Log Out"}
                    </div>
                )}
            </div>
        </div>
    );
}

export default RightSlider;
