/**
 * External dependencies.
 */
import React from "react";

/**
 * Internal dependencies.
 */
import { ReactComponent as Email } from "./assets/email.svg";
import { ReactComponent as Linkedin } from "./assets/linkedin.svg";
import { ReactComponent as Twitter } from "./assets/twitter.svg";

import "./share.scss";

type Props = {
    email?: string;
    linkedin?: string;
    twitter?: string;
};

export const Share: React.FC<Props> = ({ email, linkedin, twitter }) => (
    <ul className="share-section">
        {email && (
            <li>
                <a href={`mailto:${email}`}>
                    <Email />
                </a>
            </li>
        )}
        {linkedin && (
            <li>
                <a href={linkedin} target="_blank" rel="noreferrer">
                    <Linkedin />
                </a>
            </li>
        )}
        {twitter && (
            <li>
                <a href={twitter} target="_blank" rel="noreferrer">
                    <Twitter />
                </a>
            </li>
        )}
    </ul>
);
