/**
 * External dependencies.
 */
import classNames from "classnames";
import React from "react";

/**
 * Internal dependencies.
 */
import "./teamRowImage.scss";

type Props = {
    img1: string;
    img2: string;
    img3: string;
};

export const TeamRowImage: React.FC<Props> = ({ img1, img2, img3 }) => (
    <div className={classNames("team-row__image is-animated")}>
        <img loading="lazy" src={img1} width={500} height={446} className="img1" />
        <img loading="lazy" src={img2} width={500} height={446} className="img2" />
        <img loading="lazy" src={img3} width={500} height={446} className="img3" />
    </div>
);
