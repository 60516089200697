export interface ShopperImageInfo {
    lastDecompositionID: number;
    lastImageID: number;
    activeDecompositionID: number;
    activeImageID: number;
}

export enum ShopperImageState {
    Unknown = "unknown",
    Missing = "missing",
    Approved = "approved",
    InReview = "in-review",
    Rejected = "rejected",
}
