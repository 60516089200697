import classNames from "classnames";
import React from "react";

import "./columnBoxes.scss";

type Props = {
    children: React.ReactNode;
    className?: string;
};

export const ColumnBoxes: React.FC<Props> = ({ children, className }) => <div className={classNames("column-boxes", className)}>{children}</div>;

export * from "./columnBoxesImage";
export * from "./columnBoxesText";
