import * as React from "react";
const HeightIcon = () => (
    <svg width={10} height={22} viewBox="0 0 10 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m5 1 4 4M5 21V1v20Zm0 0-4-4 4 4Zm0 0 4-4-4 4ZM5 1 1 5l4-4Z"
            stroke="var(--klothed-textColor)"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default HeightIcon;
