import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { PhotoMobile } from "./mobile";
import { PhotoDesktop } from "./desktop";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";

export const UserProfilePhoto = () => {
    const { authState } = useSiteSession();

    const navigate = useNavigate();

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoKlothedAccount:
                // Create a shopper account
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoUserAccount:
                navigate(process.env.REACT_APP_WEB_LOGIN2 || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
                navigate(process.env.REACT_APP_WEB_SHOPPER || "#");
                return;
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                return;
        }
    }, [authState, navigate]);

    return isMobile ? <PhotoMobile /> : <PhotoDesktop />;
};
