import React from "react";
import { Photo } from "./photo";

export function PhotoDesktop() {
    return (
        <div className="artifact_group">
            <Photo />
        </div>
    );
}
