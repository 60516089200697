import React, { useState, useEffect } from "react";
import "./tryOns.scss";
import { ReactComponent as HeartOne } from "./assets/heart1.svg";
import { ReactComponent as HeartTransparent } from "./assets/heartTransparent.svg";
import { ReactComponent as HeartSelected } from "./assets/heartSelected.svg";
import { klothedAPI } from "../services";
import CustomImage from "../customImage/customImage";
import { tryon_TryonInfoWithFavorites } from "../klothed-api";

interface TryOnsProps {
    className?: string;
}

function TryOns(props: TryOnsProps) {
    const [allTryOnsSelected, setAllTryOnsSelected] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [tryOns, setTryOns] = useState<tryon_TryonInfoWithFavorites[]>([]);

    const getTryOnsData = async () => {
        const tryOns = klothedAPI.secureShopperTryonsGet({}) as Promise<tryon_TryonInfoWithFavorites[]>;
        return {
            tryOns: await tryOns,
        };
    };

    useEffect(() => {
        getTryOnsData().then((response) => {
            if (response && response.tryOns) {
                setTryOns(response.tryOns);
            }
        });
    }, []);

    const addToFavorite = (index: number) => {
        klothedAPI.secureFavoritesTryonPost({ info: { tryon_id: tryOns[index].id } }).then((response) => {
            if (response && response.id) {
                const tempTryOnsData: tryon_TryonInfoWithFavorites[] = tryOns;
                tempTryOnsData[index].favorite_id = Number(response.id);
                setTryOns(tempTryOnsData);
                setRefresh(!refresh);
            }
        });
    };

    const removeFromFavorite = (index: number) => {
        klothedAPI.secureFavoritesFavoriteIdDelete({ favorite_id: Number(tryOns[index].favorite_id) }).then(() => {
            const tempTryOnsData: tryon_TryonInfoWithFavorites[] = tryOns;
            tempTryOnsData[index].favorite_id = -1;
            setTryOns(tempTryOnsData);
            setRefresh(!refresh);
        });
    };

    useEffect(() => {
        setRefresh(!refresh);
    }, [allTryOnsSelected, tryOns]);

    return (
        <>
            <div className="tryOnsBlackBox"></div>
            <div className="retailerToggle" style={{ left: `calc(50vw - ${window.innerWidth - document.body.clientWidth}px)` }}>
                <div className={`retailerToggle_wrapper all_retailers_wrapper ${allTryOnsSelected ? "all_retailers_wrapper_selected" : ""}`}>
                    <div
                        onClick={() => setAllTryOnsSelected(true)}
                        className={`retailerToggle_btn all_retailers ${allTryOnsSelected ? "all_retailers_selected" : ""}`}
                    >
                        {"ALL TRY ONS"}
                    </div>
                </div>
                <div
                    onClick={() => setAllTryOnsSelected(false)}
                    className={`retailerToggle_wrapper my_retailers_wrapper ${!allTryOnsSelected ? "my_retailers_wrapper_selected" : ""}`}
                >
                    <div className={`retailerToggle_btn my_retailers ${!allTryOnsSelected ? "my_retailers_selected" : ""}`}>
                        {"MY TRY ONS"} <HeartOne />
                    </div>
                </div>
            </div>
            <div className={`tryOnsPage ${props.className}`}>
                <div className={`tryOnsPage_`}>
                    {tryOns.map((item, index) =>
                        !allTryOnsSelected && item.favorite_id === -1 ? null : (
                            <div key={index} className="tryOnsItem">
                                <div className="tryOnsItemInside">
                                    <div className="tryOnsItemImage">
                                        {item.image_info.image_url && (
                                            <CustomImage
                                                className="tryOnsCustomrImage tryOnsCustomrImageOriginal"
                                                source={item.image_info.image_url}
                                                index={index}
                                                height={543}
                                                width={286}
                                            />
                                        )}
                                        <CustomImage
                                            className="tryOnsCustomrImage"
                                            source={`${process.env.REACT_APP_API_URL}/secure/tryon/${
                                                item.id
                                            }/image?name=padding_286_543.jpeg&token=${sessionStorage.getItem("accessToken")}`}
                                            index={index}
                                            height={543}
                                            width={286}
                                        />
                                        {item.favorite_id !== -1 ? (
                                            <HeartSelected onClick={() => removeFromFavorite(index)} className="favoriteIcon_" />
                                        ) : (
                                            <HeartTransparent onClick={() => addToFavorite(index)} className="favoriteIcon_" />
                                        )}
                                    </div>
                                    <div className="productDetails">
                                        <div className="productDetailsBrand">{item.product_info?.brand}</div>
                                        <div className="productDetailsType">{item.product_info?.type}</div>
                                    </div>
                                </div>
                                <div className="itemViewGrp">
                                    <a href={item.url_affiliated} target={"_blank"} className="itemViewText" rel="noreferrer">
                                        {"VIEW"}
                                    </a>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
}

export default TryOns;
