import { useCallback, useEffect } from "react";

export interface ThemeContext {
    toggleTheme: () => void;
}

export function ThemeContextProvider(): ThemeContext {
    const setTheme = useCallback((themeName: string) => {
        localStorage.setItem("theme", themeName);
        document.documentElement.className = themeName;
    }, []);

    const toggleTheme = useCallback(() => {
        if (localStorage.getItem("theme") === "theme-light") {
            setTheme("theme-dark");
        } else {
            setTheme("theme-light");
        }
    }, [setTheme]);

    useEffect(() => {
        if (localStorage.getItem("theme") === "theme-dark") {
            setTheme("theme-dark");
        } else {
            setTheme("theme-light");
        }
    }, [setTheme]);

    return { toggleTheme };
}
