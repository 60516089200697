import React from "react";
import { HeightAndStyle } from "./heightAndStyle";

export function HeightAndStyleMobile() {
    return (
        <div className="artifact_group">
            <HeightAndStyle />
        </div>
    );
}
