import React, { useCallback, useState } from "react";
import { useSiteSession } from "@v2/utils";
import { FilterPriceComponent } from "./price";
import { FilterBrandComponent } from "./brands";
import { ReactComponent as Filter } from "@v2/assets/filter.svg";
import { ReactComponent as Close } from "@v2/assets/close.svg";
import { FilterSortComponent, SortOptions } from "./sort";

export const FilterComponent = () => {
    const { minPrice, maxPrice, setFilterSort, brandsSelected, sortMethod, filterSortEnabled } = useSiteSession();
    const [expanded, setExpanded] = useState(localStorage.getItem("filter-expanded") === String(true));
    const toggleExpand = useCallback(() => {
        setExpanded((s) => {
            localStorage.setItem("filter-expanded", String(!s));
            return !s;
        });
    }, [setExpanded]);

    const [filterMinPrice, setFilterMinPrice] = useState(minPrice || 0);
    const [filterMaxPrice, setFilterMaxPrice] = useState(maxPrice || 5000);
    const [filterBrands, setFilterBrands] = useState(brandsSelected || []);
    const [filterSortMethod, setFilterSortMethod] = useState(sortMethod || SortOptions.default);

    const apply = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setFilterSort(filterMinPrice, filterMaxPrice, filterBrands, filterSortMethod);
        },
        [filterMinPrice, filterMaxPrice, filterBrands, filterSortMethod, setFilterSort]
    );
    const reset = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            toggleExpand();
            setFilterSort();
        },
        [setFilterSort, toggleExpand]
    );
    return (
        <div className="filter">
            <div className="header">
                <span onClick={toggleExpand}>
                    <Filter />
                    Sort & Filter
                </span>
                {filterSortEnabled && (
                    <Close
                        className="close"
                        onClick={() => {
                            if (expanded) toggleExpand();
                            setFilterSort();
                        }}
                    />
                )}
            </div>
            {expanded && (
                <form onSubmit={apply} onReset={reset}>
                    <span>Filter by price</span>
                    <FilterPriceComponent
                        filterMaxPrice={filterMaxPrice}
                        filterMinPrice={filterMinPrice}
                        setFilterMaxPrice={setFilterMaxPrice}
                        setFilterMinPrice={setFilterMinPrice}
                    />
                    <span className="brands_title">Filter brands</span>
                    <FilterBrandComponent filterBrands={filterBrands} setFilterBrands={setFilterBrands} />
                    <span className="sort_title">Sort by</span>
                    <FilterSortComponent filterSortMethod={filterSortMethod} setFilterSortMethod={setFilterSortMethod} />
                    <div className="button-group">
                        <input type="submit" value="APPLY" />
                        <input type="reset" value="RESET" />
                    </div>
                </form>
            )}
        </div>
    );
};
