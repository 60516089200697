import React from "react";
import Login from "./login";
import PhoneImage from "@v2/assets/phone-image.webp";

import { LogoSlider } from "@v2/components/burger";

export function LoginDesktop() {
    return (
        <div className="login_desktop">
            <div className="artifact_group">
                <img src={PhoneImage} className="image" />
                <h4>Try on styles from your favorite online retailers. It&apos;s free.</h4>
                <div className="brandsSec">
                    <LogoSlider />
                </div>
            </div>
            <div className="login_group">
                <h4>Log in to try it on.</h4>
                <Login />
            </div>
        </div>
    );
}
