import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { HeightAndStyleMobile } from "./mobile";
import { HeightAndStyleDesktop } from "./desktop";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";

export const UserProfileHeightAndStyle = () => {
    const { authState, setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Account creation.`);
        setPageDescription(`Klothed - Everything looks better on you. - Account creation.`);
    }, [setPageTitle, setPageDescription]);
    const navigate = useNavigate();

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoKlothedAccount:
                // Create a shopper account
                return;
            case AuthState.NoUserAccount:
                navigate(process.env.REACT_APP_WEB_LOGIN2 || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                return;
        }
    }, [authState, navigate]);

    return isMobile ? <HeightAndStyleMobile /> : <HeightAndStyleDesktop />;
};
