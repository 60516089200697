import React from "react";
import { product_CatalogItemResponse, TileContext, TileContextProvider } from "@v2/utils";
import { CatalogTileTemplate } from "./tileTemplate";

export function CatalogTile({
    item,
    hideDeletedTryonTiles,
    hideReportedTryonTiles,
    hideUnfavoredTryonTiles,
    autoTryon,
}: {
    item: product_CatalogItemResponse;
    hideDeletedTryonTiles?: boolean;
    hideReportedTryonTiles?: boolean;
    hideUnfavoredTryonTiles?: boolean;
    autoTryon?: boolean;
}) {
    const tileContext = TileContextProvider({ item, hideDeletedTryonTiles, hideReportedTryonTiles, hideUnfavoredTryonTiles, autoTryon });
    return (
        <TileContext.Provider value={tileContext}>
            <CatalogTileTemplate />
        </TileContext.Provider>
    );
}
