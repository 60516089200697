import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "@v1/App";
import ResetPassword from "@v1/resetPassword/resetPassword";
import ScrollToTop from "@v1/components/scrollTop/scrollTop";
import PolicyBar from "@v1/components/policyBar/policyBar";
// import SignupWhitelist from "./views/signup-whitelist/signup-whitelist";
import GetStarted from "@v1/getStartedPage/getStarted";
// import RecentProducts from "./recent_products/recent_products";
import { KlothedV2Routes } from "@v2/routes";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter basename={"/"}>
            <ScrollToTop />
            <PolicyBar />
            <Routes>
                <Route path={process.env.REACT_APP_WEB_RESET_PASSWORD} element={<ResetPassword />} />
                {/* <Route path={process.env.REACT_APP_WEB_SIGNUP_WHITELIST} element={<SignupWhitelist />} /> */}
                <Route path={process.env.REACT_APP_WEB_LOGIN} element={<App />} />
                <Route path={process.env.REACT_APP_WEB_GET_STARTED} element={<GetStarted />} />
                {/* <Route path="/r/*" element={<RecentProducts />} /> */}
                <Route path="*" element={<KlothedV2Routes />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
