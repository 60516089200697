import React, { useState } from "react";
import "./createProfile.scss";
import UploadPhoto from "./uploadPhoto/uploadPhoto";
import SelectHeight from "./selectHeight/selectHeight";
import SelectStyle from "./selectStyle/selectStyle";
import AddExtension from "./addExtension/addExtension";

enum Steps {
    UploadPhoto,
    EnterHeight,
    SelectStyle,
    AddExtension,
}

interface CreateProfileProps {
    addExtensionCallback: () => void;
}

function CreateProfile(props: CreateProfileProps) {
    const shopper = sessionStorage.getItem("shopperID");
    let shopperInfo: { activeDecompositionID: number; activeImageID: number; height: number; lastDecompositionID: number; style: string } | undefined;
    if (shopper) {
        shopperInfo = JSON.parse(shopper)?.info;
    }
    const getSelectedStep = () => {
        if (shopperInfo) {
            if (!shopperInfo?.style || shopperInfo?.style == "unknown") {
                return Steps.SelectStyle;
            } else if (!shopperInfo?.activeImageID) {
                return Steps.UploadPhoto;
            } else if (!shopperInfo?.height) {
                return Steps.EnterHeight;
            } else {
                return Steps.AddExtension;
            }
        } else {
            return Steps.SelectStyle;
        }
    };

    const getDecompositionStatus = () => {
        if (shopperInfo) {
            if (shopperInfo?.activeImageID && shopperInfo?.activeDecompositionID === shopperInfo?.lastDecompositionID) {
                return "success";
            } else if (!shopperInfo?.activeImageID && !shopperInfo?.lastDecompositionID) {
                return "inactive";
            } else if (!shopperInfo?.activeImageID && shopperInfo?.lastDecompositionID) {
                return "processing";
            }
        }
        return "inactive";
    };

    const [selectedStep, setSelectedStep] = useState<Steps>(getSelectedStep());
    const [photoUploaded, setPhotoUploaded] = useState(shopperInfo?.lastDecompositionID ? true : false);
    const [heightSet, setHeightSet] = useState(shopperInfo?.height ? true : false);
    const [styleSelected, setStyleSelected] = useState(shopperInfo?.style ? true : false);
    const [decompositionID, setDecompositionID] = useState(shopperInfo?.lastDecompositionID ? shopperInfo?.lastDecompositionID.toString() : "");
    const [decompositionStatus, setDecompositionStatus] = useState<"inactive" | "processing" | "success" | "failure">(getDecompositionStatus());

    return (
        <div className="createProfile_component">
            {selectedStep !== Steps.AddExtension && (
                <div className="createProfile_header">
                    <div className="createProfile_heading">{"Create Your Profile"}</div>
                    <div className="createProfile_steps">
                        <div
                            onClick={() => setSelectedStep(Steps.SelectStyle)}
                            className={`createProfile_steps_ select_style ${decompositionID ? "tutorialDone" : ""} ${styleSelected ? "step_completed" : ""} ${
                                selectedStep === Steps.SelectStyle ? "active_step" : ""
                            }`}
                        >
                            <div className="gradient_border"></div>
                            {"Select Style"}
                        </div>
                        <div
                            onClick={() => setSelectedStep(Steps.UploadPhoto)}
                            className={`createProfile_steps_ upload_photo ${decompositionID ? "tutorialDone" : ""} ${photoUploaded ? "step_completed" : ""} ${
                                selectedStep === Steps.UploadPhoto ? "active_step" : ""
                            }`}
                        >
                            <div className="gradient_border"></div>
                            {"Upload Photo"}
                        </div>
                        <div
                            onClick={() => setSelectedStep(Steps.EnterHeight)}
                            className={`createProfile_steps_ enter_height ${decompositionID ? "tutorialDone" : ""} ${heightSet ? "step_completed" : ""} ${
                                selectedStep === Steps.EnterHeight ? "active_step" : ""
                            }`}
                        >
                            <div className="gradient_border"></div>
                            {"Enter Height"}
                        </div>
                    </div>
                </div>
            )}
            {selectedStep === Steps.SelectStyle && (
                <SelectStyle
                    styleSelectCallback={() => {
                        setStyleSelected(true);
                        setSelectedStep(Steps.UploadPhoto);
                    }}
                ></SelectStyle>
            )}
            {selectedStep === Steps.UploadPhoto && (
                <UploadPhoto
                    setPhotoUploaded={() => {
                        setPhotoUploaded(true);
                        setSelectedStep(Steps.EnterHeight);
                    }}
                    imageAlreadyUploaded={photoUploaded}
                    setDecompositionID={(decompositionID: string) => setDecompositionID(decompositionID)}
                    decompositionID={decompositionID}
                    setDecompositionStatus={(val: "inactive" | "processing" | "success" | "failure") => setDecompositionStatus(val)}
                    decompositionStatus={decompositionStatus}
                ></UploadPhoto>
            )}
            {selectedStep === Steps.EnterHeight && (
                <SelectHeight
                    heightUpdatedCallback={() => {
                        setHeightSet(true);
                        setSelectedStep(Steps.AddExtension);
                    }}
                ></SelectHeight>
            )}

            {selectedStep === Steps.AddExtension && <AddExtension addExtensionCallback={props.addExtensionCallback}></AddExtension>}
            <div className="klothedBottom">{"© Klothed Technologies Inc."}</div>
        </div>
    );
}

export default CreateProfile;
