import React from "react";
import { UserForgotPassword } from "@v2/components/forgotPassword";

const ForgotPasswordPage = () => {
    return (
        <div className="user_forgot_password">
            <UserForgotPassword />
        </div>
    );
};

export default ForgotPasswordPage;
