import React from "react";
import { FavoritesPage } from "@v2/components/favorites";

export default function FavoritesHome() {
    return (
        <div className="edits_home">
            <FavoritesPage />
        </div>
    );
}
