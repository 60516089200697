import classNames from "classnames";
import React from "react";

/**
 * Internal dependencies.
 */

interface Props {
    children: React.ReactNode;
    src: string;
    width?: number;
    height?: number;
    alt?: string;
    align?: "center" | "flex-end" | "flex-start";
    justify?: "center" | "flex-end" | "flex-start";
    className?: string;
}

export const ColumnBoxesImage: React.FC<Props> = ({ children, src, width, height, alt, align, justify, className }) => (
    <div className="column-box column-box__image">
        <div
            className={classNames("box-image", className, {
                [`align-${align}`]: align,
                [`justify-${justify}`]: justify,
            })}
        >
            <img loading="lazy" src={src} width={width} height={height} alt={alt} />
        </div>
        {children}
    </div>
);
