import React, { useState } from "react";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "@v1/../firebase-config";
import "./logIn.scss";
import { ReactComponent as Google } from "../assets/google.svg";
import { ReactComponent as Apple } from "../assets/apple.svg";
import isEmail from "validator/lib/isEmail";
import { ReactComponent as AppStore } from "../assets/appStore.svg";
import chrome from "../assets/chrome.png";
import { ReactComponent as Eye } from "../assets/eye.svg";
import { Link } from "react-router-dom";

interface LoginProps {
    goToSignUp: () => void;
    shopperAuthError: string;
}
function Login(props: LoginProps) {
    const EmailNotFound = "Sorry, the username you entered doesn't belong to an account. Please try again.";
    const InvalidPassword = "Sorry, your password was incorrect. Please try again.";

    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const onClickLogIn = () => {
        signInWithEmailAndPassword(auth, email, password).catch((error) => {
            if (error && error.code.includes("user-not-found")) {
                setError(EmailNotFound);
            } else if (error && error.code.includes("wrong-password")) {
                setError(InvalidPassword);
            } else {
                setError(error);
            }
        });
    };

    const provider = new GoogleAuthProvider();
    const googleSignUp = () => {
        signInWithPopup(auth, provider)
            .then()
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <form
            className="logIn_section"
            onSubmit={(e) => {
                e.preventDefault();
                onClickLogIn();
            }}
        >
            {error || props.shopperAuthError ? (
                <div className="errorMessage__">
                    <div className="errorMessageHeading">{error || props.shopperAuthError}</div>
                    <div className="horizontal_separator horizontal_separator_error"></div>
                </div>
            ) : (
                <div className="login_top_header">
                    Try on styles from your
                    <br />
                    favorite online retailer.
                    <br />
                    It&apos;s free.
                </div>
            )}
            <div className="email_input_div">
                <input
                    className="email_input_box"
                    placeholder=" "
                    type="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                        setIsEmailValid(isEmail(event.target.value));
                    }}
                    autoComplete="on"
                />
                <label className="email_input_label">{"Enter email address"}</label>
            </div>
            <div className="password_input_div">
                <input
                    className={`password_input_box ${showPassword ? "showPassword" : "hidePassword"}`}
                    placeholder=" "
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    autoComplete="on"
                />
                <label className="password_input_label">{"Enter password"}</label>
                <Eye className="showPasswordIMG" onClick={() => setShowPassword(!showPassword)}></Eye>
            </div>
            <div onClick={() => onClickLogIn()} className={`loginBtn_wrapper ${email && isEmailValid && password ? "login_button_active" : ""}`}>
                <button className={`login_button`}>{"LOG IN"}</button>
            </div>
            <div className="alternate_login_options">
                <div className="alternate_login">{"or"}</div>
                <Google onClick={() => googleSignUp()} className="alternate_login_options_CTA"></Google>
                <Apple onClick={() => console.warn("apple")} className="alternate_login_options_CTA apple-login"></Apple>
            </div>
            <Link to={process.env.REACT_APP_WEB_FORGOT_PASSWORD || "#"} style={{ textDecoration: "none" }}>
                <div className="forgotPassword">{"Forgot password?"}</div>
            </Link>
            <div className="horizontal_separator"></div>
            <div className="already_account">
                {"Don't have an account?"}
                <div
                    className="signUp_button"
                    onClick={() => {
                        props.goToSignUp();
                    }}
                >
                    {"SIGN UP"}
                </div>
            </div>
            <div className="horizontal_separator"></div>
            <div className="store_CTA">
                <div className="selector">
                    <a href={process.env.REACT_APP_EXTENSION_MOBILE || "#"} target={"_blank"} rel={"noreferrer"}>
                        <div className="getApp">{"Get The App"}</div>
                        <AppStore className="appleAppStore" />
                    </a>
                </div>
                <div className="selector">
                    <a href={process.env.REACT_APP_EXTENSION_DESKTOP} target={"_blank"} rel={"noreferrer"}>
                        <div className="getApp">{"Get the extension"}</div>
                        <div className="extensionLink">
                            <img className="chromeLogo" src={chrome}></img>
                            <div className="extensionText">
                                <div className="extensionTextTop">{"Available On"}</div>
                                <div className="extensionTextBottom">{"chrome web store"}</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </form>
    );
}

export default Login;
