import React, { useCallback, useEffect, useRef, useState } from "react";
import oops from "@v2/assets/oops.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import loading from "@v2/assets/loading.svg";
import classNames from "classnames";
import { useSiteSession, useTileSession } from "@v2/utils";

function TileImageFetcher() {
    const [img, setImg] = useState<string>();
    const fetchImage = useCallback(
        async (imageUrl: string, fallbackUrl?: string) => {
            try {
                let res = await fetch(imageUrl, {
                    priority: "low",
                } as RequestInit);
                if (res.status > 299) {
                    if (fallbackUrl) {
                        res = await fetch(fallbackUrl);
                        if (res.status > 299) {
                            throw "error";
                        }
                    } else {
                        throw "error";
                    }
                }
                const imageBlob = await res.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImg(imageObjectURL);
            } catch (err) {
                if (err !== "error") {
                    console.error(err);
                }
                setImg(oops);
            }
        },
        [setImg]
    );

    return { img, fetchImage };
}
const largeSuffix = "375_712";
const smallSuffix = "375_712";

export function TileImageComponent({ displayLarge, onClick }: { displayLarge: boolean; onClick?: () => void }) {
    const { token } = useSiteSession();
    const { context } = useTileSession();
    const { showTryon, tryonInQueue: queued, tryonInProgress: processing, tile, tryonFailed, setHideOverlay, hideOverlay } = context;
    const { image_id, id: tryon_id } = tile;
    const productImageFallbackSrc = useRef<string>();
    const productImageSrc = useRef<string>();
    const tryonImageSrc = useRef<string>();
    const tryonImage = TileImageFetcher();
    const productImage = TileImageFetcher();

    const [hasLoadRequested, setLoadRequested] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);

    const [imageSrc, setImageSrc] = useState<string>();

    useEffect(() => {
        setHideOverlay(false);
    }, [setHideOverlay]);

    const loadImages = useCallback(async () => {
        if (!productImageSrc.current) {
            return;
        }
        setDisplayImage(false);
        let tryonImagePromise: Promise<void> | undefined;
        if (tryonImageSrc.current) {
            tryonImagePromise = tryonImage.fetchImage(tryonImageSrc.current);
        }
        await productImage.fetchImage(productImageSrc.current, productImageFallbackSrc.current);
        await tryonImagePromise;
        setDisplayImage(true);
        setLoadRequested(true);
    }, [tryonImage.fetchImage, productImage.fetchImage, setLoadRequested, hasLoadRequested, setDisplayImage]);

    useEffect(() => {
        const suffix = displayLarge ? largeSuffix : smallSuffix;
        const hasTryon = token && tryon_id && tryon_id > 0;

        if (hideOverlay) {
            productImageSrc.current = `${process.env.REACT_APP_API_URL}/image/${image_id}`;
        } else {
            productImageSrc.current = `${process.env.REACT_APP_API_URL}/image/padded/${image_id}/padded_${suffix}_image.jpeg`;
        }
        productImageFallbackSrc.current = `${process.env.REACT_APP_API_URL}/image/${image_id}`;

        if (tryonFailed) {
            tryonImageSrc.current = oops;
        } else if (hasTryon) {
            if (hideOverlay) {
                tryonImageSrc.current = `${process.env.REACT_APP_API_URL}/secure/tryon/image/${tryon_id}/tryon_logo.jpeg?token=${token}`;
            } else {
                tryonImageSrc.current = `${process.env.REACT_APP_API_URL}/secure/tryon/image/${tryon_id}/padding_${suffix}.jpeg?token=${token}`;
            }
        }

        if (hasLoadRequested || displayLarge) {
            loadImages();
        }
    }, [tryon_id, image_id, token, tryonFailed, hasLoadRequested, displayLarge, hideOverlay]);

    useEffect(() => {
        if (!displayImage) {
            setImageSrc(loading);
        } else if (!showTryon || processing || queued) {
            setImageSrc(productImage.img);
        } else if (showTryon && tryonFailed) {
            setImageSrc(oops);
        } else if (showTryon) {
            setImageSrc(tryonImage.img);
        } else {
            setImageSrc(loading);
        }
    }, [setImageSrc, processing, queued, tryonFailed, displayImage, showTryon, tryon_id, image_id]);

    const onClickInternal = () => {
        if (onClick) {
            onClick();
        }
        if (displayLarge) setHideOverlay((o) => !o);
    };

    return (
        <LazyLoadImage
            className={classNames("catalogProductsCustomerImage", (queued || processing) && showTryon && "grayscale")}
            src={imageSrc}
            beforeLoad={() => setLoadRequested(true)}
            onClick={onClickInternal}
        />
    );
}
