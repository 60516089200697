/**
 * External dependencies.
 */
import React, { useEffect } from "react";

/**
 * Internal dependencies.
 */
import { Layout, Button, ImageSlider, TwoColumns, Container } from "@v2/components/burger";
import About1 from "@v2/assets/aboutUs/about-1.webp";
import About2 from "@v2/assets/aboutUs/about-2.webp";
import About3 from "@v2/assets/aboutUs/about-3.webp";
import Slide1 from "@v2/assets/aboutUs/slide1.webp";
import Slide3 from "@v2/assets/aboutUs/slide3.webp";
import Slide4 from "@v2/assets/aboutUs/slide4.webp";
import Slide5 from "@v2/assets/aboutUs/slide5.webp";
import Slide6 from "@v2/assets/aboutUs/slide6.webp";
import Slide7 from "@v2/assets/aboutUs/slide7.webp";
import Self1 from "@v2/assets/aboutUs/self1.webp";
import "./aboutUs.scss";
import { useSiteSession } from "@v2/utils";

const AboutUs = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - About.`);
        setPageDescription(`Klothed - Everything looks better on you. - About.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout className="about-us">
            <div className="about-small-image hide-on-mobile">
                <div className="container">
                    <div className="about-small-image__inner">
                        <div className="about-small__line"></div>
                        <img loading="lazy" src={About1} width="197" height="394" alt="A man standing" />
                    </div>
                </div>
            </div>
            <TwoColumns>
                <TwoColumns.Column className="column--text">
                    <h5>Dress your best.</h5>
                    <h3>
                        Feel empowered to try on styles from a large variety of <span>fashion</span> retailers.
                    </h3>
                    <Button href={process.env.REACT_APP_WEB_ABOUT_US_FASHION || "#"}>Fashion</Button>
                </TwoColumns.Column>
                <TwoColumns.Column className="column--right">
                    <ImageSlider images={[Slide1, Slide3, Slide4, Slide5, Slide6, Slide7]} style={{ backgroundColor: "var(--klothed-bgColor)" }} />
                </TwoColumns.Column>
            </TwoColumns>
            <div className="about-self-image hide-on-mobile">
                <Container>
                    <div className="about-self-image__inner">
                        <div className="about-self-image__element">
                            <img loading="lazy" src={Self1} width="319" height="478" alt="Girl and Guy sitting on the ground" />
                        </div>
                    </div>
                </Container>
            </div>
            <TwoColumns className="two-columns--white">
                <TwoColumns.Column className="column--fixed-width column--spacing">
                    <img loading="lazy" src={About2} width="455" height="670" alt="A girl smiling and sitting down crossing her arms" />
                </TwoColumns.Column>
                <TwoColumns.Column className="column--text">
                    <h5>Buy with confidence.</h5>
                    <h3 className="h4">
                        Buy <span>knowing</span> it will make you feel good and look your best. Returns? You&apos;ll forget what those are.
                    </h3>
                    <Button href={process.env.REACT_APP_WEB_ABOUT_US_CONFIDENCE || "#"} type="var(--klothed-textColor)">
                        Confidence
                    </Button>
                </TwoColumns.Column>
            </TwoColumns>
            <TwoColumns className="two-columns--spacing last-section">
                <TwoColumns.Column className="column--text">
                    <h5>Let&apos;s have fun.</h5>
                    <h3 className="h4">
                        <span>Share</span> your try-ons.
                    </h3>
                    <Button href={process.env.REACT_APP_WEB_ABOUT_US_FUN || "#"}>FUN</Button>
                </TwoColumns.Column>
                <TwoColumns.Column className="column--spacing column--order">
                    <div className="right-image">
                        <img loading="lazy" src={About3} width="624" height="416" alt="Girl and guy sitting and watching on a laptop" />
                    </div>
                </TwoColumns.Column>
            </TwoColumns>
        </Layout>
    );
};

export default AboutUs;
