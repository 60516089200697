import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "@v1/../firebase-config";
import "./signUp.scss";
import { ReactComponent as Google } from "../assets/google.svg";
import { ReactComponent as Apple } from "../assets/apple.svg";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";
import { ReactComponent as AppStore } from "../assets/appStore.svg";
import chrome from "../assets/chrome.png";
import { ReactComponent as Eye } from "../assets/eye.svg";

interface SignUpProps {
    goToLogIn: () => void;
    goToTermsOfServices: () => void;
    goToPrivacyPolicy: () => void;
    shopperAuthError: string;
}

function SIGNUP(props: SignUpProps) {
    const emailAlreadyRegistered = "Sorry, the username you entered already belong to an account. Please try again.";

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isGoodPassword, setIsGoodPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [mailError, setMailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const onClickSignUp = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                updateProfile(userCredential.user, { displayName: `${firstName} ${lastName}` });
            })
            .catch((error) => {
                if (error && error.code.includes("email-already-in-use")) {
                    setErrorMessage(emailAlreadyRegistered);
                } else {
                    setErrorMessage(error.message);
                }
            });
    };

    const provider = new GoogleAuthProvider();
    const googleSignUp = () => {
        signInWithPopup(auth, provider)
            .then()
            .catch((error) => {
                setErrorMessage(error);
            });
    };

    return (
        <div className="signUp_section">
            {errorMessage || props.shopperAuthError ? (
                <div className="errorMessage__">
                    <div className="errorMessageHeading">{errorMessage || props.shopperAuthError}</div>
                    <div className="horizontal_separator horizontal_separator_error"></div>
                </div>
            ) : (
                <div className="signUp_top_header">{"Try on styles from your\nfavorite online retailer.\nIt's free."}</div>
            )}
            <div className="email_input_div firstNameIp">
                <input className="email_input_box" placeholder=" " type="text" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                {/* FIRST NAME */}
                <label className="email_input_label">{"Enter first name"}</label>
            </div>
            <div className="email_input_div lastNameIp">
                <input className="email_input_box" placeholder=" " type="text" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                {/* LAST NAME */}
                <label className="email_input_label">{"Enter last name"}</label>
            </div>
            <div className="email_input_div">
                <input
                    className={`email_input_box ${mailError ? "email_input_box_error" : ""}`}
                    placeholder=" "
                    type="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                        setIsEmailValid(isEmail(event.target.value));
                    }}
                    onBlur={() => {
                        if (email && !isEmailValid) {
                            setErrorMessage("Please enter a valid email address.");
                            setMailError(true);
                            setPasswordError(false);
                        }
                    }}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                        setPasswordError(false);
                    }}
                />
                {/* EMAIL ADDRESS */}
                <label className={`email_input_label ${mailError ? "email_input_label_error" : ""}`}>{"Enter email address"}</label>
            </div>
            <div className="password_input_div">
                <input
                    className={`password_input_box ${showPassword ? "showPassword" : "hidePassword"} ${passwordError ? "password_input_box_error" : ""}`}
                    placeholder=" "
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => {
                        setPassword(event.target.value);
                        setIsGoodPassword(isStrongPassword(event.target.value));
                    }}
                    onBlur={() => {
                        if (password && !isGoodPassword) {
                            setErrorMessage(
                                "Please choose a password with at least 8 characters, 1 number, 1 symbol and each of uppercase and lowercase character."
                            );
                            setPasswordError(true);
                            setMailError(false);
                        }
                    }}
                    onFocus={() => {
                        setErrorMessage("");
                        setMailError(false);
                        setPasswordError(false);
                    }}
                />
                {/* PASSWORD */}
                <label className={`password_input_label ${passwordError ? "password_input_label_error" : ""}`}>{"Enter password"}</label>
                <Eye className="showPasswordIMG" onClick={() => setShowPassword(!showPassword)}></Eye>
            </div>
            <div className="legal_acceptance">
                {"By signing up, you agree to Klothed’s "}
                <span onClick={() => props.goToTermsOfServices()} className="legal_acceptance_orange">
                    {"Terms of Service "}
                </span>
                {"and "}
                <span onClick={() => props.goToPrivacyPolicy()} className="legal_acceptance_orange">
                    {"Privacy Policy"}
                </span>
                {
                    ". Not all features described on the Website during the Beta Testing period are yet enabled or fully functional. For example, the mobile app and extension for iPhone are not yet available."
                }
            </div>
            <div
                onClick={() => onClickSignUp()}
                className={`signUp_button ${email && isEmailValid && password && isGoodPassword && firstName && lastName ? "signUp_button_active" : ""}`}
            >
                {"SIGN UP"}
            </div>
            <div className="alternate_login_options">
                <div className="alternate_signUp">{"or"}</div>
                <Google onClick={() => googleSignUp()} className="alternate_login_options_CTA"></Google>
                <Apple onClick={() => console.warn("apple")} className="alternate_login_options_CTA apple-login"></Apple>
            </div>
            <div className="horizontal_separator"></div>
            <div className="already_account">
                {"Have an account?"}{" "}
                <div className={"login_button"} onClick={() => props.goToLogIn()}>
                    {"LOG IN"}
                </div>
            </div>
            <div className="horizontal_separator"></div>
            <div className="store_CTA">
                <div className="selector">
                    <a href={process.env.REACT_APP_EXTENSION_MOBILE || "#"} target={"_blank"} rel={"noreferrer"}>
                        <div className="getApp">{"Get The App"}</div>
                        <AppStore className="appleAppStore" />
                    </a>
                </div>
                <div className="selector">
                    <a href={process.env.REACT_APP_EXTENSION_DESKTOP} target={"_blank"} rel={"noreferrer"}>
                        <div className="getApp">{"Get the extension"}</div>
                        <div className="extensionLink">
                            <img className="chromeLogo" src={chrome}></img>
                            <div className="extensionText">
                                <div className="extensionTextTop">{"Available On"}</div>
                                <div className="extensionTextBottom">{"chrome web store"}</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SIGNUP;
