import React from "react";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";

interface FormFieldRadioElementInterface {
    text: string;
    value: string;
}

const FormInputRadioElement = ({ ele, name, value }: { ele: FormFieldRadioElementInterface; name: string; value?: string }) => (
    <div className="radio-field">
        <input type="radio" name={name} value={ele.value} id={ele.value} defaultChecked={value === ele.value} required />
        <label htmlFor={ele.value}>
            <span>
                <span />
            </span>
            <p>{ele.text}</p>
        </label>
    </div>
);

export function StyleComponent() {
    const { style } = useSiteSession();

    return (
        <div className="style-selection">
            <h2>Your style.</h2>
            <span>When shopping for yourself, do you like to buy...</span>
            <div className="style-group">
                <div className="form-group-radio">
                    <FormInputRadioElement ele={{ text: "Women's styles", value: "women" }} name="style-selection" value={style} />
                    <FormInputRadioElement ele={{ text: "Men's styles", value: "men" }} name="style-selection" value={style} />
                    <FormInputRadioElement ele={{ text: "Both!", value: "both" }} name="style-selection" value={style} />
                </div>
            </div>
        </div>
    );
}

export function Style() {
    const { authState, syncHeightAndStyle } = useSiteSession();
    const navigate = useNavigate();

    return authState === AuthState.Unknown ? (
        <div>Loading...</div>
    ) : (
        <div className="profile_height_style_section_v2">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const style = e.currentTarget["style-selection"].value;
                    syncHeightAndStyle({ style }).then(() => navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#"));
                }}
            >
                <StyleComponent />
                <input type="submit" value="SAVE" />
            </form>
        </div>
    );
}
