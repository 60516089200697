import React, { useState, useEffect } from "react";
import "./selectHeight.scss";
import { klothedAPI } from "../../services";

interface SelectHeightProps {
    className?: string;
    heightUpdatedCallback: () => void;
}

function SelectHeight(props: SelectHeightProps) {
    const shopperDetails = sessionStorage.getItem("shopperID");

    const toFeetAndInches = (value: string, toUnit: "feet" | "inches") => {
        const valueInt = parseInt(value);
        const realFeet = (valueInt * 0.3937) / 12;
        const feet = Math.floor(realFeet);
        const inches = Math.round((realFeet - feet) * 12);
        const toReturn = toUnit === "feet" ? (inches === 12 ? feet + 1 : feet) : inches === 12 ? 0 : inches;
        return JSON.stringify(toReturn);
    };

    const toCentimeters = (feet_: string, inches_: string) => {
        const feet = feet_ ? parseInt(feet_) : 0;
        const inches = inches_ ? parseInt(inches_) : 0;
        const cmTotal = feet * 30.48 + inches * 2.54;
        return JSON.stringify(Math.round(cmTotal));
    };

    let heightInCms = "";
    let heightInFeet = "";
    let heightInInches = "";
    if (shopperDetails && JSON.parse(shopperDetails)?.info?.height) {
        heightInCms = JSON.parse(shopperDetails).info.height;
        heightInFeet = toFeetAndInches(heightInCms, "feet");
        heightInInches = toFeetAndInches(heightInCms, "inches");
    }

    const [unit, setUnit] = useState<"inches" | "centimeters">("inches");
    const [feet, setFeet] = useState(heightInFeet);
    const [inches, setInches] = useState(heightInInches);
    const [centimeters, setCentimeters] = useState(heightInCms);
    const [isHeightValid, setIsHeightValid] = useState((heightInFeet && heightInInches) || heightInCms ? true : false);

    const onChangeFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(e.target.value))) {
            if (e.target.name === "feets") {
                setFeet(e.target.value);
                setCentimeters(toCentimeters(e.target.value, inches));
            }
            if (e.target.name === "inches") {
                setInches(e.target.value);
                setCentimeters(toCentimeters(feet, e.target.value));
            }
            if (e.target.name === "centimeters") {
                setCentimeters(e.target.value);
                setFeet(toFeetAndInches(e.target.value, "feet"));
                setInches(toFeetAndInches(e.target.value, "inches"));
            }
        }
    };

    useEffect(() => {
        if (unit === "inches") {
            if (feet && inches) {
                setIsHeightValid(true);
            } else {
                setIsHeightValid(false);
            }
        } else if (unit === "centimeters") {
            if (centimeters) {
                setIsHeightValid(true);
            } else {
                setIsHeightValid(false);
            }
        }
    }, [unit, feet, inches, centimeters]);

    const onClickOfNext = () => {
        const shopperID = sessionStorage.getItem("shopperID");
        if (shopperID && centimeters) {
            const shopperIDParsed = JSON.parse(shopperID);
            klothedAPI.secureShopperHeightAndStylePut({ height: parseInt(centimeters), style: shopperIDParsed?.info?.style }).then((response) => {
                if (response) {
                    sessionStorage.setItem("shopperID", JSON.stringify(response));
                    props.heightUpdatedCallback();
                }
            });
        }
    };

    return (
        <div className={`selectHeight ${props.className}`}>
            <div className="selectHeight_leftPane">
                <div className="selectHeight_leftHeading">{"Try on styles from\nyour favorite online\nretailer."}</div>
            </div>
            <div className="selectHeight_rightPane">
                <div className="heightIpGrp">
                    <div className="heightIp_">
                        {unit === "inches" ? (
                            <div className="heightIp_Input">
                                <input
                                    name="feets"
                                    value={feet}
                                    onChange={(e) => onChangeFunc(e)}
                                    maxLength={1}
                                    className="heightIp_Box heightIp_BoxFeet"
                                    autoComplete="off"
                                ></input>
                                <div className="heightIp_BoxLabel heightIp_BoxLabelFeet">{"ft"}</div>
                                <input
                                    name="inches"
                                    value={inches}
                                    onChange={(e) => onChangeFunc(e)}
                                    maxLength={2}
                                    className="heightIp_Box heightIp_BoxInches"
                                    autoComplete="off"
                                ></input>
                                <div className="heightIp_BoxLabel heightIp_BoxLabelInches">{"in"}</div>
                            </div>
                        ) : (
                            <div className="heightIp_Input">
                                <input
                                    name="centimeters"
                                    value={centimeters}
                                    onChange={(e) => onChangeFunc(e)}
                                    maxLength={3}
                                    className="heightIp_Box heightIp_BoxCentimeters"
                                    autoComplete="off"
                                ></input>
                                <div className="heightIp_BoxLabel heightIp_BoxLabelInches">{"cm"}</div>
                            </div>
                        )}
                        <div className="heightIp_Unit">
                            <div
                                onClick={() => setUnit("inches")}
                                className={`heightIp_Unit_ heightIp_Unit_Inches ${unit === "inches" ? "selected_unit" : "unselected_unit"}`}
                            >
                                {"IN"}
                            </div>
                            <div
                                onClick={() => setUnit("centimeters")}
                                className={`heightIp_Unit_ heightIp_Unit_Centimeters ${unit === "centimeters" ? "selected_unit" : "unselected_unit"}`}
                            >
                                {"CM"}
                            </div>
                        </div>
                    </div>
                    <div onClick={() => onClickOfNext()} className={`heightIpNext ${isHeightValid ? "heightIpNextActive" : ""}`}>
                        {props.className ? "SUBMIT" : "NEXT"}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectHeight;
