/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import "./headingLine.scss";
import { Container } from "@v2/components/burger";

type Props = {
    side?: string;
    children: React.ReactNode;
    className?: string;
};

export const HeadingLine: React.FC<Props> = ({ side = "left", children, className }) => (
    <div className={classNames("heading-line", `heading-line--${side}`, className)}>
        <Container className="smaller">
            <div className="heading-line__inner">
                <div className="heading-line__item">{children}</div>
            </div>
        </Container>
    </div>
);
