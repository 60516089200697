const bothBrandsAvailable = [
    ["032C", 3],
    ["711", 1],
    ["10 CORSO COMO", 1],
    ["120% LINO", 8],
    ["12 STOREEZ", 18],
    ["1.STATE", 8],
    ["24SEVEN COMFORT APPAREL", 6],
    ["2XU", 2],
    ["3.1 PHILLIP LIM", 15],
    ["321", 7],
    ["34 HERITAGE", 2],
    ["3X1", 6],
    ["424", 8],
    ["44 LABEL GROUP", 1],
    ["4SDESIGNS", 1],
    ["525", 1],
    ["66 NORTH", 3],
    ["7 FOR ALL MANKIND", 35],
    ["8 BY YOOX", 1],
    ["90 DEGREE BY REFLEX", 1],
    ["9SEED", 1],
    ["AAPE BY A BATHING APE", 6],
    ["A BATHING APE", 4],
    ["ACNE STUDIOS", 54],
    ["A-COLD-WALL*", 19],
    ["A COLLECTIVE STORY", 1],
    ["ADAM LIPPES", 1],
    ["ADAM SELMAN SPORT", 5],
    ["ADD", 1],
    ["ADER ERROR", 2],
    ["ADIDAS BY STELLA MCCARTNEY", 8],
    ["ADIDAS ORIGINALS", 19],
    ["ADIDAS ORIGINALS BY ALEXANDER WANG", 1],
    ["ADIDAS Y-3 YOHJI YAMAMOTO", 1],
    ["ADISH", 2],
    ["ADRIANNA PAPELL", 6],
    ["ADVISORY BOARD CRYSTALS", 2],
    ["A.F.VANDEVORST", 2],
    ["AG", 20],
    ["AGENT PROVOCATEUR", 1],
    ["AGGI", 2],
    ["AGNÈS B.", 1],
    ["AGNONA", 1],
    ["AGOLDE", 39],
    ["AIDAN MATTOX", 2],
    ["AIEZEN", 1],
    ["AJMONE", 1],
    ["AKRIS", 14],
    ["AKRIS PUNTO", 15],
    ["ALAÏA", 11],
    ["ALALA", 3],
    ["ALANUI", 3],
    ["ALBERTA FERRETTI", 14],
    ["A.L.C", 6],
    ["ALCHEMIST", 5],
    ["ALCHEMY", 6],
    ["ALESSANDRA RICH", 7],
    ["ALESSANDRO DELL'ACQUA", 1],
    ["ALEXA CHUNG", 5],
    ["ALEXANDER MCQUEEN", 66],
    ["ALEXANDER WANG", 21],
    ["ALEXANDER WANG T", 7],
    ["ALEXANDRE VAUTHIER", 9],
    ["ALEX EVENINGS", 13],
    ["ALEXIA ADMOR", 11],
    ["ALEXIS", 1],
    ["ALEX MILL", 2],
    ["ALFANI", 3],
    ["ALFRED SUNG", 2],
    ["ALICE AND OLIVIA", 17],
    ["ALICE MCCALL", 1],
    ["ALI & JAY", 1],
    ["ALIX NYC", 2],
    ["ALLSAINTS", 8],
    ["ALMOST FAMOUS", 3],
    ["ALO YOGA", 26],
    ["ALPHA INDUSTRIES", 12],
    ["ALTEA", 9],
    ["ALTON LANE", 1],
    ["ALTUZARRA", 2],
    ["ALYSI", 2],
    ["ALYX", 19],
    ["AMANDA UPRICHARD", 14],
    ["AMAPÔ", 4],
    ["AMBUSH", 23],
    ["AMEN", 2],
    ["AMENDI", 6],
    ["AMI ALEXANDRE MATTIUSSI", 76],
    ["AMI AMALIA", 2],
    ["AMIRI", 15],
    ["AMIR SLAMA", 4],
    ["AMO", 2],
    ["AMO DENIM", 1],
    ["AMSALE", 2],
    ["AMUR", 2],
    ["ANATOMIE", 7],
    ["ANDREÄDAMO", 1],
    ["ANDREA IYAMAH", 1],
    ["ANDREA YA'AQOV", 1],
    ["ANDREW MARC", 2],
    ["AND WANDER", 2],
    ["ANGELA MELE MILANO", 1],
    ["ANGEL MATERNITY", 1],
    ["ANGELO NARDELLI", 1],
    ["ANINE BING", 10],
    ["ANNA KAY", 1],
    ["ANNA NOOSHIN X NA-KD", 1],
    ["ANN DEMEULEMEESTER", 8],
    ["ANNE COLE", 1],
    ["ANNE KLEIN", 3],
    ["ANOTHER TOMORROW", 2],
    ["ANTHROPOLOGIE", 1],
    ["ANTI SOCIAL SOCIAL CLUB", 1],
    ["ANTONELLI", 1],
    ["A.P.C.", 38],
    ["A PEA IN THE POD", 1],
    ["APPARIS", 4],
    ["AQUA", 1],
    ["ARC'TERYX", 1],
    ["AREA", 1],
    ["ARIES", 6],
    ["ARMA", 2],
    ["ARMANI COLLEZIONI", 2],
    ["ARMANI EXCHANGE", 31],
    ["ARMARIUM", 1],
    ["ARPENTEUR", 1],
    ["ARTE", 2],
    ["AS BY DF", 1],
    ["ASCENO", 3],
    ["ASHISH", 2],
    ["ASICS", 1],
    ["ASOS DESIGN", 16],
    ["ASOS TALL", 1],
    ["ASPESI", 47],
    ["ASTR", 2],
    ["ATLEIN", 2],
    ["ATM ANTHONY THOMAS MELILLO", 13],
    ["ATOIR", 1],
    ["AT.P.CO", 2],
    ["ATTICO", 8],
    ["ATU BODY COUTURE", 2],
    ["AUGUSTE", 1],
    ["AURALEE", 1],
    ["AUTOMOBILI LAMBORGHINI", 1],
    ["AUTRY", 2],
    ["AVANT TOI", 3],
    ["AVIATOR NATION", 4],
    ["AWAKE NY", 1],
    ["AWDIS", 2],
    ["AXEL ARIGATO", 5],
    ["AYNI", 1],
    ["AZ FACTORY", 1],
    ["AZTECH MOUNTAIN", 7],
    ["AZZI & OSTA", 1],
    ["B+AB", 1],
    ["BADGLEY MISCHKA", 4],
    ["BAILEY44", 1],
    ["BALENCIAGA", 51],
    ["BALLANTYNE", 25],
    ["BALLY", 2],
    ["BALMAIN", 70],
    ["BAOBAB", 2],
    ["BAPE", 1],
    ["BAPY BY *A BATHING APE®", 3],
    ["BARACUTA", 6],
    ["BARBA", 8],
    ["BARBOUR", 30],
    ["BARBOUR INTERNATIONAL", 1],
    ["BARDOT", 5],
    ["BAREFOOT DREAMS", 3],
    ["BARENA VENEZIA", 7],
    ["BARE NECESSITIES", 1],
    ["BAR III", 2],
    ["BARRIE", 31],
    ["BARROW", 1],
    ["BASERANGE", 1],
    ["BAUM UND PFERDGARTEN", 4],
    ["BB DAKOTA", 1],
    ["BB DAKOTA BY STEVE MADDEN", 2],
    ["BCBGMAXAZRIA", 3],
    ["BEACHLUNCHLOUNGE", 3],
    ["BEACH RIOT", 1],
    ["BEBE", 1],
    ["BEDHEAD PAJAMAS", 1],
    ["BELLY BANDIT", 1],
    ["BELSTAFF", 9],
    ["BENEDETTA BRUZZICHES", 1],
    ["BEN SHERMAN", 1],
    ["BEN TAVERNITI UNRAVEL PROJECT", 8],
    ["BERSHKA", 4],
    ["BETSY & ADAM", 12],
    ["BEYOND YOGA", 9],
    ["BHLDN", 1],
    ["BIBHU MOHAPATRA", 1],
    ["BIKKEMBERGS", 2],
    ["BILLIONAIRE", 10],
    ["BILLIONAIRE BOYS CLUB", 2],
    ["BLACK COMME DES GARÇONS", 2],
    ["BLACK HALO", 14],
    ["BLACKYOTO", 2],
    ["BLANCA VITA", 11],
    ["BLANC NOIR", 3],
    ["BLANKNYC", 56],
    ["BLAZÉ MILANO", 3],
    ["BLK DNM", 5],
    ["BLUE REVIVAL", 2],
    ["BLUGIRL", 1],
    ["BLUMARINE", 3],
    ["BOBI", 4],
    ["BODE", 1],
    ["BOGLIOLI", 16],
    ["BOHO ME", 1],
    ["BORGO DE NOR", 1],
    ["BORIS BIDJAN SABERI", 2],
    ["BOTTEGA MARTINESE", 2],
    ["BOTTEGA VENETA", 20],
    ["BOTTER", 2],
    ["BOUTIQUE MOSCHINO", 10],
    ["BOYISH", 2],
    ["BOYISH JEANS", 1],
    ["BRAVA FABRICS", 1],
    ["BRIAN DALES", 2],
    ["BRIGITTE", 2],
    ["BRIGLIA", 1],
    ["BRIGLIA 1949", 3],
    ["BRIONI", 7],
    ["BROCK COLLECTION", 1],
    ["BRONX AND BANCO", 7],
    ["BRUNELLO CUCINELLI", 140],
    ["BRUNO MANETTI", 1],
    ["B SIDES", 1],
    ["BUBISH", 1],
    ["BUGATCHI", 2],
    ["BULLY", 1],
    ["BURBERRY", 146],
    ["BURTON", 1],
    ["BUSAYO", 1],
    ["BUSCEMI", 1],
    ["BY THE WAY.", 5],
    ["C2H4", 1],
    ["CAALO", 1],
    ["CABALLERO", 1],
    ["CALÉ", 1],
    ["CALLAHAN", 1],
    ["CALVIN KLEIN", 24],
    ["CALVIN KLEIN 205W39NYC", 6],
    ["CALVIN KLEIN JEANS EST.1978", 9],
    ["CAMILA COELHO", 6],
    ["CAMILLA", 1],
    ["CAMI NYC", 2],
    ["CANADA GOOSE", 61],
    ["CANALI", 20],
    ["CANESSA", 2],
    ["CARHARTT", 24],
    ["CARLO PIGNATELLI CERIMONIA", 2],
    ["CAROLINA HERRERA", 4],
    ["CAROLINE CONSTAS", 1],
    ["CARUSO", 4],
    ["CASABLANCA", 9],
    ["CASHMERE IN LOVE", 8],
    ["CASLON", 2],
    ["CASLONR", 1],
    ["C&C CALIFORNIA", 1],
    ["CECE", 5],
    ["CECILIE BAHNSEN", 3],
    ["CELINE", 2],
    ["CHAMPION", 6],
    ["CHARLES HENRY", 1],
    ["CHARLES JEFFREY LOVERBOY", 3],
    ["CHARO RUIZ", 6],
    ["CHARTER CLUB", 1],
    ["CHARVET", 1],
    ["CHAUS", 2],
    ["CHIARA BONI LA PETITE ROBE", 2],
    ["CHIARA FERRAGNI", 5],
    ["CHINATOWN MARKET", 4],
    ["CHINTI & PARKER", 1],
    ["CHLOÉ", 19],
    ["CHLOÉ B X NA-KD", 2],
    ["CHOCOOLATE", 1],
    ["CHRISTIAN WIJNANTS", 2],
    ["CHRISTOPHER ESBER", 2],
    ["CHRISTOPHER KANE", 3],
    ["CHUFY", 1],
    ["CINQ À SEPT", 7],
    ["CIRCOLO 1901", 1],
    ["CITIZENS OF HUMANITY", 42],
    ["CLAUDIE PIERLOT", 35],
    ["CLOSED", 6],
    ["CLOSET LONDON", 2],
    ["CLUB MONACO", 1],
    ["CLUB ROOM", 7],
    ["CO", 5],
    ["COBRA S.C.", 1],
    ["COCO CLOUDE", 1],
    ["COLE HAAN", 10],
    ["COLLINA STRADA", 1],
    ["COLMAR", 1],
    ["COLMAR ORIGINALS", 1],
    ["COLOMBO", 2],
    ["COLVILLE", 1],
    ["COMCOMME DES GARÃ§ONS SHIRTME DES GARÃ§ONS SHIRT", 1],
    ["COMMANDO", 9],
    ["COMME DES GARÇONS", 9],
    ["COMME DES GARÇONS COMME DES GARÇONS", 3],
    ["COMME DES GARCONS GIRL", 1],
    ["COMME DES GARÇONS HOMME DEUX", 10],
    ["COMME DES GARÇONS PLAY", 134],
    ["COMME DES GARÇONS SHIRT", 14],
    ["COMMISSION", 1],
    ["COMMISSION NYC", 1],
    ["CONCEPTO", 1],
    ["CONNECTED", 4],
    ["COOL TM", 1],
    ["COPERNI", 6],
    ["CORELATE", 1],
    ["CORMIO", 1],
    ["CORNELIANI", 19],
    ["COS", 5],
    ["COSABELLA", 2],
    ["COSTARELLOS", 2],
    ["COSTUMEIN", 2],
    ["COTTON CITIZEN", 8],
    ["COTTWEILER", 1],
    ["COURRGES", 9],
    ["COURT & ROWE", 1],
    ["C.P. COMPANY", 21],
    ["CRAIG GREEN", 5],
    ["CRUCIANI", 1],
    ["CULT OF INDIVIDUALITY", 4],
    ["CURATED STYLES", 1],
    ["CURRENT ELLIOTT", 3],
    ["CUSHNIE", 2],
    ["CUSHNIE ET OCHS", 1],
    ["CUTS", 1],
    ["CYNTHIA ROWLEY", 2],
    ["D4.0", 7],
    ["DAILY PAPER", 3],
    ["DANIEL BUCHLER", 1],
    ["DANIELE ALESSANDRINI", 1],
    ["DANIELLE GUIZIO", 2],
    ["DANNIJO", 1],
    ["DANSKIN", 1],
    ["DARKPARK", 1],
    ["DAVID DONAHUE", 2],
    ["DAVID KOMA", 1],
    ["DAWN LEVY", 2],
    ["DEAR MOON", 1],
    ["DELANTIC", 1],
    ["DELL'OGLIO", 19],
    ["DEPARTMENT 5", 6],
    ["DEPARTMENT FIVE", 1],
    ["DEPERLU", 1],
    ["DEREK ROSE", 14],
    ["DESMOND & DEMPSEY", 1],
    ["DEUS EX MACHINA", 3],
    ["DEVEAUX", 1],
    ["D.EXTERIOR", 2],
    ["DIANE VON FURSTENBERG", 7],
    ["DICE KAYEK", 1],
    ["DICKIES", 3],
    ["DICKIES CONSTRUCT", 2],
    ["DIESEL", 43],
    ["DILARA X NA-KD", 1],
    ["DION LEE", 17],
    ["DISCLAIMER", 1],
    ["DISTRICT VISION", 1],
    ["DKNY", 11],
    ["DL", 3],
    ["DL1961", 2],
    ["DODO BAR OR", 3],
    ["DOLCE E GABBANA", 1],
    ["DOLCE & GABBANA", 243],
    ["DOLLINA", 1],
    ["DOMENICO TAGLIENTE", 1],
    ["DOMREBEL", 7],
    ["DONDUP", 43],
    ["DONNA KARAN", 1],
    ["DOROTHEE SCHUMACHER", 7],
    ["DOROTHY PERKIN", 4],
    ["DOUBLE RL", 7],
    ["DOUBLET", 5],
    ["DOUUOD", 1],
    ["DR. DENIM", 1],
    ["DRESS THE POPULATION", 37],
    ["DRIES VAN NOTEN", 5],
    ["DRÔLE DE MONSIEUR", 7],
    ["DROME", 1],
    ["DRUMOHR", 14],
    ["DSQUARED2", 171],
    ["DUETTENYC", 1],
    ["DUNDAS X REVOLVE", 1],
    ["DUNHILL", 6],
    ["DUOLTD", 1],
    ["DUVETICA", 1],
    ["EA7", 14],
    ["EAST HARBOUR SURPLUS", 2],
    ["EBERJEY", 9],
    ["ECRU DESIGNS", 22],
    ["ÉDITIONS M.R", 6],
    ["EILEEN FISHER", 13],
    ["EILEEN WEST", 4],
    ["ELAN", 2],
    ["ELECTRIC YOGA", 1],
    ["ELEVENTY", 11],
    ["ELIE TAHARI", 3],
    ["ELISABETTA FRANCHI", 1],
    ["ELIZA J", 9],
    ["ELLERY", 1],
    ["ELLIATT", 5],
    ["EMILIA GEORGE", 1],
    ["EMILIA WICKSTEAD", 1],
    ["EMILIO PUCCI", 2],
    ["EMORY PARK", 1],
    ["EMPORIO ARMANI", 81],
    ["ENDLESS ROSE", 1],
    ["ENGINEERED GARMENTS", 3],
    ["ENGLISH FACTORY", 10],
    ["ENTIRE STUDIOS", 1],
    ["ENZA COSTA", 7],
    ["EQUIPMENT", 21],
    ["ERDEM", 3],
    ["ERES", 7],
    ["ERIN SNOW", 1],
    ["ERL", 1],
    ["ERMANNO FIRENZE", 4],
    ["ERMANNO SCERVINO", 2],
    ["ERMENEGILDO ZEGNA", 34],
    ["ERNEST LEOTY", 1],
    ["ESKANDAR", 3],
    ["ESSENTIALS", 2],
    ["ÉTERNE", 1],
    ["ETON", 3],
    ["ETRO", 37],
    ["ETUDES STUDIO", 13],
    ["EUROPEAN CULTURE", 1],
    ["EVERLY GREY", 1],
    ["EVERYDAY RITUAL", 3],
    ["EVISU", 2],
    ["EXTREME CASHMERE", 5],
    ["FABIANA FILIPPI", 22],
    ["FACETASM", 1],
    ["FAHERTY", 13],
    ["FAITH CONNEXION", 4],
    ["FAITHFULL THE BRAND", 2],
    ["FAMILY FIRST MILANO", 1],
    ["FANNIE SCHIAVONI", 1],
    ["FAR AFIELD", 1],
    ["FASHION CLINIC TIMELESS", 2],
    ["FAUSTO PUGLISI", 1],
    ["FAVORITE DAUGHTER", 3],
    ["FAY", 17],
    ["FEAR OF GOD", 5],
    ["FEDELI", 4],
    ["FEDERICA TOSI", 2],
    ["FENDI", 13],
    ["FENTY X PUMA", 1],
    ["FERRAGAMO", 1],
    ["FERRARI", 4],
    ["FIDELITY DENIM", 1],
    ["FILA", 4],
    ["FILERIA", 2],
    ["FILIPPA K", 13],
    ["FILIPPA-K SOFT SPORT", 1],
    ["FINAMORE 1925 NAPOLI", 1],
    ["FIORUCCI", 6],
    ["FIVE CM", 2],
    ["FJALL RAVEN", 1],
    ["FLEUR DU MAL", 2],
    ["FLORA NIKROOZ", 4],
    ["FOCUS BY SHANI", 2],
    ["FOLK", 5],
    ["FOR LOVE & LEMONS", 1],
    ["FORME D'EXPRESSION", 1],
    ["FORTE FORTE", 2],
    ["FOURLAPS", 1],
    ["FP MOVEMENT BY FREE PEOPLE", 2],
    ["FRAICHE BY J", 2],
    ["FRAME", 65],
    ["FRANCESCO SCOGNAMILIO", 1],
    ["FRANCHISE", 1],
    ["FRANK & EILEEN", 4],
    ["FREDDY WR.UP®", 1],
    ["FRED PERRY", 11],
    ["FREE PEOPLE", 15],
    ["FREE THE ROSES", 1],
    ["FRENCH CONNECTION", 5],
    ["FRESCOBOL CARIOCA", 4],
    ["FUMITO GANRYU", 2],
    ["FUSALP", 3],
    ["GABRIELA HEARST", 10],
    ["GABRIELE PASINI", 2],
    ["GALLERY DEPT.", 3],
    ["GALVAN", 5],
    ["GANNI", 27],
    ["GANT", 1],
    ["GARCONS INFIDELES", 2],
    ["GATTI NOLLI BY MARWAN", 9],
    ["GAUCHÈRE", 2],
    ["GAUGE81", 4],
    ["GCDS", 22],
    ["GEMY MAALOUF", 7],
    ["GENNY", 3],
    ["GENTRY PORTOFINO", 1],
    ["GEORGES HOBEIKA", 4],
    ["GHIAIA CASHMERE", 1],
    ["GIAMBATTISTA VALLI", 4],
    ["GIANLUCA CAPANNOLO", 1],
    ["GILDA & PEARL", 4],
    ["GIL RODRIGUEZ", 1],
    ["GIORGIO ARMANI", 33],
    ["GIRLFRIEND COLLECTIVE", 14],
    ["GIUSEPPE ZANOTTI", 6],
    ["GIVENCHY", 55],
    ["G. LABEL", 1],
    ["GLANSHIRT", 4],
    ["GLORIA COELHO", 4],
    ["GMBH", 1],
    ["GO COUTURE", 1],
    ["GOD'S MASTERFUL CHILDREN", 6],
    ["GOEN J", 2],
    ["GOETZE", 1],
    ["GOLDEN BEAR", 1],
    ["GOLDEN GOOSE", 18],
    ["GOLDSIGN", 3],
    ["GOOD AMERICAN", 1],
    ["GOODBOY", 1],
    ["GOOD MAN BRAND", 3],
    ["GOOSETECH", 1],
    ["GOSHA RUBCHINSKIY", 1],
    ["GR10K", 3],
    ["GRAMICCI", 5],
    ["GRAN SASSO", 1],
    ["GREG LAUREN", 3],
    ["GREY DANIELE ALESSANDRINI", 1],
    ["GREY LAB", 2],
    ["GREYSON", 1],
    ["GRLFRND", 5],
    ["G-STAR", 1],
    ["GUCCI", 34],
    ["GUESS", 3],
    ["GUESS FACTORY", 2],
    ["GUESS USA", 3],
    ["HACKETT", 3],
    ["HACULLA", 34],
    ["HAIDER ACKERMANN", 10],
    ["HAIKURE", 5],
    ["HALOGEN", 1],
    ["HALPERN", 1],
    ["HALSTON", 2],
    ["HALSTON HERITAGE", 1],
    ["HAND PICKED", 2],
    ["HANEY", 1],
    ["HAN KJOBENHAVN", 1],
    ["HANRO", 25],
    ["HARRIS WHARF LONDON", 9],
    ["HARSHMAN", 2],
    ["HARTFORD", 3],
    ["HART SCHAFFNER MARX", 2],
    ["HATCH", 4],
    ["HEBE STUDIO", 1],
    ["HEIDI KLEIN", 2],
    ["HELIOT EMIL", 4],
    ["HELMUT LANG", 36],
    ["HEMANT & NANDITA", 2],
    ["HENRIK VIBSKOV", 1],
    ["HERNO", 59],
    ["HERNO LAMINAR", 1],
    ["HERON PRESTON", 16],
    ["HERVE LEGER", 10],
    ["HEURUEH", 1],
    ["HEVO", 1],
    ["HOGAN", 1],
    ["HOLDEN", 1],
    ["HOLIDAY", 1],
    ["HOLZWEILER", 2],
    ["HOMEBODII", 1],
    ["HONEYDEW INTIMATES", 3],
    ["HONEY FUCKING DIJON", 2],
    ["H:OURS", 4],
    ["HOUSE OF HARLOW 1960", 8],
    ["HUDSON", 6],
    ["HUDSON PARK COLLECTION", 1],
    ["HUE", 3],
    ["HUGO", 14],
    ["HUGO BOSS", 49],
    ["HUNZA G", 2],
    ["HYDROGEN", 1],
    ["ICEBERG", 6],
    ["ICON DENIM", 2],
    ["IEENA FOR MAC DUGGAL", 6],
    ["IENKI IENKI", 2],
    ["IH NOM UH NIT", 3],
    ["INC INTERNATIONAL CONCEPTS", 2],
    ["INCOTEX", 35],
    ["INDAH", 1],
    ["INGRID & ISABEL", 3],
    ["INGRID & ISABELR", 1],
    ["IN THE MOOD FOR LOVE", 1],
    ["IOANA CIOLACU", 6],
    ["IRO", 12],
    ["ISAAC SELLAM", 1],
    ["ISAAC SELLAM EXPERIENCE", 2],
    ["ISA ARFEN", 2],
    ["ISABEL BENENATO", 2],
    ["ISABEL MARANT", 36],
    ["ISABEL MARANT ÉTOILE", 20],
    ["ISABEL SANCHIS", 7],
    ["ISAIA", 1],
    ["ISSEY MIYAKE", 7],
    ["IZZUE", 1],
    ["JACOB COHEN", 21],
    ["JACQUEMUS", 8],
    ["JAG", 1],
    ["JAG JEANS", 1],
    ["JAMES PERSE", 20],
    ["JARDIN DES ORANGERS", 4],
    ["JASON WU", 1],
    ["J BRAND", 10],
    ["JEJIA", 1],
    ["JEN7", 1],
    ["JEN7 BY 7 FOR ALL MANKIND", 3],
    ["JENNY PACKHAM", 1],
    ["JESSICA HOWARD", 2],
    ["JET SET", 1],
    ["JIEDA", 1],
    ["JIL SANDER", 52],
    ["J. LINDEBERG", 1],
    ["JOHANNA ORTIZ", 1],
    ["JOHN ELLIOTT", 47],
    ["JOHNNY WAS", 1],
    ["JOHN RICHMOND", 10],
    ["JOHN SMEDLEY", 23],
    ["JOHNSTONS OF ELGIN", 1],
    ["JOHN VARVATOS", 3],
    ["JOIE", 2],
    ["JONATHAN SIMKHAI", 10],
    ["JONATHAN SIMKHAI STANDARD", 1],
    ["JORDAN", 1],
    ["JOSEPH", 26],
    ["JOSHUA SANDERS", 1],
    ["JOSIE", 7],
    ["JOSIE NATORI", 14],
    ["JOURNELLE", 5],
    ["JOVONNA", 1],
    ["JUICY COUTURE", 1],
    ["JULIA JORDAN", 1],
    ["JULIEN DAVID", 1],
    ["JULIUS", 2],
    ["JUMP APPAREL", 1],
    ["JUNK FOOD", 1],
    ["JUNYA WATANABE", 12],
    ["JUST BEE QUEEN", 1],
    ["JUST CAVALLI", 9],
    ["JUST DON", 1],
    ["JUUN.J", 4],
    ["JW ANDERSON", 10],
    ["KALITA", 1],
    ["KAPPA", 3],
    ["KAREN KANE", 14],
    ["KARL LAGERFELD", 34],
    ["KASPER", 1],
    ["KATE SPADE", 6],
    ["KATIE MAY", 6],
    ["KENNETH COLE", 1],
    ["KENSIE", 4],
    ["KENZO", 42],
    ["KHAITE", 9],
    ["KHOON HOOI", 2],
    ["KIKI DE MONTPARNASSE", 5],
    ["KIKO KOSTADINOV", 1],
    ["KIMI AND KAI", 2],
    ["KIM SHUI", 1],
    ["KING AND TUCKFIELD", 2],
    ["KINGSMAN", 1],
    ["KIRED", 2],
    ["KIRIN", 3],
    ["KITON", 29],
    ["KIYONNA", 3],
    ["KNOW WAVE", 1],
    ["KOBI HALPERIN", 6],
    ["KOCHÉ", 7],
    ["KOLOR", 2],
    ["KORAL", 7],
    ["KRISA", 4],
    ["KSUBI", 12],
    ["KULE", 1],
    ["KURO", 1],
    ["K-WAY", 2],
    ["LAAPPAREL", 1],
    ["L'ACADEMIE", 10],
    ["LACOSTE", 17],
    ["LA DETRESSE", 1],
    ["LA DOUBLEJ", 17],
    ["LAFAYETTE 148", 20],
    ["LA FEMME", 12],
    ["L AGENCE", 32],
    ["LA MADE", 1],
    ["LAMARQUE", 2],
    ["LAMBERTO LOSANI", 1],
    ["LANEUS", 5],
    ["LANSTON", 1],
    ["LANSTON SPORT", 12],
    ["LANVIN", 8],
    ["LA PERLA", 3],
    ["LARDINI", 20],
    ["LAUNDRY BY SHELLI SEGAL", 1],
    ["LAUREN PETITE", 1],
    ["LAUREN RALPH LAUREN", 32],
    ["L'AUTRE CHOSE", 1],
    ["LAZYPANTS", 2],
    ["LBLC THE LABEL", 3],
    ["L.B.M.", 1],
    ["LE 17 SEPTEMBRE", 2],
    ["LEE MATHEWS", 2],
    ["LE JEAN", 1],
    ["LE KASHA", 1],
    ["LELA ROSE", 2],
    ["LEMAIRE", 9],
    ["LEON EMANUEL BLANCK", 4],
    ["LE RÉUSSI", 1],
    ["LESET", 3],
    ["LES GIRLS LES BOYS", 1],
    ["LES HOMMES", 4],
    ["LES RÊVERIES", 1],
    ["LES TIEN", 12],
    ["LEVI'S", 58],
    ["LHD", 13],
    ["LIBERTINE", 2],
    ["LIKELY", 7],
    ["LILLY PULITZER", 7],
    ["LILLY PULITZERR", 2],
    ["LINE & DOT", 2],
    ["LISA MARIE FERNANDEZ", 2],
    ["LITKOVSKAYA", 1],
    ["LITTLE MISTRESS", 1],
    ["LIU •JO", 12],
    ["LIVE THE PROCESS", 13],
    ["LNA", 2],
    ["LOCAL AUTHORITY", 2],
    ["LOEWE", 13],
    ["LOLA JEANS", 5],
    ["LONDON TIMES", 2],
    ["LORENA ANTONIAZZI", 7],
    ["LORO PIANA", 90],
    ["LOST + WANDER", 1],
    ["LOUISA BALLOU", 1],
    ["LOULOU STUDIO", 5],
    ["LOVEAPPELLA", 3],
    ["LOVE BY DESIGN", 1],
    ["LOVE MOSCHINO", 4],
    ["LOVERS & FRIENDS", 43],
    ["LOVESHACKFANCY", 12],
    ["LOW BRAND", 4],
    ["LOW CLASSIC", 2],
    ["LPA", 4],
    ["L*SPACE", 6],
    ["LUCA FALONI", 34],
    ["LUCKY BRAND", 7],
    ["LUISA CERANO", 2],
    ["LULULEMON", 239],
    ["LULUS", 1],
    ["LUSH", 1],
    ["LVHR", 1],
    ["LVIR", 1],
    ["LYGIA & NANNY", 21],
    ["MAC DUGGAL", 18],
    ["MACGRAW", 3],
    ["MACKAGE", 15],
    ["MACKINTOSH", 29],
    ["MADELEINE THOMPSON", 1],
    ["MADEWELL", 5],
    ["MAEVE", 1],
    ["MAGALI PASCAL", 1],
    ["MAGDA BUTRYM", 4],
    ["MAGGY LONDON", 3],
    ["MAHARISHI", 4],
    ["MAISIE WILEN", 4],
    ["MAISON BOHEMIQUE", 1],
    ["MAISON FLANEUR", 2],
    ["MAISON KITSUNÉ", 28],
    ["MAISON MARGIELA", 43],
    ["MAISON RABIH KAYROUZ", 4],
    ["MAJE", 41],
    ["MAJESTIC", 20],
    ["MAJORELLE", 14],
    ["MAKAVELIC", 1],
    ["MALO", 12],
    ["MAMMUT", 1],
    ["MANGO", 1],
    ["MANOKHI", 6],
    ["MANTARAY", 2],
    ["MANUEL RITZ", 6],
    ["MANZONI 24", 1],
    ["MARA HOFFMAN", 1],
    ["MARANE", 1],
    ["MARANT", 2],
    ["MARANT ETOILE", 1],
    ["MARCELO BURLON COUNTY OF MILAN", 37],
    ["MARCHESA", 12],
    ["MARCHESA NOTTE", 12],
    ["MARC JACOBS", 6],
    ["MARC NEW YORK", 16],
    ["MARCO RAMBALDI", 2],
    ["MARCUS ADLER", 2],
    ["MARINA", 1],
    ["MARINA RINALDI", 2],
    ["MARINE SERRE", 8],
    ["MARISSA WEBB", 2],
    ["MARNI", 15],
    ["MARQUES' ALMEIDA", 5],
    ["MARTHA MEDEIROS", 1],
    ["MARTINE ROSE", 6],
    ["MARYAM NASSIR ZADEH", 1],
    ["MARY KATRANTZOU", 1],
    ["MARYSIA", 1],
    ["MASNADA", 1],
    ["MASSIMO ALBA", 4],
    ["MASTERMIND JAPAN", 2],
    ["MATERNAL AMERICA", 1],
    ["MATHILDE GØHLER X NA-KD", 2],
    ["MATICEVSKI", 1],
    ["MAUNA KEA", 3],
    ["MAURO GRIFONI", 2],
    ["MAVI JEANS", 4],
    ["MAX MARA", 16],
    ["MAX & MOI", 1],
    ["MAZZARELLI", 1],
    ["MC2 SAINT BARTH", 3],
    ["MCQ BY ALEXANDER MCQUEEN", 7],
    ["MELISSA ODABASH", 5],
    ["MELROSE AND MARKET", 1],
    ["MERLETTE", 2],
    ["MICHAEL COSTELLO", 8],
    ["MICHAEL KORS", 13],
    ["MICHAEL LAUREN", 9],
    ["MICHAEL MICHAEL KORS", 8],
    ["MICHAEL STARS", 9],
    ["MICHELLE MASON", 4],
    ["MIHARAYASUHIRO", 4],
    ["MILLY", 13],
    ["MINIMUM", 1],
    ["MIRA MIKATI", 1],
    ["MISBHV", 10],
    ["MISHA COLLECTION", 1],
    ["MISOOK", 7],
    ["MISSONI", 4],
    ["MIU MIU", 14],
    ["MJB MARC JACQUES BURTON", 1],
    ["ML MONIQUE LHUILLIER", 3],
    ["MM6 MAISON MARGIELA", 13],
    ["M.M.LAFLEUR", 16],
    ["MONCLER", 122],
    ["MONCLER GENIUS", 37],
    ["MONCLER GRENOBLE", 13],
    ["MONFRERE", 1],
    ["MONROW", 8],
    ["MONSE", 1],
    ["MOORER", 7],
    ["MOOSE KNUCKLES", 1],
    ["MORE TO COME", 1],
    ["MOSCHINO", 58],
    ["MOSTLY HEARD RARELY SEEN", 23],
    ["MOSTLY HEARD RARELY SEEN 8-BIT", 14],
    ["MOTHER", 20],
    ["MOTHERHOOD MATERNITY", 1],
    ["MOTHER OF ALL", 1],
    ["MOUSSY", 1],
    ["MOUSSY VINTAGE", 5],
    ["MR P.", 6],
    ["MRZ", 2],
    ["MSGM", 41],
    ["MSK", 1],
    ["MUGLER", 6],
    ["MW", 1],
    ["N°21", 6],
    ["NAHMIAS", 1],
    ["NA-KD", 403],
    ["NA-KD CLASSIC", 23],
    ["NA-KD REBORN", 34],
    ["NA-KD URBAN", 1],
    ["NAKED AND FAMOUS", 1],
    ["NAKED & FAMOUS", 1],
    ["NANUSHKA", 20],
    ["NATALIE MARTIN", 1],
    ["NATASHA ZINKO", 3],
    ["NATORI", 84],
    ["NBD", 18],
    ["NEEDLES", 1],
    ["NEIGHBORHOOD", 1],
    ["NEIL BARRETT", 22],
    ["NEIMAN MARCUS", 1],
    ["NEUW", 2],
    ["NEW BALANCE", 6],
    ["NEW LOOK", 1],
    ["NEW YORK AND COMPANY", 3],
    ["NIC AND ZOE", 1],
    ["NIC AND ZOE PETITES", 1],
    ["NICCOLÒ PASQUALETTI", 1],
    ["NICHOLAS", 2],
    ["NICHOLAS DALEY", 1],
    ["NIC + ZOE", 5],
    ["NIKE", 71],
    ["NILI LOTAN", 12],
    ["NINA LEONARD", 1],
    ["NINA RICCI", 2],
    ["NISSA", 3],
    ["NN07", 2],
    ["N NATORI", 27],
    ["NOAH", 1],
    ["NOAM", 1],
    ["NOBODY DENIM", 1],
    ["NOIR KEI NINOMIYA", 1],
    ["NOISY MAY TALL", 1],
    ["NO KA'OI", 3],
    ["NOM MATERNITY", 2],
    ["NOOKIE", 9],
    ["NOON GOONS", 2],
    ["NORBA", 1],
    ["NORDSTROM", 1],
    ["NORMA KAMALI", 27],
    ["NORSE PROJECTS", 5],
    ["NORTH FACE", 1],
    ["NORWEGIAN WOOL", 4],
    ["NOTHING WRITTEN", 1],
    ["N.PEAL", 20],
    ["N:PHILANTHROPY", 1],
    ["NUDIE JEANS", 10],
    ["NUUR", 1],
    ["NY COLLECTION", 3],
    ["NYDJ", 32],
    ["OAMC", 8],
    ["O'BLANC", 2],
    ["OFFICINE GENERALE", 5],
    ["OFF-WHITE", 79],
    ["OLIPHANT", 1],
    ["OLIVERS", 1],
    ["OLIVIA RUBIN", 1],
    ["OLIVIA VON HALLE", 2],
    ["OLYMPIAH", 20],
    ["ON", 2],
    ["ONE33 SOCIAL", 4],
    ["ONEFIFTEEN", 2],
    ["ON GOSSAMER", 1],
    ["ONIA", 2],
    ["ONLY HEARTS", 1],
    ["ONZIE", 1],
    ["OPENING CEREMONY", 13],
    ["OPERASPORT", 1],
    ["OPPOSUITS", 3],
    ["ORIAN", 1],
    ["ORLEBAR BROWN", 13],
    ["ORSLOW", 1],
    ["OSCAR DE LA RENTA", 8],
    ["OSKLEN", 12],
    ["OTTOLINGER", 1],
    ["OUR LEGACY", 3],
    ["OUTDOOR VOICES", 1],
    ["OVER OVER", 2],
    ["PACO RABANNE", 11],
    ["PACSUN", 1],
    ["PAIGE", 42],
    ["PALACE", 26],
    ["PALM ANGELS", 41],
    ["PALMES", 1],
    ["PALTÒ", 2],
    ["PAL ZILERI", 5],
    ["PAMELAXNAKD", 1],
    ["PAMELA X NA-KD", 4],
    ["PAOLONI", 3],
    ["PAPILLON", 1],
    ["PAPINELLE", 1],
    ["PARAJUMPERS", 10],
    ["PARLEZ", 1],
    ["P.A.R.O.S.H.", 19],
    ["PAS NORMAL STUDIOS", 3],
    ["PATAGONIA", 7],
    ["PATOU", 3],
    ["PATRIZIA PEPE", 3],
    ["PAULA CANOVAS DEL VAS", 1],
    ["PAUL & SHARK", 30],
    ["PAUL SMITH", 23],
    ["PEIXOTO", 3],
    ["P.E NATION", 4],
    ["PENINSULA SWIMWEAR", 1],
    ["PERFECT MOMENT", 8],
    ["PESERICO", 17],
    ["PETAR PETROV", 1],
    ["PETER DO", 2],
    ["PETER MILLAR", 8],
    ["PETER PILOTTO", 1],
    ["PEUTEREY", 4],
    ["PHILIPP PLEIN", 95],
    ["PHILOSOPHY", 1],
    ["PHILOSOPHY APPAREL", 1],
    ["PHILOSOPHY DI LORENZO SERAFINI", 11],
    ["PHIPPS", 8],
    ["PIAZZA SEMPIONE", 1],
    ["PIECE OF WHITE", 1],
    ["PIERRE BALMAIN", 1],
    ["PIERRE-LOUIS MASCIA", 1],
    ["PIETRO BRUNELLI", 1],
    ["PINKO", 11],
    ["PISTOLA", 3],
    ["PITUSA", 2],
    ["P JAMAS", 2],
    ["PLAN C", 4],
    ["PLEIN SPORT", 2],
    ["PLUSH", 1],
    ["POÈME BOHÈMIEN", 1],
    ["POLO RALPH LAUREN", 187],
    ["PORTS 1961", 2],
    ["PORTS V", 1],
    ["PORTUGUESE FLANNEL", 2],
    ["POSTER GIRL", 1],
    ["POUPETTE ST BARTH", 7],
    ["PRADA", 95],
    ["PREEN BY THORNTON BREGAZZI", 2],
    ["PRESIDENT'S", 1],
    ["PRIMO EMPORIO", 1],
    ["PRINCE PETER COLLECTION", 1],
    ["PRINCIPLES", 2],
    ["PRINGLE OF SCOTLAND", 4],
    ["PRIVATE WHITE V.C.", 1],
    ["PROENZA SCHOULER", 17],
    ["PROENZA SCHOULER WHITE LABEL", 9],
    ["PROSPERITY DENIM", 1],
    ["PS BY PAUL SMITH", 24],
    ["PT01", 11],
    ["PT TORINO", 10],
    ["PUBLIC REC", 13],
    ["PULL & BEAR", 1],
    ["PUMA", 4],
    ["PURPLE", 2],
    ["PURPLE BRAND", 11],
    ["PUSHBUTTON", 1],
    ["QUEEN OF JETLAGS X NA-KD", 3],
    ["QUIZ", 1],
    ["R13", 15],
    ["RACHEL GILBERT", 1],
    ["RACHEL RACHEL ROY", 1],
    ["RACIL", 1],
    ["RAEY", 8],
    ["RAF SIMONS", 11],
    ["RAG & BONE", 37],
    ["RAILS", 10],
    ["RAKKÌ", 1],
    ["RALPH LAUREN", 196],
    ["RALPH LAUREN RRL", 1],
    ["RAMY BROOK", 7],
    ["RANDOM IDENTITIES", 1],
    ["RAPHA", 2],
    ["RAQUETTE", 1],
    ["RASARIO", 2],
    ["RASSVET", 3],
    ["REBECCA MINKOFF", 1],
    ["REBECCA TAYLOR", 1],
    ["REBECCA VALLANCE", 2],
    ["REBODY", 4],
    ["RECREATIONAL HABITS", 1],
    ["RECTO", 2],
    ["RE/DONE", 23],
    ["RE/DONE BY LEVI'S", 1],
    ["RED VALENTINO", 9],
    ["REEBOK", 2],
    ["REFORMATION", 7],
    ["REGATTA", 32],
    ["REIGNING CHAMP", 5],
    ["REISS", 7],
    ["REMAIN", 3],
    ["REMI RELIEF", 1],
    ["RE ONA", 1],
    ["REPRESENT", 2],
    ["RESA", 1],
    ["RETROFÉTE", 10],
    ["RHONE", 1],
    ["RHUDE", 2],
    ["RICHARD JAMES", 1],
    ["RICHARD QUINN", 1],
    ["RICHER POORER", 1],
    ["RICKIE FREEMAN FOR TERI JON", 2],
    ["RICK OWENS", 40],
    ["RICK OWENS DRKSHDW", 10],
    ["RICK OWENS X CHAMPION", 1],
    ["RILEY & RAE", 2],
    ["RIP CURL", 2],
    ["RIVER ISLAND", 3],
    ["RIVET UTILITY", 3],
    ["RIXO LONDON", 3],
    ["R & M RICHARDS", 6],
    ["ROBERT GRAHAM", 21],
    ["ROBERTO CAVALLI", 3],
    ["ROBERTO COLLINA", 10],
    ["ROCHAMBEAU", 1],
    ["ROCOCO SAND", 1],
    ["RODA", 1],
    ["RODARTE", 1],
    ["RODD & GUNN", 20],
    ["RODEBJER", 1],
    ["ROKH", 1],
    ["ROKIT", 1],
    ["ROKSANDA", 1],
    ["ROLAND MOURET", 5],
    ["ROLLA'S", 1],
    ["ROLLER RABBIT", 2],
    ["ROMEO HUNTE", 2],
    ["ROSEANNA", 1],
    ["ROSETTA GETTY", 4],
    ["ROSIE ASSOULIN", 2],
    ["ROTATE BIRGER CHRISTENSEN", 1],
    ["ROXY", 2],
    ["ROYAL ROBBINS", 1],
    ["RRD", 1],
    ["RRD - ROBERTO RICCI DESIGN", 1],
    ["RRL", 2],
    ["RTA", 4],
    ["RUT & CIRCLE", 7],
    ["RVNG COUTURE", 2],
    ["RYDER", 1],
    ["SABINA MUSAYEV", 1],
    ["SACAI", 12],
    ["SACHIN & BABI", 4],
    ["SAIID KOBEISY", 4],
    ["SAINT JAMES", 1],
    ["SAINT LAURENT", 82],
    ["SAKS FIFTH AVENUE", 4],
    ["SAKS POTTS", 2],
    ["SALONI", 1],
    ["SALVATORE FERRAGAMO", 10],
    ["SALVATORE SANTORO", 9],
    ["SAM.", 2],
    ["SAMI MIRO VINTAGE", 1],
    ["SAMSOE & SAMSOE", 1],
    ["SANCTUARY", 2],
    ["SANDRO", 82],
    ["SANDY LIANG", 1],
    ["SANKUANZ", 1],
    ["SANTORELLI", 1],
    ["SAPIO", 3],
    ["SÀPOPA", 1],
    ["SARA BATTAGLIA", 1],
    ["SARTORIO", 2],
    ["SAU LEE", 3],
    ["SAVE KHAKI UNITED", 1],
    ["SAVE THE DUCK", 2],
    ["SAVILE ROW CO", 1],
    ["SAYLOR", 1],
    ["SCHOTT", 3],
    ["SEA", 2],
    ["SEAFOLLY", 3],
    ["SEASE", 5],
    ["SEE BY CHLOÉ", 7],
    ["SÉFR", 2],
    ["SELF-PORTRAIT", 1],
    ["SEMICOUTURE", 1],
    ["SENTALER", 2],
    ["SERAPH SIN", 4],
    ["SER.O.YA", 1],
    ["SHANGHAI TANG", 1],
    ["SHANI", 26],
    ["SHIATZY CHEN", 1],
    ["SHO", 1],
    ["SHONA JOY", 1],
    ["SHOSHANNA", 1],
    ["SHOW ME YOUR MUMU", 2],
    ["SHUSHU-TONG", 2],
    ["SIES MARJAN", 4],
    ["SILVER JEANS CO.", 1],
    ["SIMKHAI", 1],
    ["SIMONE ROCHA", 1],
    ["SIMON MILLER", 1],
    ["SIR", 1],
    ["SKIMS", 5],
    ["SKIN", 7],
    ["SLEEPER", 1],
    ["SLEEPHERO", 1],
    ["SL FASHIONS", 13],
    ["SLNY", 3],
    ["SLVRLAKE", 13],
    ["'S MAX MARA", 4],
    ["SMYTHE", 1],
    ["SNDYS", 2],
    ["SNIDER", 1],
    ["SNOW PEAK", 1],
    ["S.N.S. HERNING", 1],
    ["SOAR", 2],
    ["SOFIE D'HOORE", 2],
    ["SOLID & STRIPED", 2],
    ["SONG FOR THE MUTE", 1],
    ["SONG OF STYLE", 2],
    ["SONIA RYKIEL", 1],
    ["SOTTOMETTIMI", 5],
    ["SOULLAND", 2],
    ["SOUL OF LONDON", 1],
    ["SPANX", 5],
    ["SPLENDID", 5],
    ["SPLITS59", 3],
    ["SPORT B. BY AGNÈS B.", 2],
    ["SPORTMAX", 3],
    ["SPORTY AND RICH", 3],
    ["SPRWMN", 5],
    ["SSENSE WORKS", 1],
    ["SSS WORLD CORP", 2],
    ["STAND STUDIO", 5],
    ["STAUD", 5],
    ["STEFANO DE LELLIS", 2],
    ["STELLA MCCARTNEY", 52],
    ["STÉPHANIE DURANT X NA-KD", 2],
    ["STEPHAN SCHNEIDER", 1],
    ["STEWART", 1],
    ["STILL HERE", 1],
    ["STITCHES & STRIPES", 1],
    ["ST. JOHN", 13],
    ["STONE ISLAND", 21],
    ["STONE ISLAND SHADOW PROJECT", 3],
    ["STOULS", 2],
    ["STOWAWAY COLLECTION", 2],
    ["STUDIO NICHOLSON", 4],
    ["STUDIO TOMBOY", 2],
    ["STUSSY", 4],
    ["STUTTERHEIM", 1],
    ["STYLAND", 10],
    ["STYLE & CO", 1],
    ["SUNFLOWER", 2],
    ["SUNNEI", 1],
    ["SUNSPEL", 22],
    ["SUPERDOWN", 62],
    ["SUPERDRY", 1],
    ["SUPPLIES BY UNIONBAY", 2],
    ["SUPREME", 27],
    ["SUSANA MONACO", 12],
    ["SWEATY BETTY", 1],
    ["SWORD 6.6.44", 3],
    ["SYSTEM", 2],
    ["TADASHI SHOJI", 8],
    ["TAGLIATORE", 18],
    ["TAKESHY KUROSAWA", 3],
    ["TANYA TAYLOR", 1],
    ["TED BAKER", 9],
    ["TEKLA", 3],
    ["TELFAR", 1],
    ["TEMPERLEY LONDON", 1],
    ["TEN C", 4],
    ["TEREZ", 1],
    ["THE BAR", 1],
    ["THE ELDER STATESMAN", 2],
    ["THE FASHION FRACTION X NA-KD", 1],
    ["THE FRANKIE SHOP", 3],
    ["THEGIGI", 1],
    ["THE GIGI", 1],
    ["THE GREAT", 2],
    ["THEIA", 3],
    ["THE KOOPLES", 1],
    ["THE LINE BY K", 1],
    ["THE MEN'S STORE AT BLOOMINGDALE'S", 1],
    ["THE NORTH FACE", 6],
    ["THEO AND SPENCE", 1],
    ["THEORY", 44],
    ["THE ROW", 33],
    ["THE SILTED COMPANY", 1],
    ["THE UPSIDE", 9],
    ["THE VIRIDI-ANNE", 1],
    ["THE WEBSTER", 1],
    ["THOMBROWNE", 1],
    ["THOM BROWNE", 271],
    ["THOM KROM", 1],
    ["THOM SWEENEY", 1],
    ["THREE GRACES LONDON", 1],
    ["THROWBACK.", 4],
    ["TIBI", 2],
    ["TIGER OF SWEDEN", 2],
    ["TIMBERLAND", 1],
    ["TINTORIA MATTEI", 1],
    ["TOCCIN", 3],
    ["TOM FORD", 80],
    ["TOMMY BAHAMA", 1],
    ["TOMMY HILFIGER", 33],
    ["TOMMY JEANS", 5],
    ["TOM SACHS", 10],
    ["TONELLO", 5],
    ["TONY WARD", 2],
    ["TOPMAN", 1],
    ["TOPSHOP", 4],
    ["TORY BURCH", 8],
    ["TORY SPORT", 7],
    ["TOTÊME", 17],
    ["TRENDYOL", 13],
    ["TRESPASS", 12],
    ["TRIARCHY", 2],
    ["TRINA TURK", 2],
    ["TRUE RELIGION", 1],
    ["TRU TRUSSARDI", 1],
    ["T TAHARI", 2],
    ["TTSWTRS", 1],
    ["TULAROSA", 6],
    ["TWINSET", 7],
    ["UGG", 7],
    ["ULLA JOHNSON", 8],
    ["ULTRACOR", 3],
    ["UMA RAQUEL DAVIDOWICZ", 1],
    ["UMA WANG", 4],
    ["UNDER ARMOUR", 1],
    ["UNDERCOVER", 22],
    ["UNDERCOVERISM", 1],
    ["UNION DENIM", 1],
    ["UNITED RIVERS", 1],
    ["UNITED STANDARD", 2],
    ["UNREAL FUR", 1],
    ["UP TO BE", 1],
    ["VALENTINO", 55],
    ["VANESSA BRUNO", 1],
    ["VANS", 9],
    ["VARLEY", 7],
    ["VELVET", 18],
    ["VELVET TORCH", 1],
    ["VERO MODA", 1],
    ["VERONICA BEARD", 27],
    ["VERONICA BEARD JEANS", 2],
    ["VERSACE", 41],
    ["VERSACE JEANS", 1],
    ["VERSACE JEANS COUTURE", 18],
    ["VETEMENTS", 10],
    ["VICI COLLECTION", 1],
    ["VICTORIA BECKHAM", 5],
    ["VICTORIA VICTORIA BECKHAM", 2],
    ["VIKTOR & ROLF", 5],
    ["VILEBREQUIN", 12],
    ["VINCE", 22],
    ["VINCE CAMUTO", 10],
    ["VINEYARD VINES", 1],
    ["VIRGOS LOUNGE", 1],
    ["VISION OF SUPER", 4],
    ["VISVIM", 2],
    ["VITAMIN A", 1],
    ["VIVIENNE WESTWOOD", 6],
    ["VOICE OF INSIDERS", 1],
    ["VOZ", 1],
    ["WACKO MARIA", 2],
    ["WACOAL", 2],
    ["WAIMARI", 1],
    ["WALES BONNER", 2],
    ["WALTER BAKER", 8],
    ["WARDROBE.NYC", 6],
    ["WARP & WEFT", 1],
    ["WASHINGTON DEE CEE", 2],
    ["WASH LAB", 1],
    ["WASH LAB DENIM", 1],
    ["WAYF", 3],
    ["WE11 DONE", 14],
    ["WEEKEND MAX MARA", 1],
    ["WE THE FREE", 1],
    ["WEWOREWHAT", 1],
    ["WHISTLES", 4],
    ["WHITE HOUSE BLACK MARKET", 1],
    ["WHITE MARK", 13],
    ["WHITE MOUNTAINEERING", 2],
    ["WILLOW", 1],
    ["WILT", 1],
    ["WINDSOR", 1],
    ["WINNIE", 1],
    ["WINNIE NEW YORK", 1],
    ["WINNIE N.Y.C", 5],
    ["WOLFORD", 8],
    ["WOOD WOOD", 2],
    ["WOOLRICH", 14],
    ["WSLY", 4],
    ["WTAPS", 1],
    ["XACUS", 1],
    ["XIRENA", 2],
    ["XIX PALMS", 1],
    ["XSCAPE", 7],
    ["Y-3", 14],
    ["YANG LI", 3],
    ["YEAR OF OURS", 3],
    ["YMC YOU MUST CREATE", 2],
    ["YOHJI YAMAMOTO", 6],
    ["YOUTHS IN BALACLAVA", 3],
    ["Y/PROJECT", 4],
    ["Y'S", 1],
    ["YUMMIE", 2],
    ["ZADIG & VOLTAIRE", 10],
    ["ZANONE", 27],
    ["Z BY ZELLA", 1],
    ["ZEGNA", 3],
    ["ZELLA", 2],
    ["ZERO + MARIA CORNEJO", 1],
    ["ZHIVAGO", 4],
    ["ZIGGY CHEN", 1],
    ["ZILVER", 1],
    ["ZIMMERLI", 2],
    ["ZIMMERMANN", 5],
    ["Z SUPPLY", 1],
    ["ZUHAIR MURAD", 1],
    ["ZZDNU SHANI", 1],
    ["Z ZEGNA", 5],
];

const maleBrandsAvailable = [
    ["032C", 2],
    ["120% LINO", 8],
    ["2XU", 2],
    ["3.1 PHILLIP LIM", 3],
    ["321", 7],
    ["34 HERITAGE", 2],
    ["424", 8],
    ["44 LABEL GROUP", 1],
    ["4SDESIGNS", 1],
    ["66 NORTH", 2],
    ["7 FOR ALL MANKIND", 8],
    ["8 BY YOOX", 1],
    ["AAPE BY A BATHING APE", 6],
    ["A BATHING APE", 4],
    ["ACNE STUDIOS", 27],
    ["A-COLD-WALL*", 19],
    ["ADD", 1],
    ["ADER ERROR", 2],
    ["ADIDAS ORIGINALS", 11],
    ["ADIDAS ORIGINALS BY ALEXANDER WANG", 1],
    ["ADISH", 2],
    ["ADVISORY BOARD CRYSTALS", 2],
    ["AG", 4],
    ["AGNÈS B.", 1],
    ["AGNONA", 1],
    ["AIEZEN", 1],
    ["AJMONE", 1],
    ["ALANUI", 1],
    ["ALCHEMIST", 5],
    ["ALCHEMY", 5],
    ["ALESSANDRO DELL'ACQUA", 1],
    ["ALEXANDER MCQUEEN", 40],
    ["ALEX MILL", 1],
    ["ALFANI", 2],
    ["ALLSAINTS", 5],
    ["ALO YOGA", 1],
    ["ALPHA INDUSTRIES", 11],
    ["ALTEA", 9],
    ["ALTON LANE", 1],
    ["ALYX", 16],
    ["AMBUSH", 17],
    ["AMENDI", 2],
    ["AMI ALEXANDRE MATTIUSSI", 61],
    ["AMIRI", 14],
    ["ANDREW MARC", 2],
    ["AND WANDER", 2],
    ["ANGELO NARDELLI", 1],
    ["ANTI SOCIAL SOCIAL CLUB", 1],
    ["A.P.C.", 24],
    ["ARC'TERYX", 1],
    ["ARIES", 4],
    ["ARMANI COLLEZIONI", 1],
    ["ARMANI EXCHANGE", 12],
    ["ARPENTEUR", 1],
    ["ARTE", 2],
    ["ASICS", 1],
    ["ASOS DESIGN", 7],
    ["ASPESI", 38],
    ["ATM ANTHONY THOMAS MELILLO", 5],
    ["AT.P.CO", 2],
    ["AURALEE", 1],
    ["AUTOMOBILI LAMBORGHINI", 1],
    ["AUTRY", 2],
    ["AVANT TOI", 2],
    ["AWAKE NY", 1],
    ["AWDIS", 2],
    ["AXEL ARIGATO", 4],
    ["AZTECH MOUNTAIN", 7],
    ["BALENCIAGA", 22],
    ["BALLANTYNE", 23],
    ["BALLY", 1],
    ["BALMAIN", 35],
    ["BAPE", 1],
    ["BARACUTA", 6],
    ["BARBA", 8],
    ["BARBOUR", 27],
    ["BARENA VENEZIA", 7],
    ["BARRIE", 3],
    ["BELSTAFF", 8],
    ["BEN SHERMAN", 1],
    ["BEN TAVERNITI UNRAVEL PROJECT", 3],
    ["BERSHKA", 1],
    ["BIKKEMBERGS", 2],
    ["BILLIONAIRE", 10],
    ["BILLIONAIRE BOYS CLUB", 2],
    ["BLACK COMME DES GARÇONS", 2],
    ["BLANKNYC", 10],
    ["BLK DNM", 4],
    ["BODE", 1],
    ["BOGLIOLI", 16],
    ["BORIS BIDJAN SABERI", 2],
    ["BOTTEGA MARTINESE", 2],
    ["BOTTEGA VENETA", 7],
    ["BOTTER", 2],
    ["BRIAN DALES", 2],
    ["BRIGLIA", 1],
    ["BRIGLIA 1949", 3],
    ["BRIONI", 7],
    ["BRUNELLO CUCINELLI", 94],
    ["BRUNO MANETTI", 1],
    ["BUGATCHI", 2],
    ["BURBERRY", 96],
    ["BURTON", 1],
    ["BUSCEMI", 1],
    ["C2H4", 1],
    ["CALVIN KLEIN", 6],
    ["CALVIN KLEIN 205W39NYC", 1],
    ["CALVIN KLEIN JEANS EST.1978", 7],
    ["CANADA GOOSE", 44],
    ["CANALI", 20],
    ["CARHARTT", 22],
    ["CARLO PIGNATELLI CERIMONIA", 2],
    ["CARUSO", 4],
    ["CASABLANCA", 7],
    ["CELINE", 2],
    ["CHAMPION", 5],
    ["CHARLES JEFFREY LOVERBOY", 3],
    ["CHARVET", 1],
    ["CHINATOWN MARKET", 2],
    ["CITIZENS OF HUMANITY", 7],
    ["CLOSED", 3],
    ["CLUB ROOM", 7],
    ["COBRA S.C.", 1],
    ["COLE HAAN", 9],
    ["COLMAR ORIGINALS", 1],
    ["COLOMBO", 2],
    ["COMCOMME DES GARÃ§ONS SHIRTME DES GARÃ§ONS SHIRT", 1],
    ["COMME DES GARÇONS", 4],
    ["COMME DES GARÇONS HOMME DEUX", 10],
    ["COMME DES GARÇONS PLAY", 74],
    ["COMME DES GARÇONS SHIRT", 14],
    ["COOL TM", 1],
    ["CORELATE", 1],
    ["CORNELIANI", 19],
    ["COS", 5],
    ["COSTUMEIN", 2],
    ["COTTWEILER", 1],
    ["COURRGES", 1],
    ["C.P. COMPANY", 21],
    ["CRAIG GREEN", 5],
    ["CRUCIANI", 1],
    ["CULT OF INDIVIDUALITY", 4],
    ["CUTS", 1],
    ["D4.0", 7],
    ["DAILY PAPER", 3],
    ["DANIEL BUCHLER", 1],
    ["DANIELE ALESSANDRINI", 1],
    ["DARKPARK", 1],
    ["DAVID DONAHUE", 2],
    ["DELANTIC", 1],
    ["DELL'OGLIO", 19],
    ["DEPARTMENT 5", 5],
    ["DEPARTMENT FIVE", 1],
    ["DEPERLU", 1],
    ["DEREK ROSE", 14],
    ["DEUS EX MACHINA", 3],
    ["DICKIES", 3],
    ["DICKIES CONSTRUCT", 2],
    ["DIESEL", 37],
    ["DISCLAIMER", 1],
    ["DISTRICT VISION", 1],
    ["DKNY", 1],
    ["DL", 2],
    ["DOLCE & GABBANA", 178],
    ["DOMENICO TAGLIENTE", 1],
    ["DOMREBEL", 7],
    ["DONDUP", 30],
    ["DOUBLE RL", 2],
    ["DOUBLET", 5],
    ["DRIES VAN NOTEN", 3],
    ["DRÔLE DE MONSIEUR", 7],
    ["DRUMOHR", 14],
    ["DSQUARED2", 124],
    ["DUNHILL", 6],
    ["DUOLTD", 1],
    ["DUVETICA", 1],
    ["EA7", 12],
    ["EAST HARBOUR SURPLUS", 2],
    ["EBERJEY", 2],
    ["ÉDITIONS M.R", 5],
    ["ELEVENTY", 10],
    ["EMPORIO ARMANI", 64],
    ["ENGINEERED GARMENTS", 3],
    ["ERL", 1],
    ["ERMENEGILDO ZEGNA", 34],
    ["ESSENTIALS", 1],
    ["ETON", 3],
    ["ETRO", 19],
    ["ETUDES STUDIO", 13],
    ["EVISU", 2],
    ["FACETASM", 1],
    ["FAHERTY", 11],
    ["FAITH CONNEXION", 3],
    ["FAMILY FIRST MILANO", 1],
    ["FAR AFIELD", 1],
    ["FASHION CLINIC TIMELESS", 2],
    ["FAY", 11],
    ["FEAR OF GOD", 5],
    ["FEDELI", 4],
    ["FENDI", 9],
    ["FERRAGAMO", 1],
    ["FERRARI", 1],
    ["FILA", 2],
    ["FILERIA", 2],
    ["FILIPPA K", 3],
    ["FINAMORE 1925 NAPOLI", 1],
    ["FIORUCCI", 1],
    ["FIVE CM", 2],
    ["FJALL RAVEN", 1],
    ["FOLK", 5],
    ["FORME D'EXPRESSION", 1],
    ["FOURLAPS", 1],
    ["FRAME", 19],
    ["FRANCHISE", 1],
    ["FRED PERRY", 11],
    ["FRESCOBOL CARIOCA", 4],
    ["FUMITO GANRYU", 2],
    ["FUSALP", 3],
    ["GABRIELA HEARST", 8],
    ["GABRIELE PASINI", 2],
    ["GALLERY DEPT.", 3],
    ["GARCONS INFIDELES", 2],
    ["GCDS", 18],
    ["GHIAIA CASHMERE", 1],
    ["GIORGIO ARMANI", 30],
    ["GIUSEPPE ZANOTTI", 3],
    ["GIVENCHY", 28],
    ["GLANSHIRT", 4],
    ["GMBH", 1],
    ["GOD'S MASTERFUL CHILDREN", 6],
    ["GOETZE", 1],
    ["GOLDEN BEAR", 1],
    ["GOLDEN GOOSE", 11],
    ["GOOD MAN BRAND", 3],
    ["GOSHA RUBCHINSKIY", 1],
    ["GR10K", 3],
    ["GRAMICCI", 5],
    ["GRAN SASSO", 1],
    ["GREG LAUREN", 3],
    ["GREY DANIELE ALESSANDRINI", 1],
    ["GREYSON", 1],
    ["G-STAR", 1],
    ["GUCCI", 23],
    ["GUESS", 1],
    ["GUESS FACTORY", 1],
    ["GUESS USA", 1],
    ["HACKETT", 3],
    ["HACULLA", 34],
    ["HAIDER ACKERMANN", 2],
    ["HAIKURE", 1],
    ["HAND PICKED", 2],
    ["HAN KJOBENHAVN", 1],
    ["HANRO", 5],
    ["HARRIS WHARF LONDON", 8],
    ["HARTFORD", 3],
    ["HART SCHAFFNER MARX", 2],
    ["HELIOT EMIL", 4],
    ["HELMUT LANG", 17],
    ["HERNO", 42],
    ["HERNO LAMINAR", 1],
    ["HERON PRESTON", 14],
    ["HEVO", 1],
    ["HOGAN", 1],
    ["HOLDEN", 1],
    ["HOLIDAY", 1],
    ["HONEY FUCKING DIJON", 2],
    ["HUDSON", 1],
    ["HUGO", 14],
    ["HUGO BOSS", 41],
    ["HYDROGEN", 1],
    ["ICEBERG", 6],
    ["IH NOM UH NIT", 3],
    ["INC INTERNATIONAL CONCEPTS", 1],
    ["INCOTEX", 34],
    ["ISAAC SELLAM EXPERIENCE", 2],
    ["ISABEL BENENATO", 2],
    ["ISABEL MARANT", 11],
    ["ISAIA", 1],
    ["ISSEY MIYAKE", 3],
    ["IZZUE", 1],
    ["JACOB COHEN", 19],
    ["JACQUEMUS", 4],
    ["JAMES PERSE", 15],
    ["JIEDA", 1],
    ["JIL SANDER", 21],
    ["J. LINDEBERG", 1],
    ["JOHN ELLIOTT", 37],
    ["JOHN RICHMOND", 9],
    ["JOHN SMEDLEY", 23],
    ["JOHN VARVATOS", 3],
    ["JULIEN DAVID", 1],
    ["JULIUS", 2],
    ["JUNYA WATANABE", 8],
    ["JUST CAVALLI", 1],
    ["JUST DON", 1],
    ["JUUN.J", 4],
    ["JW ANDERSON", 6],
    ["KAPPA", 2],
    ["KARL LAGERFELD", 13],
    ["KENNETH COLE", 1],
    ["KENZO", 20],
    ["KIKO KOSTADINOV", 1],
    ["KING AND TUCKFIELD", 2],
    ["KINGSMAN", 1],
    ["KIRED", 2],
    ["KITON", 29],
    ["KNOW WAVE", 1],
    ["KOCHÉ", 4],
    ["KOLOR", 1],
    ["KSUBI", 9],
    ["KURO", 1],
    ["LAAPPAREL", 1],
    ["LACOSTE", 15],
    ["LAMBERTO LOSANI", 1],
    ["LANEUS", 4],
    ["LANVIN", 6],
    ["LARDINI", 20],
    ["L.B.M.", 1],
    ["LE 17 SEPTEMBRE", 2],
    ["LE KASHA", 1],
    ["LEMAIRE", 3],
    ["LES HOMMES", 4],
    ["LES TIEN", 8],
    ["LEVI'S", 24],
    ["LOCAL AUTHORITY", 2],
    ["LOEWE", 5],
    ["LORO PIANA", 80],
    ["LOW BRAND", 4],
    ["LUCA FALONI", 34],
    ["LUCKY BRAND", 5],
    ["LULULEMON", 122],
    ["MACKAGE", 7],
    ["MACKINTOSH", 28],
    ["MAHARISHI", 4],
    ["MAISON FLANEUR", 2],
    ["MAISON KITSUNÉ", 15],
    ["MAISON MARGIELA", 26],
    ["MAJESTIC", 9],
    ["MAKAVELIC", 1],
    ["MALO", 12],
    ["MAMMUT", 1],
    ["MANUEL RITZ", 3],
    ["MARANE", 1],
    ["MARANT", 2],
    ["MARCELO BURLON COUNTY OF MILAN", 36],
    ["MARC NEW YORK", 13],
    ["MARINE SERRE", 3],
    ["MARNI", 4],
    ["MARTINE ROSE", 6],
    ["MASNADA", 1],
    ["MASSIMO ALBA", 4],
    ["MASTERMIND JAPAN", 2],
    ["MAUNA KEA", 3],
    ["MAURO GRIFONI", 1],
    ["MAVI JEANS", 1],
    ["MAZZARELLI", 1],
    ["MC2 SAINT BARTH", 2],
    ["MCQ BY ALEXANDER MCQUEEN", 3],
    ["MICHAEL KORS", 9],
    ["MICHAEL MICHAEL KORS", 2],
    ["MIHARAYASUHIRO", 4],
    ["MISBHV", 5],
    ["MISSONI", 3],
    ["MJB MARC JACQUES BURTON", 1],
    ["MONCLER", 103],
    ["MONCLER GENIUS", 31],
    ["MONCLER GRENOBLE", 12],
    ["MONFRERE", 1],
    ["MOORER", 6],
    ["MOOSE KNUCKLES", 1],
    ["MOSCHINO", 25],
    ["MOSTLY HEARD RARELY SEEN", 23],
    ["MOSTLY HEARD RARELY SEEN 8-BIT", 14],
    ["MR P.", 6],
    ["MSGM", 17],
    ["MW", 1],
    ["NAHMIAS", 1],
    ["NAKED AND FAMOUS", 1],
    ["NAKED & FAMOUS", 1],
    ["NANUSHKA", 8],
    ["NEEDLES", 1],
    ["NEIGHBORHOOD", 1],
    ["NEIL BARRETT", 21],
    ["NEIMAN MARCUS", 1],
    ["NEUW", 2],
    ["NEW BALANCE", 6],
    ["NEW LOOK", 1],
    ["NICHOLAS DALEY", 1],
    ["NIKE", 42],
    ["NN07", 2],
    ["NOAH", 1],
    ["NOON GOONS", 2],
    ["NORDSTROM", 1],
    ["NORSE PROJECTS", 5],
    ["NORTH FACE", 1],
    ["NORWEGIAN WOOL", 4],
    ["N.PEAL", 8],
    ["NUDIE JEANS", 10],
    ["NUUR", 1],
    ["OAMC", 8],
    ["OFFICINE GENERALE", 5],
    ["OFF-WHITE", 48],
    ["OLIVERS", 1],
    ["ON", 2],
    ["ONIA", 1],
    ["OPENING CEREMONY", 7],
    ["OPPOSUITS", 3],
    ["ORIAN", 1],
    ["ORLEBAR BROWN", 13],
    ["ORSLOW", 1],
    ["OSKLEN", 10],
    ["OUR LEGACY", 3],
    ["OVER OVER", 2],
    ["PACSUN", 1],
    ["PAIGE", 15],
    ["PALACE", 26],
    ["PALM ANGELS", 28],
    ["PALMES", 1],
    ["PALTÒ", 2],
    ["PAL ZILERI", 5],
    ["PAOLONI", 3],
    ["PARAJUMPERS", 7],
    ["PARLEZ", 1],
    ["PAS NORMAL STUDIOS", 1],
    ["PATAGONIA", 7],
    ["PAUL & SHARK", 30],
    ["PAUL SMITH", 21],
    ["PENINSULA SWIMWEAR", 1],
    ["PERFECT MOMENT", 1],
    ["PETER MILLAR", 8],
    ["PEUTEREY", 4],
    ["PHILIPP PLEIN", 57],
    ["PHIPPS", 8],
    ["PIERRE BALMAIN", 1],
    ["PLEIN SPORT", 2],
    ["POÈME BOHÈMIEN", 1],
    ["POLO RALPH LAUREN", 172],
    ["PORTUGUESE FLANNEL", 2],
    ["PRADA", 57],
    ["PRESIDENT'S", 1],
    ["PRIMO EMPORIO", 1],
    ["PRINGLE OF SCOTLAND", 4],
    ["PRIVATE WHITE V.C.", 1],
    ["PS BY PAUL SMITH", 16],
    ["PT01", 11],
    ["PT TORINO", 10],
    ["PUBLIC REC", 10],
    ["PULL & BEAR", 1],
    ["PUMA", 3],
    ["PURPLE", 2],
    ["PURPLE BRAND", 11],
    ["RAEY", 2],
    ["RAF SIMONS", 11],
    ["RAG & BONE", 13],
    ["RAILS", 5],
    ["RAKKÌ", 1],
    ["RALPH LAUREN", 146],
    ["RALPH LAUREN RRL", 1],
    ["RANDOM IDENTITIES", 1],
    ["RAPHA", 2],
    ["RASSVET", 3],
    ["RECTO", 1],
    ["REEBOK", 1],
    ["REGATTA", 24],
    ["REIGNING CHAMP", 5],
    ["REISS", 4],
    ["REMI RELIEF", 1],
    ["REPRESENT", 2],
    ["RHONE", 1],
    ["RHUDE", 2],
    ["RICHARD JAMES", 1],
    ["RICK OWENS", 20],
    ["RICK OWENS DRKSHDW", 7],
    ["RIVER ISLAND", 2],
    ["ROBERT GRAHAM", 21],
    ["ROBERTO CAVALLI", 1],
    ["ROBERTO COLLINA", 10],
    ["ROCHAMBEAU", 1],
    ["RODA", 1],
    ["RODD & GUNN", 20],
    ["ROKIT", 1],
    ["ROMEO HUNTE", 2],
    ["RRL", 2],
    ["SACAI", 8],
    ["SAINT JAMES", 1],
    ["SAINT LAURENT", 36],
    ["SAKS FIFTH AVENUE", 4],
    ["SALVATORE FERRAGAMO", 5],
    ["SALVATORE SANTORO", 5],
    ["SAM.", 1],
    ["SANDRO", 30],
    ["SANTORELLI", 1],
    ["SAPIO", 1],
    ["SARTORIO", 2],
    ["SAVE KHAKI UNITED", 1],
    ["SAVILE ROW CO", 1],
    ["SCHOTT", 3],
    ["SEASE", 5],
    ["SÉFR", 2],
    ["SHANGHAI TANG", 1],
    ["SLEEPHERO", 1],
    ["SNOW PEAK", 1],
    ["S.N.S. HERNING", 1],
    ["SOAR", 2],
    ["SONG FOR THE MUTE", 1],
    ["SOULLAND", 2],
    ["SOUL OF LONDON", 1],
    ["SSS WORLD CORP", 2],
    ["STELLA MCCARTNEY", 1],
    ["STEPHAN SCHNEIDER", 1],
    ["STEWART", 1],
    ["STONE ISLAND", 21],
    ["STONE ISLAND SHADOW PROJECT", 3],
    ["STUSSY", 3],
    ["STUTTERHEIM", 1],
    ["STYLAND", 1],
    ["SUNFLOWER", 2],
    ["SUNNEI", 1],
    ["SUNSPEL", 22],
    ["SUPERDRY", 1],
    ["SUPREME", 27],
    ["SWORD 6.6.44", 2],
    ["TAGLIATORE", 17],
    ["TAKESHY KUROSAWA", 3],
    ["TED BAKER", 8],
    ["TEKLA", 1],
    ["TELFAR", 1],
    ["TEN C", 4],
    ["THE ELDER STATESMAN", 1],
    ["THEGIGI", 1],
    ["THE GIGI", 1],
    ["THE MEN'S STORE AT BLOOMINGDALE'S", 1],
    ["THE NORTH FACE", 6],
    ["THEORY", 22],
    ["THE ROW", 5],
    ["THE SILTED COMPANY", 1],
    ["THE VIRIDI-ANNE", 1],
    ["THOMBROWNE", 1],
    ["THOM BROWNE", 175],
    ["THOM KROM", 1],
    ["THOM SWEENEY", 1],
    ["THROWBACK.", 3],
    ["TIGER OF SWEDEN", 2],
    ["TIMBERLAND", 1],
    ["TINTORIA MATTEI", 1],
    ["TOM FORD", 61],
    ["TOMMY HILFIGER", 17],
    ["TOMMY JEANS", 5],
    ["TOM SACHS", 5],
    ["TONELLO", 5],
    ["TOPMAN", 1],
    ["TRESPASS", 5],
    ["TRUE RELIGION", 1],
    ["TRU TRUSSARDI", 1],
    ["UGG", 2],
    ["UMA WANG", 4],
    ["UNDERCOVER", 20],
    ["UNDERCOVERISM", 1],
    ["UNION DENIM", 1],
    ["UNITED RIVERS", 1],
    ["UNITED STANDARD", 2],
    ["VALENTINO", 38],
    ["VANS", 8],
    ["VERSACE", 24],
    ["VERSACE JEANS COUTURE", 11],
    ["VETEMENTS", 3],
    ["VIKTOR & ROLF", 5],
    ["VILEBREQUIN", 12],
    ["VINCE", 4],
    ["VISION OF SUPER", 4],
    ["VISVIM", 1],
    ["VIVIENNE WESTWOOD", 3],
    ["WACKO MARIA", 2],
    ["WALES BONNER", 2],
    ["WE11 DONE", 11],
    ["WHITE MOUNTAINEERING", 2],
    ["WINDSOR", 1],
    ["WINNIE", 1],
    ["WINNIE NEW YORK", 1],
    ["WINNIE N.Y.C", 5],
    ["WOOD WOOD", 2],
    ["WOOLRICH", 13],
    ["WTAPS", 1],
    ["XACUS", 1],
    ["Y-3", 9],
    ["YANG LI", 1],
    ["YMC YOU MUST CREATE", 2],
    ["YOHJI YAMAMOTO", 2],
    ["YOUTHS IN BALACLAVA", 3],
    ["ZADIG & VOLTAIRE", 3],
    ["ZANONE", 27],
    ["ZEGNA", 3],
    ["ZELLA", 1],
    ["ZIGGY CHEN", 1],
    ["ZILVER", 1],
    ["ZIMMERLI", 2],
    ["Z ZEGNA", 5],
];

const femaleBrandsAvailable = [
    ["032C", 1],
    ["711", 1],
    ["10 CORSO COMO", 1],
    ["12 STOREEZ", 18],
    ["1.STATE", 8],
    ["24SEVEN COMFORT APPAREL", 6],
    ["3.1 PHILLIP LIM", 12],
    ["3X1", 6],
    ["525", 1],
    ["66 NORTH", 1],
    ["7 FOR ALL MANKIND", 27],
    ["90 DEGREE BY REFLEX", 1],
    ["9SEED", 1],
    ["ACNE STUDIOS", 27],
    ["A COLLECTIVE STORY", 1],
    ["ADAM LIPPES", 1],
    ["ADAM SELMAN SPORT", 5],
    ["ADIDAS BY STELLA MCCARTNEY", 8],
    ["ADIDAS ORIGINALS", 8],
    ["ADIDAS Y-3 YOHJI YAMAMOTO", 1],
    ["ADRIANNA PAPELL", 6],
    ["A.F.VANDEVORST", 2],
    ["AG", 16],
    ["AGENT PROVOCATEUR", 1],
    ["AGGI", 2],
    ["AGOLDE", 39],
    ["AIDAN MATTOX", 2],
    ["AKRIS", 14],
    ["AKRIS PUNTO", 15],
    ["ALAÏA", 11],
    ["ALALA", 3],
    ["ALANUI", 2],
    ["ALBERTA FERRETTI", 14],
    ["A.L.C", 6],
    ["ALCHEMY", 1],
    ["ALESSANDRA RICH", 7],
    ["ALEXA CHUNG", 5],
    ["ALEXANDER MCQUEEN", 26],
    ["ALEXANDER WANG", 21],
    ["ALEXANDER WANG T", 7],
    ["ALEXANDRE VAUTHIER", 9],
    ["ALEX EVENINGS", 13],
    ["ALEXIA ADMOR", 11],
    ["ALEXIS", 1],
    ["ALEX MILL", 1],
    ["ALFANI", 1],
    ["ALFRED SUNG", 2],
    ["ALICE AND OLIVIA", 17],
    ["ALICE MCCALL", 1],
    ["ALI & JAY", 1],
    ["ALIX NYC", 2],
    ["ALLSAINTS", 3],
    ["ALMOST FAMOUS", 3],
    ["ALO YOGA", 25],
    ["ALPHA INDUSTRIES", 1],
    ["ALTUZARRA", 2],
    ["ALYSI", 2],
    ["ALYX", 3],
    ["AMANDA UPRICHARD", 14],
    ["AMAPÔ", 4],
    ["AMBUSH", 6],
    ["AMEN", 2],
    ["AMENDI", 4],
    ["AMI ALEXANDRE MATTIUSSI", 15],
    ["AMI AMALIA", 2],
    ["AMIRI", 1],
    ["AMIR SLAMA", 4],
    ["AMO", 2],
    ["AMO DENIM", 1],
    ["AMSALE", 2],
    ["AMUR", 2],
    ["ANATOMIE", 7],
    ["ANDREÄDAMO", 1],
    ["ANDREA IYAMAH", 1],
    ["ANDREA YA'AQOV", 1],
    ["ANGELA MELE MILANO", 1],
    ["ANGEL MATERNITY", 1],
    ["ANINE BING", 10],
    ["ANNA KAY", 1],
    ["ANNA NOOSHIN X NA-KD", 1],
    ["ANN DEMEULEMEESTER", 8],
    ["ANNE COLE", 1],
    ["ANNE KLEIN", 3],
    ["ANOTHER TOMORROW", 2],
    ["ANTHROPOLOGIE", 1],
    ["ANTONELLI", 1],
    ["A.P.C.", 14],
    ["A PEA IN THE POD", 1],
    ["APPARIS", 4],
    ["AQUA", 1],
    ["AREA", 1],
    ["ARIES", 2],
    ["ARMA", 2],
    ["ARMANI COLLEZIONI", 1],
    ["ARMANI EXCHANGE", 19],
    ["ARMARIUM", 1],
    ["AS BY DF", 1],
    ["ASCENO", 3],
    ["ASHISH", 2],
    ["ASOS DESIGN", 9],
    ["ASOS TALL", 1],
    ["ASPESI", 9],
    ["ASTR", 2],
    ["ATLEIN", 2],
    ["ATM ANTHONY THOMAS MELILLO", 8],
    ["ATOIR", 1],
    ["ATTICO", 8],
    ["ATU BODY COUTURE", 2],
    ["AUGUSTE", 1],
    ["AVANT TOI", 1],
    ["AVIATOR NATION", 4],
    ["AXEL ARIGATO", 1],
    ["AYNI", 1],
    ["AZ FACTORY", 1],
    ["AZZI & OSTA", 1],
    ["B+AB", 1],
    ["BADGLEY MISCHKA", 4],
    ["BAILEY44", 1],
    ["BALENCIAGA", 29],
    ["BALLANTYNE", 2],
    ["BALLY", 1],
    ["BALMAIN", 35],
    ["BAOBAB", 2],
    ["BAPY BY *A BATHING APE®", 3],
    ["BARBOUR", 3],
    ["BARBOUR INTERNATIONAL", 1],
    ["BARDOT", 5],
    ["BAREFOOT DREAMS", 3],
    ["BARE NECESSITIES", 1],
    ["BAR III", 2],
    ["BARRIE", 28],
    ["BARROW", 1],
    ["BASERANGE", 1],
    ["BAUM UND PFERDGARTEN", 4],
    ["BB DAKOTA", 1],
    ["BB DAKOTA BY STEVE MADDEN", 2],
    ["BCBGMAXAZRIA", 3],
    ["BEACHLUNCHLOUNGE", 3],
    ["BEACH RIOT", 1],
    ["BEBE", 1],
    ["BEDHEAD PAJAMAS", 1],
    ["BELLY BANDIT", 1],
    ["BELSTAFF", 1],
    ["BENEDETTA BRUZZICHES", 1],
    ["BEN TAVERNITI UNRAVEL PROJECT", 5],
    ["BERSHKA", 3],
    ["BETSY & ADAM", 12],
    ["BEYOND YOGA", 9],
    ["BHLDN", 1],
    ["BIBHU MOHAPATRA", 1],
    ["BLACK HALO", 14],
    ["BLACKYOTO", 2],
    ["BLANCA VITA", 11],
    ["BLANC NOIR", 3],
    ["BLANKNYC", 46],
    ["BLAZÉ MILANO", 3],
    ["BLK DNM", 1],
    ["BLUE REVIVAL", 2],
    ["BLUGIRL", 1],
    ["BLUMARINE", 3],
    ["BOBI", 4],
    ["BOHO ME", 1],
    ["BORGO DE NOR", 1],
    ["BOTTEGA VENETA", 13],
    ["BOUTIQUE MOSCHINO", 10],
    ["BOYISH", 2],
    ["BOYISH JEANS", 1],
    ["BRAVA FABRICS", 1],
    ["BRIGITTE", 2],
    ["BROCK COLLECTION", 1],
    ["BRONX AND BANCO", 7],
    ["BRUNELLO CUCINELLI", 46],
    ["B SIDES", 1],
    ["BUBISH", 1],
    ["BULLY", 1],
    ["BURBERRY", 50],
    ["BUSAYO", 1],
    ["BY THE WAY.", 5],
    ["CAALO", 1],
    ["CABALLERO", 1],
    ["CALÉ", 1],
    ["CALLAHAN", 1],
    ["CALVIN KLEIN", 18],
    ["CALVIN KLEIN 205W39NYC", 5],
    ["CALVIN KLEIN JEANS EST.1978", 2],
    ["CAMILA COELHO", 6],
    ["CAMILLA", 1],
    ["CAMI NYC", 2],
    ["CANADA GOOSE", 17],
    ["CANESSA", 2],
    ["CARHARTT", 2],
    ["CAROLINA HERRERA", 4],
    ["CAROLINE CONSTAS", 1],
    ["CASABLANCA", 2],
    ["CASHMERE IN LOVE", 8],
    ["CASLON", 2],
    ["CASLONR", 1],
    ["C&C CALIFORNIA", 1],
    ["CECE", 5],
    ["CECILIE BAHNSEN", 3],
    ["CHAMPION", 1],
    ["CHARLES HENRY", 1],
    ["CHARO RUIZ", 6],
    ["CHARTER CLUB", 1],
    ["CHAUS", 2],
    ["CHIARA BONI LA PETITE ROBE", 2],
    ["CHIARA FERRAGNI", 5],
    ["CHINATOWN MARKET", 2],
    ["CHINTI & PARKER", 1],
    ["CHLOÉ", 19],
    ["CHLOÉ B X NA-KD", 2],
    ["CHOCOOLATE", 1],
    ["CHRISTIAN WIJNANTS", 2],
    ["CHRISTOPHER ESBER", 2],
    ["CHRISTOPHER KANE", 3],
    ["CHUFY", 1],
    ["CINQ À SEPT", 7],
    ["CIRCOLO 1901", 1],
    ["CITIZENS OF HUMANITY", 35],
    ["CLAUDIE PIERLOT", 35],
    ["CLOSED", 3],
    ["CLOSET LONDON", 2],
    ["CLUB MONACO", 1],
    ["CO", 5],
    ["COCO CLOUDE", 1],
    ["COLE HAAN", 1],
    ["COLLINA STRADA", 1],
    ["COLMAR", 1],
    ["COLVILLE", 1],
    ["COMMANDO", 9],
    ["COMME DES GARÇONS", 5],
    ["COMME DES GARÇONS COMME DES GARÇONS", 3],
    ["COMME DES GARCONS GIRL", 1],
    ["COMME DES GARÇONS PLAY", 60],
    ["COMMISSION", 1],
    ["COMMISSION NYC", 1],
    ["CONCEPTO", 1],
    ["CONNECTED", 4],
    ["COPERNI", 6],
    ["CORMIO", 1],
    ["COSABELLA", 2],
    ["COSTARELLOS", 2],
    ["COTTON CITIZEN", 8],
    ["COURRGES", 8],
    ["COURT & ROWE", 1],
    ["CURATED STYLES", 1],
    ["CURRENT ELLIOTT", 3],
    ["CUSHNIE", 2],
    ["CUSHNIE ET OCHS", 1],
    ["CYNTHIA ROWLEY", 2],
    ["DANIELLE GUIZIO", 2],
    ["DANNIJO", 1],
    ["DANSKIN", 1],
    ["DAVID KOMA", 1],
    ["DAWN LEVY", 2],
    ["DEAR MOON", 1],
    ["DEPARTMENT 5", 1],
    ["DESMOND & DEMPSEY", 1],
    ["DEVEAUX", 1],
    ["D.EXTERIOR", 2],
    ["DIANE VON FURSTENBERG", 7],
    ["DICE KAYEK", 1],
    ["DIESEL", 6],
    ["DILARA X NA-KD", 1],
    ["DION LEE", 17],
    ["DKNY", 10],
    ["DL", 1],
    ["DL1961", 2],
    ["DODO BAR OR", 3],
    ["DOLCE E GABBANA", 1],
    ["DOLCE & GABBANA", 65],
    ["DOLLINA", 1],
    ["DONDUP", 13],
    ["DONNA KARAN", 1],
    ["DOROTHEE SCHUMACHER", 7],
    ["DOROTHY PERKIN", 4],
    ["DOUBLE RL", 5],
    ["DOUUOD", 1],
    ["DR. DENIM", 1],
    ["DRESS THE POPULATION", 37],
    ["DRIES VAN NOTEN", 2],
    ["DROME", 1],
    ["DSQUARED2", 47],
    ["DUETTENYC", 1],
    ["DUNDAS X REVOLVE", 1],
    ["EA7", 2],
    ["EBERJEY", 7],
    ["ECRU DESIGNS", 22],
    ["ÉDITIONS M.R", 1],
    ["EILEEN FISHER", 13],
    ["EILEEN WEST", 4],
    ["ELAN", 2],
    ["ELECTRIC YOGA", 1],
    ["ELEVENTY", 1],
    ["ELIE TAHARI", 3],
    ["ELISABETTA FRANCHI", 1],
    ["ELIZA J", 9],
    ["ELLERY", 1],
    ["ELLIATT", 5],
    ["EMILIA GEORGE", 1],
    ["EMILIA WICKSTEAD", 1],
    ["EMILIO PUCCI", 2],
    ["EMORY PARK", 1],
    ["EMPORIO ARMANI", 17],
    ["ENDLESS ROSE", 1],
    ["ENGLISH FACTORY", 10],
    ["ENTIRE STUDIOS", 1],
    ["ENZA COSTA", 7],
    ["EQUIPMENT", 21],
    ["ERDEM", 3],
    ["ERES", 7],
    ["ERIN SNOW", 1],
    ["ERMANNO FIRENZE", 4],
    ["ERMANNO SCERVINO", 2],
    ["ERNEST LEOTY", 1],
    ["ESKANDAR", 3],
    ["ESSENTIALS", 1],
    ["ÉTERNE", 1],
    ["ETRO", 18],
    ["EUROPEAN CULTURE", 1],
    ["EVERLY GREY", 1],
    ["EVERYDAY RITUAL", 3],
    ["EXTREME CASHMERE", 5],
    ["FABIANA FILIPPI", 22],
    ["FAHERTY", 2],
    ["FAITH CONNEXION", 1],
    ["FAITHFULL THE BRAND", 2],
    ["FANNIE SCHIAVONI", 1],
    ["FAUSTO PUGLISI", 1],
    ["FAVORITE DAUGHTER", 3],
    ["FAY", 6],
    ["FEDERICA TOSI", 2],
    ["FENDI", 4],
    ["FENTY X PUMA", 1],
    ["FERRARI", 3],
    ["FIDELITY DENIM", 1],
    ["FILA", 2],
    ["FILIPPA K", 10],
    ["FILIPPA-K SOFT SPORT", 1],
    ["FIORUCCI", 5],
    ["FLEUR DU MAL", 2],
    ["FLORA NIKROOZ", 4],
    ["FOCUS BY SHANI", 2],
    ["FOR LOVE & LEMONS", 1],
    ["FORTE FORTE", 2],
    ["FP MOVEMENT BY FREE PEOPLE", 2],
    ["FRAICHE BY J", 2],
    ["FRAME", 46],
    ["FRANCESCO SCOGNAMILIO", 1],
    ["FRANK & EILEEN", 4],
    ["FREDDY WR.UP®", 1],
    ["FREE PEOPLE", 15],
    ["FREE THE ROSES", 1],
    ["FRENCH CONNECTION", 5],
    ["GABRIELA HEARST", 2],
    ["GALVAN", 5],
    ["GANNI", 27],
    ["GANT", 1],
    ["GATTI NOLLI BY MARWAN", 9],
    ["GAUCHÈRE", 2],
    ["GAUGE81", 4],
    ["GCDS", 4],
    ["GEMY MAALOUF", 7],
    ["GENNY", 3],
    ["GENTRY PORTOFINO", 1],
    ["GEORGES HOBEIKA", 4],
    ["GIAMBATTISTA VALLI", 4],
    ["GIANLUCA CAPANNOLO", 1],
    ["GILDA & PEARL", 4],
    ["GIL RODRIGUEZ", 1],
    ["GIORGIO ARMANI", 3],
    ["GIRLFRIEND COLLECTIVE", 14],
    ["GIUSEPPE ZANOTTI", 3],
    ["GIVENCHY", 27],
    ["G. LABEL", 1],
    ["GLORIA COELHO", 4],
    ["GO COUTURE", 1],
    ["GOEN J", 2],
    ["GOLDEN GOOSE", 7],
    ["GOLDSIGN", 3],
    ["GOOD AMERICAN", 1],
    ["GOODBOY", 1],
    ["GOOSETECH", 1],
    ["GREY LAB", 2],
    ["GRLFRND", 5],
    ["GUCCI", 11],
    ["GUESS", 2],
    ["GUESS FACTORY", 1],
    ["GUESS USA", 2],
    ["HAIDER ACKERMANN", 8],
    ["HAIKURE", 4],
    ["HALOGEN", 1],
    ["HALPERN", 1],
    ["HALSTON", 2],
    ["HALSTON HERITAGE", 1],
    ["HANEY", 1],
    ["HANRO", 20],
    ["HARRIS WHARF LONDON", 1],
    ["HARSHMAN", 2],
    ["HATCH", 4],
    ["HEBE STUDIO", 1],
    ["HEIDI KLEIN", 2],
    ["HELMUT LANG", 19],
    ["HEMANT & NANDITA", 2],
    ["HENRIK VIBSKOV", 1],
    ["HERNO", 17],
    ["HERON PRESTON", 2],
    ["HERVE LEGER", 10],
    ["HEURUEH", 1],
    ["HOLZWEILER", 2],
    ["HOMEBODII", 1],
    ["HONEYDEW INTIMATES", 3],
    ["H:OURS", 4],
    ["HOUSE OF HARLOW 1960", 8],
    ["HUDSON", 5],
    ["HUDSON PARK COLLECTION", 1],
    ["HUE", 3],
    ["HUGO BOSS", 8],
    ["HUNZA G", 2],
    ["ICON DENIM", 2],
    ["IEENA FOR MAC DUGGAL", 6],
    ["IENKI IENKI", 2],
    ["INC INTERNATIONAL CONCEPTS", 1],
    ["INCOTEX", 1],
    ["INDAH", 1],
    ["INGRID & ISABEL", 3],
    ["INGRID & ISABELR", 1],
    ["IN THE MOOD FOR LOVE", 1],
    ["IOANA CIOLACU", 6],
    ["IRO", 12],
    ["ISAAC SELLAM", 1],
    ["ISA ARFEN", 2],
    ["ISABEL MARANT", 25],
    ["ISABEL MARANT ÉTOILE", 20],
    ["ISABEL SANCHIS", 7],
    ["ISSEY MIYAKE", 4],
    ["JACOB COHEN", 2],
    ["JACQUEMUS", 4],
    ["JAG", 1],
    ["JAG JEANS", 1],
    ["JAMES PERSE", 5],
    ["JARDIN DES ORANGERS", 4],
    ["JASON WU", 1],
    ["J BRAND", 10],
    ["JEJIA", 1],
    ["JEN7", 1],
    ["JEN7 BY 7 FOR ALL MANKIND", 3],
    ["JENNY PACKHAM", 1],
    ["JESSICA HOWARD", 2],
    ["JET SET", 1],
    ["JIL SANDER", 31],
    ["JOHANNA ORTIZ", 1],
    ["JOHN ELLIOTT", 10],
    ["JOHNNY WAS", 1],
    ["JOHN RICHMOND", 1],
    ["JOHNSTONS OF ELGIN", 1],
    ["JOIE", 2],
    ["JONATHAN SIMKHAI", 10],
    ["JONATHAN SIMKHAI STANDARD", 1],
    ["JORDAN", 1],
    ["JOSEPH", 26],
    ["JOSHUA SANDERS", 1],
    ["JOSIE", 7],
    ["JOSIE NATORI", 14],
    ["JOURNELLE", 5],
    ["JOVONNA", 1],
    ["JUICY COUTURE", 1],
    ["JULIA JORDAN", 1],
    ["JUMP APPAREL", 1],
    ["JUNK FOOD", 1],
    ["JUNYA WATANABE", 4],
    ["JUST BEE QUEEN", 1],
    ["JUST CAVALLI", 8],
    ["JW ANDERSON", 4],
    ["KALITA", 1],
    ["KAPPA", 1],
    ["KAREN KANE", 14],
    ["KARL LAGERFELD", 21],
    ["KASPER", 1],
    ["KATE SPADE", 6],
    ["KATIE MAY", 6],
    ["KENSIE", 4],
    ["KENZO", 22],
    ["KHAITE", 9],
    ["KHOON HOOI", 2],
    ["KIKI DE MONTPARNASSE", 5],
    ["KIMI AND KAI", 2],
    ["KIM SHUI", 1],
    ["KIRIN", 3],
    ["KIYONNA", 3],
    ["KOBI HALPERIN", 6],
    ["KOCHÉ", 3],
    ["KOLOR", 1],
    ["KORAL", 7],
    ["KRISA", 4],
    ["KSUBI", 3],
    ["KULE", 1],
    ["K-WAY", 2],
    ["L'ACADEMIE", 10],
    ["LACOSTE", 2],
    ["LA DETRESSE", 1],
    ["LA DOUBLEJ", 17],
    ["LAFAYETTE 148", 20],
    ["LA FEMME", 12],
    ["L AGENCE", 32],
    ["LA MADE", 1],
    ["LAMARQUE", 2],
    ["LANEUS", 1],
    ["LANSTON", 1],
    ["LANSTON SPORT", 12],
    ["LANVIN", 2],
    ["LA PERLA", 3],
    ["LAUNDRY BY SHELLI SEGAL", 1],
    ["LAUREN PETITE", 1],
    ["LAUREN RALPH LAUREN", 32],
    ["L'AUTRE CHOSE", 1],
    ["LAZYPANTS", 2],
    ["LBLC THE LABEL", 3],
    ["LEE MATHEWS", 2],
    ["LE JEAN", 1],
    ["LELA ROSE", 2],
    ["LEMAIRE", 6],
    ["LEON EMANUEL BLANCK", 4],
    ["LE RÉUSSI", 1],
    ["LESET", 3],
    ["LES GIRLS LES BOYS", 1],
    ["LES RÊVERIES", 1],
    ["LES TIEN", 4],
    ["LEVI'S", 34],
    ["LHD", 13],
    ["LIBERTINE", 2],
    ["LIKELY", 7],
    ["LILLY PULITZER", 7],
    ["LILLY PULITZERR", 2],
    ["LINE & DOT", 2],
    ["LISA MARIE FERNANDEZ", 2],
    ["LITKOVSKAYA", 1],
    ["LITTLE MISTRESS", 1],
    ["LIU •JO", 12],
    ["LIVE THE PROCESS", 13],
    ["LNA", 2],
    ["LOEWE", 8],
    ["LOLA JEANS", 5],
    ["LONDON TIMES", 2],
    ["LORENA ANTONIAZZI", 7],
    ["LORO PIANA", 10],
    ["LOST + WANDER", 1],
    ["LOUISA BALLOU", 1],
    ["LOULOU STUDIO", 5],
    ["LOVEAPPELLA", 3],
    ["LOVE BY DESIGN", 1],
    ["LOVE MOSCHINO", 4],
    ["LOVERS & FRIENDS", 43],
    ["LOVESHACKFANCY", 12],
    ["LOW CLASSIC", 2],
    ["LPA", 4],
    ["L*SPACE", 6],
    ["LUCKY BRAND", 2],
    ["LUISA CERANO", 2],
    ["LULULEMON", 117],
    ["LULUS", 1],
    ["LUSH", 1],
    ["LVHR", 1],
    ["LVIR", 1],
    ["LYGIA & NANNY", 21],
    ["MAC DUGGAL", 18],
    ["MACGRAW", 3],
    ["MACKAGE", 8],
    ["MACKINTOSH", 1],
    ["MADELEINE THOMPSON", 1],
    ["MADEWELL", 5],
    ["MAEVE", 1],
    ["MAGALI PASCAL", 1],
    ["MAGDA BUTRYM", 4],
    ["MAGGY LONDON", 3],
    ["MAISIE WILEN", 4],
    ["MAISON BOHEMIQUE", 1],
    ["MAISON KITSUNÉ", 13],
    ["MAISON MARGIELA", 17],
    ["MAISON RABIH KAYROUZ", 4],
    ["MAJE", 41],
    ["MAJESTIC", 11],
    ["MAJORELLE", 14],
    ["MANGO", 1],
    ["MANOKHI", 6],
    ["MANTARAY", 2],
    ["MANUEL RITZ", 3],
    ["MANZONI 24", 1],
    ["MARA HOFFMAN", 1],
    ["MARANT ETOILE", 1],
    ["MARCELO BURLON COUNTY OF MILAN", 1],
    ["MARCHESA", 12],
    ["MARCHESA NOTTE", 12],
    ["MARC JACOBS", 6],
    ["MARC NEW YORK", 3],
    ["MARCO RAMBALDI", 2],
    ["MARCUS ADLER", 2],
    ["MARINA", 1],
    ["MARINA RINALDI", 2],
    ["MARINE SERRE", 5],
    ["MARISSA WEBB", 2],
    ["MARNI", 11],
    ["MARQUES' ALMEIDA", 5],
    ["MARTHA MEDEIROS", 1],
    ["MARYAM NASSIR ZADEH", 1],
    ["MARY KATRANTZOU", 1],
    ["MARYSIA", 1],
    ["MATERNAL AMERICA", 1],
    ["MATHILDE GØHLER X NA-KD", 2],
    ["MATICEVSKI", 1],
    ["MAURO GRIFONI", 1],
    ["MAVI JEANS", 3],
    ["MAX MARA", 16],
    ["MAX & MOI", 1],
    ["MC2 SAINT BARTH", 1],
    ["MCQ BY ALEXANDER MCQUEEN", 4],
    ["MELISSA ODABASH", 5],
    ["MELROSE AND MARKET", 1],
    ["MERLETTE", 2],
    ["MICHAEL COSTELLO", 8],
    ["MICHAEL KORS", 4],
    ["MICHAEL LAUREN", 9],
    ["MICHAEL MICHAEL KORS", 6],
    ["MICHAEL STARS", 9],
    ["MICHELLE MASON", 4],
    ["MILLY", 13],
    ["MINIMUM", 1],
    ["MIRA MIKATI", 1],
    ["MISBHV", 5],
    ["MISHA COLLECTION", 1],
    ["MISOOK", 7],
    ["MISSONI", 1],
    ["MIU MIU", 14],
    ["ML MONIQUE LHUILLIER", 3],
    ["MM6 MAISON MARGIELA", 13],
    ["M.M.LAFLEUR", 16],
    ["MONCLER", 19],
    ["MONCLER GENIUS", 6],
    ["MONCLER GRENOBLE", 1],
    ["MONROW", 8],
    ["MONSE", 1],
    ["MOORER", 1],
    ["MORE TO COME", 1],
    ["MOSCHINO", 33],
    ["MOTHER", 20],
    ["MOTHERHOOD MATERNITY", 1],
    ["MOTHER OF ALL", 1],
    ["MOUSSY", 1],
    ["MOUSSY VINTAGE", 5],
    ["MRZ", 2],
    ["MSGM", 24],
    ["MSK", 1],
    ["MUGLER", 6],
    ["N°21", 6],
    ["NA-KD", 403],
    ["NA-KD CLASSIC", 23],
    ["NA-KD REBORN", 34],
    ["NA-KD URBAN", 1],
    ["NANUSHKA", 12],
    ["NATALIE MARTIN", 1],
    ["NATASHA ZINKO", 3],
    ["NATORI", 84],
    ["NBD", 18],
    ["NEIL BARRETT", 1],
    ["NEW YORK AND COMPANY", 3],
    ["NIC AND ZOE", 1],
    ["NIC AND ZOE PETITES", 1],
    ["NICCOLÒ PASQUALETTI", 1],
    ["NICHOLAS", 2],
    ["NIC + ZOE", 5],
    ["NIKE", 29],
    ["NILI LOTAN", 12],
    ["NINA LEONARD", 1],
    ["NINA RICCI", 2],
    ["NISSA", 3],
    ["N NATORI", 27],
    ["NOAM", 1],
    ["NOBODY DENIM", 1],
    ["NOIR KEI NINOMIYA", 1],
    ["NOISY MAY TALL", 1],
    ["NO KA'OI", 3],
    ["NOM MATERNITY", 2],
    ["NOOKIE", 9],
    ["NORBA", 1],
    ["NORMA KAMALI", 27],
    ["NOTHING WRITTEN", 1],
    ["N.PEAL", 12],
    ["N:PHILANTHROPY", 1],
    ["NY COLLECTION", 3],
    ["NYDJ", 32],
    ["O'BLANC", 2],
    ["OFF-WHITE", 31],
    ["OLIPHANT", 1],
    ["OLIVIA RUBIN", 1],
    ["OLIVIA VON HALLE", 2],
    ["OLYMPIAH", 20],
    ["ONE33 SOCIAL", 4],
    ["ONEFIFTEEN", 2],
    ["ON GOSSAMER", 1],
    ["ONIA", 1],
    ["ONLY HEARTS", 1],
    ["ONZIE", 1],
    ["OPENING CEREMONY", 6],
    ["OPERASPORT", 1],
    ["OSCAR DE LA RENTA", 8],
    ["OSKLEN", 2],
    ["OTTOLINGER", 1],
    ["OUTDOOR VOICES", 1],
    ["PACO RABANNE", 11],
    ["PAIGE", 27],
    ["PALM ANGELS", 13],
    ["PAMELAXNAKD", 1],
    ["PAMELA X NA-KD", 4],
    ["PAPILLON", 1],
    ["PAPINELLE", 1],
    ["PARAJUMPERS", 3],
    ["P.A.R.O.S.H.", 19],
    ["PAS NORMAL STUDIOS", 2],
    ["PATOU", 3],
    ["PATRIZIA PEPE", 3],
    ["PAULA CANOVAS DEL VAS", 1],
    ["PAUL SMITH", 2],
    ["PEIXOTO", 3],
    ["P.E NATION", 4],
    ["PERFECT MOMENT", 7],
    ["PESERICO", 17],
    ["PETAR PETROV", 1],
    ["PETER DO", 2],
    ["PETER PILOTTO", 1],
    ["PHILIPP PLEIN", 38],
    ["PHILOSOPHY", 1],
    ["PHILOSOPHY APPAREL", 1],
    ["PHILOSOPHY DI LORENZO SERAFINI", 11],
    ["PIAZZA SEMPIONE", 1],
    ["PIECE OF WHITE", 1],
    ["PIERRE-LOUIS MASCIA", 1],
    ["PIETRO BRUNELLI", 1],
    ["PINKO", 11],
    ["PISTOLA", 3],
    ["PITUSA", 2],
    ["P JAMAS", 2],
    ["PLAN C", 4],
    ["PLUSH", 1],
    ["POLO RALPH LAUREN", 15],
    ["PORTS 1961", 2],
    ["PORTS V", 1],
    ["POSTER GIRL", 1],
    ["POUPETTE ST BARTH", 7],
    ["PRADA", 38],
    ["PREEN BY THORNTON BREGAZZI", 2],
    ["PRINCE PETER COLLECTION", 1],
    ["PRINCIPLES", 2],
    ["PROENZA SCHOULER", 17],
    ["PROENZA SCHOULER WHITE LABEL", 9],
    ["PROSPERITY DENIM", 1],
    ["PS BY PAUL SMITH", 8],
    ["PUBLIC REC", 3],
    ["PUMA", 1],
    ["PUSHBUTTON", 1],
    ["QUEEN OF JETLAGS X NA-KD", 3],
    ["QUIZ", 1],
    ["R13", 15],
    ["RACHEL GILBERT", 1],
    ["RACHEL RACHEL ROY", 1],
    ["RACIL", 1],
    ["RAEY", 6],
    ["RAG & BONE", 24],
    ["RAILS", 5],
    ["RALPH LAUREN", 50],
    ["RAMY BROOK", 7],
    ["RAQUETTE", 1],
    ["RASARIO", 2],
    ["REBECCA MINKOFF", 1],
    ["REBECCA TAYLOR", 1],
    ["REBECCA VALLANCE", 2],
    ["REBODY", 4],
    ["RECREATIONAL HABITS", 1],
    ["RECTO", 1],
    ["RE/DONE", 23],
    ["RE/DONE BY LEVI'S", 1],
    ["RED VALENTINO", 9],
    ["REEBOK", 1],
    ["REFORMATION", 7],
    ["REGATTA", 8],
    ["REISS", 3],
    ["REMAIN", 3],
    ["RE ONA", 1],
    ["RESA", 1],
    ["RETROFÉTE", 10],
    ["RICHARD QUINN", 1],
    ["RICHER POORER", 1],
    ["RICKIE FREEMAN FOR TERI JON", 2],
    ["RICK OWENS", 20],
    ["RICK OWENS DRKSHDW", 3],
    ["RICK OWENS X CHAMPION", 1],
    ["RILEY & RAE", 2],
    ["RIP CURL", 2],
    ["RIVER ISLAND", 1],
    ["RIVET UTILITY", 3],
    ["RIXO LONDON", 3],
    ["R & M RICHARDS", 6],
    ["ROBERTO CAVALLI", 2],
    ["ROCOCO SAND", 1],
    ["RODARTE", 1],
    ["RODEBJER", 1],
    ["ROKH", 1],
    ["ROKSANDA", 1],
    ["ROLAND MOURET", 5],
    ["ROLLA'S", 1],
    ["ROLLER RABBIT", 2],
    ["ROSEANNA", 1],
    ["ROSETTA GETTY", 4],
    ["ROSIE ASSOULIN", 2],
    ["ROTATE BIRGER CHRISTENSEN", 1],
    ["ROXY", 2],
    ["ROYAL ROBBINS", 1],
    ["RRD", 1],
    ["RRD - ROBERTO RICCI DESIGN", 1],
    ["RTA", 4],
    ["RUT & CIRCLE", 7],
    ["RVNG COUTURE", 2],
    ["RYDER", 1],
    ["SABINA MUSAYEV", 1],
    ["SACAI", 4],
    ["SACHIN & BABI", 4],
    ["SAIID KOBEISY", 4],
    ["SAINT LAURENT", 46],
    ["SAKS POTTS", 2],
    ["SALONI", 1],
    ["SALVATORE FERRAGAMO", 5],
    ["SALVATORE SANTORO", 4],
    ["SAM.", 1],
    ["SAMI MIRO VINTAGE", 1],
    ["SAMSOE & SAMSOE", 1],
    ["SANCTUARY", 2],
    ["SANDRO", 52],
    ["SANDY LIANG", 1],
    ["SANKUANZ", 1],
    ["SAPIO", 2],
    ["SÀPOPA", 1],
    ["SARA BATTAGLIA", 1],
    ["SAU LEE", 3],
    ["SAVE THE DUCK", 2],
    ["SAYLOR", 1],
    ["SEA", 2],
    ["SEAFOLLY", 3],
    ["SEE BY CHLOÉ", 7],
    ["SELF-PORTRAIT", 1],
    ["SEMICOUTURE", 1],
    ["SENTALER", 2],
    ["SERAPH SIN", 4],
    ["SER.O.YA", 1],
    ["SHANI", 26],
    ["SHIATZY CHEN", 1],
    ["SHO", 1],
    ["SHONA JOY", 1],
    ["SHOSHANNA", 1],
    ["SHOW ME YOUR MUMU", 2],
    ["SHUSHU-TONG", 2],
    ["SIES MARJAN", 4],
    ["SILVER JEANS CO.", 1],
    ["SIMKHAI", 1],
    ["SIMONE ROCHA", 1],
    ["SIMON MILLER", 1],
    ["SIR", 1],
    ["SKIMS", 5],
    ["SKIN", 7],
    ["SLEEPER", 1],
    ["SL FASHIONS", 13],
    ["SLNY", 3],
    ["SLVRLAKE", 13],
    ["'S MAX MARA", 4],
    ["SMYTHE", 1],
    ["SNDYS", 2],
    ["SNIDER", 1],
    ["SOFIE D'HOORE", 2],
    ["SOLID & STRIPED", 2],
    ["SONG OF STYLE", 2],
    ["SONIA RYKIEL", 1],
    ["SOTTOMETTIMI", 5],
    ["SPANX", 5],
    ["SPLENDID", 5],
    ["SPLITS59", 3],
    ["SPORT B. BY AGNÈS B.", 2],
    ["SPORTMAX", 3],
    ["SPORTY AND RICH", 3],
    ["SPRWMN", 5],
    ["SSENSE WORKS", 1],
    ["STAND STUDIO", 5],
    ["STAUD", 5],
    ["STEFANO DE LELLIS", 2],
    ["STELLA MCCARTNEY", 51],
    ["STÉPHANIE DURANT X NA-KD", 2],
    ["STILL HERE", 1],
    ["STITCHES & STRIPES", 1],
    ["ST. JOHN", 13],
    ["STOULS", 2],
    ["STOWAWAY COLLECTION", 2],
    ["STUDIO NICHOLSON", 4],
    ["STUDIO TOMBOY", 2],
    ["STUSSY", 1],
    ["STYLAND", 9],
    ["STYLE & CO", 1],
    ["SUPERDOWN", 62],
    ["SUPPLIES BY UNIONBAY", 2],
    ["SUSANA MONACO", 12],
    ["SWEATY BETTY", 1],
    ["SWORD 6.6.44", 1],
    ["SYSTEM", 2],
    ["TADASHI SHOJI", 8],
    ["TAGLIATORE", 1],
    ["TANYA TAYLOR", 1],
    ["TED BAKER", 1],
    ["TEKLA", 2],
    ["TEMPERLEY LONDON", 1],
    ["TEREZ", 1],
    ["THE BAR", 1],
    ["THE ELDER STATESMAN", 1],
    ["THE FASHION FRACTION X NA-KD", 1],
    ["THE FRANKIE SHOP", 3],
    ["THE GREAT", 2],
    ["THEIA", 3],
    ["THE KOOPLES", 1],
    ["THE LINE BY K", 1],
    ["THEO AND SPENCE", 1],
    ["THEORY", 22],
    ["THE ROW", 28],
    ["THE UPSIDE", 9],
    ["THE WEBSTER", 1],
    ["THOM BROWNE", 96],
    ["THREE GRACES LONDON", 1],
    ["THROWBACK.", 1],
    ["TIBI", 2],
    ["TOCCIN", 3],
    ["TOM FORD", 19],
    ["TOMMY BAHAMA", 1],
    ["TOMMY HILFIGER", 16],
    ["TOM SACHS", 5],
    ["TONY WARD", 2],
    ["TOPSHOP", 4],
    ["TORY BURCH", 8],
    ["TORY SPORT", 7],
    ["TOTÊME", 17],
    ["TRENDYOL", 13],
    ["TRESPASS", 7],
    ["TRIARCHY", 2],
    ["TRINA TURK", 2],
    ["T TAHARI", 2],
    ["TTSWTRS", 1],
    ["TULAROSA", 6],
    ["TWINSET", 7],
    ["UGG", 5],
    ["ULLA JOHNSON", 8],
    ["ULTRACOR", 3],
    ["UMA RAQUEL DAVIDOWICZ", 1],
    ["UNDER ARMOUR", 1],
    ["UNDERCOVER", 2],
    ["UNREAL FUR", 1],
    ["UP TO BE", 1],
    ["VALENTINO", 17],
    ["VANESSA BRUNO", 1],
    ["VANS", 1],
    ["VARLEY", 7],
    ["VELVET", 18],
    ["VELVET TORCH", 1],
    ["VERO MODA", 1],
    ["VERONICA BEARD", 27],
    ["VERONICA BEARD JEANS", 2],
    ["VERSACE", 17],
    ["VERSACE JEANS", 1],
    ["VERSACE JEANS COUTURE", 7],
    ["VETEMENTS", 7],
    ["VICI COLLECTION", 1],
    ["VICTORIA BECKHAM", 5],
    ["VICTORIA VICTORIA BECKHAM", 2],
    ["VINCE", 18],
    ["VINCE CAMUTO", 10],
    ["VINEYARD VINES", 1],
    ["VIRGOS LOUNGE", 1],
    ["VISVIM", 1],
    ["VITAMIN A", 1],
    ["VIVIENNE WESTWOOD", 3],
    ["VOICE OF INSIDERS", 1],
    ["VOZ", 1],
    ["WACOAL", 2],
    ["WAIMARI", 1],
    ["WALTER BAKER", 8],
    ["WARDROBE.NYC", 6],
    ["WARP & WEFT", 1],
    ["WASHINGTON DEE CEE", 2],
    ["WASH LAB", 1],
    ["WASH LAB DENIM", 1],
    ["WAYF", 3],
    ["WE11 DONE", 3],
    ["WEEKEND MAX MARA", 1],
    ["WE THE FREE", 1],
    ["WEWOREWHAT", 1],
    ["WHISTLES", 4],
    ["WHITE HOUSE BLACK MARKET", 1],
    ["WHITE MARK", 13],
    ["WILLOW", 1],
    ["WILT", 1],
    ["WOLFORD", 8],
    ["WOOLRICH", 1],
    ["WSLY", 4],
    ["XIRENA", 2],
    ["XIX PALMS", 1],
    ["XSCAPE", 7],
    ["Y-3", 5],
    ["YANG LI", 2],
    ["YEAR OF OURS", 3],
    ["YOHJI YAMAMOTO", 4],
    ["Y/PROJECT", 4],
    ["Y'S", 1],
    ["YUMMIE", 2],
    ["ZADIG & VOLTAIRE", 7],
    ["Z BY ZELLA", 1],
    ["ZELLA", 1],
    ["ZERO + MARIA CORNEJO", 1],
    ["ZHIVAGO", 4],
    ["ZIMMERMANN", 5],
    ["Z SUPPLY", 1],
    ["ZUHAIR MURAD", 1],
    ["ZZDNU SHANI", 1],
];

export interface BrandsAvailableEntry {
    value: string;
    label: string;
    count: number;
}

export const brandsAvailable = [maleBrandsAvailable, femaleBrandsAvailable, bothBrandsAvailable].map((cur) =>
    cur.map(
        (brand) =>
            ({
                value: brand[0],
                label: `${brand[0]} (${brand[1]})`,
                count: brand[1],
            } as BrandsAvailableEntry)
    )
);
