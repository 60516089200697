import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../helpPage/help.scss";
import { ReactComponent as KlothedLogo } from "../assets/logo-beta.svg";
import { ReactComponent as Aa } from "../helpPage//assets/aa.svg";
import oneImg from "../helpPage/assets/1.png";
import { ReactComponent as Extension } from "../helpPage/assets/extension.svg";
import twoImg from "../helpPage/assets/2.png";
import { ReactComponent as Knob } from "../helpPage/assets/knob.svg";
import Klothed from "./assets/k-logo.png";
import eightImg from "../helpPage/assets/8.png";
import nineImg from "../helpPage/assets/9.png";
import tenImg from "../helpPage/assets/10.png";
import elevenImg from "../helpPage/assets/11.png";
import Footer from "../components/footer/footer";
import RightSlider from "../rightSlider/rightSlider";

function GetStarted() {
    const [showRightSlider, setShowRightSlider] = useState<boolean>(false);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [window.location.pathname, showRightSlider]);
    return (
        <div className={`help_page`}>
            <Link to={process.env.REACT_APP_WEB_RACKS_HOME || "#"}>
                <KlothedLogo className="klothed_logo"></KlothedLogo>
            </Link>
            {!showRightSlider && (
                <div className="rightSliderLine">
                    <div onClick={() => setShowRightSlider(true)} className="rightSliderLine__">
                        <div className="rightSliderLine_"></div>
                        <div className="rightSliderLine_"></div>
                    </div>
                </div>
            )}
            {showRightSlider && <div className="rightSliderOverlay"></div>}
            {showRightSlider && <RightSlider closeAct={() => setShowRightSlider(false)}></RightSlider>}
            <div className="help_left_section">
                <div className="leftFixedSection">
                    <div className="leftHeading">{"To use Klothed on your iPhone, first you need to enable the Safari browser extension."}</div>
                    <div className="leftHeadingTwo">
                        {"If you’ve already enabled the extension, choose one of your favorite retailers at "}
                        <Link className="orangeText" to={process.env.REACT_APP_WEB_RETAILERS || "#"}>
                            getklothed.com/retailers
                        </Link>
                        {" and then go to any product page to try on a style."}
                    </div>
                </div>
            </div>
            {
                <div className="help_right_section">
                    <div className="leftTop_heading">{"HELP"}</div>
                    <div className="horizonta_seperator_legal"></div>
                    <div className="fewFAQ">{"How to enable the extension for Safari."}</div>
                    <div className="point_one">
                        <div className="point_one_1">{"1"}</div>
                        <div className="point_one_2">{"Tap the"}</div>
                        <Aa></Aa>
                    </div>
                    <img className="oneImg_" src={oneImg}></img>
                    <div className="point_one">
                        <div className="point_one_1">{"2"}</div>
                        <div className="point_one_2">{"Tap the"}</div>
                        <Extension></Extension>
                    </div>
                    <img className="oneImg_" src={twoImg}></img>
                    <div className="point_one">
                        <div className="point_one_1">{"3"}</div>
                        <div className="point_one_2">{"Turn on Klothed"}</div>
                        <Knob></Knob>
                    </div>
                    <img className="oneImg_" src={eightImg}></img>
                    <div className="point_one">
                        <div className="point_one_1">{"4"}</div>
                        <div className="point_one_2">{"Tap the"}</div>
                        <img src={Klothed} width={24} height={24} />
                        <div className="point_one_2" style={{ marginLeft: "8px" }}>
                            {"extension"}
                        </div>
                    </div>
                    <img className="oneImg_" src={nineImg}></img>
                    <div className="point_one">
                        <div className="point_one_1">{"5"}</div>
                        <div className="point_one_2">{"Tap"}</div>
                        <div className="point_one_2" style={{ color: "rgba(52, 120, 246, 1)" }}>
                            {" Always Allow..."}
                        </div>
                    </div>
                    <img className="oneImg_" src={tenImg} style={{ width: "314px", height: "212px" }}></img>
                    <div className="point_one">
                        <div className="point_one_1">{"6"}</div>
                        <div className="point_one_2">
                            Tap
                            <span style={{ color: "rgba(52, 120, 246, 1)" }}>{" Always Allow on Every Website"}</span>
                        </div>
                    </div>
                    <img className="oneImg_" src={elevenImg} style={{ width: "314px", height: "212px" }}></img>
                    <div className="point_one">
                        <div className="point_one_1">{"7"}</div>
                        <div className="point_one_2">
                            {"Go "}
                            <a
                                style={{ color: "#ff855f", cursor: "pointer", textDecoration: "none" }}
                                href={process.env.REACT_APP_WEB_RETAILERS}
                                target={"_blank"}
                                rel={"noreferrer"}
                            >
                                here
                            </a>
                            {" and pick a retailer to getKlothed™"}
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    );
}

export default GetStarted;
