/**
 * External dependencies.
 */
import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

/**
 * Internal dependencies.
 */
import { AutoTab } from "../autoTab";
import { Container } from "../container";

import Tab1_1 from "@v2/assets/activeTab/tab1-1.webp";
import Tab1_2 from "@v2/assets/activeTab/tab1-2.webp";
import Tab2_1 from "@v2/assets/activeTab/tab2-1.webp";
import Tab2_2 from "@v2/assets/activeTab/tab2-2.webp";
import Tab2_3 from "@v2/assets/activeTab/tab2-3.webp";
import Phone1_1 from "@v2/assets/activeTab/phone1-1.webp";
import Phone1_2 from "@v2/assets/activeTab/phone1-2.webp";
import Phone1_3 from "@v2/assets/activeTab/phone1-3.webp";
import Phone2_1 from "@v2/assets/activeTab/phone2-1.webp";
import Phone2_3 from "@v2/assets/activeTab/phone2-3.webp";
import Logo from "@v2/assets/activeTab/logo.webp";
import { ReactComponent as Desktop } from "@v2/assets/activeTab/laptop.svg";
import { ReactComponent as Mobile } from "@v2/assets/activeTab/mobile.svg";
import { ReactComponent as PuzzlePiece } from "@v2/assets/activeTab/puzzle-piece.svg";
import { ReactComponent as Like } from "@v2/assets/activeTab/like.svg";
import { ReactComponent as ArrowLeft } from "@v2/assets/activeTab/arrow-left.svg";
import "./activeTab.scss";

type PropItem = {
    content: React.ReactNode;
    button?: {
        text: string;
        link: string;
        type?: string;
        onClick?: () => void;
    };
    image: string;
    additionalContent?: React.ReactNode;
};

type TabData = {
    title: string;
    data: PropItem[];
};

type TabProp = {
    icon: React.ReactNode;
    isMobile: boolean;
    tabs: TabData[];
};

export const ActiveTab = () => {
    const [active, setActive] = useState(isMobile ? 1 : 0);
    const handleActive = (index: number) => setActive(index);

    const tabs: TabProp[] = useMemo(
        () => [
            {
                icon: <Desktop />,
                isMobile: false,
                tabs: [
                    {
                        title: "Get Started.",
                        data: [
                            {
                                content: <span>Sign up and create your profile (it&apos;s free).</span>,
                                button: {
                                    text: "Sign Up",
                                    link: process.env.REACT_APP_WEB_SIGNUP2 || "#",
                                    type: "fill",
                                },
                                image: Tab1_1,
                            },
                            {
                                content: <span>Then Follow the instructions to install the Chrome browser extension.</span>,
                                button: {
                                    text: "Add to Chrome",
                                    link: "#",
                                    onClick: () => {
                                        window.open(process.env.REACT_APP_EXTENSION_DESKTOP);
                                    },
                                },
                                image: Tab1_2,
                            },
                        ],
                    },
                    {
                        title: "Try It On.",
                        data: [
                            {
                                content: (
                                    <span>
                                        Go to a product page on a <strong>covered</strong> retailer&apos;s site.
                                    </span>
                                ),
                                image: Tab2_1,
                            },
                            {
                                content: <span>Click the klothed try-on button in the upper right.</span>,
                                image: Tab2_2,
                                additionalContent: (
                                    <div className="additional-logo">
                                        <img src={Logo} />
                                    </div>
                                ),
                            },
                            {
                                content: <span>And voila! You&apos;re wearing the product.</span>,
                                image: Tab2_3,
                                additionalContent: (
                                    <div className="additional-likes">
                                        <Like />
                                    </div>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                icon: <Mobile />,
                isMobile: true,
                tabs: [
                    {
                        title: "Get Started.",
                        data: [
                            {
                                content: <span>Get the Safari Extension App.</span>,
                                button: {
                                    text: "Get the extension",
                                    link: "#",
                                    // onClick: () => {
                                    //     window.open(process.env.REACT_APP_EXTENSION_MOBILE);
                                    // },
                                },
                                image: Phone1_1,
                            },
                            {
                                content: (
                                    <span>
                                        Follow the <strong>instructions to enable the extension</strong>.
                                    </span>
                                ),
                                image: Phone1_2,
                            },
                            {
                                content: <span>Sign up and create your profile (It&apos;s free).</span>,
                                image: Phone1_3,
                            },
                        ],
                    },
                    {
                        title: "Try It On.",
                        data: [
                            {
                                content: (
                                    <span>
                                        Go to a product page on a <strong>covered</strong> retailer&apos;s site.
                                    </span>
                                ),
                                image: Phone2_1,
                            },
                            {
                                content: (
                                    <>
                                        <span>Tap the Klothed try-on button.</span>
                                        <span>
                                            Don&apos;t see a try-on button? Just tap the <PuzzlePiece /> in the browser bar and select Klothed from the menu
                                            that appears.
                                        </span>
                                    </>
                                ),
                                image: Phone2_1,
                                additionalContent: (
                                    <div className="additional-arrow">
                                        <ArrowLeft />
                                    </div>
                                ),
                            },
                            {
                                content: <span>And voila! You&apos;re wearing the product.</span>,
                                image: Phone2_3,
                                additionalContent: (
                                    <div className="additional-likes">
                                        <Like />
                                    </div>
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
        []
    );

    return (
        <div className="active-tabs">
            <ul className="tab-line">
                {tabs.map((item: TabProp, index: number) => (
                    <li key={index} className={classNames({ "is-active": active === index })}>
                        <button type="button" onClick={() => handleActive(index)}>
                            {item.icon}
                        </button>
                    </li>
                ))}
            </ul>
            <Container className="smaller">
                {tabs[active].tabs?.map((item: TabData, index) => (
                    <AutoTab data={item.data} title={item.title} key={`${active}-${index}`} isMobile={tabs[active].isMobile} />
                ))}
            </Container>
        </div>
    );
};
