import { Api } from "./klothed-api";
import { auth } from "./firebase-config";

const fetchMethod = async (url: string, config: RequestInit) => {
    const accessToken = await auth.currentUser?.getIdToken();
    if (config?.headers) {
        Object.assign(config.headers, { authorization: accessToken });
    } else {
        Object.assign(config, { headers: { authorization: accessToken } });
    }
    return fetch(url, {
        mode: "cors",
        ...config,
    });
};

export const klothedAPI = new Api({
    fetchMethod,
    basePath: process.env.REACT_APP_API_URL,
});
