/**
 * External dependencies.
 */
import React, { useEffect } from "react";

/**
 * Internal dependencies.
 */
import { Layout, HeadingLine, TwoColumns } from "@v2/components/burger";

import Img from "@v2/assets/aboutUsFun/about-fun.webp";
import Img2 from "@v2/assets/aboutUsFun/fun.webp";
import { ReactComponent as Arrow } from "@v2/assets/aboutUsFun/arrow.svg";
import { ReactComponent as Instagram } from "@v2/assets/aboutUsFun/instagram.svg";
import "./aboutUsFun.scss";
import { useSiteSession } from "@v2/utils";

const AboutFun = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - About.`);
        setPageDescription(`Klothed - Everything looks better on you. - About.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout spacing className="about-us-fun">
            <HeadingLine>
                <h2>
                    <span>
                        LET’S HAVE FUN<strong className="no-ls">.</strong>
                    </span>
                </h2>
            </HeadingLine>
            <TwoColumns align="center" smaller>
                <TwoColumns.Column className="column--image">
                    <div className="image-element">
                        <img loading="lazy" src={Img} width={456} height={683} className="img1" />
                    </div>
                    <h5 className="show-mobile">Share your try-ons with friends and family to up your style and purchase confidently.</h5>
                </TwoColumns.Column>
                <TwoColumns.Column className="column--text column--no-right column--order">
                    <Arrow />
                    <h3 className="spacing">
                        <span>Invite</span> your friends.
                    </h3>
                    <h5 className="hide-mobile">Share your try-ons with friends and family to up your style and purchase confidently.</h5>
                </TwoColumns.Column>
                <div className="line line--right"></div>
            </TwoColumns>
            <TwoColumns smaller className="overflow-visible mobie-mb-0">
                <TwoColumns.Column className="column--text column--no-left top-space column--order">
                    <Instagram />
                    <h3 className="spacing2">
                        <span>Impress</span> your followers.
                    </h3>
                    <h5 className="hide-mobile">Post across social media to influence your broader community.</h5>
                </TwoColumns.Column>
                <TwoColumns.Column className="column--image fixed-width border-left">
                    <div className="image-element mobile-move">
                        <img loading="lazy" src={Img2} width={252} height={536} />
                    </div>
                    <h5 className="show-mobile">Post across social media to influence your broader community.</h5>
                </TwoColumns.Column>
            </TwoColumns>
        </Layout>
    );
};

export default AboutFun;
