import React from "react";
import { Help } from "@v2/components/help";

export default function HelpPage() {
    return (
        <div className="help">
            <Help />
        </div>
    );
}
