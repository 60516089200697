import React, { useEffect } from "react";
import { HelpComponent } from "./help";
import { useSiteSession } from "@v2/utils";

export const Help = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Help.`);
        setPageDescription(`Klothed - Everything looks better on you. - Help.`);
    }, [setPageTitle, setPageDescription]);

    return (
        <div className="artifact_group">
            <HelpComponent />
        </div>
    );
};
