import React from "react";
import { ClosetPage } from "@v2/components/closet";

export default function ClosetHome() {
    return (
        <div className="edits_home">
            <ClosetPage />
        </div>
    );
}
