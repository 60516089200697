import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { SignUpMobile } from "./mobile";
import { SignUpDesktop } from "./desktop";
import { AuthState, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";

const UserSignUp = () => {
    const { authState, setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Sign up.`);
        setPageDescription(`Klothed - Everything looks better on you. - Sign up.`);
    }, [setPageTitle, setPageDescription]);
    const navigate = useNavigate();

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoKlothedAccount:
                // Create a shopper account
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoUserAccount:
                return; // we are on the correct page
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#");
                return;
        }
    }, [authState, navigate]);

    return isMobile ? <SignUpMobile /> : <SignUpDesktop />;
};

export default UserSignUp;
