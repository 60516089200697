/**
 * External dependencies.
 */
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

type Props = {
    href: string;
    children: React.ReactNode;
    target?: string;
    fill?: boolean;
    type?: string;
    className?: string;
    onClick?: () => void;
};

export const Button: React.FC<Props> = ({ children, onClick, href, target = "", fill = true, type = "var(--klothed-bgColor)", className = "" }) => {
    if (href.startsWith("http")) {
        if (!fill) {
            return (
                <a href={href} target={"_blank"} className={classNames("button", className)} rel="noreferrer">
                    {children}
                </a>
            );
        } else {
            return (
                <a onClick={onClick} className={classNames("button", `button--${type}`, className)} href={href} target={target}>
                    <span className="button__inner">{children}</span>
                </a>
            );
        }
    }
    if (!fill) {
        return (
            <Link className={classNames("button", className)} to={href} target={target}>
                {children}
            </Link>
        );
    }

    return (
        <Link onClick={onClick} className={classNames("button", `button--${type}`, className)} to={href} target={target}>
            <span className="button__inner">{children}</span>
        </Link>
    );
};
