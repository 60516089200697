import React from "react";
import { UserProfileStyle } from "@v2/components/profile/style";

export default function ProfileStylePage() {
    return (
        <div className="user_profile_height_style">
            <UserProfileStyle />
        </div>
    );
}
