/**
 * External dependencies.
 */
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/**
 * Internal dependencies.
 */
import { ReactComponent as Close } from "./assets/close.svg";
import { getCookie, setCookie } from "./utils";
import "./policyBar.scss";

const PolicyBar = () => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        const policyCookie = getCookie("policy-closed");

        if (!policyCookie) {
            setActive(true);
        }
    }, [setActive]);

    const handleClick = useCallback(() => {
        setActive(false);
        setCookie("policy-closed", true, 366);
    }, [setActive]);

    if (!active) {
        return null;
    }

    return (
        <div className="policy-bar">
            <div className="container">
                <div className="policy-bar__inner">
                    <div className="policy-bar__col">
                        <p>
                            {"Welcome to Klothed. Select the link to view our"}{" "}
                            <Link to={process.env.REACT_APP_WEB_COOKIE_POLICY || "#"}>{"Cookie Policy"}</Link>.
                        </p>
                    </div>
                    <div className="policy-bar__col">
                        <p>{"© Klothed Technologies Inc."}</p>
                        <button type="button" className="policy-bar__close" onClick={handleClick}>
                            <Close />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyBar;
