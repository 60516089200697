import React, { useCallback, useRef, useState } from "react";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import { auth } from "@v2/utils";
import { ReactComponent as Google } from "@v2/assets/google.svg";
import { ReactComponent as Apple } from "@v2/assets/apple.svg";
import isEmail from "validator/lib/isEmail";
import { ReactComponent as Eye } from "@v2/assets/eye.svg";
import { Link } from "react-router-dom";
import { ReactComponent as AppStore } from "@v2/assets/appStore.svg";
import chrome from "@v2/assets/chrome.webp";

function Login({ displayStoreLinks }: { displayStoreLinks?: boolean }) {
    const EmailNotFound = "Sorry, the username you entered doesn't belong to an account. Please try again.";
    const InvalidPassword = "Sorry, your password was incorrect. Please try again.";

    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const onClickLogIn = () => {
        signInWithEmailAndPassword(auth, email, password).catch((error) => {
            if (error && error.code.includes("user-not-found")) {
                setError(EmailNotFound);
            } else if (error && error.code.includes("wrong-password")) {
                setError(InvalidPassword);
            } else {
                setError(error);
            }
        });
    };

    const googleProvider = useRef(new GoogleAuthProvider());
    const googleSignUp = useCallback(() => {
        signInWithPopup(auth, googleProvider.current)
            .then()
            .catch((error) => {
                setError(error);
            });
    }, [auth]);

    const appleProvider = useRef(
        (() => {
            const provider = new OAuthProvider("apple.com");
            provider.addScope("email");
            provider.addScope("name");
            return provider;
        })()
    );
    const appleSignUp = useCallback(() => {
        signInWithPopup(auth, appleProvider.current)
            .then()
            .catch((error) => {
                setError(error);
            });
    }, [auth]);

    return (
        <div className="logIn_section_v2">
            {error ? (
                <div className="errorMessage__">
                    <div className="errorMessageHeading">{error}</div>
                    <div className="horizontal_separator horizontal_separator_error"></div>
                </div>
            ) : (
                <div className="login_top_header"></div>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onClickLogIn();
                }}
            >
                <div className="email_input_div">
                    <input
                        className="email_input_box"
                        placeholder=" "
                        type="email"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                            setIsEmailValid(isEmail(event.target.value));
                        }}
                        autoComplete="email"
                    />
                    <label className="email_input_label">{"Enter email address"}</label>
                </div>
                <div className="password_input_div">
                    <input
                        className={`password_input_box ${showPassword ? "showPassword" : "hidePassword"}`}
                        placeholder=" "
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        autoComplete="current-password"
                    />
                    <label className="password_input_label">{"Enter password"}</label>
                    <Eye className="showPasswordIMG" onClick={() => setShowPassword(!showPassword)}></Eye>
                </div>
                <div className={`loginBtn_wrapper ${email && isEmailValid && password ? "login_button_active" : ""}`}>
                    <input type="submit" className={`login_button`} value="LOG IN" />
                </div>
                <div className="alternate_login_options">
                    <div className="alternate_login">{"or"}</div>
                    <Google onClick={() => googleSignUp()} className="alternate_login_options_CTA"></Google>
                    <Apple onClick={() => appleSignUp()} className="alternate_login_options_CTA"></Apple>
                </div>
                <Link to={process.env.REACT_APP_WEB_FORGOT_PASSWORD || "#"} className="forgotPasswordV2">
                    <span>Forgot password?</span>
                    <hr />
                </Link>
                <div className="horizontal_separator"></div>
                <div className="already_account">
                    {"Don't have an account?"}
                    <Link to={process.env.REACT_APP_WEB_SIGNUP2 || "#"}>
                        <div className={"signUp_button"}>SIGN UP</div>
                    </Link>
                </div>
                {displayStoreLinks && (
                    <>
                        <div className="horizontal_separator"></div>
                        <div className="store_CTA">
                            <div className="selector">
                                <a href={process.env.REACT_APP_EXTENSION_MOBILE || "#"} target={"_blank"} rel={"noreferrer"}>
                                    <div className="getApp">{"Get The App"}</div>
                                    <AppStore className="appleAppStore" />
                                </a>
                            </div>
                            <div className="selector">
                                <a href={process.env.REACT_APP_EXTENSION_DESKTOP} target={"_blank"} rel={"noreferrer"}>
                                    <div className="getApp">{"Get the extension"}</div>
                                    <div className="extensionLink">
                                        <img className="chromeLogo" src={chrome}></img>
                                        <div className="extensionText">
                                            <div className="extensionTextTop">{"Available On"}</div>
                                            <div className="extensionTextBottom">{"chrome web store"}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
}

export default Login;
