import { User, onAuthStateChanged } from "firebase/auth";
import { Api, decomposition_DecompositionInfo, shopper_ShopperInfo } from "../klothed-api";
import { HeightInFeetAndInches, toCentimeters, toFeetAndInches } from "../height";
import { useCallback, useEffect, useRef, useState } from "react";
import { auth } from "@v2/../firebase-config";
import { ShopperImageInfo, ShopperImageState } from "../shopperImage";
import { decompositionSync } from "../decompSync";
import { Id, toast } from "react-toastify";
import { useNavigate } from "react-router";

export enum AuthState {
    Unknown = 0, // loading
    NoUserAccount = Unknown + 1,
    NoKlothedAccount = NoUserAccount + 1,
    NoVerification = NoKlothedAccount + 1,
    ServerError = NoVerification + 1,
    NoAdmittance = ServerError + 1,
    NoTryOnProfile = NoAdmittance + 1,
    RacksReady = NoTryOnProfile,
    TryonProfile = RacksReady + 1,
}

export interface ShopperProfileInfo {
    email: string;
    displayName: string;
}

export interface AuthContext {
    authState: AuthState;
    token?: string;
    user?: User;
    adam?: Api;
    shopper?: shopper_ShopperInfo;
    style?: string;
    height?: number;
    createKlothedAccount: (heightInFeetAndInches: HeightInFeetAndInches, style: string) => Promise<void>;
    syncHeightAndStyle: (heightAndStyle: { heightInFeetAndInches?: HeightInFeetAndInches; style?: string }) => Promise<void>;
    syncEmailChange: (email: string) => Promise<void>;
    heightInFeetAndInches?: HeightInFeetAndInches;
    shopperImageInfo?: ShopperImageInfo;
    shopperImageState?: ShopperImageState;
    shopperProfileInfo?: ShopperProfileInfo;
    uploadShopperImage: (im: File) => Promise<void>;
    isKlothed: boolean;
    signOut: () => void;
}

export function AuthContextProvider(): AuthContext {
    const [loading, setLoading] = useState(true);
    const [authState, setAuthState] = useState(AuthState.Unknown);
    const [user, setUser] = useState<User>();
    const isKlothed = useRef(false);
    const [shopper, setShopper] = useState<shopper_ShopperInfo>();
    const [style, setStyle] = useState<string>();
    const [token, setToken] = useState<string>();
    const adam = useRef<Api>();
    const heightInFeetAndInches = useRef<HeightInFeetAndInches>();
    const tryonBetaUser = useRef<boolean>();

    const [shopperImageInfo, setShopperImageInfo] = useState<ShopperImageInfo>();

    const shopperProfileInfo = useRef<ShopperProfileInfo>();

    const [shopperImageState, setShopperImageState] = useState(ShopperImageState.Unknown);

    const fetchShopperImageState = useCallback(async () => {
        if (!adam.current || !token || !shopperImageInfo) {
            setShopperImageState(ShopperImageState.Unknown);
            return;
        }
        if (!shopperImageInfo.lastDecompositionID) {
            setShopperImageState(ShopperImageState.Missing);
            return;
        }
        if (shopperImageInfo.activeDecompositionID === shopperImageInfo.lastDecompositionID) {
            setShopperImageState(ShopperImageState.Approved);
            return;
        } else {
            try {
                const decompStatus: decomposition_DecompositionInfo = await adam.current.secureDecompositionDecompositionIdGet({
                    decomposition_id: shopperImageInfo.lastDecompositionID,
                });
                if (decompStatus.status === "failure") {
                    setShopperImageState(ShopperImageState.Rejected);
                    if (uploadToastID.current) {
                        toast.update(uploadToastID.current, {
                            render: "Unable To Process - Please Review The Photo Guidelines.",
                            isLoading: false,
                            type: "error",
                            autoClose: 5000,
                        });
                    }
                    return;
                }
                if (decompStatus.status === "success") {
                    setShopperImageState(ShopperImageState.Approved);
                    return;
                }
                if (decompStatus.status === "processing") {
                    const decompPromise = decompositionSync(shopperImageInfo.lastDecompositionID, token);
                    if (uploadToastID.current) {
                        toast.update(uploadToastID.current, {
                            render: "Analyzing Photo.",
                            isLoading: true,
                        });
                    } else {
                        uploadToastID.current = toast.loading("Analyzing Photo.", { toastId: uploadToastID.current });
                    }
                    setShopperImageState(ShopperImageState.InReview);
                    decompPromise.then(() => {
                        if (uploadToastID.current) {
                            toast.update(uploadToastID.current, {
                                render: "Success! You're Try-On Ready.",
                                isLoading: false,
                                type: "success",
                                autoClose: 5000,
                            });
                        }
                        adam.current?.secureShopperGet().then(setShopper);
                    });
                    return;
                }
            } catch (err) {
                console.error(err);
                if (uploadToastID.current) {
                    toast.update(uploadToastID.current, {
                        render: "Unable To Process - Please Review The Photo Guidelines.",
                        isLoading: false,
                        type: "error",
                        autoClose: 5000,
                    });
                }
            }
        }
        setShopperImageState(ShopperImageState.Unknown);
    }, [adam, token, shopperImageInfo, setShopperImageState, setShopper]);
    useEffect(() => {
        fetchShopperImageState();
    }, [shopperImageInfo, fetchShopperImageState]);

    useEffect(() => {
        if (!user || !shopper) return;
        if (process.env.REACT_APP_VERIFICATION_ENABLE && !user.emailVerified) {
            shopperProfileInfo.current = { email: user.email || "", displayName: user.displayName || "" };
            setAuthState(AuthState.NoVerification);
            return;
        }
        // if (!tryonBetaUser.current) {
        //     shopperProfileInfo.current = { email: user.email || "", displayName: user.displayName || "" };
        //     setAuthState(AuthState.NoAdmittance);
        //     return;
        // }

        const { lastDecompositionID, lastImageID, activeDecompositionID, activeImageID, height, style, email, displayName } = shopper.info;
        if (!email || !displayName) {
            // One time fix to accounts without email or display name
            console.log("One-time accounts update");
            adam.current?.secureShopperPut({
                info: { email: user.email || "", displayName: user.displayName || "" },
            });
            shopperProfileInfo.current = { email: user.email || "", displayName: user.displayName || "" };
        } else {
            shopperProfileInfo.current = { email, displayName };
        }
        isKlothed.current = shopperProfileInfo.current.email.endsWith("@getklothed.com");
        const shopperImageInfo = { lastDecompositionID, lastImageID: lastImageID || activeImageID, activeDecompositionID, activeImageID };
        setShopperImageInfo(shopperImageInfo);
        heightInFeetAndInches.current = toFeetAndInches(height);
        const userImageRequired = !(lastDecompositionID === activeDecompositionID && activeDecompositionID > 0);
        const heightRequired = !(height > 0);
        setStyle(style || "both");
        if (userImageRequired || heightRequired) {
            setAuthState(AuthState.NoTryOnProfile);
        } else {
            setAuthState(AuthState.TryonProfile);
        }
    }, [shopper, setAuthState, setShopperImageState, setStyle, setShopperImageInfo]);

    const fetchShopper = useCallback(async () => {
        if (!adam.current || !token) return;
        try {
            try {
                const s = await adam.current.secureShopperGet();
                setShopper(s);
                return;
            } catch (err) {
                const status = (err as { status?: number }).status;
                if (status && status === 404) {
                    setAuthState(AuthState.NoKlothedAccount);
                    return;
                }
            }
        } catch (err) {
            console.error(err);
        }
        setAuthState(AuthState.ServerError);
        setShopperImageState(ShopperImageState.Unknown);
        setShopper(undefined);
        setStyle(undefined);
    }, [setShopper, setStyle, setAuthState, setShopperImageState, token]);
    const uploadToastID = useRef<Id>();
    const uploadShopperImage = useCallback(
        async (im: File) => {
            const uploadingPromise = (async () => {
                if (!adam.current) return;
                const imgArrayBuffer = await im.arrayBuffer();
                await adam.current.secureShopperImagePost({
                    image: imgArrayBuffer as unknown as number[],
                    image_info: JSON.stringify({}),
                    name: im.name,
                });
            })();
            uploadToastID.current = toast.loading("Uploading photo.", { toastId: uploadToastID.current });
            try {
                await uploadingPromise;
                await fetchShopper();
            } catch (err) {
                console.error(err);
                uploadToastID.current = toast.error("Upload failed.", { toastId: uploadToastID.current });
            }
        },
        [fetchShopper]
    );

    const navigate = useNavigate();
    const signOut = useCallback(async () => {
        await auth.signOut();
        navigate(0);
    }, []);

    const createKlothedAccount = useCallback(
        async (heightInFeetAndInches: HeightInFeetAndInches, style: string) => {
            if (!adam.current || !user) return;
            const height = toCentimeters(heightInFeetAndInches);
            const { email, displayName, uid } = user;
            setShopper(
                await adam.current.secureShopperPost({
                    info: {
                        info: { height, style, email, displayName },
                        uid,
                    },
                })
            );
            return;
        },
        [setShopper, shopper, user]
    );
    const syncHeightAndStyle = useCallback(
        async (heightAndStyle: { heightInFeetAndInches?: HeightInFeetAndInches; style?: string }) => {
            if (!adam.current || !shopper) return;
            const height = heightAndStyle.heightInFeetAndInches && toCentimeters(heightAndStyle.heightInFeetAndInches);
            const style = heightAndStyle.style;
            setShopper(await adam.current.secureShopperHeightAndStylePut({ height, style }));
            return;
        },
        [setShopper, shopper]
    );

    const syncEmailChange = useCallback(
        async (email: string) => {
            if (!adam.current || !shopper) return;
            setShopper(
                await adam.current.secureShopperEmailPost({
                    info: {
                        email,
                    },
                })
            );
            return;
        },
        [setShopper, shopper]
    );

    const refreshToken = useCallback(async () => {
        if (!user) return;
        const accessToken = await user.getIdToken();
        // const claims = JSON.parse((user as any).reloadUserInfo.customAttributes);
        const claims = (await user.getIdTokenResult()).claims;
        tryonBetaUser.current = !!claims["tryon-beta"];

        sessionStorage.setItem("accessToken", accessToken);
        // Send the token to klothed extension if available
        if (document.body.getAttribute("klothed-extension") === "true") {
            window.postMessage(
                {
                    type: "klothed_user",
                    user: JSON.parse(JSON.stringify(user)),
                },
                "*"
            );
        }
        adam.current = new Api({
            // eslint-disable-next-line
            fetchMethod: (url: string, config: any) => {
                if (config?.headers) {
                    Object.assign(config.headers, { authorization: accessToken });
                } else {
                    Object.assign(config, {
                        headers: { authorization: accessToken },
                    });
                }
                const fetchParams = {
                    mode: "cors",
                    ...config,
                };
                return fetch(url, fetchParams);
            },
            basePath: process.env.REACT_APP_API_URL,
        });
        setToken(accessToken);
    }, [user, setToken]);

    useEffect(() => {
        if (loading) return;
        if (!user) {
            setAuthState(AuthState.NoUserAccount);
            setToken(undefined);
            return;
        }
        const intervalId = setInterval(() => refreshToken(), 15 * 60 * 1000);
        refreshToken();
        return () => clearInterval(intervalId);
    }, [user, loading, setAuthState, setToken]);

    useEffect(() => {
        const removeListener = onAuthStateChanged(auth, (user) => {
            setUser(user || undefined);
            setLoading(false);
        });
        return () => {
            setLoading(true);
            removeListener();
        };
    }, [setUser, setLoading]);

    useEffect(() => {
        fetchShopper();
    }, [token]);

    return {
        authState,
        isKlothed: isKlothed.current,
        syncHeightAndStyle,
        syncEmailChange,
        adam: adam.current,
        heightInFeetAndInches: heightInFeetAndInches.current,
        shopper,
        style,
        user,
        shopperImageInfo,
        shopperImageState,
        shopperProfileInfo: shopperProfileInfo.current,
        token,
        uploadShopperImage,
        createKlothedAccount,
        signOut,
    };
}
