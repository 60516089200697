import React from "react";
import Login from "./login";

export function LoginMobile() {
    return (
        <div className="artifact_group">
            <h4>Log in to try it on.</h4>
            <Login />
        </div>
    );
}
