/**
 * External dependencies.
 */
import React, { useEffect } from "react";
import { Button, Container, Layout, HeadingLine } from "@v2/components/burger";

/**
 * Internal dependencies.
 */
import Team1 from "./assets/team1.jpg";
import Team2 from "./assets/team2.jpg";
import "./team.scss";
import { useSiteSession } from "@v2/utils";

const Team = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Team.`);
        setPageDescription(`Klothed - Everything looks better on you. - Team.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout spacing className="team-landing">
            <HeadingLine side="left">
                <h2>
                    <span>
                        WE THINK EVERYTHING LOOKS BETTER <em>ON YOU</em>
                        <strong className="no-ls">.</strong>
                    </span>
                </h2>
            </HeadingLine>
            <div className="team-wrapper">
                <div className="team-image-row">
                    <Container className="smaller">
                        <div className="team-image-row__inner align-center">
                            <div className="left-column">
                                <h5>
                                    FOUNDED IN NEW YORK, OUR COMPANY BRINGS TOGETHER A DIVERSE SET OF LEADERS IN FASHION, RETAIL TECHNOLOGY, AI + ML, AND
                                    COMPUTER VISION TO EMBED PERSONALIZED PHOTOREALISM WITHIN ONLINE SHOPPING.{" "}
                                </h5>
                                <Button href={process.env.REACT_APP_WEB_TEAM_MEMBERS || "#"} className="hide-mobile">
                                    Meet our Team
                                </Button>
                            </div>
                            <div className="right-column">
                                <img loading="lazy" src={Team1} width={456} height={684} className="br-0-0-6-0 mobile-left-side" />
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="team-image-row margin-top">
                    <Container className="smaller">
                        <div className="team-image-row__inner align-flex-end overflow-hidden">
                            <div className="left-column relative mobile-order">
                                <img loading="lazy" src={Team2} width={515} height={772} className="br-6-6-0-6 mobile-flush" />
                            </div>
                            <div className="right-column border-box">
                                <div className="line left-top"></div>
                                <div className="line right-bottom"></div>
                                <h3>
                                    In fashion e-commerce, <span>we are crafting a social shopping experience</span> that increases conversion, reduces returns,
                                    elevates purchaser confidence, and rivals in-store shopping.
                                </h3>
                                <Button href={process.env.REACT_APP_WEB_TEAM_MEMBERS || "#"} className="show-mobile">
                                    Meet our Team
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </Layout>
    );
};

export default Team;
