import React from "react";
import { Button } from "@v2/components/burger";
import { useSiteSession } from "@v2/utils";
import { verifyUser } from "@v2/utils/verify";

export default function Verify() {
    const { user } = useSiteSession();

    return (
        <>
            <h4>Check your email to try on styles from your favorite online retailers.</h4>
            <Button
                href={"#"}
                fill
                onClick={() => {
                    if (user) {
                        verifyUser(user);
                    }
                }}
            >
                Send link
            </Button>
        </>
    );
}
