import React, { useEffect, useState } from "react";
import ClosetRow from "./closetRow";
import { AuthState, product_ShopperCatalogsResponse, useSiteSession } from "@v2/utils";
import { CatalogRowTemplate } from "./catalogRowTemplate";
import { useNavigate } from "react-router-dom";
import { InfiniteRacks } from "./infiniteRacks";
import FavoritesRow from "./favoritesRow";
import { FilterComponent } from "../filter";
import { Welcome } from "../welcome";

function RacksPage() {
    const { authState, shopperCatalogs, setPageDescription, setPageTitle, style, filterSortEnabled } = useSiteSession();

    const navigate = useNavigate();

    const [racks, setRacks] = useState<product_ShopperCatalogsResponse[]>();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Fashion store front.`);
        setPageDescription(`Klothed - Everything looks better on you. - Fashion store front.`);
    }, [setPageTitle, setPageDescription]);

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoUserAccount:
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
                return; // show sign up page
            case AuthState.NoKlothedAccount:
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                return;
        }
    }, [authState, navigate]);

    useEffect(() => {
        if (!style || !shopperCatalogs) {
            setRacks(undefined);
            return;
        }
        if (style !== "men") {
            setRacks(shopperCatalogs);
            return;
        }
        setRacks(
            shopperCatalogs
                .filter((c) => {
                    if (!c.title) {
                        // corrupted or incomplete edit.
                        return false;
                    }

                    if (c.title === "Activewear." || c.title === "Overalls.") {
                        return false;
                    }

                    return true;
                })
                .map((c) => {
                    if (c.title === "Dresses.") {
                        return {
                            ...c,
                            title: "Clothing.",
                        };
                    }
                    return c;
                })
        );
    }, [setRacks, shopperCatalogs, style]);
    return authState === AuthState.Unknown ? (
        <div>Loading</div>
    ) : (
        <div className="racks_home">
            {authState === AuthState.NoUserAccount ? (
                <Welcome />
            ) : (
                <InfiniteRacks
                    keyPerFetch={3}
                    keys={racks}
                    contentViewer={(edit, i) => (
                        <CatalogRowTemplate
                            category={edit}
                            key={i.toString()}
                            count={edit.count}
                            autoTryon={i < Number(process.env.REACT_APP_AUTO_TRYON_RACKS) ? Number(process.env.REACT_APP_AUTO_TRYON_TILES) : undefined}
                        />
                    )}
                >
                    <FilterComponent />
                    {!filterSortEnabled && (
                        <>
                            <FavoritesRow />
                            <ClosetRow />
                        </>
                    )}
                </InfiniteRacks>
            )}
        </div>
    );
}

export default RacksPage;
