import React, { useState, useEffect, useRef, useCallback } from "react";
import "./retailers.scss";
import { ReactComponent as HeartOne } from "./assets/heart1.svg";
import { ReactComponent as HeartDefault } from "./assets/heartDefault.svg";
import { ReactComponent as HeartSelected } from "./assets/heartSelected.svg";
import classNames from "classnames";
import { Layout } from "@v2/components/burger";
import { AuthState, retailers_Retailer, useSiteSession } from "@v2/utils";

interface RetailersInterface extends retailers_Retailer {
    isFavorite: boolean;
    favoriteID: number;
}

interface FavoriteRetailersInterface {
    id: number;
    retailer_id: { Int64: number; Valid: boolean };
}

interface RetailersWithFavors {
    retailers: RetailersInterface[];
    favoriteRetailers: FavoriteRetailersInterface[];
}

const COMING_SOON: string[] = [];

function Retailers() {
    const { retailers, adam, setPageDescription, setPageTitle, authState } = useSiteSession();
    const [allRetailersSelected, setAllRetailersSelected] = useState(true);
    const [allRetailersSegregated, setAllRetailersSegregated] = useState<RetailersInterface[][]>();
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Retailers.`);
        setPageDescription(`Klothed - Everything looks better on you. - Retailers.`);
    }, [setPageTitle, setPageDescription]);
    const getRetailersData = useCallback(async () => {
        if (!adam) {
            return {
                retailers:
                    retailers?.map((r) => ({
                        ...r,
                        favoriteID: -1,
                        isFavorite: false,
                    })) || [],
                favoriteRetailers: [],
            } as RetailersWithFavors;
        } else {
            const favoriteRetailers = adam.secureFavoritesRetailersGet({});
            return {
                retailers,
                favoriteRetailers: await favoriteRetailers,
            } as RetailersWithFavors;
        }
    }, [adam, retailers]);

    const Insecure = authState === AuthState.NoUserAccount;

    const addToFavorite = (index: number, indexRet: number) => {
        if (!adam) return;
        adam.secureFavoritesRetailerPost({ info: { retailer_id: allRetailersSegregated?.[index][indexRet].id } }).then(
            (response: FavoriteRetailersInterface) => {
                if (allRetailersSegregated) {
                    allRetailersSegregated[index][indexRet].isFavorite = true;
                    allRetailersSegregated[index][indexRet].favoriteID = response.id;
                    setAllRetailersSegregated(allRetailersSegregated);
                    setRefresh(!refresh);
                }
            }
        );
    };

    const removeFromFavorite = (index: number, indexRet: number) => {
        if (!adam) return;
        adam.secureFavoritesFavoriteIdDelete({
            favorite_id: allRetailersSegregated?.[index][indexRet].favoriteID ? allRetailersSegregated[index][indexRet].favoriteID : -1,
        }).then(() => {
            if (allRetailersSegregated) {
                allRetailersSegregated[index][indexRet].isFavorite = false;
                allRetailersSegregated[index][indexRet].favoriteID = -1;
                setAllRetailersSegregated(allRetailersSegregated);
                setRefresh(!refresh);
            }
        });
    };

    useEffect(() => {
        setRefresh(!refresh);
    }, [allRetailersSelected, allRetailersSegregated]);

    const ref: React.RefObject<HTMLDivElement>[] = [];
    for (let index = 0; index < 26; index++) {
        ref.push(useRef<HTMLDivElement>(null));
    }

    const scrollToAlphabet = (index_: number) => {
        if (ref[index_] && ref[index_].current) {
            ref[index_].current?.scrollIntoView({ block: "center", behavior: "smooth" });
        } else {
            for (let index = index_ + 1; index < 26; index++) {
                if (ref[index] && ref[index].current) {
                    ref[index].current?.scrollIntoView({ block: "center", behavior: "smooth" });
                    break;
                }
            }
        }
    };

    useEffect(() => {
        getRetailersData().then((response: { retailers: RetailersInterface[]; favoriteRetailers: FavoriteRetailersInterface[] }) => {
            if (response && response.retailers) {
                // eslint-disable-next-line
                response.retailers.sort((itemA, itemB) => itemA.info!.display!.localeCompare(itemB.info!.display!));
                if (response.favoriteRetailers) {
                    response.retailers.forEach((retailer__) => {
                        retailer__.isFavorite = false;
                        response.favoriteRetailers.forEach((favoriteRetailer__) => {
                            if (retailer__.id === favoriteRetailer__.retailer_id.Int64) {
                                retailer__.isFavorite = true;
                                retailer__.favoriteID = favoriteRetailer__.id;
                            }
                        });
                    });
                }
                const tempObj: RetailersInterface[][] = new Array(0);
                for (let index = 0; index < 26; index++) {
                    tempObj.push([]);
                }
                response.retailers.forEach((item) => {
                    // eslint-disable-next-line
                    tempObj[item.info!.display!.toUpperCase().charCodeAt(0) - 65].push(item);
                });

                setAllRetailersSegregated(tempObj);
            }
        });
    }, [adam, retailers]);

    if (Insecure) {
        return (
            <Layout className="retailers">
                <div className={`retailers_section retailers_public`}>
                    <div className={`retailers_section_left`}>
                        {allRetailersSegregated &&
                            allRetailersSegregated.map((item, index) => {
                                const atLeastOneFav = item.find((obj) => obj.isFavorite);
                                return item && item.length ? (
                                    !allRetailersSelected && !atLeastOneFav ? null : (
                                        <div ref={ref[index]} key={index} className={"retailersAlphaGrp"}>
                                            <div className="alphaBold">{String.fromCharCode(index + 65).toUpperCase()}</div>
                                            {item.map((retailer_, indexRet) => {
                                                const { info } = retailer_;
                                                // eslint-disable-next-line
                                                let display: string;
                                                if (info && info.display) {
                                                    display = info.display;
                                                } else {
                                                    display = "";
                                                }
                                                return !allRetailersSelected && !retailer_.isFavorite ? null : (
                                                    <div key={indexRet} className={"retailerGrp"}>
                                                        <div
                                                            className={classNames("favoriteIcon_Div", {
                                                                grayed: COMING_SOON.indexOf(display) > -1,
                                                            })}
                                                        >
                                                            {retailer_.isFavorite ? (
                                                                <HeartSelected className="favoriteIcon_" onClick={() => removeFromFavorite(index, indexRet)} />
                                                            ) : (
                                                                <HeartDefault className="favoriteIcon_" onClick={() => addToFavorite(index, indexRet)} />
                                                            )}
                                                        </div>
                                                        <a
                                                            className={classNames("retailerAnchor", {
                                                                grayed: COMING_SOON.indexOf(display) > -1,
                                                            })}
                                                            // eslint-disable-next-line
                                                            href={retailer_.affiliate_url}
                                                            target={"_blank"}
                                                            rel="noreferrer"
                                                        >
                                                            {display}
                                                        </a>
                                                        {COMING_SOON.indexOf(display) > -1 && <span className="coming-soon">COMING SOON</span>}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                ) : null;
                            })}
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <div className="retailers">
            {Insecure && (
                <div className="retailers_section_left">
                    <div className="footer_section">{"© Klothed Technologies Inc."}</div>
                </div>
            )}
            {!Insecure && (
                <div className="retailerToggle" style={{ left: `calc(50vw - ${window.innerWidth - document.body.clientWidth}px)` }}>
                    <div className={`retailerToggle_wrapper all_retailers_wrapper ${allRetailersSelected ? "all_retailers_wrapper_selected" : ""}`}>
                        <div
                            onClick={() => setAllRetailersSelected(true)}
                            className={`retailerToggle_btn all_retailers ${allRetailersSelected ? "all_retailers_selected" : ""}`}
                        >
                            {"ALL RETAILERS"}
                        </div>
                    </div>
                    <div
                        onClick={() => setAllRetailersSelected(false)}
                        className={`retailerToggle_wrapper my_retailers_wrapper ${!allRetailersSelected ? "my_retailers_wrapper_selected" : ""}`}
                    >
                        <div className={`retailerToggle_btn my_retailers ${!allRetailersSelected ? "my_retailers_selected" : ""}`}>
                            {"MY RETAILERS"} <HeartOne />
                        </div>
                    </div>
                </div>
            )}
            <div className={`retailers_section retailers_user`}>
                <div className={`retailers_section_left`}>
                    {allRetailersSegregated &&
                        allRetailersSegregated.map((item, index) => {
                            const atLeastOneFav = item.find((obj) => obj.isFavorite);
                            return item && item.length ? (
                                !allRetailersSelected && !atLeastOneFav ? null : (
                                    <div ref={ref[index]} key={index} className={"retailersAlphaGrp"}>
                                        <div className="alphaBold">{String.fromCharCode(index + 65).toUpperCase()}</div>
                                        {item.map((retailer_, indexRet) => {
                                            const { info } = retailer_;
                                            // eslint-disable-next-line
                                            let display: string;
                                            if (info && info.display) {
                                                display = info.display;
                                            } else {
                                                display = "";
                                            }
                                            return !allRetailersSelected && !retailer_.isFavorite ? null : (
                                                <div key={indexRet} className={"retailerGrp"}>
                                                    <div
                                                        className={classNames("favoriteIcon_Div", {
                                                            grayed: COMING_SOON.indexOf(display) > -1,
                                                        })}
                                                    >
                                                        {retailer_.isFavorite ? (
                                                            <HeartSelected className="favoriteIcon_" onClick={() => removeFromFavorite(index, indexRet)} />
                                                        ) : (
                                                            <HeartDefault className="favoriteIcon_" onClick={() => addToFavorite(index, indexRet)} />
                                                        )}
                                                    </div>
                                                    <a
                                                        className={classNames("retailerAnchor", {
                                                            grayed: COMING_SOON.indexOf(display) > -1,
                                                        })}
                                                        // eslint-disable-next-line
                                                        href={retailer_.affiliate_url}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {display}
                                                    </a>
                                                    {COMING_SOON.indexOf(display) > -1 && <span className="coming-soon">COMING SOON</span>}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )
                            ) : null;
                        })}
                </div>
                <div className={`retailers_section_right`}>
                    <div className="retailerRightHeading">{"Try it on."}</div>
                </div>
            </div>
            <div className="alphabetList" style={{ left: `calc(50vw - ${window.innerWidth - document.body.clientWidth}px)` }}>
                {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"].map(
                    (item, index) => (
                        <div onClick={() => scrollToAlphabet(index)} className="alphabetList_" key={index}>
                            {item}
                        </div>
                    )
                )}
            </div>
            <div className="horizontalSep__" style={{ left: `calc(50vw - ${window.innerWidth - document.body.clientWidth}px)` }}></div>
        </div>
    );
}

export default Retailers;
