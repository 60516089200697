import React, { useCallback, useEffect, useRef, useState } from "react";
import { AuthState, ShopperImageState, checkImage, useSiteSession } from "@v2/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import classNames from "classnames";
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from "react-dropzone";
import { ReactComponent as CrossIcon } from "@v2/assets/photo/cross.svg";
import Cloth2 from "@v2/assets/photo/cloth-2.webp";
import Selfie1 from "@v2/assets/photo/selfie-1.webp";
import Selfie2 from "@v2/assets/photo/selfie-2.webp";
import Shadow from "@v2/assets/photo/shadow.webp";
import Solo2 from "@v2/assets/photo/solo-2.webp";
import Upright2 from "@v2/assets/photo/upright-2.webp";

interface UnderlineTextProps extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
}

function UnderlineText(props: UnderlineTextProps) {
    const { text, className, ...rest } = props;

    return (
        <div className={classNames("underline-text", className)} {...rest}>
            <span>{text}</span>
            <hr />
        </div>
    );
}

function PhotoDropZone({
    className,
    getInputProps,
    getRootProps,
}: {
    className?: string;
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}) {
    return (
        <div {...getRootProps()} className={classNames("dropzone_wrapper", className)}>
            <input {...getInputProps()} />
            <div className={classNames("dropzone", className)}>
                <div className="spacer" />
                <p>Tap to select.</p>
                <p>Toss on form-fitting pants and a light-colored t-shirt, throw up long hair, and stand upright against a plain background.</p>
                <p>Check below to learn more about how to pose.</p>
                <div className="spacer" />
            </div>
        </div>
    );
}

function PhotoHolder({
    displayImage,
    imageSrc,
    clearPhoto,
    getInputProps,
    getRootProps,
}: {
    displayImage: boolean;
    imageSrc?: string;
    clearPhoto: () => void;
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}) {
    const { shopperImageState } = useSiteSession();
    return (
        <div className="profile_photo_wrapper">
            <LazyLoadImage className={classNames("photo", shopperImageState)} src={imageSrc} />
            <PhotoDropZone getRootProps={getRootProps} getInputProps={getInputProps} className={displayImage ? undefined : "overlay"} />
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classNames("clear", !displayImage && "hide")}
                onClick={clearPhoto}
            >
                <path
                    d="M12 1.209 10.791 0 6 4.791 1.209 0 0 1.209 4.791 6 0 10.791 1.209 12 6 7.209 10.791 12 12 10.791 7.209 6 12 1.209Z"
                    fill="var(--klothed-tile-textColor)"
                />
            </svg>
        </div>
    );
}

function PhotoHandler() {
    const { shopperImageState, token, adam, uploadShopperImage, shopperImageInfo, setPageTitle, setPageDescription } = useSiteSession();
    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Your Photo.`);
        setPageDescription(`Klothed - Everything looks better on you. - Your Photo.`);
    }, [setPageTitle, setPageDescription]);

    const isDirty = useRef(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const image = useRef<File>();
    const [displayImage, setDisplayImage] = useState(shopperImageState !== ShopperImageState.Missing);

    const [uploading, setUploading] = useState(false);

    const shopperPhotoExists = shopperImageState !== ShopperImageState.Missing && shopperImageState !== ShopperImageState.Unknown;

    const [displayImageSrc, setDisplayImageSrc] = useState("");

    const clearPhoto = useCallback(() => {
        setDisplayImage(false);
        isDirty.current = true;
        setSubmitEnabled(false);
        image.current = undefined;
    }, [setDisplayImage, setSubmitEnabled]);

    const currentClicked = useCallback(async () => {
        setUploading(false);
        isDirty.current = false;
        setSubmitEnabled(false);
        if (shopperImageInfo?.lastImageID && shopperPhotoExists) {
            const url = `${process.env.REACT_APP_API_URL}/secure/image/${shopperImageInfo.lastImageID}?token=${token}`;
            const r = await checkImage(url);
            if (r) {
                setDisplayImageSrc(url);
                setDisplayImage(true);
                return;
            } else {
                const url = `${process.env.REACT_APP_API_URL}/secure/image/${shopperImageInfo.lastImageID}?name=image&token=${token}`;
                const r = await checkImage(url);
                if (r) {
                    setDisplayImageSrc(url);
                    setDisplayImage(true);
                    return;
                }
            }
        }
        setDisplayImageSrc("");
        setDisplayImage(false);
    }, [shopperPhotoExists, shopperImageInfo, setDisplayImageSrc, setDisplayImage, setSubmitEnabled]);

    const onImageChange = useCallback(
        async (files: File[]) => {
            if (!Array.isArray(files) || files.length < 1) {
                setDisplayImage(false);
                return;
            }
            image.current = files[0];
            const imageURL = URL.createObjectURL(image.current);
            if (await checkImage(imageURL)) {
                setDisplayImageSrc(imageURL);
                setDisplayImage(true);
                isDirty.current = true;
                // TODO: check image
                setSubmitEnabled(true);
            }
        },
        [setDisplayImage, setDisplayImageSrc]
    );

    useEffect(() => {
        currentClicked();
    }, [shopperImageState, shopperImageInfo?.activeImageID, currentClicked]);

    const onSubmit = useCallback(async () => {
        setSubmitEnabled(false);
        if (!image.current || !adam) return;
        setUploading(true);
        await uploadShopperImage(image.current);
        currentClicked();
    }, [adam, uploadShopperImage, currentClicked, setSubmitEnabled]);
    const { getRootProps, getInputProps, open } = useDropzone({ onDrop: onImageChange, multiple: false });

    return (
        <div className="profile_photo_section_v2">
            <h1>Your photo.</h1>
            <form
                id="photo-upload"
                className={shopperImageState}
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <PhotoHolder
                    imageSrc={displayImageSrc}
                    displayImage={displayImage}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    clearPhoto={clearPhoto}
                />
                {submitEnabled ? (
                    <input type="submit" className="uploadImageBtn" value="Upload this photo" disabled={!submitEnabled} />
                ) : uploading ? (
                    <div className="status in-review">
                        <h2>Uploading ...</h2>
                        <span>Please do not refresh the site while your photo uploads.</span>
                    </div>
                ) : shopperImageState === ShopperImageState.Missing ? (
                    <button
                        className={classNames(shopperImageState, "current")}
                        onClick={(e) => {
                            e.preventDefault();
                            open();
                        }}
                    >
                        Upload a photo
                    </button>
                ) : shopperImageState === ShopperImageState.InReview ? (
                    <div className="status in-review">
                        <h2>Optimizing ...</h2>
                        <span>
                            We’re optimizing our AI for your uploaded photo. This could take a few moments or a few minutes. Feel free to leave this page and
                            shop styles.
                        </span>
                    </div>
                ) : shopperImageState === ShopperImageState.Approved ? (
                    <div className="status approved">
                        <h2>You’re ready to try on styles!</h2>
                        <UnderlineText text="Change photo" onClick={open} />
                    </div>
                ) : shopperImageState === ShopperImageState.Rejected ? (
                    <div className="status rejected">
                        <h2>Your photo didn’t work with our AI. Try uploading another photo.</h2>
                        <UnderlineText text="Upload photo" onClick={open} />
                    </div>
                ) : (
                    <button
                        className={classNames(shopperImageState, "current")}
                        onClick={(e) => {
                            e.preventDefault();
                            open();
                        }}
                    >
                        Upload a photo
                    </button>
                )}
            </form>
            <div className="tips-section">
                <div>
                    <h2>Pose like this.</h2>
                    <img src={Selfie1} />
                    <p>Toss on form-fitting pants and a light-colored t-shirt, throw up long hair, and stand upright against a plain background.</p>
                </div>
                <div>
                    <h2>Don&apos;t pose like this.</h2>
                    <div className="crossed">
                        <img src={Selfie2} />
                        <div>
                            <CrossIcon />
                        </div>
                    </div>
                    <p>No selfies. Face the camera as a friend takes your shot.</p>
                    <div className="crossed">
                        <img src={Upright2} />
                        <div>
                            <CrossIcon />
                        </div>
                    </div>
                    <p>Stand up straight and show your full body. Show your arms and legs against a plain background.</p>
                    <div className="crossed">
                        <img src={Solo2} />
                        <div>
                            <CrossIcon />
                        </div>
                    </div>
                    <p>Stand alone in the foreground. We want to focus on you.</p>
                    <div className="crossed">
                        <img src={Cloth2} />
                        <div>
                            <CrossIcon />
                        </div>
                    </div>
                    <p>Don&apos;t wear dark clothes. Light colored clothes work best.</p>
                    <div className="crossed">
                        <img src={Shadow} />
                        <div>
                            <CrossIcon />
                        </div>
                    </div>
                    <p>No shadows. Stand evenly lit.</p>
                </div>
            </div>
        </div>
    );
}

export function Photo() {
    const { authState } = useSiteSession();

    return authState === AuthState.Unknown ? <div>Loading...</div> : <PhotoHandler />;
}
