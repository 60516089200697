import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth, useSiteSession } from "@v2/utils";
import isEmail from "validator/lib/isEmail";
import { ReactComponent as MailIcon } from "@v2/assets/mailIcon.svg";

export function ForgotPassword() {
    const { user } = useSiteSession();

    const EmailNotFound = "Sorry, the email you entered doesn't belong to an account.";

    const [email, setEmail] = useState<string>(user?.email || "");
    const [isEmailValid, setIsEmailValid] = useState(!!user?.email);
    const [mailSent, setMailSent] = useState(false);
    const [emailError, setEmailError] = useState<string>("");

    const onClickSubmit = () => {
        sendPasswordResetEmail(auth, email, { url: `${process.env.REACT_APP_WEB_URL}${process.env.REACT_APP_WEB_LOGIN2}` })
            .then(() => {
                setMailSent(true);
            })
            .catch((error) => {
                if (error && error.code.includes("user-not-found")) {
                    setEmailError(EmailNotFound);
                } else {
                    setEmailError(error.code);
                }
            });
    };

    return (
        <div className="forgotPassword_v2">
            {mailSent ? (
                <div className="email_enterDiv">
                    <h4 className="email_enterDivText">Check your email.</h4>
                    <MailIcon />
                    <div className="email_enterDivTextLower">Please check your email for password reset instructions.</div>
                </div>
            ) : (
                <form
                    className="email_enterDiv"
                    onSubmit={(e) => {
                        e.preventDefault();
                        onClickSubmit();
                    }}
                >
                    <h4>Reset your password.</h4>
                    <div className="email_input_div">
                        <input
                            className="email_input_box"
                            placeholder=" "
                            type="email"
                            value={email}
                            onFocus={() => setEmailError("")}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                setIsEmailValid(isEmail(event.target.value));
                                setEmailError("");
                            }}
                        />
                        <label className="email_input_label">{"Enter email address"}</label>
                    </div>
                    {emailError && <div className="invalidEmailEntered">{emailError}</div>}
                    <input type="submit" className={`email_enterDivSubmit ${email && isEmailValid ? "email_enterDivSubmitActive" : ""}`} value="Send link" />
                </form>
            )}
        </div>
    );
}
