import React from "react";
import { UserProfileHeightAndStyle } from "@v2/components/profile/heightAndStyle";

export default function ProfileHeightAndStylePage() {
    return (
        <div className="user_profile_height_style">
            <UserProfileHeightAndStyle />
        </div>
    );
}
