/**
 * External dependencies.
 */
import React, { useEffect } from "react";

/**
 * Internal dependencies.
 */
import { WrapperLine, Layout, ColumnBoxes, ColumnBoxesImage, ColumnBoxesText, HeadingLine, Container } from "@v2/components/burger";
import Box1 from "@v2/assets/aboutUsConfidence/box1.webp";
import Box2 from "@v2/assets/aboutUsConfidence/box2.webp";
import Box3 from "@v2/assets/aboutUsConfidence/box3.webp";
import LineImage from "@v2/assets/aboutUsConfidence/line-image.webp";

import { ReactComponent as Icon1 } from "@v2/assets/aboutUsConfidence/icon1.svg";
import { ReactComponent as Icon2 } from "@v2/assets/aboutUsConfidence/icon2.svg";
import { ReactComponent as Icon3 } from "@v2/assets/aboutUsConfidence/icon3.svg";

import "./aboutUsConfidence.scss";
import { useSiteSession } from "@v2/utils";

const AboutUsConfidence = () => {
    const { setPageDescription, setPageTitle } = useSiteSession();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - About.`);
        setPageDescription(`Klothed - Everything looks better on you. - About.`);
    }, [setPageTitle, setPageDescription]);
    return (
        <Layout spacing>
            <HeadingLine side="right">
                <h2>
                    <span>
                        BUY WITH CONFIDENCE<strong className="no-ls">.</strong>
                    </span>
                </h2>
            </HeadingLine>
            <WrapperLine side="left">
                <ColumnBoxes>
                    <ColumnBoxesText>
                        <Icon1 />
                        <h3>Nothing holds you back. Easily try on whatever you want...</h3>
                    </ColumnBoxesText>
                    <ColumnBoxesImage src={Box1} width={341} height={511} className="img1">
                        <p>
                            <span className="colored-text">Discover.</span> <span className="text">Step into a style you never thought you’d wear.</span>
                        </p>
                    </ColumnBoxesImage>
                </ColumnBoxes>
                <ColumnBoxes>
                    <ColumnBoxesText>
                        <Icon2 />
                        <h3>as much as you want...</h3>
                    </ColumnBoxesText>
                    <ColumnBoxesImage src={Box2} width={247} height={449} align="center" justify="center" className="constrict">
                        <p>
                            <span className="colored-text">Create.</span> <span className="text">Save and heart try-ons for future comparison.</span>
                        </p>
                    </ColumnBoxesImage>
                </ColumnBoxes>
                <ColumnBoxes>
                    <ColumnBoxesText>
                        <Icon3 />
                        <h3>whenever and wherever you want.</h3>
                    </ColumnBoxesText>
                    <ColumnBoxesImage src={Box3} width={338} height={508} align="flex-end" justify="flex-end" className="img3">
                        <p>
                            <span className="colored-text">Envision.</span> <span className="text">Get the precision of in-store shopping from your bed.</span>
                        </p>
                    </ColumnBoxesImage>
                </ColumnBoxes>
            </WrapperLine>
            <div className="line-image">
                <Container>
                    <div className="line-image__inner">
                        <img loading="lazy" src={LineImage} width="195" height="292" />
                    </div>
                </Container>
            </div>
        </Layout>
    );
};

export default AboutUsConfidence;
