import * as React from "react";
const HeightIcon = () => (
    <svg width={22} height={23} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.92 4.363c-.897-1.023-1.401-2.357-2.745-3.028-.695-.35-1.464-.58-2.206-.851-.398-.146-.808-.265-1.21-.4-.339-.115-.627-.152-.902.158-.774.881-1.775 1.524-2.968 1.796a5.328 5.328 0 0 1-.89.122 5.312 5.312 0 0 1-.888-.122C8.92 1.766 7.918 1.123 7.144.242c-.277-.31-.566-.273-.904-.158-.402.135-.81.254-1.208.4-.742.272-1.513.5-2.21.851C1.48 2.006.976 3.34.08 4.363c-.086.096.013.381.11.534.088.129.295.193.451.286.933.55 2.005.9 2.804 1.647.228.213.601.294.869.482.147.104.28.296.304.464.113.792.23 1.586.263 2.383.073 1.837.293 3.677-.206 5.5-.261.96-.519 1.925-.708 2.899-.195 1-.304 2.013-.444 2.983.39.142.626.29.873.307 1.148.08 2.3.17 3.451.17 1.051 0 2.102-.017 3.154-.039 1.05.022 2.102.04 3.152.04 1.152 0 2.303-.09 3.452-.171.248-.018.484-.165.873-.307-.14-.97-.25-1.984-.444-2.983-.19-.974-.448-1.94-.709-2.9-.498-1.823-.278-3.663-.204-5.5.032-.796.15-1.59.263-2.382.023-.167.156-.36.304-.464.266-.189.64-.27.868-.482.799-.748 1.871-1.098 2.803-1.647.156-.093.365-.157.45-.286.1-.153.198-.438.113-.534"
            fill="var(--klothed-textColor)"
        />
    </svg>
);
export default HeightIcon;
