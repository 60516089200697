/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import "./wrapperLine.scss";
import { Container } from "../container";

type Props = {
    side?: string;
    children: React.ReactNode;
};

export const WrapperLine: React.FC<Props> = ({ side = "right", children }) => (
    <div className={classNames("wrapper-line", `wrapper-line--${side}`)}>
        <Container>
            <div className="wrapper-line__inner">{children}</div>
        </Container>
    </div>
);
