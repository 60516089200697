/**
 * External dependencies.
 */
import classNames from "classnames";
import React from "react";

type Props = {
    className?: string;
    children: React.ReactNode;
};

export const Container: React.FC<Props> = ({ children, className }) => <div className={classNames("container", className)}>{children}</div>;
