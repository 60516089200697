import * as React from "react";
const ImageCameraIcon = () => (
    <svg width={24} height={18} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.77.413H9.23L6.462 3.275H.924v14.312h22.154V3.275h-5.539L14.769.413ZM12 14.725c2.294 0 4.154-1.923 4.154-4.294 0-2.371-1.86-4.294-4.154-4.294-2.294 0-4.154 1.923-4.154 4.294 0 2.371 1.86 4.294 4.154 4.294Zm2.77-4.294c0 1.581-1.24 2.863-2.77 2.863s-2.77-1.282-2.77-2.863c0-1.58 1.24-2.862 2.77-2.862s2.77 1.281 2.77 2.862Z"
            fill="var(--klothed-textColor)"
        />
    </svg>
);
export default ImageCameraIcon;
