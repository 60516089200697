import React, { useState } from "react";
import "./home.scss";
import HomeLandingPage from "../homeLandingPage/homeLandingPage";
import HomeProfile from "../homeProfile/homeProfile";
import { ReactComponent as Profile } from "./assets/profile.svg";
import { ReactComponent as ProfileSelected } from "./assets/profileSelected.svg";
import { ReactComponent as TryOns } from "./assets/tryOns.svg";
import { ReactComponent as TryOnsSelected } from "./assets/tryOnsSelected.svg";
import { ReactComponent as Retailors } from "./assets/retailors.svg";
import { ReactComponent as RetailorsSelected } from "./assets/retailorsSelected.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import { ReactComponent as SettingsSelected } from "./assets/settingsSelected.svg";
import { ReactComponent as Search } from "./assets/search.svg";
import Settings from "../settings/settings";
import Retailers from "@v2/components/retailers";
import TryOnsComponent from "../tryOns/tryOns";

enum HomePage {
    Profile = 0,
    TryOns = 1,
    Retailors = 2,
    Settings = 3,
    Search = 4,
    LandingPage = 5,
}

interface headerDate {
    image: React.FunctionComponent;
    selectedImage: React.FunctionComponent;
    headerText: string;
    component: HomePage;
}

interface HomeProps {
    deleteAccountCallback: () => void;
}

function Home(props: HomeProps) {
    const [homeComponent, setHomeComponent] = useState(HomePage.LandingPage);
    const headerDateArray: headerDate[] = [
        { image: Profile, selectedImage: ProfileSelected, headerText: "MY PROFILE", component: HomePage.Profile },
        { image: TryOns, selectedImage: TryOnsSelected, headerText: "My Try-Ons", component: HomePage.TryOns },
        { image: Retailors, selectedImage: RetailorsSelected, headerText: "Retailers", component: HomePage.Retailors },
        { image: SettingsIcon, selectedImage: SettingsSelected, headerText: "Settings", component: HomePage.Settings },
    ];
    return (
        <div className="home_page">
            <div className="homePage_header">
                <div className="homePage_header_left homePage_header_">
                    {headerDateArray.map((items, index) => (
                        <div
                            onClick={() => {
                                setHomeComponent(items.component);
                            }}
                            className="homePage_header_imgDiv"
                            key={index}
                        >
                            {homeComponent === items.component ? <items.selectedImage /> : <items.image />}
                        </div>
                    ))}
                </div>
                <div className="homePage_header_verticalSeperator">
                    <div className="homePage_header_verticalSeperatorLine"></div>
                </div>
                <div className="homePage_header_right homePage_header_">
                    <div className="homePage_header_imgDiv">
                        <Search className="homePage_header_img" />
                    </div>
                    <div className="homePage_header_text">{headerDateArray[homeComponent]?.headerText}</div>
                </div>
            </div>
            {homeComponent === HomePage.LandingPage && <HomeLandingPage key={1}></HomeLandingPage>}
            {homeComponent === HomePage.Profile && <HomeProfile key={2}></HomeProfile>}
            {homeComponent === HomePage.Settings && <Settings deleteAccountCallback={props.deleteAccountCallback} key={3}></Settings>}
            {homeComponent === HomePage.Retailors && <Retailers key={4} />}
            {homeComponent === HomePage.TryOns && <TryOnsComponent key={5} />}
        </div>
    );
}

export default Home;
