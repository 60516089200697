import React from "react";
import Popup from "reactjs-popup";
import { ReactComponent as HeartTransparent } from "./assets/heartTransparent.svg";
import { ReactComponent as HeartSelected } from "./assets/heartSelected.svg";
import { ReactComponent as ContextDots } from "./assets/contextDots.svg";
import { useTileSession } from "@v2/utils";
import { useContextMenu } from "react-contexify";
import { TileDetailsMenu, TileDetailsMenuID } from "@v2/components/menu/tile";
import { TileImageComponent } from "./tileImage";
import classNames from "classnames";

// https://loading.io/palette/647849 <= gif
export interface TileDetails {
    open: boolean;
    close: () => void;
}

export const TileDetailPopup = ({ open, close }: TileDetails) => {
    const { show } = useContextMenu({ id: TileDetailsMenuID });
    const { context } = useTileSession();
    const { showTryon, tile, toggleTryonImage, tryonInProgress, tryonInQueue, isFav, toggleFav, shareTryon, hideOverlay, setHideOverlay } = context;

    return (
        <Popup
            open={open}
            onClose={() => {
                close();
                setHideOverlay(false);
            }}
            className="container"
            modal
        >
            <TileDetailsMenu />
            <div className="close" onClick={close}>
                <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 1.209 10.791 0 6 4.791 1.209 0 0 1.209 4.791 6 0 10.791 1.209 12 6 7.209 10.791 12 12 10.791 7.209 6 12 1.209Z"
                        fill="var(--klothed-textColor)"
                    />
                </svg>
            </div>
            <div className={classNames("tile-detail", hideOverlay && "zoom")}>
                <div className="content">
                    <TileImageComponent displayLarge={true} />
                    {tile.id && (
                        <div onClick={toggleFav} className="favoriteIcon_">
                            {isFav ? <HeartSelected /> : <HeartTransparent />}
                        </div>
                    )}
                    {tile.id && (
                        <div
                            className="contextDots"
                            onClick={(e) =>
                                show({
                                    event: e,
                                })
                            }
                        >
                            <ContextDots />
                        </div>
                    )}
                </div>
                <div className="sub-details">
                    <div className="itemShop">
                        <a href={tile.product_url} target={"_blank"} className="itemShopDetails" rel="noreferrer">
                            <div className="itemShopDetailsProduct">
                                <div className="itemShopDetailsBrand">{tile.product_brand}</div>
                                <div className="itemShopDetailsType">{tile.product_color}</div>
                                <div className="itemShopDetailsRetailer">
                                    <div className="itemShopDetailsRetailer">{tile.product_sale_price ? `$${tile.product_sale_price}` : undefined}</div>
                                </div>
                            </div>
                            <div className="itemShopDetailsShopNow">Shop Now</div>
                        </a>
                        <div className="itemTryonToggle" onClick={toggleTryonImage}>
                            <div>
                                <span>{showTryon ? (tryonInQueue ? "HANG TIGHT" : tryonInProgress ? "UP NEXT" : "VIEW ON MODEL") : "TRY IT ON"}</span>
                            </div>
                        </div>
                        {tile.id && (
                            <div className="itemShareTryOn" onClick={shareTryon}>
                                <span>Share try-on</span>
                                <hr />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Popup>
    );
};
