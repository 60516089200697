import React, { ReactElement } from "react";
import { product_CatalogItemResponse } from "@v2/utils";
import { CatalogTile } from "../tile";
import InfiniteScroll from "react-infinite-scroll-component";

export function CatalogGridComponent({
    title,
    children,
    hide,
    count,
    next,
    hasMore,
}: {
    children: ReactElement[];
    title: string;
    hide?: boolean;
    count?: number;
    next?: () => void;
    hasMore?: boolean;
}) {
    function Header() {
        return (
            <div className="edits_title">
                <span>{title}</span>
            </div>
        );
    }
    return hide ? (
        <></>
    ) : (
        <div className="edits_page">
            {Header()}
            <InfiniteScroll
                dataLength={children.length}
                next={() => {
                    if (next) next();
                }}
                hasMore={hasMore || false}
                loader={<h4>Loading...</h4>}
                className="edits_pageContent"
            >
                <>{children}</>
            </InfiniteScroll>
            {count && (
                <span className="style-count">
                    {count} style{count !== 1 && "s"}
                </span>
            )}
        </div>
    );
}

export function CatalogGridProductContentTemplate({
    products,
    hideDeletedTryonTiles,
    hideReportedTryonTiles,
    hideUnfavoredTryonTiles,
    autoTryon,
}: {
    products: product_CatalogItemResponse[];
    hideDeletedTryonTiles?: boolean;
    hideUnfavoredTryonTiles?: boolean;
    hideReportedTryonTiles?: boolean;
    autoTryon?: number;
}): ReactElement[] {
    return products.map((item, index) => (
        <CatalogTile
            key={index}
            item={item}
            hideDeletedTryonTiles={hideDeletedTryonTiles}
            hideUnfavoredTryonTiles={hideUnfavoredTryonTiles}
            hideReportedTryonTiles={hideReportedTryonTiles}
            autoTryon={autoTryon ? index < autoTryon : false}
        />
    ));
}
