import React, { useState, useEffect, ReactElement } from "react";
import { product_ShopperCatalogsResponse, product_CatalogItemResponse } from "@v2/utils";
import { CatalogTile } from "../tile";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

function Arrow({ children, disabled, className }: { children: React.ReactNode; disabled: boolean; className: string }) {
    return (
        <button disabled={disabled} className={`arrow ${className}`}>
            {children}
        </button>
    );
}

export function LeftArrow() {
    const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(!initComplete || (initComplete && isFirstItemVisible));
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleElements]);

    return (
        <Arrow disabled={disabled} className="left-arrow">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
                transform="rotate(180)"
                onClick={(e) => {
                    e.stopPropagation();
                    scrollPrev();
                }}
            >
                <path
                    d="M15.244 11.767 8.39 18.672a1.12 1.12 0 1 1-1.59-1.579l6.064-6.11-6.11-6.064a1.12 1.12 0 0 1 1.58-1.59l6.904 6.854a1.117 1.117 0 0 1 .006 1.584"
                    fill="var(--klothed-textColor)"
                />
            </svg>
        </Arrow>
    );
}

export function RightArrow() {
    const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(!visibleElements.length && isLastItemVisible);
    React.useEffect(() => {
        if (visibleElements.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleElements]);

    return (
        <Arrow disabled={disabled} className="right-arrow">
            <svg
                onClick={(e) => {
                    e.stopPropagation();
                    scrollNext();
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
            >
                <path
                    d="M15.244 11.767 8.39 18.672a1.12 1.12 0 1 1-1.59-1.579l6.064-6.11-6.11-6.064a1.12 1.12 0 0 1 1.58-1.59l6.904 6.854a1.117 1.117 0 0 1 .006 1.584"
                    fill="var(--klothed-textColor)"
                />
            </svg>
        </Arrow>
    );
}

export function CatalogRowComponent({
    title,
    children,
    hide,
    shopLink,
    count,
}: {
    children: ReactElement[];
    title: string;
    hide?: boolean;
    shopLink: string;
    count?: number;
}) {
    const [expanded, setExpanded] = useState(true);
    const [showArrows, setShowArrows] = useState(false);
    const history = createBrowserHistory();

    function Header(showArrows?: boolean) {
        return (
            <div className={`catalogProductsTitle ${expanded ? "expanded" : "minimized"}`}>
                <span>{title}</span>
                <span className="expand" onClick={() => setExpanded((s) => !s)}>
                    {expanded ? (
                        <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 2.53149L10 12.5315L0 2.53149L1.775 0.756495L10 8.98149L18.225 0.756495L20 2.53149Z" fill="var(--klothed-textColor)" />
                        </svg>
                    ) : (
                        <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 10.7566L10 0.756592L20 10.7566L18.225 12.5316L10 4.30659L1.775 12.5316L0 10.7566Z" fill="var(--klothed-textColor)" />
                        </svg>
                    )}
                </span>
                <Link to={{ search: history.location.search, pathname: shopLink }} className="shop-all">
                    <span>Shop All</span>
                    <hr />
                </Link>
                {!isMobile && showArrows && (
                    <>
                        <LeftArrow /> <RightArrow />
                    </>
                )}
            </div>
        );
    }

    return hide ? (
        <></>
    ) : (
        <>
            {isMobile ? (
                <div className="catalog_products_page">
                    {Header()}
                    {expanded && <div className="catalog_products_pageContentMobile">{children}</div>}
                    {(count || children.length) && expanded && (
                        <span className="style-count">
                            {count} style{count !== 1 && "s"}
                        </span>
                    )}
                </div>
            ) : (
                <div
                    className="catalog_products_page"
                    onMouseEnter={() => {
                        setShowArrows(true);
                    }}
                    onMouseLeave={() => {
                        setShowArrows(false);
                    }}
                >
                    <ScrollMenu
                        Header={Header(showArrows)}
                        Footer={
                            count &&
                            expanded && (
                                <span className="style-count">
                                    {count} style{count !== 1 && "s"}
                                </span>
                            )
                        }
                    >
                        {expanded ? [...children] : <></>}
                    </ScrollMenu>
                </div>
            )}
        </>
    );
}

export function CatalogRowProductContentTemplate({
    products,
    hideDeletedTryonTiles,
    hideReportedTryonTiles,
    hideUnfavoredTryonTiles,
    autoTryon,
}: {
    products: product_CatalogItemResponse[];
    hideDeletedTryonTiles?: boolean;
    hideReportedTryonTiles?: boolean;
    hideUnfavoredTryonTiles?: boolean;
    autoTryon?: number;
}): ReactElement[] {
    return products.map((item, index) => (
        <CatalogTile
            key={index}
            item={item}
            hideDeletedTryonTiles={hideDeletedTryonTiles}
            hideReportedTryonTiles={hideReportedTryonTiles}
            hideUnfavoredTryonTiles={hideUnfavoredTryonTiles}
            autoTryon={autoTryon ? index < autoTryon : false}
        />
    ));
}

export const CatalogRowTemplate = ({
    category,
    hideOnDelete,
    count,
    autoTryon,
}: {
    category?: product_ShopperCatalogsResponse;
    hideOnDelete?: boolean;
    count?: number;
    autoTryon?: number;
}) => {
    const [productsToDisplay, setProductsToDisplay] = useState<product_CatalogItemResponse[]>([]);
    useEffect(() => {
        if (!category || !category.products) {
            // reset state
            setProductsToDisplay([]);
            return;
        } else if (!Array.isArray(category.products) || category.products.length === 0) {
            // empty rack
            setProductsToDisplay([]);
            return;
        }
        setProductsToDisplay(category.products);
    }, [category, setProductsToDisplay]);

    return (
        <CatalogRowComponent
            title={category?.title || ""}
            hide={!category || !category.products}
            shopLink={`${process.env.REACT_APP_WEB_EDITS_HOME}/${category?.uid}`}
            count={count}
        >
            {[
                ...CatalogRowProductContentTemplate({
                    hideDeletedTryonTiles: hideOnDelete,
                    hideReportedTryonTiles: false,
                    products: productsToDisplay || [],
                    autoTryon,
                }),
            ]}
        </CatalogRowComponent>
    );
};
