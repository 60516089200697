import React from "react";
import { UserProfilePhoto } from "@v2/components/profile/photo";

export default function ProfilePhotoPage() {
    return (
        <div className="user_profile_photo">
            <UserProfilePhoto />
        </div>
    );
}
