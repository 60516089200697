import React from "react";
import { Menu, Item } from "react-contexify";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

export const PoliciesMenuID = "policies_menu";
export function PoliciesMenu() {
    const history = createBrowserHistory();
    return (
        <Menu id={PoliciesMenuID} theme="klothed-menu">
            <Item
                className="title"
                onClick={(event) => {
                    event.props.back();
                }}
                closeOnClick={false}
            >
                Policies.
            </Item>
            <Item>
                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_TERMS_OF_SERVICE || "#" }}>Terms of service.</Link>
            </Item>
            <Item>
                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_PRIVACY || "#" }}>Privacy policy.</Link>
            </Item>
            <Item>
                <Link to={{ search: history.location.search, pathname: process.env.REACT_APP_WEB_COOKIE_POLICY || "#" }}>Cookie policy.</Link>
            </Item>
        </Menu>
    );
}
