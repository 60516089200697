import React, { useCallback, useEffect, useRef, useState } from "react";
import { AuthState, product_CatalogItemResponse, tryon_ShopperTryonInfoResult, useSiteSession } from "@v2/utils";
import { useNavigate } from "react-router-dom";
import { CatalogGridComponent, CatalogGridProductContentTemplate } from "../edits/catalogGridTemplate";

export function FavoritesPage() {
    const { authState, favoriteProducts, adam, setPageTitle, setPageDescription } = useSiteSession();
    const [catalogProducts, setCatalogProducts] = useState<product_CatalogItemResponse[]>();

    const [hasMore, setHasMore] = useState(false);
    const page = useRef(0);

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Your loves.`);
        setPageDescription(`Klothed - Everything looks better on you. - Your loves.`);
    }, [setPageTitle, setPageDescription]);

    const navigate = useNavigate();
    const next = useCallback(async () => {
        if (!adam) {
            return;
        }
        try {
            let res: tryon_ShopperTryonInfoResult;
            if (page.current === 0 && favoriteProducts) {
                res = favoriteProducts;
            } else {
                res = (await adam.secureShopperFavoritesGet({
                    page: page.current,
                })) as tryon_ShopperTryonInfoResult;
            }
            page.current += 1;
            if (res?.result) {
                const catalogProducts = res?.result.map((t) => ({
                    favorites_id: t.favorite_id,
                    id: t.id,
                    image_id: t.product_image_id,
                    product_info: t.product_info,
                    product_url: t.url_affiliated,
                    product_color: t.product_color,
                    product_gender: t.product_gender,
                    product_sale_price: t.product_sale_price,
                    product_sizes: t.product_sizes,
                    product_price: t.product_price,
                    product_brand: t.product_brand,
                    product_type: t.product_type,
                }));
                setCatalogProducts((c) => {
                    if (!c) return catalogProducts;
                    return [...c, ...catalogProducts];
                });
            }
        } catch (err) {
            console.error("failed fetch products for catalog", err);
        }
    }, [setCatalogProducts, adam, setHasMore, favoriteProducts]);

    useEffect(() => {
        if (favoriteProducts?.count && catalogProducts) {
            setHasMore(catalogProducts.length < favoriteProducts?.count);
        }
    }, [catalogProducts, setHasMore, favoriteProducts?.count]);

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoUserAccount:
            case AuthState.ServerError:
            case AuthState.NoAdmittance:
                navigate(process.env.REACT_APP_WEB_RACKS_HOME || "#");
                return; // show sign up page
            case AuthState.NoKlothedAccount:
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                next();
                return;
        }
    }, [authState, navigate]);

    return authState === AuthState.Unknown ? (
        <div>Loading</div>
    ) : (
        <CatalogGridComponent title={"Your loves."} count={favoriteProducts?.count} hasMore={hasMore} next={next}>
            {[
                ...CatalogGridProductContentTemplate({
                    hideDeletedTryonTiles: true,
                    hideReportedTryonTiles: false,
                    hideUnfavoredTryonTiles: true,
                    products: catalogProducts || [],
                }),
            ]}
        </CatalogGridComponent>
    );
}
