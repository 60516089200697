import React from "react";
import { Link } from "react-router-dom";
export const QAPair = [
    {
        question: "What is Klothed?",
        answer: () => (
            <>
                <div>
                    Klothed is AI for clothing try-on. Our Klothed Racks product is a shopping experience where shoppers can browse a curated set of over 10k
                    in-season items across hundreds of major brands and try-on every single one. The shopper gets a completely personalized style preview of how
                    they would look wearing an item.
                </div>
                <div>
                    Klothed is designed to address the frustration you feel when your clothing purchase arrives at home for you only to realize that you don’t
                    like the way the style looks on you. At that moment, this leads to shopper frustration and returns. This phenomenon stymies conversion for
                    retailers as shoppers have trouble building the purchase confidence to convert - primarily because they have trouble imagining what they
                    would look like in a particular style.
                </div>
            </>
        ),
    },
    {
        question: "Does Klothed cost anything for shoppers to use?",
        answer: () => <div>No, Klothed is completely free for shoppers! We don’t charge you anything, and we don’t spam you with annoying ads.</div>,
    },
    {
        question: "How does Klothed make money?",
        answer: () => (
            <div>
                When you use our getKlothed try-on service and then make a purchase, the retailer pays us a small commission through our affiliate partners. It
                is a win for everyone: you get a nice outfit, the retailer makes a sale, and we earn our commission.
            </div>
        ),
    },
    {
        question: "How does Klothed work?",
        answer: () => (
            <div>
                There are a number of ways to interact with Klothed.
                <ol>
                    <li>
                        <p>
                            Klothed Racks is a curated shopping experience with curated racks of clothing from hundreds of brands that are available for anyone
                            to browse; however, if you want to try on clothing, the shopper must upload a picture of themselves. This is the best way to get
                            started with Klothed. We have worked hard to curate the clothing and shopper experience.
                        </p>
                    </li>
                    <li>
                        <p>In the future, Klothed will be accessible through our API. Please contact us for further information on access and pricing.</p>
                    </li>
                </ol>
            </div>
        ),
    },
    {
        question: "Is Klothed a web app, mobile app, or an extension?",
        answer: () => <div>First and foremost, Klothed Racks is a web browser based application. You can use Klothed Racks on desktop and mobile.</div>,
    },
    {
        question: "How much information do I need to provide Klothed?",
        answer: () =>
            `We ask you for two pieces of information to enable virtual try-on. First, we ask for one solo photo of you facing forward wearing simple clothing against a plain background. Second, we ask for your height. That’s it!  But, please follow our directions closely during the signup process to ensure your picture will work with our getKlothed™ try-on engine.  The better job you do getting your photo taken, the better our AI will perform for you!`,
    },
    {
        question: "What do I need to wear in my try-on photo?",
        answer: () =>
            `Wear whatever you are comfortable in, but a general rule of thumb is that simple is better. While you don’t need to wear yoga pants, you definitely shouldn’t wear a poncho or other items that make it difficult to see the shape of your body. Our recommendation: shorts or jeans and a form-fitting t-shirt. Light colors work best, and don’t wear a hat. If you have long hair, please put it up or back (future product enhancements will include expanding coverage for additional hair styles). Wear whatever shoes you like, but please keep in mind that simple or barefoot is always better.`,
    },
    {
        question: "Does Klothed sell my personal information?",
        answer: () =>
            `No! We know that your information belongs to you. We absolutely respect that and use your information for two – and only two – things. First, we use it to generate your personalized try-on images. Second, we use it to improve our algorithms that power your experience. AI programs learn from examples, so every time you try something on, it is an opportunity for our algorithms to improve a little bit and serve you better.`,
    },
    {
        question: "Can I change/update my picture?",
        answer: () => `Yes, not to fret, you can change your photo for any or no reason whenever you like.`,
    },
    {
        question: "Can I save my try-ons for later?",
        answer: () =>
            `Yes, all of your try-ons are automatically saved in your “Latest looks” Rack for you to go back to whenever you like. You can mark favorites, share with friends, or put together a wish list.`,
    },
    {
        question: "Does Klothed help you determine what size to order?",
        answer: () =>
            `Our focus is on helping you visualize how something would look on you. This is all done by using AI to combine your picture with the product picture. So we can’t tell you what size to get, but we can show you an image illustrating how that outfit might look on you.  It’s a completely personalized style preview.`,
    },
    {
        question: "How accurate does the clothing look?",
        answer: () =>
            `The output varies depending on the picture. Our AI algorithms normally do a pretty good job at creating the try-on visualization, but they can get confused sometimes. When that happens, the try-on visualization becomes less accurate. Occasionally, the AI gets very confused and makes a nonsensical image. Don’t worry if that happens. Think of the AI as an easily confused friend who is really good with Photoshop.`,
    },
    {
        question: "Do you guarantee the clothing will look the same as it does in my virtual try-on?",
        answer: () =>
            `No, we can’t provide any guarantee. Our AI algorithms are good at what they do but are not perfect. We are working hard to continuously improve our algorithms and give you the most accurate images we can, but please check the merchant’s return policy before making a purchase. We don’t have any control over the merchant’s return policy.`,
    },
    {
        question: "Can I share my looks with my friends and family?",
        answer: () =>
            `Yes! Please share the try-on images with your friends, family, followers, and anyone else you like. We are working to continuously improve our social media integration to make it super easy for you to share the pictures. More is coming soon!`,
    },
    {
        question: "Does Klothed work for trying on all categories of clothing?",
        answer: () =>
            `Currently, Klothed works with most types of garments except for swimwear, underwear, lingerie, or other revealing outfits. This isn’t because we don’t like those garments or anything like that. Our AI algorithms just don’t work very well on those types of clothing. We plan to include these categories in the future.`,
    },
    {
        question: "Does Klothed work for trying on accessories, like jewelry, handbags, shoes, hats, and belts?",
        answer: () =>
            `We don’t yet offer try-on targeted at accessories. However, if the model wearing the outfit in the product image is wearing a watch or other accessory, then our AI engine might include it in the try-on image. In the near future, we plan to add mix-and-match features that would let you add or remove accessories. Stay tuned!`,
    },
    {
        question: "Can I use Klothed for clothing I already own?",
        answer: () =>
            `Currently, Klothed only works on our covered retailer sites, so it won’t work on pictures of clothing in your physical closet. Our hope is that over time you will have fun and find value in using Klothed so that your try-ons eventually match your actual closet!`,
    },
    {
        question: "What happens if I lose or gain weight after my try-on photo is taken?",
        answer: () => (
            <div>
                We know that people change over time. If you feel that your Klothed try-ons no longer match your body, then please upload a new replacement
                photo in your
                <Link className="orangeText" to={process.env.REACT_APP_WEB_SHOPPER || "#"}>
                    {" profile settings"}
                </Link>
                .
            </div>
        ),
    },
    {
        question: "Can I try on any outfit I want?",
        answer: () => (
            <div>
                You are welcome to try on any product on Klothed Racks. That being said, our getKlothed™ service is new, and our AI is learning. The more your
                body differs from the model’s, the more variability is introduced, and the harder it is for our AI to get it right. We are working hard to
                improve this, so please bear with us as we continue to make progress. If you notice a try-on that does not look right, please use the “report”
                button to let us know!
            </div>
        ),
    },
    {
        question: "My friend was able to try on an outfit, but when I try, it doesn’t work. Are you discriminating against people who look like me?",
        answer: () => (
            <div>
                Never! We want everyone to be able to getKlothed™. Our goal is to build something that helps every single human on our planet choose clothing
                that makes them feel good and look their best. No one should be excluded! That’s part of our core mission. Currently, there are limitations to
                what our generative AI technology can do, and some combinations of product and shopper don’t produce appropriate images. Please let us know if
                you are unhappy with an image or if you couldn’t try on a particular outfit. When you let us know what didn’t work, we can use that data to
                improve our algorithms. We never deliberately exclude anyone, and we are working hard to include everyone.
            </div>
        ),
    },
    {
        question: "How does Klothed determine which product picture to use for a try-on?",
        answer: () => (
            <div>
                The AI tends to favor forward-facing, full-length poses. As discussed above, high variability between the product and shopper can also be
                challenging for our AI. In the event that a product has multiple fashion models in poses above our threshold, our algorithms favor the fashion
                model with the highest affinity to the shopper. If the affinity to the shopper is too little, the algorithm will not be able to complete the
                try-on. Like all of our algorithms, this one is improving all the time.
            </div>
        ),
    },
    {
        question: "Can I do more than one try-on at a time?",
        answer: () => (
            <div>
                You can tee up as many try-ons as you like in Klothed Racks, but they will run one at a time. In fact, we encourage you to be as efficient with
                your online shopping as you’d like. Kickoff a bunch of try-ons in Klothed Racks and feel free to keep browsing and come back to your results.
            </div>
        ),
    },
    {
        question: "What type of products work best for a try-on?",
        answer: () => (
            <div>
                It’s not so much about the product as it is about the product model’s pose. Forward-facing, full-length poses work best for a try-on. That’s not
                to say other poses won’t work; rather, the farther the product model’s pose gets from a forward-facing, full-length pose, the harder it is for
                our AI to get the try-on just right. We anticipate that over time, our AI will learn to handle more and more poses accurately. Just as
                complicated poses may pose a challenge, really complicated fabrics may be tough as well. The more experience our AI gets, the better we will do
                with these fabrics.
            </div>
        ),
    },
    {
        question: "What is the getKlothed™ service?",
        answer: () => (
            <div>
                The getKlothed™ service enables shoppers to try-on clothing (virtually) when browsing the Klothed Racks. The service then saves all your virtual
                try-on pictures for future reference. The service enables shoppers to share their try-on pictures with others (e.g. family, friends, and social
                media followers).
            </div>
        ),
    },
    {
        question: "What if I want to try on a particular outfit, but there is no human model present in the image?",
        answer: () => <div>Currently, we only support products with a product model wearing the outfit.</div>,
    },
    {
        question: "What do I do if the “K” try-on button doesn’t appear?",
        answer: () => (
            <div>
                Please reload the page, if you still don’t see it, please try scrolling down to see if it appears. Sometimes websites have overlays that block
                the “K” try-on button from showing up. On mobile you may need to log out of the app and login to the extension again. If none of that works,
                please quit the browser and try again.
            </div>
        ),
    },
];

export const GetExtension = [
    {
        question: (
            <div>
                First, download the <a className="orangeText">Klothed app</a> in your iPhone’s App Store.
            </div>
        ),
        answer: () => "Then launch the app and select the Enable for Safari button.",
    },
    {
        question: "Then just follow the steps.",
        answer: () =>
            "Once you select that Enable for Safari button in the Klothed app, we guide you through how to enable the extension. But check in here if you want to review the steps at any point.",
    },
    {
        question: "Enable the extension.",
        answer: () => "Enable the Klothed extension for Safari.",
    },
    {
        question: "Almost there...",
        answer: () => "Just follow the prompts to allow the extension to work while you shop.",
    },
    {
        question: "What if I don’t see a Review button?",
        answer: () => (
            <div>
                Tap the <span style={{ fontStyle: "italic" }}>I don’t see a Review button</span> link at the bottom of the screen to allow the extension to work
                while you shop.
            </div>
        ),
    },
];
