import React, { useEffect, useState } from "react";
import { AuthState, product_CatalogItemResponse } from "@v2/utils";
import { CatalogRowComponent, CatalogRowProductContentTemplate } from "./catalogRowTemplate";
import { useSiteSession } from "@v2/utils";

export default function ClosetRow() {
    const { adam, shopper, closetProducts, favoriteProducts, authState } = useSiteSession();
    const [catalogProducts, setCatalogProducts] = useState<product_CatalogItemResponse[]>();

    useEffect(() => {
        if (adam && shopper) {
            try {
                if (closetProducts?.result && closetProducts.result.length > 0) {
                    setCatalogProducts(
                        closetProducts.result.map((t) => ({
                            favorites_id: t.favorite_id,
                            id: t.id,
                            image_id: t.product_image_id,
                            product_info: t.product_info,
                            product_url: t.url_affiliated,
                            product_color: t.product_color,
                            product_gender: t.product_gender,
                            product_sale_price: t.product_sale_price,
                            product_sizes: t.product_sizes,
                            product_brand: t.product_brand,
                            product_type: t.product_type,
                        }))
                    );
                    return;
                } else if (favoriteProducts?.result && favoriteProducts.result.length > 0) {
                    setCatalogProducts(
                        favoriteProducts.result.map((t) => ({
                            favorites_id: t.favorite_id,
                            id: t.id,
                            image_id: t.product_image_id,
                            product_info: t.product_info,
                            product_url: t.url_affiliated,
                            product_color: t.product_color,
                            product_gender: t.product_gender,
                            product_sale_price: t.product_sale_price,
                            product_sizes: t.product_sizes,
                            product_brand: t.product_brand,
                            product_type: t.product_type,
                        }))
                    );
                    return;
                }
            } catch (err) {
                console.error(err);
            }
        }
        setCatalogProducts(undefined);
    }, [setCatalogProducts, closetProducts, favoriteProducts, shopper]);

    return (
        <CatalogRowComponent
            title="Your latest looks."
            shopLink={process.env.REACT_APP_WEB_CLOSET_HOME || "#"}
            count={closetProducts?.count}
            hide={!catalogProducts || authState === AuthState.NoUserAccount}
        >
            {CatalogRowProductContentTemplate({
                hideDeletedTryonTiles: true,
                hideReportedTryonTiles: false,
                hideUnfavoredTryonTiles: false,
                products: catalogProducts || [],
            })}
        </CatalogRowComponent>
    );
}
