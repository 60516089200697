import React from "react";
import { auth } from "@v1/../firebase-config";
import "./homeLandingPage.scss";
import picSmall from "./assets/picSmall.png";
import picLarge from "./assets/picLarge.png";

function HomeLandingPage() {
    const firstName = auth?.currentUser?.displayName?.split(" ")?.[0];
    return (
        <div className="homeLandingPage">
            <div className="homeLandingPage_leftPane">
                <img className="picLarge" src={picLarge}></img>
                <img className="picSmall" src={picSmall}></img>
            </div>
            <div className="homeLandingPage_rightPane">
                <div className="homeLandingPage_rightPane_topText">
                    <span>{"Everything looks\nbetter"}</span>
                    <span className="text_italic">{" on you,\n"}</span>
                    {firstName && <span className="text_capitalize">{`${firstName}.`}</span>}
                </div>
                <div className="homeLandingPage_rightPane_line"></div>
                <div className="homeLandingPage_rightPane_text">{"Try on styles from\nyour favorite online\nretailer."}</div>
            </div>
        </div>
    );
}

export default HomeLandingPage;
