// @ts-nocheck
/*
    Adam HTTP API generated
    Documentation of the Adam service. It is the core of Klothed.
    version: 2.4.0
    Contact name: Klothed Engineering
    Contact email: info@getklothed.com
*/

export class Configuration {
    basePath? = "";
    fetchMethod = window.fetch;
    headers?: any = {};
    getHeaders: any = () => {
        return {};
    };
    responseHandler: any = null;
    errorHandler: any = null;

    constructor(config: Configuration | any) {
        if (config) {
            if (config.basePath) {
                this.basePath = config.basePath;
            }
            if (config.fetchMethod) {
                this.fetchMethod = config.fetchMethod;
            }
            if (config.headers) {
                this.headers = config.headers;
            }
            if (config.getHeaders) {
                this.getHeaders = config.getHeaders;
            }
            if (config.responseHandler) {
                this.responseHandler = config.responseHandler;
            }
            if (config.errorHandler) {
                this.errorHandler = config.errorHandler;
            }
        }
    }
}

const setAdditionalParams = (params = [], additionalParams = {}) => {
    Object.keys(additionalParams).forEach((key) => {
        if (additionalParams[key]) {
            params.append(key, additionalParams[key]);
        }
    });
};

export class affiliates_VigResponse {
    url?: string;

    constructor(obj: affiliates_VigResponse) {
        this.url = obj.url;
    }
}

export class crawl_CrawlQueueURLs {
    urls?: string[];

    constructor(obj: crawl_CrawlQueueURLs) {
        this.urls = obj.urls;
    }
}

export class decomposition_DecompositionBatchParameters {
    product_image_ids?: number[];
    shopper_image_ids?: number[];

    constructor(obj: decomposition_DecompositionBatchParameters) {
        this.product_image_ids = obj.product_image_ids;
        this.shopper_image_ids = obj.shopper_image_ids;
    }
}

export class decomposition_DecompositionInfo {
    date?: string;
    failure?: string;
    hash?: string;
    id?: number;
    image_id?: number;
    image_info?: any;
    score?: number;
    status?: string;

    constructor(obj: decomposition_DecompositionInfo) {
        this.date = obj.date;
        this.failure = obj.failure;
        this.hash = obj.hash;
        this.id = obj.id;
        this.image_id = obj.image_id;
        this.image_info = obj.image_info;
        this.score = obj.score;
        this.status = obj.status;
    }
}

export class decomposition_DecompositionParameters {
    product_image_id?: number;
    shopper_image_id?: number;

    constructor(obj: decomposition_DecompositionParameters) {
        this.product_image_id = obj.product_image_id;
        this.shopper_image_id = obj.shopper_image_id;
    }
}

export class favorites_CreateFavoriteRetailerResponse {
    id?: number;
    retailer_id?: number;

    constructor(obj: favorites_CreateFavoriteRetailerResponse) {
        this.id = obj.id;
        this.retailer_id = obj.retailer_id;
    }
}

export class favorites_CreateFavoriteRetailerSecure {
    retailer_id?: number;

    constructor(obj: favorites_CreateFavoriteRetailerSecure) {
        this.retailer_id = obj.retailer_id;
    }
}

export class favorites_CreateFavoriteTryOnResponse {
    id?: number;
    tryon_id?: number;

    constructor(obj: favorites_CreateFavoriteTryOnResponse) {
        this.id = obj.id;
        this.tryon_id = obj.tryon_id;
    }
}

export class favorites_CreateFavoriteTryonSecure {
    tryon_id?: number;

    constructor(obj: favorites_CreateFavoriteTryonSecure) {
        this.tryon_id = obj.tryon_id;
    }
}

export class favorites_GetFavoriteRetailersRow {
    id?: number;
    retailer_id?: number;

    constructor(obj: favorites_GetFavoriteRetailersRow) {
        this.id = obj.id;
        this.retailer_id = obj.retailer_id;
    }
}

export class helpers_WorkerStatusResponse {
    status?: string;
    tick?: string;

    constructor(obj: helpers_WorkerStatusResponse) {
        this.status = obj.status;
        this.tick = obj.tick;
    }
}

export class image_ImageInfo {
    date?: string;
    hash?: string;
    id?: number;
    info?: any;
    owner_id?: number;
    product_id?: number;
    shopper_id?: number;
    type?: string;

    constructor(obj: image_ImageInfo) {
        this.date = obj.date;
        this.hash = obj.hash;
        this.id = obj.id;
        this.info = obj.info;
        this.owner_id = obj.owner_id;
        this.product_id = obj.product_id;
        this.shopper_id = obj.shopper_id;
        this.type = obj.type;
    }
}

export class infra_InfraInfo {
    congested_queues?: string[];
    offline_queues?: string[];
    online_queues?: string[];
    queues?: infra_QueueResponse[];

    constructor(obj: infra_InfraInfo) {
        this.congested_queues = obj.congested_queues;
        this.offline_queues = obj.offline_queues;
        this.online_queues = obj.online_queues;
        this.queues = obj.queues;
    }
}

export class infra_InfraStatus {
    reason?: string;
    shutdown?: string;

    constructor(obj: infra_InfraStatus) {
        this.reason = obj.reason;
        this.shutdown = obj.shutdown;
    }
}

export class infra_QueueResponse {
    consumer_capacity?: number;
    consumers?: number;
    messages?: number;
    messages_ready?: number;
    messages_unacknowledged?: number;
    name?: string;
    state?: string;

    constructor(obj: infra_QueueResponse) {
        this.consumer_capacity = obj.consumer_capacity;
        this.consumers = obj.consumers;
        this.messages = obj.messages;
        this.messages_ready = obj.messages_ready;
        this.messages_unacknowledged = obj.messages_unacknowledged;
        this.name = obj.name;
        this.state = obj.state;
    }
}

export class poc_GroupRow {
    decomposition_date?: string;
    decomposition_failure?: string;
    decomposition_id?: number;
    decomposition_status?: string;
    group_category?: string;
    group_date?: string;
    group_id?: number;
    group_info?: any;
    group_tryon_id?: number;
    image_id?: number;
    image_info?: any;
    product_code?: string;
    product_id?: number;
    product_identifier?: string;
    product_info?: any;
    shopper_id?: number;
    shopper_info?: any;
    shopper_uid?: string;
    tryon_date?: string;
    tryon_failure?: string;
    tryon_id?: number;
    tryon_product_image_id?: number;
    tryon_shopper_image_id?: number;
    tryon_status?: string;

    constructor(obj: poc_GroupRow) {
        this.decomposition_date = obj.decomposition_date;
        this.decomposition_failure = obj.decomposition_failure;
        this.decomposition_id = obj.decomposition_id;
        this.decomposition_status = obj.decomposition_status;
        this.group_category = obj.group_category;
        this.group_date = obj.group_date;
        this.group_id = obj.group_id;
        this.group_info = obj.group_info;
        this.group_tryon_id = obj.group_tryon_id;
        this.image_id = obj.image_id;
        this.image_info = obj.image_info;
        this.product_code = obj.product_code;
        this.product_id = obj.product_id;
        this.product_identifier = obj.product_identifier;
        this.product_info = obj.product_info;
        this.shopper_id = obj.shopper_id;
        this.shopper_info = obj.shopper_info;
        this.shopper_uid = obj.shopper_uid;
        this.tryon_date = obj.tryon_date;
        this.tryon_failure = obj.tryon_failure;
        this.tryon_id = obj.tryon_id;
        this.tryon_product_image_id = obj.tryon_product_image_id;
        this.tryon_shopper_image_id = obj.tryon_shopper_image_id;
        this.tryon_status = obj.tryon_status;
    }
}

export class poc_InfoRow {
    decomposition_date?: string;
    decomposition_failure?: string;
    decomposition_id?: number;
    decomposition_jobs?: any[];
    decomposition_status?: string;
    image_id?: number;
    image_info?: any;
    product_code?: string;
    product_id?: number;
    product_identifier?: string;
    product_info?: any;
    shopper_id?: number;
    shopper_info?: any;
    shopper_uid?: string;
    tryon_date?: string;
    tryon_failure?: string;
    tryon_id?: number;
    tryon_jobs?: any[];
    tryon_product_image_id?: number;
    tryon_shopper_image_id?: number;
    tryon_status?: string;

    constructor(obj: poc_InfoRow) {
        this.decomposition_date = obj.decomposition_date;
        this.decomposition_failure = obj.decomposition_failure;
        this.decomposition_id = obj.decomposition_id;
        this.decomposition_jobs = obj.decomposition_jobs;
        this.decomposition_status = obj.decomposition_status;
        this.image_id = obj.image_id;
        this.image_info = obj.image_info;
        this.product_code = obj.product_code;
        this.product_id = obj.product_id;
        this.product_identifier = obj.product_identifier;
        this.product_info = obj.product_info;
        this.shopper_id = obj.shopper_id;
        this.shopper_info = obj.shopper_info;
        this.shopper_uid = obj.shopper_uid;
        this.tryon_date = obj.tryon_date;
        this.tryon_failure = obj.tryon_failure;
        this.tryon_id = obj.tryon_id;
        this.tryon_jobs = obj.tryon_jobs;
        this.tryon_product_image_id = obj.tryon_product_image_id;
        this.tryon_shopper_image_id = obj.tryon_shopper_image_id;
        this.tryon_status = obj.tryon_status;
    }
}

export class poc_ShopVerificationResponse {
    email?: string;
    link?: string;

    constructor(obj: poc_ShopVerificationResponse) {
        this.email = obj.email;
        this.link = obj.link;
    }
}

export class poc_TryonParameters {
    product_image_id?: number;
    shopper_image_id?: number;

    constructor(obj: poc_TryonParameters) {
        this.product_image_id = obj.product_image_id;
        this.shopper_image_id = obj.shopper_image_id;
    }
}

export class poc_TryonParametersBatch {
    product_image_ids?: number[];
    shopper_image_ids?: number[];

    constructor(obj: poc_TryonParametersBatch) {
        this.product_image_ids = obj.product_image_ids;
        this.shopper_image_ids = obj.shopper_image_ids;
    }
}

export class product_CatalogItemResponse {
    favorites_id?: number;
    id?: number;
    image_id?: number;
    product_brand?: string;
    product_color?: string;
    product_currency_type?: string;
    product_gender?: string;
    product_price?: number;
    product_sale_price?: number;
    product_sizes?: string[];
    product_type?: string;
    product_url?: string;

    constructor(obj: product_CatalogItemResponse) {
        this.favorites_id = obj.favorites_id;
        this.id = obj.id;
        this.image_id = obj.image_id;
        this.product_brand = obj.product_brand;
        this.product_color = obj.product_color;
        this.product_currency_type = obj.product_currency_type;
        this.product_gender = obj.product_gender;
        this.product_price = obj.product_price;
        this.product_sale_price = obj.product_sale_price;
        this.product_sizes = obj.product_sizes;
        this.product_type = obj.product_type;
        this.product_url = obj.product_url;
    }
}

export class product_CreateProductParams {
    brand?: string;
    code?: string;
    color?: string;
    date?: string;
    gender?: string;
    google_product_category?: string;
    identifier?: string;
    info?: any;
    price?: number;
    retailer_id?: number;
    sale_price?: number;
    sizes?: string[];
    type?: string;

    constructor(obj: product_CreateProductParams) {
        this.brand = obj.brand;
        this.code = obj.code;
        this.color = obj.color;
        this.date = obj.date;
        this.gender = obj.gender;
        this.google_product_category = obj.google_product_category;
        this.identifier = obj.identifier;
        this.info = obj.info;
        this.price = obj.price;
        this.retailer_id = obj.retailer_id;
        this.sale_price = obj.sale_price;
        this.sizes = obj.sizes;
        this.type = obj.type;
    }
}

export class product_PostRetrieveOrUploadImageParameters {
    hash?: string;
    image?: string;
    info?: any;

    constructor(obj: product_PostRetrieveOrUploadImageParameters) {
        this.hash = obj.hash;
        this.image = obj.image;
        this.info = obj.info;
    }
}

export class product_PostRetrieveOrUploadParameters {
    code?: string;
    images?: product_PostRetrieveOrUploadImageParameters[];
    info?: any;
    url?: string;

    constructor(obj: product_PostRetrieveOrUploadParameters) {
        this.code = obj.code;
        this.images = obj.images;
        this.info = obj.info;
        this.url = obj.url;
    }
}

export class product_PostRetrieveParameters {
    code?: string;
    url?: string;

    constructor(obj: product_PostRetrieveParameters) {
        this.code = obj.code;
        this.url = obj.url;
    }
}

export class product_ProductInfo {
    code?: string;
    date?: string;
    id?: number;
    identifier?: string;
    info?: any;

    constructor(obj: product_ProductInfo) {
        this.code = obj.code;
        this.date = obj.date;
        this.id = obj.id;
        this.identifier = obj.identifier;
        this.info = obj.info;
    }
}

export class product_ShopperCatalogsResponse {
    count?: number;
    page?: number;
    priority?: number;
    products?: product_CatalogItemResponse[];
    title?: string;
    uid?: string;

    constructor(obj: product_ShopperCatalogsResponse) {
        this.count = obj.count;
        this.page = obj.page;
        this.priority = obj.priority;
        this.products = obj.products;
        this.title = obj.title;
        this.uid = obj.uid;
    }
}

export class report_ReportBody {
    attachments?: any;
    category?: string;
    image_id?: number;
    info?: any;
    product_id?: number;
    retailer_id?: number;
    tryon_id?: number;

    constructor(obj: report_ReportBody) {
        this.attachments = obj.attachments;
        this.category = obj.category;
        this.image_id = obj.image_id;
        this.info = obj.info;
        this.product_id = obj.product_id;
        this.retailer_id = obj.retailer_id;
        this.tryon_id = obj.tryon_id;
    }
}

export class report_ReportPutBody {
    attachments?: any;
    category?: string;
    id?: number;
    image_id?: number;
    info?: any;
    product_id?: number;
    retailer_id?: number;
    tryon_id?: number;

    constructor(obj: report_ReportPutBody) {
        this.attachments = obj.attachments;
        this.category = obj.category;
        this.id = obj.id;
        this.image_id = obj.image_id;
        this.info = obj.info;
        this.product_id = obj.product_id;
        this.retailer_id = obj.retailer_id;
        this.tryon_id = obj.tryon_id;
    }
}

export class report_ReportResponse {
    id?: number;

    constructor(obj: report_ReportResponse) {
        this.id = obj.id;
    }
}

export class retailers_Retailer {
    affiliate_url?: string;
    common_name?: string;
    id?: number;
    info?: retailers_RetailerInfo;

    constructor(obj: retailers_Retailer) {
        this.affiliate_url = obj.affiliate_url;
        this.common_name = obj.common_name;
        this.id = obj.id;
        this.info = obj.info;
    }
}

export class retailers_RetailerInfo {
    display?: string;
    host?: string;
    icon?: string;

    constructor(obj: retailers_RetailerInfo) {
        this.display = obj.display;
        this.host = obj.host;
        this.icon = obj.icon;
    }
}

export class share_CreateShareResponse {
    date?: string;
    uid?: string;

    constructor(obj: share_CreateShareResponse) {
        this.date = obj.date;
        this.uid = obj.uid;
    }
}

export class share_ProductInfo {
    affiliated_url?: string;
    brand?: string;
    details?: string;
    gender?: string;
    type?: string;
    url?: string;

    constructor(obj: share_ProductInfo) {
        this.affiliated_url = obj.affiliated_url;
        this.brand = obj.brand;
        this.details = obj.details;
        this.gender = obj.gender;
        this.type = obj.type;
        this.url = obj.url;
    }
}

export class share_ShareInfo {
    product_id?: number;
    product_image_id?: number;
    product_info?: share_ProductInfo;
    retailer_icon?: string;
    retailer_name?: string;

    constructor(obj: share_ShareInfo) {
        this.product_id = obj.product_id;
        this.product_image_id = obj.product_image_id;
        this.product_info = obj.product_info;
        this.retailer_icon = obj.retailer_icon;
        this.retailer_name = obj.retailer_name;
    }
}

export class shopper_CreateShopperParams {
    info?: any;
    uid?: string;

    constructor(obj: shopper_CreateShopperParams) {
        this.info = obj.info;
        this.uid = obj.uid;
    }
}

export class shopper_DeleteShopperResult {
    removal_time?: string;
    shopper_id?: number;

    constructor(obj: shopper_DeleteShopperResult) {
        this.removal_time = obj.removal_time;
        this.shopper_id = obj.shopper_id;
    }
}

export class shopper_ImageURLResponse {
    url?: string;

    constructor(obj: shopper_ImageURLResponse) {
        this.url = obj.url;
    }
}

export class shopper_RecoverShopperResult {
    shopper_id?: number;

    constructor(obj: shopper_RecoverShopperResult) {
        this.shopper_id = obj.shopper_id;
    }
}

export class shopper_ShopperInfo {
    date?: string;
    id?: number;
    image_date?: string;
    image_id?: number;
    image_info?: any;
    info?: any;
    onboarded?: any;
    uid?: string;

    constructor(obj: shopper_ShopperInfo) {
        this.date = obj.date;
        this.id = obj.id;
        this.image_date = obj.image_date;
        this.image_id = obj.image_id;
        this.image_info = obj.image_info;
        this.info = obj.info;
        this.onboarded = obj.onboarded;
        this.uid = obj.uid;
    }
}

export class shopper_shopperHeightAndStyle {
    height?: number;
    style?: string;

    constructor(obj: shopper_shopperHeightAndStyle) {
        this.height = obj.height;
        this.style = obj.style;
    }
}

export class shopper_shopperPreferences {
    news?: any;
    reminders?: any;

    constructor(obj: shopper_shopperPreferences) {
        this.news = obj.news;
        this.reminders = obj.reminders;
    }
}

export class tryon_GenericResponse {
    success?: any;

    constructor(obj: tryon_GenericResponse) {
        this.success = obj.success;
    }
}

export class tryon_ImageURLResponse {
    url?: string;

    constructor(obj: tryon_ImageURLResponse) {
        this.url = obj.url;
    }
}

export class tryon_ShopperTryonInfoResult {
    count?: number;
    page?: number;
    result?: tryon_TryonInfoWithFavorites[];

    constructor(obj: tryon_ShopperTryonInfoResult) {
        this.count = obj.count;
        this.page = obj.page;
        this.result = obj.result;
    }
}

export class tryon_TryonInfo {
    date?: string;
    failure?: string;
    id?: number;
    product_image_id?: number;
    shopper_image_id?: number;
    status?: string;

    constructor(obj: tryon_TryonInfo) {
        this.date = obj.date;
        this.failure = obj.failure;
        this.id = obj.id;
        this.product_image_id = obj.product_image_id;
        this.shopper_image_id = obj.shopper_image_id;
        this.status = obj.status;
    }
}

export class tryon_TryonInfoWithFavorites {
    date?: string;
    favorite_id?: number;
    id?: number;
    product_brand?: string;
    product_color?: string;
    product_currency_type?: string;
    product_gender?: string;
    product_image_id?: number;
    product_info?: any;
    product_price?: number;
    product_sale_price?: number;
    product_sizes?: string[];
    product_type?: string;
    retailer_name?: string;
    shopper_image_id?: number;
    status?: string;
    url?: string;
    url_affiliated?: string;

    constructor(obj: tryon_TryonInfoWithFavorites) {
        this.date = obj.date;
        this.favorite_id = obj.favorite_id;
        this.id = obj.id;
        this.product_brand = obj.product_brand;
        this.product_color = obj.product_color;
        this.product_currency_type = obj.product_currency_type;
        this.product_gender = obj.product_gender;
        this.product_image_id = obj.product_image_id;
        this.product_info = obj.product_info;
        this.product_price = obj.product_price;
        this.product_sale_price = obj.product_sale_price;
        this.product_sizes = obj.product_sizes;
        this.product_type = obj.product_type;
        this.retailer_name = obj.retailer_name;
        this.shopper_image_id = obj.shopper_image_id;
        this.status = obj.status;
        this.url = obj.url;
        this.url_affiliated = obj.url_affiliated;
    }
}

export class MethodOptions {
    headers?: any = {};
    returnResponse?: boolean = false;

    constructor(options: MethodOptions) {
        if (options.headers) {
            this.headers = options.headers;
        }
        if (options.returnResponse) {
            this.returnResponse = options.returnResponse;
        }
    }
}

export class AffiliatesVigGetArgs {
    product_url: string;

    constructor(args: AffiliatesVigGetArgs) {
        this.product_url = args.product_url;
    }
}

export class ImagePaddedImageIdGetArgs {
    image_id: number;
    file: number;

    constructor(args: ImagePaddedImageIdGetArgs) {
        this.image_id = args.image_id;
        this.file = args.file;
    }
}

export class ImagePaddedImageIdFileGetArgs {
    image_id: number;
    file: number;

    constructor(args: ImagePaddedImageIdFileGetArgs) {
        this.image_id = args.image_id;
        this.file = args.file;
    }
}

export class ImageIdGetArgs {
    image_id: number;
    name: string;

    constructor(args: ImageIdGetArgs) {
        this.image_id = args.image_id;
        this.name = args.name;
    }
}

export class ProductCatalogsUidGetArgs {
    uid: string;
    page?: number;

    constructor(args: ProductCatalogsUidGetArgs) {
        this.uid = args.uid;
        this.page = args.page;
    }
}

export class ProductCatalogsUidPageGetArgs {
    uid: string;
    page?: number;

    constructor(args: ProductCatalogsUidPageGetArgs) {
        this.uid = args.uid;
        this.page = args.page;
    }
}

export class ReportPutArgs {
    info: report_ReportPutBody;

    constructor(args: ReportPutArgs) {
        this.info = args.info;
    }
}

export class ReportPostArgs {
    info: report_ReportBody;

    constructor(args: ReportPostArgs) {
        this.info = args.info;
    }
}

export class SecureAffiliatesVigGetArgs {
    product_url: string;

    constructor(args: SecureAffiliatesVigGetArgs) {
        this.product_url = args.product_url;
    }
}

export class SecureCrawlPostArgs {
    CrawlQueueURLs: crawl_CrawlQueueURLs;

    constructor(args: SecureCrawlPostArgs) {
        this.CrawlQueueURLs = args.CrawlQueueURLs;
    }
}

export class SecureDecompositionBatchGetArgs {
    id: number[];

    constructor(args: SecureDecompositionBatchGetArgs) {
        this.id = args.id;
    }
}

export class SecureDecompositionCodeHashGetArgs {
    code: string;
    hash: string;
    restart_failed_decomp?: any;
    restart_processing_decomp?: any;
    restart_processing_decomp_timeout?: string;
    restart_success_decomp?: any;

    constructor(args: SecureDecompositionCodeHashGetArgs) {
        this.code = args.code;
        this.hash = args.hash;
        this.restart_failed_decomp = args.restart_failed_decomp;
        this.restart_processing_decomp = args.restart_processing_decomp;
        this.restart_processing_decomp_timeout = args.restart_processing_decomp_timeout;
        this.restart_success_decomp = args.restart_success_decomp;
    }
}

export class SecureDecompositionDecompositionIdGetArgs {
    decomposition_id: number;

    constructor(args: SecureDecompositionDecompositionIdGetArgs) {
        this.decomposition_id = args.decomposition_id;
    }
}

export class SecureDecompositionImageIdImageGetArgs {
    image_id: number;

    constructor(args: SecureDecompositionImageIdImageGetArgs) {
        this.image_id = args.image_id;
    }
}

export class SecureFavoritesRetailerPostArgs {
    info: favorites_CreateFavoriteRetailerSecure;

    constructor(args: SecureFavoritesRetailerPostArgs) {
        this.info = args.info;
    }
}

export class SecureFavoritesRetailersGetArgs {
    page_id?: number;

    constructor(args: SecureFavoritesRetailersGetArgs) {
        this.page_id = args.page_id;
    }
}

export class SecureFavoritesTryonPostArgs {
    info: favorites_CreateFavoriteTryonSecure;

    constructor(args: SecureFavoritesTryonPostArgs) {
        this.info = args.info;
    }
}

export class SecureFavoritesTryonsGetArgs {
    page_id?: number;

    constructor(args: SecureFavoritesTryonsGetArgs) {
        this.page_id = args.page_id;
    }
}

export class SecureFavoritesFavoriteIdDeleteArgs {
    favorite_id: number;

    constructor(args: SecureFavoritesFavoriteIdDeleteArgs) {
        this.favorite_id = args.favorite_id;
    }
}

export class SecureImageHashHashGetArgs {
    hash: number;

    constructor(args: SecureImageHashHashGetArgs) {
        this.hash = args.hash;
    }
}

export class SecureImageIdGetArgs {
    image_id: number;
    name: string;

    constructor(args: SecureImageIdGetArgs) {
        this.image_id = args.image_id;
        this.name = args.name;
    }
}

export class SecureProductCatalogsUidGetArgs {
    uid: string;
    page?: number;

    constructor(args: SecureProductCatalogsUidGetArgs) {
        this.uid = args.uid;
        this.page = args.page;
    }
}

export class SecureProductCatalogsUidPageGetArgs {
    uid: string;
    page?: number;

    constructor(args: SecureProductCatalogsUidPageGetArgs) {
        this.uid = args.uid;
        this.page = args.page;
    }
}

export class SecureProductModesensCatalogsGetArgs {
    min_price?: number;
    max_price?: number;
    brands?: string[];
    sort?: string;

    constructor(args: SecureProductModesensCatalogsGetArgs) {
        this.min_price = args.min_price;
        this.max_price = args.max_price;
        this.brands = args.brands;
        this.sort = args.sort;
    }
}

export class SecureProductModesensCatalogsUidGetArgs {
    uid: string;
    page?: number;
    min_price?: number;
    max_price?: number;
    brands?: string[];
    sort?: string;

    constructor(args: SecureProductModesensCatalogsUidGetArgs) {
        this.uid = args.uid;
        this.page = args.page;
        this.min_price = args.min_price;
        this.max_price = args.max_price;
        this.brands = args.brands;
        this.sort = args.sort;
    }
}

export class SecureProductModesensCatalogsUidPageGetArgs {
    uid: string;
    page?: number;
    min_price?: number;
    max_price?: number;
    brands?: string[];
    sort?: string;

    constructor(args: SecureProductModesensCatalogsUidPageGetArgs) {
        this.uid = args.uid;
        this.page = args.page;
        this.min_price = args.min_price;
        this.max_price = args.max_price;
        this.brands = args.brands;
        this.sort = args.sort;
    }
}

export class SecureProductRetrievePostArgs {
    body: product_PostRetrieveParameters;

    constructor(args: SecureProductRetrievePostArgs) {
        this.body = args.body;
    }
}

export class SecureProductRetrieveOrUploadPostArgs {
    body: product_PostRetrieveOrUploadParameters;
    restart_failed_decomp?: any;
    restart_processing_decomp?: any;
    restart_processing_decomp_timeout?: string;
    restart_success_decomp?: any;
    priority?: number;

    constructor(args: SecureProductRetrieveOrUploadPostArgs) {
        this.body = args.body;
        this.restart_failed_decomp = args.restart_failed_decomp;
        this.restart_processing_decomp = args.restart_processing_decomp;
        this.restart_processing_decomp_timeout = args.restart_processing_decomp_timeout;
        this.restart_success_decomp = args.restart_success_decomp;
        this.priority = args.priority;
    }
}

export class SecureProductRetrieveOrUploadBinaryPostArgs {
    code: string;
    product_url: string;
    hash: string;
    image_info: string;
    product_info: string;
    image: any;
    name: string;
    restart_failed_decomp?: any;
    restart_processing_decomp?: any;
    restart_processing_decomp_timeout?: string;
    restart_success_decomp?: any;
    priority?: number;

    constructor(args: SecureProductRetrieveOrUploadBinaryPostArgs) {
        this.code = args.code;
        this.product_url = args.product_url;
        this.hash = args.hash;
        this.image_info = args.image_info;
        this.product_info = args.product_info;
        this.image = args.image;
        this.name = args.name;
        this.restart_failed_decomp = args.restart_failed_decomp;
        this.restart_processing_decomp = args.restart_processing_decomp;
        this.restart_processing_decomp_timeout = args.restart_processing_decomp_timeout;
        this.restart_success_decomp = args.restart_success_decomp;
        this.priority = args.priority;
    }
}

export class SecureProductIdPutArgs {
    id: number;
    info: any;

    constructor(args: SecureProductIdPutArgs) {
        this.id = args.id;
        this.info = args.info;
    }
}

export class SecureProductIdImageIdPutArgs {
    id: number;
    info: any;

    constructor(args: SecureProductIdImageIdPutArgs) {
        this.id = args.id;
        this.info = args.info;
    }
}

export class SecureReportPutArgs {
    info: report_ReportPutBody;

    constructor(args: SecureReportPutArgs) {
        this.info = args.info;
    }
}

export class SecureReportPostArgs {
    info: report_ReportBody;

    constructor(args: SecureReportPostArgs) {
        this.info = args.info;
    }
}

export class SecureShareTryonIdPostArgs {
    tryon_id: number;

    constructor(args: SecureShareTryonIdPostArgs) {
        this.tryon_id = args.tryon_id;
    }
}

export class SecureShopperPutArgs {
    info: any;

    constructor(args: SecureShopperPutArgs) {
        this.info = args.info;
    }
}

export class SecureShopperPostArgs {
    info: shopper_CreateShopperParams;

    constructor(args: SecureShopperPostArgs) {
        this.info = args.info;
    }
}

export class SecureShopperClosetGetArgs {
    page?: number;

    constructor(args: SecureShopperClosetGetArgs) {
        this.page = args.page;
    }
}

export class SecureShopperEmailPostArgs {
    info: any;

    constructor(args: SecureShopperEmailPostArgs) {
        this.info = args.info;
    }
}

export class SecureShopperFavoritesGetArgs {
    page?: number;

    constructor(args: SecureShopperFavoritesGetArgs) {
        this.page = args.page;
    }
}

export class SecureShopperHeightAndStylePutArgs {
    height?: number;
    style?: string;

    constructor(args: SecureShopperHeightAndStylePutArgs) {
        this.height = args.height;
        this.style = args.style;
    }
}

export class SecureShopperImagePostArgs {
    image: number[];
    name: string;
    image_info: string;

    constructor(args: SecureShopperImagePostArgs) {
        this.image = args.image;
        this.name = args.name;
        this.image_info = args.image_info;
    }
}

export class SecureShopperPreferencesPutArgs {
    news?: any;
    reminders?: any;

    constructor(args: SecureShopperPreferencesPutArgs) {
        this.news = args.news;
        this.reminders = args.reminders;
    }
}

export class SecureShopperTryonsGetArgs {
    page_id?: number;
    product_image_id?: number;

    constructor(args: SecureShopperTryonsGetArgs) {
        this.page_id = args.page_id;
        this.product_image_id = args.product_image_id;
    }
}

export class SecureTryonPostArgs {
    product_image_id: string[];

    constructor(args: SecureTryonPostArgs) {
        this.product_image_id = args.product_image_id;
    }
}

export class SecureTryonDeleteArgs {
    tryon_id: number[];

    constructor(args: SecureTryonDeleteArgs) {
        this.tryon_id = args.tryon_id;
    }
}

export class SecureTryonImageTryonIdNameGetArgs {
    tryon_id: number;
    name: string;

    constructor(args: SecureTryonImageTryonIdNameGetArgs) {
        this.tryon_id = args.tryon_id;
        this.name = args.name;
    }
}

export class SecureTryonProductImageIdImagePostArgs {
    product_image_id: number;
    force?: any;
    priority?: number;

    constructor(args: SecureTryonProductImageIdImagePostArgs) {
        this.product_image_id = args.product_image_id;
        this.force = args.force;
        this.priority = args.priority;
    }
}

export class SecureTryonTryonIdGetArgs {
    tryon_id: number;

    constructor(args: SecureTryonTryonIdGetArgs) {
        this.tryon_id = args.tryon_id;
    }
}

export class SecureTryonTryonIdImageGetArgs {
    tryon_id: number;
    name: string;

    constructor(args: SecureTryonTryonIdImageGetArgs) {
        this.tryon_id = args.tryon_id;
        this.name = args.name;
    }
}

export class SecureTryonTryonIdImageUrlGetArgs {
    tryon_id: number;
    name: string;

    constructor(args: SecureTryonTryonIdImageUrlGetArgs) {
        this.tryon_id = args.tryon_id;
        this.name = args.name;
    }
}

export class SecureTryonTryonIdSyncGetArgs {
    tryon_id: number;

    constructor(args: SecureTryonTryonIdSyncGetArgs) {
        this.tryon_id = args.tryon_id;
    }
}

export class ShareInfoUidGetArgs {
    uid: string;

    constructor(args: ShareInfoUidGetArgs) {
        this.uid = args.uid;
    }
}

export class ShareProductUidjpgGetArgs {
    uid: string;

    constructor(args: ShareProductUidjpgGetArgs) {
        this.uid = args.uid;
    }
}

export class ShareTryonUidjpgGetArgs {
    uid: string;
    name: string;

    constructor(args: ShareTryonUidjpgGetArgs) {
        this.uid = args.uid;
        this.name = args.name;
    }
}

export class TryonGenericProductImageIdModelGetArgs {
    product_image_id: number;
    name: string;

    constructor(args: TryonGenericProductImageIdModelGetArgs) {
        this.product_image_id = args.product_image_id;
        this.name = args.name;
    }
}

export class Api {
    private readonly config: Configuration;

    constructor(config: Configuration | any) {
        this.config = new Configuration(config);
    }

    affiliatesVigGet(args: AffiliatesVigGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { product_url } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/affiliates/vig";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (product_url !== undefined) {
            params.append("product_url", product_url as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    healthcheckGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/healthcheck";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    imagePaddedImageIdGet(args: ImagePaddedImageIdGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { image_id, file } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/image/padded/{image_id}";
        url = url.split(["{", "}"].join("image_id")).join(encodeURIComponent(String(image_id)));
        url = url.split(["{", "}"].join("file")).join(encodeURIComponent(String(file)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    imagePaddedImageIdFileGet(args: ImagePaddedImageIdFileGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { image_id, file } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/image/padded/{image_id}/{file}";
        url = url.split(["{", "}"].join("image_id")).join(encodeURIComponent(String(image_id)));
        url = url.split(["{", "}"].join("file")).join(encodeURIComponent(String(file)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    imageIdGet(args: ImageIdGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { image_id, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/image/{id}";
        url = url.split(["{", "}"].join("image_id")).join(encodeURIComponent(String(image_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    productCatalogsGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/product/catalogs";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    productCatalogsUidGet(args: ProductCatalogsUidGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, page } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/product/catalogs/{uid}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        url = url.split(["{", "}"].join("page")).join(encodeURIComponent(String(page)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    productCatalogsUidPageGet(args: ProductCatalogsUidPageGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, page } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/product/catalogs/{uid}/{page}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        url = url.split(["{", "}"].join("page")).join(encodeURIComponent(String(page)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    reportPut(args: ReportPutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/report/";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    reportPost(args: ReportPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/report/";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    retailersGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/retailers";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureAffiliatesVigGet(args: SecureAffiliatesVigGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { product_url } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/affiliates/vig";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (product_url !== undefined) {
            params.append("product_url", product_url as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureCrawlPost(args: SecureCrawlPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { CrawlQueueURLs } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/crawl";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof CrawlQueueURLs ? JSON.stringify(CrawlQueueURLs) : CrawlQueueURLs,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    secureDecompositionBatchGet(args: SecureDecompositionBatchGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/decomposition/batch";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (id !== undefined) {
            params.append("id", id as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureDecompositionCodeHashGet(args: SecureDecompositionCodeHashGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { code, hash, restart_failed_decomp, restart_processing_decomp, restart_processing_decomp_timeout, restart_success_decomp } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/decomposition/{code}/hash";
        url = url.split(["{", "}"].join("code")).join(encodeURIComponent(String(code)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (hash !== undefined) {
            params.append("hash", hash as any);
        }
        if (restart_failed_decomp !== undefined) {
            params.append("restart_failed_decomp", restart_failed_decomp as any);
        }
        if (restart_processing_decomp !== undefined) {
            params.append("restart_processing_decomp", restart_processing_decomp as any);
        }
        if (restart_processing_decomp_timeout !== undefined) {
            params.append("restart_processing_decomp_timeout", restart_processing_decomp_timeout as any);
        }
        if (restart_success_decomp !== undefined) {
            params.append("restart_success_decomp", restart_success_decomp as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureDecompositionDecompositionIdGet(args: SecureDecompositionDecompositionIdGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { decomposition_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/decomposition/{decomposition_id}";
        url = url.split(["{", "}"].join("decomposition_id")).join(encodeURIComponent(String(decomposition_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureDecompositionImageIdImageGet(args: SecureDecompositionImageIdImageGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { image_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/decomposition/{image_id}/image";
        url = url.split(["{", "}"].join("image_id")).join(encodeURIComponent(String(image_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureFavoritesRetailerPost(args: SecureFavoritesRetailerPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/favorites/retailer";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureFavoritesRetailersGet(args: SecureFavoritesRetailersGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { page_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/favorites/retailers";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page_id !== undefined) {
            params.append("page_id", page_id as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureFavoritesTryonPost(args: SecureFavoritesTryonPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/favorites/tryon";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureFavoritesTryonsGet(args: SecureFavoritesTryonsGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { page_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/favorites/tryons";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page_id !== undefined) {
            params.append("page_id", page_id as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureFavoritesFavoriteIdDelete(args: SecureFavoritesFavoriteIdDeleteArgs, options: MethodOptions | any = {}): Promise<any> {
        const { favorite_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/favorites/{favorite_id}";
        url = url.split(["{", "}"].join("favorite_id")).join(encodeURIComponent(String(favorite_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "delete",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    secureImageHashHashGet(args: SecureImageHashHashGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { hash } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/image/{hash}/hash";
        url = url.split(["{", "}"].join("hash")).join(encodeURIComponent(String(hash)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureImageIdGet(args: SecureImageIdGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { image_id, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/image/{id}";
        url = url.split(["{", "}"].join("image_id")).join(encodeURIComponent(String(image_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    securePocInfraInfoGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/poc/infra/info";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductCatalogsGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/catalogs";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductCatalogsUidGet(args: SecureProductCatalogsUidGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, page } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/catalogs/{uid}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        url = url.split(["{", "}"].join("page")).join(encodeURIComponent(String(page)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductCatalogsUidPageGet(args: SecureProductCatalogsUidPageGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, page } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/catalogs/{uid}/{page}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        url = url.split(["{", "}"].join("page")).join(encodeURIComponent(String(page)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductModesensCatalogsGet(args: SecureProductModesensCatalogsGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { min_price, max_price, brands, sort } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/modesens/catalogs";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (min_price !== undefined) {
            params.append("min_price", min_price as any);
        }
        if (max_price !== undefined) {
            params.append("max_price", max_price as any);
        }
        if (brands !== undefined) {
            params.append("brands", brands as any);
        }
        if (sort !== undefined) {
            params.append("sort", sort as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductModesensCatalogsUidGet(args: SecureProductModesensCatalogsUidGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, page, min_price, max_price, brands, sort } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/modesens/catalogs/{uid}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        url = url.split(["{", "}"].join("page")).join(encodeURIComponent(String(page)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (min_price !== undefined) {
            params.append("min_price", min_price as any);
        }
        if (max_price !== undefined) {
            params.append("max_price", max_price as any);
        }
        if (brands !== undefined) {
            params.append("brands", brands as any);
        }
        if (sort !== undefined) {
            params.append("sort", sort as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductModesensCatalogsUidPageGet(args: SecureProductModesensCatalogsUidPageGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, page, min_price, max_price, brands, sort } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/modesens/catalogs/{uid}/{page}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        url = url.split(["{", "}"].join("page")).join(encodeURIComponent(String(page)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (min_price !== undefined) {
            params.append("min_price", min_price as any);
        }
        if (max_price !== undefined) {
            params.append("max_price", max_price as any);
        }
        if (brands !== undefined) {
            params.append("brands", brands as any);
        }
        if (sort !== undefined) {
            params.append("sort", sort as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductRetrievePost(args: SecureProductRetrievePostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { body } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/retrieve";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof body ? JSON.stringify(body) : body,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductRetrieveOrUploadPost(args: SecureProductRetrieveOrUploadPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { body, restart_failed_decomp, restart_processing_decomp, restart_processing_decomp_timeout, restart_success_decomp, priority } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/retrieveOrUpload";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (restart_failed_decomp !== undefined) {
            params.append("restart_failed_decomp", restart_failed_decomp as any);
        }
        if (restart_processing_decomp !== undefined) {
            params.append("restart_processing_decomp", restart_processing_decomp as any);
        }
        if (restart_processing_decomp_timeout !== undefined) {
            params.append("restart_processing_decomp_timeout", restart_processing_decomp_timeout as any);
        }
        if (restart_success_decomp !== undefined) {
            params.append("restart_success_decomp", restart_success_decomp as any);
        }
        if (priority !== undefined) {
            params.append("priority", priority as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof body ? JSON.stringify(body) : body,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductRetrieveOrUploadBinaryPost(args: SecureProductRetrieveOrUploadBinaryPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const {
            code,
            product_url,
            hash,
            image_info,
            product_info,
            image,
            name,
            restart_failed_decomp,
            restart_processing_decomp,
            restart_processing_decomp_timeout,
            restart_success_decomp,
            priority,
        } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/retrieveOrUploadBinary";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (code !== undefined) {
            params.append("code", code as any);
        }
        if (product_url !== undefined) {
            params.append("product_url", product_url as any);
        }
        if (hash !== undefined) {
            params.append("hash", hash as any);
        }
        if (image_info !== undefined) {
            params.append("image_info", image_info as any);
        }
        if (product_info !== undefined) {
            params.append("product_info", product_info as any);
        }
        if (name !== undefined) {
            params.append("name", name as any);
        }
        if (restart_failed_decomp !== undefined) {
            params.append("restart_failed_decomp", restart_failed_decomp as any);
        }
        if (restart_processing_decomp !== undefined) {
            params.append("restart_processing_decomp", restart_processing_decomp as any);
        }
        if (restart_processing_decomp_timeout !== undefined) {
            params.append("restart_processing_decomp_timeout", restart_processing_decomp_timeout as any);
        }
        if (restart_success_decomp !== undefined) {
            params.append("restart_success_decomp", restart_success_decomp as any);
        }
        if (priority !== undefined) {
            params.append("priority", priority as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
                body: false ? JSON.stringify(image) : image,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductIdPut(args: SecureProductIdPutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { id, info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/{id}";
        url = url.split(["{", "}"].join("id")).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureProductIdImageIdPut(args: SecureProductIdImageIdPutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { id, info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/product/{id}/image_id";
        url = url.split(["{", "}"].join("id")).join(encodeURIComponent(String(id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureReportPut(args: SecureReportPutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/report/";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureReportPost(args: SecureReportPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/report/";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureRetailerGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/retailer";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShareTryonIdPost(args: SecureShareTryonIdPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/share/{tryon_id}";
        url = url.split(["{", "}"].join("tryon_id")).join(encodeURIComponent(String(tryon_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperPut(args: SecureShopperPutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperDelete(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "delete",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperPost(args: SecureShopperPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperClosetGet(args: SecureShopperClosetGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { page } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/closet";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append("page", page as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperEmailPost(args: SecureShopperEmailPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/email";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { "Content-Type": "application/json", ...headers, ...getHeaders(), ...options.headers },
                body: "object" === typeof info ? JSON.stringify(info) : info,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperFavoritesGet(args: SecureShopperFavoritesGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { page } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/favorites";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page !== undefined) {
            params.append("page", page as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperHeightAndStyleGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/heightAndStyle";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperHeightAndStylePut(args: SecureShopperHeightAndStylePutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { height, style } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/heightAndStyle";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (height !== undefined) {
            params.append("height", height as any);
        }
        if (style !== undefined) {
            params.append("style", style as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperImageGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/image";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    secureShopperImagePost(args: SecureShopperImagePostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { image, name, image_info } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/image";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        if (image_info !== undefined) {
            params.append("image_info", image_info as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
                body: false ? JSON.stringify(image) : image,
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperImageDelete(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/image";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "delete",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperImageUrlGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/image-url";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperPreferencesGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/preferences";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperPreferencesPut(args: SecureShopperPreferencesPutArgs, options: MethodOptions | any = {}): Promise<any> {
        const { news, reminders } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/preferences";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (news !== undefined) {
            params.append("news", news as any);
        }
        if (reminders !== undefined) {
            params.append("reminders", reminders as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "put",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    secureShopperRecoverPost(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/recover";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureShopperTryonsGet(args: SecureShopperTryonsGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { page_id, product_image_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/shopper/tryons";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (page_id !== undefined) {
            params.append("page_id", page_id as any);
        }
        if (product_image_id !== undefined) {
            params.append("product_image_id", product_image_id as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureTryonPost(args: SecureTryonPostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { product_image_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (product_image_id !== undefined) {
            params.append("product_image_id", product_image_id as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureTryonDelete(args: SecureTryonDeleteArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (tryon_id !== undefined) {
            params.append("tryon_id", tryon_id as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "delete",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureTryonImageTryonIdNameGet(args: SecureTryonImageTryonIdNameGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon/image/{tryon_id}/{name}";
        url = url.split(["{", "}"].join("tryon_id")).join(encodeURIComponent(String(tryon_id)));
        url = url.split(["{", "}"].join("name")).join(encodeURIComponent(String(name)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    secureTryonProductImageIdImagePost(args: SecureTryonProductImageIdImagePostArgs, options: MethodOptions | any = {}): Promise<any> {
        const { product_image_id, force, priority } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon/{product_image_id}/image";
        url = url.split(["{", "}"].join("product_image_id")).join(encodeURIComponent(String(product_image_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (force !== undefined) {
            params.append("force", force as any);
        }
        if (priority !== undefined) {
            params.append("priority", priority as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "post",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureTryonTryonIdGet(args: SecureTryonTryonIdGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon/{tryon_id}";
        url = url.split(["{", "}"].join("tryon_id")).join(encodeURIComponent(String(tryon_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureTryonTryonIdImageGet(args: SecureTryonTryonIdImageGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon/{tryon_id}/image";
        url = url.split(["{", "}"].join("tryon_id")).join(encodeURIComponent(String(tryon_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    secureTryonTryonIdImageUrlGet(args: SecureTryonTryonIdImageUrlGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon/{tryon_id}/image-url";
        url = url.split(["{", "}"].join("tryon_id")).join(encodeURIComponent(String(tryon_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    secureTryonTryonIdSyncGet(args: SecureTryonTryonIdSyncGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { tryon_id } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/secure/tryon/{tryon_id}/sync";
        url = url.split(["{", "}"].join("tryon_id")).join(encodeURIComponent(String(tryon_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    shareInfoUidGet(args: ShareInfoUidGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/share/info/{uid}";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    shareProductUidjpgGet(args: ShareProductUidjpgGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/share/product/{uid}.jpg";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    shareTryonUidjpgGet(args: ShareTryonUidjpgGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { uid, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/share/tryon/{uid}.jpg";
        url = url.split(["{", "}"].join("uid")).join(encodeURIComponent(String(uid)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }

    statusGet(options: MethodOptions | any = {}): Promise<any> {
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/status";
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            if (options.returnResponse) {
                promise.then((response) => resolve(response as any));
            } else {
                promise
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            return response.json();
                        } else {
                            reject(response);
                        }
                    })
                    .then((data) => resolve(data));
            }
            promise.catch((error) => reject(error));
        });
    }

    tryonGenericProductImageIdModelGet(args: TryonGenericProductImageIdModelGetArgs, options: MethodOptions | any = {}): Promise<any> {
        const { product_image_id, name } = args;
        const { fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler } = this.config;
        let url = "/tryon/generic/{product_image_id}/{model}";
        url = url.split(["{", "}"].join("product_image_id")).join(encodeURIComponent(String(product_image_id)));
        const params = new URLSearchParams();
        setAdditionalParams(params, options.params);
        if (name !== undefined) {
            params.append("name", name as any);
        }
        const query = params.toString();
        return new Promise((resolve, reject) => {
            const promise = fetchMethod(basePath + url + (query ? "?" + query : ""), {
                method: "get",
                headers: { ...headers, ...getHeaders(), ...options.headers },
            });
            !!responseHandler && promise.then(responseHandler);
            !!errorHandler && promise.catch(errorHandler);
            promise.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            promise.catch((error) => reject(error));
        });
    }
}
