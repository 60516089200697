import { useCallback, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useNavigate } from "react-router";
import { SortOptions } from "@v2/components/filter/sort";
import { SecureProductModesensCatalogsGetArgs } from "../klothed-api";

export interface FilterSortContext {
    // When you add anything here, add it to tileContext to disable auto tryons when active.
    loading: boolean;
    minPrice?: number;
    maxPrice?: number;
    brandsSelected?: string[];
    sortMethod?: SortOptions;
    filterSortEnabled?: boolean;
    filterSortParams?: SecureProductModesensCatalogsGetArgs;
    setFilterSort: (minPrice?: number, maxPrice?: number, brands?: string[], sortMethod?: SortOptions) => void;
}

export function FilterSortContextProvider(): FilterSortContext {
    const [loading, setLoading] = useState(true);
    const [minPrice, setMinPrice] = useState<number>();
    const [maxPrice, setMaxPrice] = useState<number>();
    const [brandsSelected, setBrandsSelected] = useState<string[]>();
    const [sortMethod, setSortMethod] = useState<SortOptions>();
    const [filterSortEnabled, setFilterSortEnabled] = useState(false);
    const [params, setParams] = useState<SecureProductModesensCatalogsGetArgs>();

    const history = createBrowserHistory();
    const navigate = useNavigate();

    useEffect(() => {
        setFilterSortEnabled(
            (minPrice && minPrice > 0) || (maxPrice && maxPrice > 0) || (Array.isArray(brandsSelected) && brandsSelected.length > 0) || !!sortMethod
        );
        setParams({
            max_price: maxPrice && maxPrice > 0 ? maxPrice : undefined,
            min_price: minPrice && minPrice > 0 ? minPrice : undefined,
            brands: Array.isArray(brandsSelected) ? brandsSelected : undefined,
            sort: sortMethod ? SortOptions[sortMethod] : undefined,
        });
    }, [setFilterSortEnabled, setParams, minPrice, maxPrice, brandsSelected, sortMethod]);

    const setFilterSort = useCallback(
        (newMinPrice?: number, newMaxPrice?: number, newBrands?: string[], newSortMethod?: SortOptions) => {
            const params = new URLSearchParams();
            if (newMinPrice && newMinPrice > 0) {
                params.append("min_price", newMinPrice.toString());
            }
            if (newMaxPrice && newMaxPrice > 0 && newMaxPrice !== 5000) {
                params.append("max_price", newMaxPrice.toString());
            }
            if (Array.isArray(newBrands) && newBrands.length > 0) {
                params.append("brands", newBrands.join(","));
            }
            if (newSortMethod === SortOptions.priceAsc || newSortMethod === SortOptions.priceDesc) {
                params.append("sort", SortOptions[newSortMethod]);
            }
            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            });
            setMinPrice(newMinPrice);
            setMaxPrice(newMaxPrice);
            setBrandsSelected(newBrands);
            setSortMethod(newSortMethod);
            navigate(0);
        },
        [setMinPrice, setMaxPrice, setBrandsSelected, setSortMethod, history, navigate]
    );

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const minPrice = Number(params.get("min_price"));
        const maxPrice = Number(params.get("max_price"));
        const brands = params.get("brands")?.split(",");
        const sortParam = params.get("sort");
        const sortMethod = sortParam ? SortOptions[sortParam as keyof typeof SortOptions] : SortOptions.default;
        setMinPrice(minPrice > 0 ? minPrice : undefined);
        setMaxPrice(maxPrice > 0 ? maxPrice : undefined);
        setBrandsSelected(Array.isArray(brands) && brands.length > 0 ? brands : undefined);
        setSortMethod(sortMethod);
        setLoading(false);
    }, [setMinPrice, setMaxPrice, setLoading, setBrandsSelected, setSortMethod]);

    useEffect(() => {
        if (loading) {
            return;
        }
        const params = new URLSearchParams();
        if (minPrice) {
            params.append("min_price", minPrice.toString());
        }
        if (maxPrice) {
            params.append("max_price", maxPrice.toString());
        }
        if (brandsSelected) {
            params.append("brands", brandsSelected.join(","));
        }
        if (sortMethod) {
            params.append("sort", SortOptions[sortMethod]);
        }
        history.push({ search: "?" + params });
    }, [minPrice, maxPrice, brandsSelected, sortMethod, loading]);

    return {
        loading,
        setFilterSort,
        maxPrice,
        minPrice,
        brandsSelected,
        sortMethod,
        filterSortEnabled,
        filterSortParams: params,
    };
}
