import { useSiteSession, useTileSession } from "@v2/utils";
import React from "react";
import { Menu, Item } from "react-contexify";

export const TileDetailsMenuID = "tile_details_menu";
export function TileDetailsMenu() {
    const { isKlothed } = useSiteSession();
    const { context } = useTileSession();
    const { reportTryon, setHidden, deleteTryon } = context;

    return (
        <Menu id={TileDetailsMenuID} theme="tile-menu">
            <Item onClick={deleteTryon}>Delete try-on</Item>
            <Item onClick={reportTryon}>Report item</Item>
            {isKlothed && <Item onClick={() => setHidden(true)}>Hide tile</Item>}
        </Menu>
    );
}
