import React from "react";
import { Icon } from "@iconify/react";

export function Socials() {
    return (
        <div className="socials">
            <a href={process.env.REACT_APP_WEB_TWITTER} target="_blank" rel="noreferrer">
                <Icon icon="ri:twitter-line" />
            </a>
            <a href={process.env.REACT_APP_WEB_INSTAGRAM} target="_blank" rel="noreferrer">
                <Icon icon="ri:instagram-line" />
            </a>
            <a href={process.env.REACT_APP_WEB_TIKTOK} target="_blank" rel="noreferrer">
                <Icon icon="ri:tiktok-line" />
            </a>
            <a href={process.env.REACT_APP_WEB_LINKEDIN} target="_blank" rel="noreferrer">
                <Icon icon="ri:linkedin-line" />
            </a>
            <a href={process.env.REACT_APP_WEB_YOUTUBE} target="_blank" rel="noreferrer">
                <Icon icon="ri:youtube-line" />
            </a>
        </div>
    );
}
