import React, { Dispatch, SetStateAction, useCallback } from "react";
import Select, { SingleValue } from "react-select";

export enum SortOptions {
    default,
    priceAsc,
    priceDesc,
}

const SortSelectMap = [
    { value: SortOptions.default, label: "Recent" },
    { value: SortOptions.priceAsc, label: "Lowest Price" },
    { value: SortOptions.priceDesc, label: "Highest Price" },
];

export const FilterSortComponent = ({
    filterSortMethod,
    setFilterSortMethod,
}: {
    filterSortMethod: SortOptions;
    setFilterSortMethod: Dispatch<SetStateAction<SortOptions>>;
}) => {
    const onChange = useCallback(
        (s: SingleValue<{ value: SortOptions; label: string }>) => {
            if (s && s.value) {
                setFilterSortMethod(s.value);
            } else {
                setFilterSortMethod(SortOptions.default);
            }
        },
        [setFilterSortMethod]
    );
    return (
        <Select
            name="sortMethods"
            options={SortSelectMap}
            onChange={onChange}
            defaultValue={SortSelectMap.find((s) => s.value === filterSortMethod)}
            className="basic-multi-select"
            classNamePrefix="select"
        />
    );
};
