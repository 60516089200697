import { MutableRefObject, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AuthContext, AuthContextProvider, AuthState } from "./authContext";
import { EditsContext, EditsContextProvider } from "./editsContext";
import { TryonContext, TryonContextProvider } from "./tryonContext";
import { retailers_Retailer } from "../klothed-api";
import { useLocation } from "react-router-dom";
import { klothedAPI } from "../services";
import { HelmetContext, HelmetContextProvider } from "./helmetContext";
import { ThemeContext, ThemeContextProvider } from "./themeContext";
import { ServerContext, ServerContextProvider } from "./serverContext";
import { FilterSortContext, FilterSortContextProvider } from "./filterSortContext";

export interface SessionContext extends AuthContext, EditsContext, TryonContext, HelmetContext, ThemeContext, ServerContext, FilterSortContext {
    isPopup: MutableRefObject<boolean>;
    retailers?: retailers_Retailer[];
}

export function SessionContextProvider(): SessionContext {
    const isPopup = useRef(false);
    const { pathname } = useLocation();

    useEffect(() => {
        isPopup.current = false;
        // setPageTitle("Klothed - Everything looks better on you.");
        // setPageDescription("Klothed - Everything looks better on you.");
        // setPageImage(PhoneImage);
    }, [pathname]);

    const [retailers, setRetailers] = useState<retailers_Retailer[]>();
    const updateRetailers = useCallback(async () => {
        try {
            setRetailers(await klothedAPI.retailersGet());
        } catch (err) {
            console.error(err);
            setRetailers(undefined);
        }
    }, [setRetailers]);

    useEffect(() => {
        updateRetailers();
    }, [updateRetailers]);

    const authContext = AuthContextProvider();

    const filterSortContext = FilterSortContextProvider();

    const editsContext = EditsContextProvider({
        authState: authContext.authState,
        adam: authContext.adam,
        style: authContext.style,
        filterSortParams: filterSortContext.filterSortParams,
    });
    const tryonContext = TryonContextProvider({ authState: authContext.authState, adam: authContext.adam, token: authContext.token });

    const helmetContext = HelmetContextProvider();
    const themeContext = ThemeContextProvider();
    const serverContext = ServerContextProvider();

    return {
        ...authContext,
        ...tryonContext,
        ...editsContext,
        ...helmetContext,
        ...themeContext,
        ...serverContext,
        ...filterSortContext,
        retailers,
        isPopup,
    };
}

export const SiteSessionContext = createContext<SessionContext>({
    authState: AuthState.Unknown,
} as SessionContext);

export const useSiteSession = () => useContext(SiteSessionContext);

export { AuthState };
