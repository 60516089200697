import React from "react";
import { Link } from "react-router-dom";
import FootLogo from "../../assets/foot-logo.png";
import "./footer.scss";

const Footer = () => (
    <div className="footer_section">
        <Link to={process.env.REACT_APP_WEB_RACKS_HOME || "#"} className="footer_section-logo">
            <img src={FootLogo} width={167} height={26} />
        </Link>
        <p>{"© Klothed Technologies Inc."}</p>
    </div>
);

export default Footer;
