import React from "react";
import { Routes, Route } from "react-router-dom";

import RacksHome from "@v2/pages/home";
import LoginPage from "@v2/pages/login";
import SignUpPage from "@v2/pages/signup";
import VerifyPage from "@v2/pages/verify";
import ProfilePage from "@v2/pages/profile";
import ProfileHeightPage from "@v2/pages/profile/height";
import ProfilePhotoPage from "@v2/pages/profile/photo";
import CookiePage from "@v2/pages/cookie-policy";
import TermsOfService from "@v2/pages/terms-of-service";
import PrivacyPolicy from "@v2/pages/privacy-policy";
import ProfileHeightAndStylePage from "@v2/pages/profile/heightAndStyle";
import EditsHome from "@v2/pages/edits";
import ClosetHome from "@v2/pages/closet";
import FavoritesHome from "@v2/pages/favorites";
import ProfileStylePage from "@v2/pages/profile/style";
import HelpPage from "@v2/pages/help";
import { SiteSessionContext, SessionContextProvider } from "@v2/utils";
import "@v2/styles/style.scss";
import NotFoundHome from "@v2/pages/404";
import AboutUs from "@v2/pages/aboutUs/aboutUs";
import AboutUsConfidence from "@v2/pages/aboutUsConfidence/aboutUsConfidence";
import AboutFun from "@v2/pages/aboutUsFun/aboutUsFun";
import TeamMembers from "@v2/pages/teamMembers/teamMembers";
import SharedTryOnV2 from "@v2/pages/shared-try-on";
import Team from "@v2/pages/team/team";
import HowItWorks from "@v2/pages/howItWorks";
import Retailers from "@v2/pages/retailers";
import AboutFashion from "@v2/pages/aboutFashion";
import { HelmetProvider } from "react-helmet-async";
import ForgotPasswordPage from "@v2/pages/forgot-password";
import Header from "@v2/components/header";
import Footer from "@v2/components/footer";
import { Utility } from "@v2/components/utility";
import ProfileEmailPage from "@v2/pages/profile/email";

export function KlothedV2Routes() {
    return (
        <HelmetProvider>
            <SiteSessionContext.Provider value={SessionContextProvider()}>
                <div className="klothed_page_v2">
                    <Header />
                    <Utility />
                    <Routes>
                        <Route path={process.env.REACT_APP_WEB_FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                        <Route path={process.env.REACT_APP_WEB_HELP} element={<HelpPage />} />
                        <Route path={process.env.REACT_APP_WEB_LOGIN2} element={<LoginPage />} />
                        <Route path={process.env.REACT_APP_WEB_SIGNUP2} element={<SignUpPage />} />
                        <Route path={process.env.REACT_APP_WEB_VERIFY} element={<VerifyPage />} />
                        <Route path={process.env.REACT_APP_WEB_SHOPPER} element={<ProfilePage />} />
                        <Route path={process.env.REACT_APP_WEB_SHOPPER_HEIGHT} element={<ProfileHeightPage />} />
                        <Route path={process.env.REACT_APP_WEB_SHOPPER_EMAIL} element={<ProfileEmailPage />} />
                        <Route path={process.env.REACT_APP_WEB_SHOPPER_STYLE} element={<ProfileStylePage />} />
                        <Route path={process.env.REACT_APP_WEB_SHOPPER_PHOTO} element={<ProfilePhotoPage />} />
                        <Route path={process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE} element={<ProfileHeightAndStylePage />} />
                        <Route path={process.env.REACT_APP_WEB_COOKIE_POLICY} element={<CookiePage />} />
                        <Route path={process.env.REACT_APP_WEB_TERMS_OF_SERVICE} element={<TermsOfService />} />
                        <Route path={process.env.REACT_APP_WEB_PRIVACY} element={<PrivacyPolicy />} />
                        <Route path={`${process.env.REACT_APP_WEB_EDITS_HOME}/:uid`} element={<EditsHome />} />
                        <Route path={process.env.REACT_APP_WEB_CLOSET_HOME} element={<ClosetHome />} />
                        <Route path={process.env.REACT_APP_WEB_FAVORITES_HOME} element={<FavoritesHome />} />
                        <Route path={process.env.REACT_APP_WEB_ABOUT_US} element={<AboutUs />} />
                        <Route path={process.env.REACT_APP_WEB_ABOUT_US_CONFIDENCE} element={<AboutUsConfidence />} />
                        <Route path={process.env.REACT_APP_WEB_ABOUT_US_FUN} element={<AboutFun />} />
                        <Route path={process.env.REACT_APP_WEB_ABOUT_US_FASHION} element={<AboutFashion />} />
                        <Route path={process.env.REACT_APP_WEB_RETAILERS} element={<Retailers />} />
                        <Route path={process.env.REACT_APP_WEB_TEAM_MEMBERS} element={<TeamMembers />} />
                        <Route path={process.env.REACT_APP_WEB_HOW_IT_WORKS} element={<HowItWorks />} />
                        <Route path="/s/*" element={<SharedTryOnV2 />} />
                        <Route path={process.env.REACT_APP_WEB_TEAM} element={<Team />} />
                        <Route path="/" element={<RacksHome />} />
                        <Route path="*" element={<NotFoundHome />} />
                    </Routes>
                    <Footer />
                </div>
            </SiteSessionContext.Provider>
        </HelmetProvider>
    );
}
