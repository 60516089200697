/**
 * External dependencies.
 */
import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

/**
 * Internal dependencies.
 */
import { ReactComponent as Twitter } from "./assets/icon-twitter.svg";
import { ReactComponent as Download } from "./assets/icon-download.svg";

import "./try-share.scss";
import LinkCopy from "../link-copy";

const twitterBody = (link: string, brand?: string, display?: string) => {
    if (!brand && !display) {
        return `I used getKLOTHED™ to try on a product!&hashtags=EverythingLooksBetterOnYou,TryItOn&url=${link}`;
    } else if (!brand) {
        return `I used getKLOTHED™ to try on from ${display}!&hashtags=EverythingLooksBetterOnYou,TryItOn&url=${link}`;
    } else {
        return `I used getKLOTHED™ to try on ${brand} from ${display}!&hashtags=EverythingLooksBetterOnYou,TryItOn&url=${link}`;
    }
};

type Props = {
    image?: string;
    brand?: string;
    display?: string;
};

export const TryShare: React.FC<Props> = ({ image, brand, display }) => {
    return (
        <div className="try-on__share">
            <LinkCopy />
            <a
                href={`https://twitter.com/intent/tweet?text=${twitterBody(window.location.href, brand, display)}`}
                id="share-twitter"
                target="_blank"
                rel="noreferrer"
            >
                <Twitter />
            </a>
            <Tooltip anchorId="share-twitter" content="Twitter" className="tooltip" />
            <a href={image} id="share-download" download>
                <Download />
            </a>
            <Tooltip anchorId="share-download" content="Download image" className="tooltip" />
        </div>
    );
};
