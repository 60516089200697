import React from "react";
import SignUp from "./signup";
import PhoneImage from "@v2/assets/phone-image.webp";

import { LogoSlider } from "@v2/components/burger";

export function SignUpDesktop() {
    return (
        <div className="signup_desktop">
            <div className="artifact_group">
                <img src={PhoneImage} className="image" />
                <h4>Try on styles from your favorite online retailers. It&apos;s free.</h4>
                <div className="brandsSec">
                    <LogoSlider />
                </div>
            </div>
            <div className="signup_group">
                <SignUp />
            </div>
        </div>
    );
}
