/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import "./layout.scss";

type Props = {
    children: React.ReactNode;
    spacing?: boolean;
    className?: string;
};

export const Layout: React.FC<Props> = ({ children, spacing, className }) => (
    <div className={classNames("layout", className, { "layout--spacing": spacing })}>{children}</div>
);
