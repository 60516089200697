import React, { useEffect, useState } from "react";
import User from "@v2/assets/user.webp";
import { AuthState, useSiteSession } from "@v2/utils";
import PasswordKeyIcon from "@v2/assets/password-key-icon";
import ImageCameraIcon from "@v2/assets/image-camera-icon";
import HeightIcon from "@v2/assets/height-icon";
import StyleIcon from "@v2/assets/style-icon";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ShopperImageState } from "@v2/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icon } from "@iconify/react";

function Profile() {
    const {
        heightInFeetAndInches,
        shopperProfileInfo,
        shopperImageInfo,
        authState,
        shopperImageState,
        token,
        style,
        setPageDescription,
        setPageTitle,
        signOut,
    } = useSiteSession();

    const [profileStatusText, setProfileStatusText] = useState<string>();

    useEffect(() => {
        setPageTitle(`Klothed - Everything looks better on you. - Your profile.`);
        setPageDescription(`Klothed - Everything looks better on you. - Your profile.`);
    }, [setPageTitle, setPageDescription]);

    const navigate = useNavigate();

    useEffect(() => {
        switch (authState) {
            case AuthState.Unknown:
                return; // we wait for more info
            case AuthState.NoUserAccount:
                navigate(process.env.REACT_APP_WEB_LOGIN2 || "#");
                return;
            case AuthState.NoKlothedAccount:
                // Create a shopper account
                navigate(process.env.REACT_APP_WEB_SHOPPER_HEIGHT_STYLE || "#");
                return;
            case AuthState.NoVerification:
                navigate(process.env.REACT_APP_WEB_VERIFY || "#");
                return;
            case AuthState.ServerError:
                setProfileStatusText("We are encountering some server issues. If the issue persists, please try again later.");
                return;
            case AuthState.NoAdmittance:
                setProfileStatusText("We are a little overloaded. Please bear with us while we set up our capacity.");
                return;
            case AuthState.NoTryOnProfile:
            case AuthState.TryonProfile:
                break;
        }
        switch (shopperImageState) {
            case ShopperImageState.InReview:
                setProfileStatusText("We’re optimizing our AI for your photo. This will take a moment.");
                break;
            case ShopperImageState.Missing:
                setProfileStatusText("Everything looks better on you. Upload a photo to try on styles.");
                break;
        }
    }, [authState, shopperImageState, navigate, setProfileStatusText]);

    return authState === AuthState.Unknown ? (
        <div>Loading...</div>
    ) : (
        <div className="profile_section_v2">
            <div className="profile_title">Your profile.</div>
            <div className="profile_entry disabled">
                <div className="icon_holder">
                    <img src={User} width={24} height={24} />
                </div>
                <div className="text">{shopperProfileInfo && shopperProfileInfo.displayName}</div>
                <div className="edit">
                    <span>Edit</span>
                    <hr />
                </div>
            </div>
            <Link className="profile_entry" to={process.env.REACT_APP_WEB_SHOPPER_EMAIL || "#"}>
                <div className="icon_holder email_icon_holder">@</div>
                <div className="text">{shopperProfileInfo && shopperProfileInfo.email}</div>
                <div className="edit">
                    <span>Edit</span>
                    <hr />
                </div>
            </Link>
            <Link className="profile_entry" to={process.env.REACT_APP_WEB_FORGOT_PASSWORD || "#"}>
                <div className="icon_holder">
                    <PasswordKeyIcon />
                </div>
                <div className="text">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</div>
                <div className="edit">
                    <span>Edit</span>
                    <hr />
                </div>
            </Link>
            <Link
                className={classNames(
                    "profile_entry",
                    shopperImageState === ShopperImageState.Unknown || shopperImageState === ShopperImageState.InReview
                        ? "disabled"
                        : authState === AuthState.NoTryOnProfile && "missing"
                )}
                to={process.env.REACT_APP_WEB_SHOPPER_PHOTO || "#"}
            >
                <div className="icon_holder">
                    <ImageCameraIcon />
                </div>
                <div className="text">
                    {shopperImageInfo?.lastImageID && (
                        <LazyLoadImage
                            src={`${process.env.REACT_APP_API_URL}/secure/image/${shopperImageInfo.lastImageID}?name=image&token=${token}`}
                            width={"auto"}
                            height={48}
                        />
                    )}
                </div>
                <div className={classNames("edit", shopperImageState === ShopperImageState.InReview && "in-review")}>
                    <span>
                        {shopperImageState === ShopperImageState.InReview
                            ? "Processing"
                            : authState === AuthState.NoTryOnProfile
                            ? "Upload photo"
                            : "Change photo"}
                    </span>
                    <hr />
                </div>
            </Link>
            <Link className="profile_entry" to={process.env.REACT_APP_WEB_SHOPPER_HEIGHT || "#"}>
                <div className="icon_holder">
                    <HeightIcon />
                </div>
                <div className={"text"}>
                    {(heightInFeetAndInches && heightInFeetAndInches.feet) || "?"}
                    <span>ft</span>
                    {heightInFeetAndInches && heightInFeetAndInches.feet ? heightInFeetAndInches.inches : "?"}
                    <span>in</span>
                </div>
                <div className={"edit"}>
                    <span>{heightInFeetAndInches ? "Edit" : "Enter"}</span>
                    <hr />
                </div>
            </Link>
            <Link className="profile_entry" to={process.env.REACT_APP_WEB_SHOPPER_STYLE || "#"}>
                <div className="icon_holder">
                    <StyleIcon />
                </div>
                <div className="text capitalize">{style === "both" ? "Women's + Men's" : style}</div>
                <div className="edit">
                    <span>{heightInFeetAndInches ? "Edit" : "Enter"}</span>
                    <hr />
                </div>
            </Link>
            <Link className="profile_entry" to={"#"} onClick={signOut}>
                <div className="icon_holder">
                    <Icon icon="ri:logout-box-line" />
                </div>
                <div className="edit">
                    <span>Log out</span>
                    <hr />
                </div>
            </Link>
            {profileStatusText && <div className="profile_hints">{profileStatusText}</div>}
        </div>
    );
}

export default Profile;
