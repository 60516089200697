import React, { Fragment } from "react";
import "./uploadPhoto.scss";
import { ReactComponent as Close } from "../assets/close.svg";
import { klothedAPI } from "../../services";
import Steps from "../../components/steps/steps";

interface UploadPhotoProps {
    setPhotoUploaded: () => void;
    imageAlreadyUploaded: boolean;
    className?: string;
    setDecompositionID?: (decompositionID: string) => void;
    decompositionID?: string;
    setDecompositionStatus?: (val: "inactive" | "processing" | "success" | "failure") => void;
    decompositionStatus?: "inactive" | "processing" | "success" | "failure";
}

interface UploadPhotoState {
    step: "one" | "two" | "three" | "four" | "five";
    stepCount: number;
    uploadedImageURL: string;
    imageUploading: "default" | "imageUploading" | "imageUploaded";
    isImageValid: boolean;
    analysingImage: boolean;
    showAllSteps: boolean;
}

class UploadPhoto extends React.Component<UploadPhotoProps, UploadPhotoState> {
    timeout: NodeJS.Timer | undefined;

    constructor(props: UploadPhotoProps) {
        super(props);
        this.state = {
            step: "three",
            stepCount: -1,
            uploadedImageURL: "",
            imageUploading: "default",
            isImageValid: false,
            analysingImage: false,
            showAllSteps: false,
        };
        this.timeout = undefined;
    }

    interval_: NodeJS.Timeout | undefined;

    componentDidMount() {
        if (this.props.imageAlreadyUploaded || this.props.decompositionID) {
            const localImageURL = sessionStorage.getItem("localImageURL");
            if (this.props.decompositionID && localImageURL) {
                this.setState({
                    step: "five",
                    stepCount: 5,
                    uploadedImageURL: `${process.env.REACT_APP_API_URL}/secure/shopper/image?token=${sessionStorage.getItem("accessToken")}`,
                    isImageValid: this.props.decompositionStatus === "success" ? true : false,
                    showAllSteps: true,
                    imageUploading: "imageUploaded",
                    analysingImage: this.props.decompositionStatus === "processing" ? true : false,
                });
                if (this.props.decompositionStatus === "processing") {
                    this.checkDecompositionStatus(this.props.decompositionID);
                }
            } else {
                this.setState({ step: "three", stepCount: 5, showAllSteps: true });
            }
        } else {
            this.timeout = setTimeout(() => {
                this.setState({ showAllSteps: true });
            }, 3000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval_);
        clearTimeout(this.timeout);
    }

    secureDecompositionIdGet = (id: string) => {
        klothedAPI.secureDecompositionDecompositionIdGet({ decomposition_id: Number(id) }).then((response) => {
            if (response.status !== "processing") {
                clearInterval(this.interval_);
                this.props.setDecompositionStatus?.(response.status);
                this.setState({ analysingImage: false, isImageValid: response.status === "success" });
            }
        });
    };

    checkDecompositionStatus = (id: string) => {
        this.secureDecompositionIdGet(id);
        this.interval_ = setInterval(() => this.secureDecompositionIdGet(id), 2000);
    };

    onImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const shopperDetails = sessionStorage.getItem("shopperID");
        const file = event.target.files?.[0];
        if (shopperDetails && file) {
            this.setState({ imageUploading: "imageUploading", step: "four" });
            const imgArrayBuffer = await file.arrayBuffer();
            klothedAPI
                .secureShopperImagePost({ image: imgArrayBuffer as unknown as number[], image_info: JSON.stringify({}), name: file.name })
                .then((value) => {
                    if (event.target.files?.[0] && value) {
                        sessionStorage.setItem("localImageURL", URL.createObjectURL(event.target.files[0]));
                        this.setState(
                            {
                                uploadedImageURL: URL.createObjectURL(event.target.files[0]),
                                imageUploading: "imageUploaded",
                                step: "five",
                                analysingImage: true,
                            },
                            () => {
                                this.props.setDecompositionID?.(value.id);
                                this.props.setDecompositionStatus?.("processing");
                            }
                        );
                        this.interval_ = setInterval(() => this.secureDecompositionIdGet(value.id), 2000);
                    }
                });
        }
    };

    onTryAgainOrClose = () => {
        clearInterval(this.interval_);
        this.props.setDecompositionID?.("");
        this.props.setDecompositionStatus?.("inactive");
        sessionStorage.removeItem("localImageURL");
        this.setState({ step: "three", stepCount: 5, uploadedImageURL: "", imageUploading: "default", isImageValid: false });
    };

    render() {
        return (
            <div className={`upload_photo_section ${this.props.className}`}>
                <div className="upload_photo_left_pane">
                    {this.state.step === "three" && (
                        <div className={`leftPane_three ${this.state.showAllSteps ? "allSteps" : ""}`}>
                            {!this.state.showAllSteps ? (
                                <Fragment>
                                    <div className="poseLikeThis">
                                        {"Here are 5 tips to set you up for success as you upload your photo (you’ll only have to do this once)..."}
                                    </div>
                                    <div className={"instructionSetBar"}>
                                        <div className="instructionSetBarInside"></div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Steps />
                            )}
                        </div>
                    )}
                    {(this.state.step === "four" || this.state.step === "five") && (
                        <div className="leftPane_one">
                            <div className="leftPane_one_heading">
                                {"Everything looks\nbetter "}
                                <span className="leftPane_one_heading leftPane_one_heading_italycs">{"on you."}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="upload_photo_right_pane">
                    {(this.state.step === "three" || this.state.step === "four" || this.state.step === "five") &&
                        this.state.showAllSteps &&
                        this.state.imageUploading !== "imageUploading" && <h2>Upload your photo</h2>}

                    {(this.state.step === "three" || this.state.step === "four") && this.state.showAllSteps && (
                        <div className="imageUploadDiv">
                            {this.state.step === "four" && (
                                <Close className="image_upload_close" onClick={() => this.setState({ step: "three", stepCount: 5 })} />
                            )}
                            <div className="imageUploadText">
                                {this.state.step === "three"
                                    ? "Drag your photo here or"
                                    : this.state.imageUploading === "imageUploading"
                                    ? "Uploading..."
                                    : this.state.imageUploading === "imageUploaded"
                                    ? "Upload successful"
                                    : ""}
                            </div>
                            {this.state.step === "three" && (
                                <div className="imageUploadInputBtn">
                                    {"Browse"}
                                    <input
                                        className="ip__"
                                        type={"file"}
                                        accept={"image/*"}
                                        onChange={(event) => {
                                            this.onImageUpload(event);
                                        }}
                                    ></input>
                                </div>
                            )}
                            {this.state.step === "four" && (
                                <div className="instructionSetBar">
                                    <div className="instructionSetBarInside"></div>
                                </div>
                            )}
                            {this.state.imageUploading === "imageUploading" && <h4>This might take a minute. </h4>}
                        </div>
                    )}
                    {this.state.step === "five" && this.state.imageUploading === "imageUploaded" && (
                        <div className="uploadedImageView">
                            <div className="uploadedImageViewImgDiv">
                                {this.state.isImageValid && <Close className="image_upload_close" onClick={() => this.onTryAgainOrClose()} />}
                                <div className="uploadedImageViewImgDivInside">
                                    <img
                                        className={`uploadedImageViewImg ${!this.state.isImageValid ? "uploadedImageViewImgGreyed" : ""}`}
                                        src={this.state.uploadedImageURL}
                                    ></img>
                                </div>
                                {this.state.analysingImage && (
                                    <>
                                        <div className="imageUploadText">{"Analyzing Image..."}</div>
                                        <div className="instructionSetBar">
                                            <div className="instructionSetBarInside"></div>
                                        </div>
                                    </>
                                )}
                                {!this.state.isImageValid && !this.state.analysingImage && (
                                    <div className="invalidImageText">{"Check out these tips and try\nagain with another photo"}</div>
                                )}
                            </div>

                            <div
                                onClick={() => {
                                    this.state.isImageValid ? this.props.setPhotoUploaded() : this.onTryAgainOrClose();
                                }}
                                className={`moveNextStep ${this.state.analysingImage ? "moveNextStepDisable" : ""}`}
                            >
                                {this.state.analysingImage ? "NEXT" : this.state.isImageValid ? "NEXT" : "TRY AGAIN"}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default UploadPhoto;
