import React from "react";
import { UserProfile } from "@v2/components/profile";

export default function ProfilePage() {
    return (
        <div className="user_profile">
            <UserProfile />
        </div>
    );
}
