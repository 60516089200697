import { decomposition_DecompositionInfo } from "./klothed-api";

export async function decompositionSync(decompositionID: number, token: string) {
    return new Promise<decomposition_DecompositionInfo>((res, rej) => {
        const adamURL = process.env.REACT_APP_API_URL;
        if (!adamURL) {
            rej();
            return;
        }
        const webSocketURL = `ws${adamURL.slice(4)}/secure/decomposition/ws?token=${token}`;
        const socket = new WebSocket(webSocketURL);
        socket.onopen = () => {
            socket.send(String(decompositionID));
        };
        socket.onmessage = (event) => {
            const wsResponse = JSON.parse(event.data) as decomposition_DecompositionInfo[];
            if (wsResponse[0].status === "processing") {
                socket.send(String(wsResponse[0].id));
            } else {
                // finished
                res(wsResponse[0]);
                socket.close(1000, "exhausted requests");
            }
        };
        socket.onclose = (event) => {
            if (!event.wasClean) {
                rej(event);
            }
        };

        socket.onerror = (error) => {
            console.error(error);
        };
    });
}
