/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import { Button } from "@v2/components/burger";

type PropItem = {
    content: React.ReactNode;
    button?: {
        text: string;
        link: string;
        type?: string;
        onClick?: () => void;
    };
    image: string;
    additionalContent?: React.ReactNode;
};

type Props = {
    data: PropItem;
    active: boolean;
    // eslint-disable-next-line
    handleClick: any;
    index: number;
};

export const AutoTabItem: React.FC<Props> = ({ data, active, handleClick, index }) => (
    <div className={classNames("list-item", { "is-active": active })}>
        <div className="list-item__number">
            <button className="list-item__number-button" onClick={() => handleClick(index)}>
                <span>{index + 1}</span>
            </button>
        </div>
        <div className="list-item__content">
            <button onClick={() => handleClick(index)}>{data.content}</button>
            {data.button && (
                <Button onClick={data.button.onClick} href={data.button.link} className="list-item__content-button" type={data.button.type}>
                    {data.button.text}
                </Button>
            )}
        </div>
    </div>
);
