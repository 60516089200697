import MImage1 from "./assets/m-image1.jpg";
import MImage2 from "./assets/m-image2.jpg";
import MImage3 from "./assets/m-image3.jpg";
import MImage4 from "./assets/m-image4.jpg";
import MImage5 from "./assets/m-image5.jpg";
import MImage6 from "./assets/m-image6.jpg";

import WImage1 from "./assets/w-image1.jpg";
import WImage2 from "./assets/w-image2.jpg";
import WImage3 from "./assets/w-image3.jpg";
import WImage4 from "./assets/w-image4.jpg";
import WImage5 from "./assets/w-image5.jpg";
import WImage6 from "./assets/w-image6.jpg";

export type ListProp = {
    text: string;
    image?: string;
};

export type ItemProp = {
    title: string;
    not: boolean;
    list: ListProp[];
};

type StepsDataProp = {
    men: ItemProp[];
    women: ItemProp[];
};

const StepsData: StepsDataProp = {
    men: [
        {
            title: "Pose like this...",
            not: false,
            list: [
                {
                    text: "Toss on form-fitting pants and a light-colored t-shirt, throw up long hair, and stand upright against a plain background.",
                    image: MImage1,
                },
            ],
        },
        {
            title: "Not like this...",
            not: true,
            list: [
                {
                    text: "No selfies. Face the camera as a friend takes your shot.",
                    image: MImage2,
                },
                {
                    text: "No dark clothes.",
                    image: MImage6,
                },
                {
                    text: "No friends. Stand alone in the foreground.",
                    image: MImage3,
                },
                {
                    text: "No shadows. Stand evenly lit.",
                    image: MImage4,
                },
                {
                    text: "No criss-crossing of arms or legs.",
                    image: MImage5,
                },
            ],
        },
    ],
    women: [
        {
            title: "Pose like this...",
            not: false,
            list: [
                {
                    text: "Toss on form-fitting pants and a light-colored t-shirt, throw up long hair, and stand upright against a plain background.",
                    image: WImage1,
                },
            ],
        },
        {
            title: "Not like this...",
            not: true,
            list: [
                {
                    text: "No selfies. Face the camera as a friend takes your shot.",
                    image: WImage2,
                },
                {
                    text: "No dark clothes.",
                    image: WImage6,
                },
                {
                    text: "No friends. Stand alone in the foreground.",
                    image: WImage3,
                },
                {
                    text: "No shadows. Stand evenly lit.",
                    image: WImage4,
                },
                {
                    text: "No criss-crossing of arms or legs.",
                    image: WImage5,
                },
            ],
        },
    ],
};

export default StepsData;
