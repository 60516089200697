import React from "react";
import Verify from "./verify";
import PhoneImage from "@v2/assets/phone-image.webp";

export function VerifyMobile() {
    return (
        <div className="artifact_group">
            <img src={PhoneImage} className="image" />
            <Verify />
        </div>
    );
}
