import React from "react";
import { UserProfileEmail } from "@v2/components/profile/email";

export default function ProfileEmailPage() {
    return (
        <div className="user_profile_email">
            <UserProfileEmail />
        </div>
    );
}
